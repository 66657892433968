import React, { useEffect, useState } from "react";
import Dashboard from "../../components/Pages/Dashboard/Dashboard.js";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, getDocs, collection, where, query, orderBy } from "firebase/firestore";
import MemberNavbar from "../../components/Pages/MemberArea/Navbar/Navbar.js";
import { Helmet } from "react-helmet"

export default function AdminPannel() {
    const queryParameters = new URLSearchParams(window.location.search)
    let page = queryParameters.get("page")

    const [user, setUser] = useState()
    const [certificates, setCertificates] = useState()
    const [userInfo, setUserInfo] = useState()
    const [courses, setCourses] = useState()

    const [userType, setUserType] = useState()

    const [companyMembershipInfo, setCompanyMembershipInfo] = useState()

    useEffect(() => {
        const db = getFirestore();
        getAuth().onAuthStateChanged(async user => {
            if (user) {
                // set user
                setUser(user)

                // get certifications
                var request = new XMLHttpRequest();
                request.open('POST', 'https://api.accredible.com/v1/credentials/search');
                request.setRequestHeader('Content-Type', 'application/json');
                request.setRequestHeader('Authorization', 'Token token=54dc431a507f5f49a059b8b5d1f96427');
                request.onreadystatechange = function () {
                    if (this.readyState === 4) {
                        var credentials_temp = JSON.parse(this.responseText).credentials
                        var cert_list = []
                        credentials_temp.map((cred) => {
                            cert_list.push(cred)
                        })

                        setCertificates(cert_list)
                    } else setCertificates([])
                };
                var body = {
                    'recipient': {
                        'email': user?.email
                    }
                };
                request.send(JSON.stringify(body));

                // get user info
                const docRef = doc(db, "Users", user.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setUserInfo(docSnap.data())

                    if (docSnap.data().idcaRole?.includes("corporate-member-admin")) {
                        setUserType("corporate-member-admin")

                        // get user info
                        const docRefCorp = doc(db, "Corporate Members", docSnap.data().corporateMemberID);
                        const docSnapCorp = await getDoc(docRefCorp);

                        if (docSnapCorp.exists()) {
                            setCompanyMembershipInfo(docSnapCorp.data())
                        }
                    }

                    var all_courses = []
                    var all_event_id = []
                    docSnap.data().courses?.map(i => all_event_id.push(i.eventID))

                    const now = new Date()
                    const lastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);

                    if (all_event_id.length > 0) {
                        const eventRef = collection(db, "Event");
                        var q = query(eventRef, where('startDateTime', '>=', lastWeek.toISOString()), where('_id', 'in', all_event_id.slice(0, 10)), orderBy("startDateTime"));
                        var querySnapshot = await getDocs(q);
                        querySnapshot.forEach((doc) => {
                            all_courses.push(doc.data())
                        });
                        if (all_event_id.length > 10) {
                            q = query(eventRef, where('startDateTime', '>=', lastWeek.toISOString()), where('_id', 'in', all_event_id.slice(10, 18)), orderBy("startDateTime"));
                            querySnapshot = await getDocs(q);
                            querySnapshot.forEach((doc) => {
                                all_courses.push(doc.data())
                            });
                        }
                    }
                    setCourses(all_courses)
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            } else {
                window.location.href = "/login";
            }
        })
    }, [])


    return (
        <div>
            <Helmet>
                <title>Admin Pannel</title>
            </Helmet>
            <link rel="icon" href="/favicon.png" />
            <link rel="apple-touch-icon" href="/idca-logo-with-bg.jpg" />

            <nav>
                {userInfo &&
                    <MemberNavbar user={userInfo} companyMembershipInfo={companyMembershipInfo} page='corporate admin panel' />
                }

            </nav>

            <main>
                {userInfo && certificates && courses ?
                    <Dashboard user={user} certificates={certificates} userInfo={userInfo} courses={courses} companyMembershipInfo={companyMembershipInfo} page={page} />
                    :
                    <div className="payment-course-before-load-spinner-container" style={{ marginTop: '250px', marginBottom: '150px' }}>
                        <div className="payment-course-before-loading-spinner">
                        </div>
                    </div>
                }
            </main>
        </div >
    )
}