import React, { useState, useEffect } from 'react';
import courseJson from '../../../../../components/Pages/All_Courses_Detail.json'
import { MdAccessTime } from "react-icons/md";
import { PiCertificateLight } from "react-icons/pi";
import { IoPricetagsOutline } from "react-icons/io5";

function NextStep({ certificates, courses }) {
    const [nextCourse, setNextCourse] = useState()

    useEffect(() => {
        const coursesTaken = []

        certificates?.map(cert => coursesTaken.push(cert?.group_name?.replace('®', '')))
        courses?.map(course => coursesTaken.push(course?.titleAbbrev))

        if (coursesTaken.includes('DCA')) {
            setNextCourse('Done!')
        } else if (coursesTaken.includes('DCM') && coursesTaken.includes('DCA')) {
            setNextCourse(getCourseJson('DCA'))
        } else if (coursesTaken.includes('DCM')) {
            setNextCourse(getCourseJson('DCES'))
        } else if (coursesTaken.includes('DCE')) {
            setNextCourse(getCourseJson('DCOS'))
        } else if (coursesTaken.includes('DCTP') && coursesTaken.includes('DCES')) {
            setNextCourse(getCourseJson('DCOS'))
        } else if (coursesTaken.includes('DCTP') && coursesTaken.includes('DCOS')) {
            setNextCourse(getCourseJson('DCES'))
        } else if (coursesTaken.includes('DCOM')) {
            setNextCourse(getCourseJson('DCES'))
        } else if (coursesTaken.includes('DCIE')) {
            setNextCourse(getCourseJson('DCOS'))
        } else if (coursesTaken.includes('DCIS')) {
            setNextCourse(getCourseJson('DCOS'))
        } else {
            setNextCourse(getCourseJson('DCIS'))
        }
    }, [certificates, courses])

    function getCourseJson(courseTitleAbbrev) {
        let course;
        courseJson.map(iCourse => {
            if (iCourse.title == courseTitleAbbrev)
                course = iCourse
        })
        return course
    }


    return (
        <div className='user_dashboard_home_next_step_top_div'>
            <div>
                <p className='user_dashboard_home_journey_title' style={{ color: 'white' }}>Your Next Step</p>
            </div>

            {(nextCourse && nextCourse != 'Done!') ?
                <div className='user_dashboard_home_next_step_top_inner_div'>
                    <div>
                        <div className='user_dashboard_home_next_step_name_img_div'>
                            <img src={nextCourse.badge_img_2024} style={{ position: 'relative', width: '80px', height: '90px' }} />
                            <p className='user_dashboard_home_next_step_course_title'>{nextCourse.full_title} ({nextCourse.title})®</p>
                        </div>
                        <p className='user_dashboard_home_next_step_course_description'>{nextCourse.topDescription}</p>
                        <a style={{ textDecoration: 'none' }} href={'https://idc-a.org/register-course-payment?course=' + nextCourse.title}>
                            <p className='user_dashboard_home_next_step_buy'>Book the Course</p>
                        </a>
                    </div>

                    <div className='user_dashboard_home_next_step_divider_vert_div'></div>

                    <div>
                        <div className='user_dashboard_home_next_step_detail_indiv_div'>
                            <div className='user_dashboard_home_next_step_detail_indiv_inner_div'>
                                <MdAccessTime size={20} />
                                <div>
                                    <p>Duration</p>
                                    <p>{nextCourse.duration}</p>
                                </div>
                            </div>
                        </div>
                        <div className='user_dashboard_home_next_step_divider_horiz_div'></div>
                        <div className='user_dashboard_home_next_step_detail_indiv_div'>
                            <div className='user_dashboard_home_next_step_detail_indiv_inner_div'>
                                <PiCertificateLight size={25} />
                                <div>
                                    <p>Certifications</p>
                                    <p>{nextCourse.professionalCertifications} Certifications</p>
                                </div>
                            </div>
                        </div>
                        <div className='user_dashboard_home_next_step_divider_horiz_div'></div>
                        <div className='user_dashboard_home_next_step_detail_indiv_div'>
                            <div className='user_dashboard_home_next_step_detail_indiv_inner_div'>
                                <IoPricetagsOutline size={20} />
                                <div>
                                    <p>Price</p>
                                    <p>{nextCourse.price}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
        </div>
    );
}

export default NextStep
