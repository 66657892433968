import React, { useEffect, useState } from 'react';
import UserPortalCourseDetail from './CourseDetail/CourseDetail';
import UserPortalCourseExam from './Exam/Exam';
import courseJson from '../../../components/Pages/All_Courses_Detail.json'
import UserPortalEmailSignature from './EmailSignature/EmailSignature';
import UserPortalLinkedIn from './LinkedinJoin/LinkedinJoin';
import UserPortalSocial from './Social/Social';
import UserPortalCourseBook from './CourseBook/CourseBook';
import UserPortalCourseBookLarge from './CourseBookLarge/CourseBookLarge';

function UserPortalCourse({ userInfo, course, courseJsonInfo, certificates, inPerson }) {
    const [subCourses, setSubCourses] = useState([])

    function getCourseJson(courseTitleAbbrev) {
        let course;
        courseJson.map(iCourse => {
            if (iCourse.title == courseTitleAbbrev)
                course = iCourse
        })
        return course
    }
    useEffect(() => {
        if (course) {
            const subCoursesTmp = []
            if (course.titleAbbrev == 'DCOM') {
                subCoursesTmp.push(getCourseJson('DCIS'))
                subCoursesTmp.push(getCourseJson('DCOS'))
                setSubCourses(subCoursesTmp)
            } else if (course.titleAbbrev == 'DCIE') {
                subCoursesTmp.push(getCourseJson('DCIS'))
                subCoursesTmp.push(getCourseJson('DCES'))
                setSubCourses(subCoursesTmp)
            } else setSubCourses(null)
        }
    }, [course])

    return (
        <div className='user_dashboard_home_top_div'>
            <div className='user_dashboard_home_main_div'>
                <UserPortalCourseDetail course={course} courseJsonInfo={courseJsonInfo} inPerson={inPerson} />
                {!userInfo?.restricted_access_to_book && subCourses && subCourses.length ? <UserPortalCourseBookLarge courseJsonInfo={subCourses[0]} num={0} /> : null}
                {!userInfo?.restricted_access_to_exam && subCourses && subCourses.length ? <UserPortalCourseExam courseJsonInfo={subCourses[0]} /> : null}
                {!userInfo?.restricted_access_to_book && subCourses && subCourses.length ? <UserPortalCourseBookLarge courseJsonInfo={subCourses[1]} num={1} /> : null}
                {!userInfo?.restricted_access_to_exam && subCourses && subCourses.length ? <UserPortalCourseExam courseJsonInfo={subCourses[1]} /> : null}

                {(!userInfo?.restricted_access_to_book && courseJsonInfo && !subCourses) ? <UserPortalCourseBookLarge courseJsonInfo={courseJsonInfo} /> : null}
                {(!userInfo?.restricted_access_to_exam && courseJsonInfo && !subCourses) ? <UserPortalCourseExam courseJsonInfo={courseJsonInfo} /> : null}
            </div>

            <div className='user_dashboard_home_right_div'>
                {/* <UserPortalCourseBook courseJsonInfo={courseJsonInfo} /> */}
                {(certificates && certificates.length) ? <UserPortalEmailSignature certificates={certificates} /> : null}
                <UserPortalLinkedIn />
                <UserPortalSocial />
            </div>
        </div>
    );
}

export default UserPortalCourse
