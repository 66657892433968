import axios from "axios";


export default async function SuccessfulRegistration({ userEmail, userName, eventData, user_virtual, dcrpTime }) {
    var response = 'fail'

    var eventTime = '9:00am-5:00pm'
    var daysAdd = 2
    var eventDateEnd
    if (eventData.title.includes('OS')) {
        daysAdd = 2
    } else if (eventData.title.includes('RP')) {
        daysAdd = 0
        console.log(dcrpTime)
        eventTime = dcrpTime ? dcrpTime : '10:00am-2:00pm'
    } else if (eventData.title.includes('IS')) {
        daysAdd = 1
    } else if (eventData.title.includes('OM')) {
        daysAdd = 4
    } else if (eventData.title.includes('IE')) {
        daysAdd = 4
    } else if (eventData.title.includes('ES')) {
        daysAdd = 2
    } else if (eventData.title.includes('TP')) {
        daysAdd = 2
    }
    eventDateEnd = new Date(eventData.startDateTime).getTime() + daysAdd * 24 * 60 * 60 * 1000

    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const eventDateStartEnd = (daysAdd > 0 ? 'from ' : 'on ') + month[new Date(eventData.startDateTime).getMonth()] + ' ' + new Date(eventData.startDateTime).getDate() +
        (daysAdd > 0 ? ' to ' + month[new Date(eventDateEnd).getMonth()] + ' ' + new Date(eventDateEnd).getDate() : '') + ', ' + new Date(eventDateEnd).getFullYear()


    if (user_virtual) {
        await axios.post('/event_registered_email_virtual', {
            email: userEmail,
            name: userName,
            eventName: eventData.title,
            eventDate: eventDateStartEnd,
            eventTime: eventTime
        }).then((res) => {
            if (res.data == 'All Done!')
                response = 'Success'
        })
    } else {
        if (eventData.venue && eventData.venue.firstLineAddress)
            await axios.post('/event_registered_email_in_person_venue', {
                email: userEmail,
                name: userName,
                eventName: eventData.title,
                eventDate: eventDateStartEnd,
                eventTime: eventTime,
                venue: eventData.venue,
            }).then((res) => {
                if (res.data == 'All Done!')
                    response = 'Success'
            })
        else
            await axios.post('/event_registered_email_in_person', {
                email: userEmail,
                name: userName,
                eventName: eventData.title,
                eventDate: eventDateStartEnd,
                eventTime: eventTime,
                venue: eventData.venue,
                location: eventData.location
            }).then((res) => {
                if (res.data == 'All Done!')
                    response = 'Success'
            })
    }

    return response
}