
function ListCorporateMemberships({ corporateAccounts }) {
    return (
        <div>
            <div className={'Saira_Medium_Gray member_corp_admin_list_of_members_div'} style={{ padding: '10px 20px', width: '90%' }}>
                <p>Company Name</p>
                <p>Admin Email</p>
                <p>Number of Members</p>
                <p>Number of Licenses</p>
            </div>
            {corporateAccounts.map((account, i) => (
                <a href={'/admin/corporate-member?id=' + account._id} key={i} target="_blank" style={{ textDecoration: 'none' }}>
                    <div key={i} style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center', position: 'relative' }}>
                        <div className={'Saira_Medium_Gray member_corp_admin_list_of_members_div admin_event_list_individual_course_div_' + i % 2} style={{ padding: '10px 20px', width: '90%' }}>
                            <p>{account.companyName}</p>
                            <p>{account.adminEmail}</p>
                            <p>{account?.members?.length ? account?.members.length : 0}</p>
                            <p>{account.numLicenses}</p>
                        </div>
                    </div>
                </a>
            ))}
        </div>
    );
}


export default ListCorporateMemberships

