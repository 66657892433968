import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

function ResetPasswordPage({ url, redirectCourse, redirectEvent }) {

    const [invalidCredentials, setInvalidCredentials] = useState(false)
    const [firstRun, setFirstRun] = useState(true)
    const [emailValue, setEmailValue] = useState('')

    function resetPass() {
        const email = document.getElementById('create_account_form_email_input').value

        let redirectURL = 'login'
        console.log(redirectEvent)
        const event = redirectEvent ? '&event=' + redirectEvent : ''
        if (url && redirectCourse)
            redirectURL = url + '?course=' + redirectCourse + event


        console.log(redirectURL)
        const actionCodeSettings = {
            url: 'http://idc-a.org/' + redirectURL,
            // This must be true.x
            handleCodeInApp: true,
        };

        const auth = getAuth();
        sendPasswordResetEmail(auth, email, actionCodeSettings)
            .then(() => {
                // Password reset email sent!
                // ..
                setInvalidCredentials(false)
                setEmailValue(email)
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorMessage)
                // ..
                setInvalidCredentials(true)
            });
        setFirstRun(false)
    }

    return (
        <div className='all_div_margin' style={{ marginTop: '150px', marginBottom: '100px' }}>
            <center>
                <div>
                    <h1 className='Saira_Medium_Black'>Reset Your Password</h1>
                </div>

                <div className='Saira_Regular_Black'>
                    <div>
                        <div className="login_to_account_email_pass_div" style={{ width: '280px' }}>
                            <p className='Saira_Medium_Red' style={{ display: invalidCredentials ? 'initial' : 'none' }}>Invalid email</p>
                            <p className='Saira_Medium_Red' style={{ display: invalidCredentials || firstRun ? 'none' : 'initial', color: 'green' }}>Password reset link has been sent to {emailValue}</p>
                            <div>
                                <label htmlFor="email" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <p className="data-center-contact-us-form-input-label">Email</p>
                                </label>
                                <input id="create_account_form_email_input"
                                    className="Saira_Medium_Black data-center-contact-us-form-input-box" maxLength="35" name="email" size="20" type="text" required="required" />
                            </div>
                        </div>
                        <div className='login_to_account_login_div'>
                            <button className='login_to_account_login_button Saira_Regular_White' id="data-center-contact-us-form-submit-button" onClick={e => resetPass()}>Send Link</button>
                        </div>
                    </div>
                </div>

            </center>
        </div >
    );
}


export default ResetPasswordPage

