import React, { useEffect, useState } from "react";
import { getFirestore, query, where, collection, getDocs } from "firebase/firestore";
import Indiv_User from "../../components/Pages/IndividualUsers/Indiv_User.js";
import { getAuth } from "firebase/auth";
import { useParams } from 'react-router-dom';
import MemberNavbar from "../../components/Pages/MemberArea/Navbar/Navbar.js";
import { Helmet } from "react-helmet"

export default function AdminUsers() {
    const { user_id } = useParams();

    const [user, setUser] = useState()
    const [userInfo, setUserInfo] = useState()

    useEffect(() => {
        getAuth().onAuthStateChanged(async user => {
            const db = getFirestore();
            const qUser = query(collection(db, "Users"), where("uid", "==", user.uid));
            const querySnapshot = await getDocs(qUser)

            querySnapshot.forEach(async (data) => {
                if (data.data().idcaRole?.includes("admin")) {
                    setUserInfo(data.data())

                    const qUser2 = query(collection(db, "Users"), where("uid", "==", user_id));
                    const querySnapshot2 = await getDocs(qUser2)
                    querySnapshot2.forEach((data2) => {
                        setUser(data2.data())
                    });
                } else {
                    window.location.href = '/'
                }

            })
        })
    }, []);

    return (
        <div>
            <Helmet>
                <title>Admin User</title>
            </Helmet>
            <link rel="icon" href="/favicon.png" />
            <link rel="apple-touch-icon" href="/idca-logo-with-bg.jpg" />

            <nav>
                {userInfo &&
                    <MemberNavbar user={userInfo} page='mileage' />
                }
            </nav>

            <main>
                <Indiv_User user={user} user_id={user_id} />
            </main>
        </div >
    )
}
