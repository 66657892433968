function UserPortalLinkedIn() {

    return (
        <div className='user_portal_right_top_all_div'>
            <div>
                <p className='user_dashboard_home_journey_title'>IDCA LinkedIn Group</p>
            </div>

            <a href={'https://www.linkedin.com/company/international-data-center-authority-idca/'} target={'_blank'} rel="noreferrer" style={{ textDecoration: 'none' }}>
                <div className="user_portal_right_linkedin_div">
                    <div>
                        <p className="user_portal_right_linkedin_join">JOIN</p>
                        <p className="user_portal_right_linkedin_community">THE COMMUNITY</p>
                    </div>
                </div>
            </a>
        </div>
    );
}

export default UserPortalLinkedIn
