import React from "react"
import img from "../resources/idca-logo.png"
import MemberNavbar from "../components/Pages/MemberArea/Navbar/Navbar"
import { Helmet } from "react-helmet"

function Error() {
    return (
        <div>
            <Helmet>
                <title>404</title>
            </Helmet>
            <link rel="icon" href="/favicon.png" />
            <link rel="apple-touch-icon" href="/idca-logo-with-bg.jpg" />

            <nav>
                <MemberNavbar not_signed_in={true} />
            </nav>

            <center style={{ margin: "0", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <img src={img} />
                <h1 className="Saira_Medium_Black">
                    404 Page Not Found
                </h1>
            </center>
        </div>
    )
}

export default Error