
import React from "react";
import ResetPasswordPage from '../../components/Pages/ResetPassword/ResetPassword.js'
import MemberNavbar from "../../components/Pages/MemberArea/Navbar/Navbar.js";
import { Helmet } from "react-helmet"


export default function ResetPassword() {
    const queryParameters = new URLSearchParams(window.location.search)
    let redirectURL = queryParameters.get("redirectURL")
    let redirectCourse = queryParameters.get("course")
    let redirectEvent = queryParameters.get("event")

    return (
        <div>
            <Helmet>
                <title>Reset Password</title>
            </Helmet>
            <meta name="description" content="IDCA portal password reset" />
            <link rel="icon" href="/favicon.png" />
            <link rel="apple-touch-icon" href="/idca-logo-with-bg.jpg" />

            <nav>
                <MemberNavbar not_signed_in={true} />
            </nav>

            <main>
                <ResetPasswordPage url={redirectURL} redirectCourse={redirectCourse} redirectEvent={redirectEvent} />
            </main>
        </div >
    )
}