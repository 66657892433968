import React, { useEffect, useState } from 'react';
import { getFirestore, query, collection, getDocs, doc, updateDoc, where, arrayUnion, getDoc } from "firebase/firestore";
import Modal from 'react-modal';

import ListCorporateMemberships from './Functions/listCorporateMemberships.js'
import ListAllMembers from './Functions/listAllMembers.js';
import AddCorpMembership from './Functions/AddCorpMembership.js';

import { BsCheck2Circle, BsSearch } from 'react-icons/bs'
import axios from 'axios';

function MemberAdminPanel() {
    const db = getFirestore();

    const [allMembers, setAllMembers] = useState([])
    const [corporateAccounts, setCorporateAccounts] = useState([])
    const [showCorporateAccounts, setShowCorporateAccounts] = useState('members')
    const [userSearch, setUserSearch] = useState([])
    const [addMemberModule, setAddMemberModule] = useState(false)
    const [createNewUser, setCreateNewUser] = useState(false)
    const [selectedUserID, setSelectedUserID] = useState()
    const [done, setDone] = useState(false)

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [middleName, setMiddleName] = useState("")

    const [invalidCredentials, setInvalidCredentials] = useState(false)
    const [createMemberClicked, setCreateMemberClicked] = useState(false)
    const [userExists, setUserExists] = useState(false)

    const [showCorpMemModal, setShowCorpMemModal] = useState(false)

    const [noUserFound, setNoUserFound] = useState(false)

    const [name_des, setName_des] = useState(false)
    const [date_des, setDate_des] = useState(false)
    const [corp_mem_des, setCorp_mem_des] = useState(false)
    const [mileage_des, setMileage_des] = useState(false)

    const customStyles = {
        content: {
            marginTop: '40px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderWidth: '0px',
            backgroundColor: 'white',
            boxShadow: '0px 4px 27px rgba(0, 0, 0, 0.25)',
            borderRadius: '20px',
            padding: '40px 60px',
            width: '500px',
            height: '500px'
        },
    };

    useEffect(() => {
        function_use_effect()
    }, [])

    async function function_use_effect() {
        const userRef = collection(db, "Users");
        // Create a query against the collection.
        const memberQ = query(userRef, where("idcaRole", "array-contains", "member"));
        const memberSnapshot = await getDocs(memberQ);

        const membersTemp = []
        memberSnapshot.forEach((doc) => {
            membersTemp.push(doc.data())
        });
        membersTemp.sort(function (a, b) {
            var timeA = 0
            var timeB = 0
            if (a.membershipDetail?.dateActivated)
                timeA = new Date(a.membershipDetail?.dateActivated).getTime()
            if (b.membershipDetail?.dateActivated)
                timeB = new Date(b.membershipDetail?.dateActivated).getTime()
            return timeB - timeA

        });
        setAllMembers(membersTemp)


        const corporateRef = collection(db, "Corporate Members");
        // Create a query against the collection.
        const corporateQ = query(corporateRef);
        const corporateSnapshot = await getDocs(corporateQ);

        const corporateTemp = []
        corporateSnapshot.forEach((doc) => {
            corporateTemp.push(doc.data())
        });
        setCorporateAccounts(corporateTemp)
    }

    function sortName() {
        allMembers.sort(function (a, b) {
            if (name_des)
                return b.firstName.localeCompare(a.firstName)
            return a.firstName.localeCompare(b.firstName)
        });
        setAllMembers([...allMembers])
        setName_des(!name_des)
        removeAllCheck('name')
    }

    function sortDate() {
        allMembers.sort(function (a, b) {
            var timeA = 0
            var timeB = 0
            if (a.membershipDetail?.dateActivated)
                timeA = new Date(a.membershipDetail?.dateActivated).getTime()
            if (b.membershipDetail?.dateActivated)
                timeB = new Date(b.membershipDetail?.dateActivated).getTime()
            if (date_des)
                return timeB - timeA
            return timeA - timeB

        });
        setAllMembers([...allMembers])
        setDate_des(!date_des)
        removeAllCheck('date')
    }

    function sortCorporateMembers() {
        const corpMem = allMembers.filter(member => member?.membershipDetail?.underCorporateMembership);
        const nonCorpMem = allMembers.filter(member => !member?.membershipDetail?.underCorporateMembership);

        corpMem.sort(function (a, b) {
            return a.firstName.localeCompare(b.firstName)
        })
        nonCorpMem.sort(function (a, b) {
            return a.firstName.localeCompare(b.firstName)
        })
        if (corp_mem_des)
            setAllMembers([...nonCorpMem, ...corpMem])
        else
            setAllMembers([...corpMem, ...nonCorpMem])
        setCorp_mem_des(!corp_mem_des)
        removeAllCheck('corp_mem')
    }

    function sortMileagPoint() {
        allMembers.sort(function (a, b) {
            var mileageA = 0
            var mileageB = 0
            if (a.mileageScore)
                mileageA = a.mileageScore
            if (b.mileageScore)
                mileageB = b.mileageScore
            if (mileage_des)
                return mileageB - mileageA
            return mileageA - mileageB
        });
        setAllMembers([...allMembers])
        setMileage_des(!mileage_des)
        removeAllCheck('mileage')
    }

    function removeAllCheck(dont_remove) {
        if (dont_remove != 'name')
            document.getElementById('all_member_admin_sort_name').checked = false
        if (dont_remove != 'date')
            document.getElementById('all_member_admin_sort_date').checked = false
        if (dont_remove != 'corp_mem')
            document.getElementById('all_member_admin_sort_corp_mem').checked = false
        if (dont_remove != 'mileage')
            document.getElementById('all_member_admin_sort_mileage').checked = false
    }


    async function searchUser() {
        var userValue = document.getElementById('admin_course_list_find_user_input_id').value

        if (!userValue) {
            setUserSearch([])
            setNoUserFound(false)
            return
        }

        const userRef = collection(db, "Users");

        const users = []
        // Create a query against the collection.
        var q = query(userRef, where("email", ">=", userValue), where("email", "<=", userValue + '\uf8ff'));
        var querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => { users.push(doc.data()) });

        q = query(userRef, where("displayName", ">=", userValue), where("displayName", "<=", userValue + '\uf8ff'));
        querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => { if (!arrayContains(doc.data(), users)) users.push(doc.data()) });


        q = query(userRef, where("lastName", ">=", userValue), where("lastName", "<=", userValue + '\uf8ff'));
        querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => { if (!arrayContains(doc.data(), users)) users.push(doc.data()) });

        q = query(userRef, where("uid", ">=", userValue), where("uid", "<=", userValue + '\uf8ff'));
        querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => { if (!arrayContains(doc.data(), users)) users.push(doc.data()) });

        const finalUser = []
        users.map(user => {
            // don't put admin in user list
            if (user.email != 'admin@idc-a.org') finalUser.push(user)
        })

        // No user found
        if (userValue && finalUser.length == 0)
            setNoUserFound(true)
        else
            setNoUserFound(false)

        setUserSearch(finalUser)
    }

    function arrayContains(data, array) {
        var result = false
        array.map(i => { if (i.uid == data.uid) result = true })
        return result
    }

    function userSelected(user) {
        document.getElementById('admin_course_list_find_user_input_id').value = user.displayName
        setSelectedUserID(user.uid)
        setUserSearch([])
    }

    function unselect() {
        document.getElementById('admin_course_list_find_user_input_id').value = ''
        setSelectedUserID()
        setUserSearch([])
    }

    function onMouseEnterDiv(i) {
        document.getElementById('admin_event_list_individual_course_div_id_' + i).style.backgroundColor = 'rgba(183, 35, 35, 0.519)'
    }
    function onMouseLeaveDiv(i) {
        if (i % 2 == 0)
            document.getElementById('admin_event_list_individual_course_div_id_' + i).style.backgroundColor = 'rgba(220, 220, 220, 0.491)'
        else
            document.getElementById('admin_event_list_individual_course_div_id_' + i).style.backgroundColor = 'white'
    }

    function update_name_case(n, num) {
        var result = n.charAt(0).toUpperCase() +
            n.slice(1).toLowerCase();
        if (num == 1) {
            setFirstName(result)

        } else if (num == 2) {
            setLastName(result)
        } else if (num == 3) {
            setMiddleName(result)
        }
    }

    function create_new_user_member_by_member_admin() {
        setCreateMemberClicked(true);

        var email = document.getElementById('create_account_form_email_input_admin_member').value;
        email = email.toLowerCase();

        const company = document.getElementById('create_account_form_company_input_admin_member').value
        const title = document.getElementById('create_account_form_title_input_admin_member').value
        const phone = document.getElementById('create_account_form_phone_input_admin_member').value
        const plan = document.getElementById('create_account_form_plan_input_admin_member').value
        const paymentMethod = document.getElementById('create_account_form_payment_method_input_admin_member').value

        var filter = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        // if any of the fields are incomplete then don't allow the user to be made and sent to firebase
        if (!firstName || !lastName || !email || !plan || !filter.test(email) || !company) {
            setInvalidCredentials(true)
            setCreateMemberClicked(false)
            return;
        }

        axios.post('/create_new_user_member_by_member_admin', {
            firstName: firstName,
            lastName: lastName,
            email: email,
            title: title,
            company: company,
            phone: phone,
            plan: plan,
            paymentMethod: paymentMethod
        }).then((response) => {
            console.log(response)
            if (response.data.status == 200)
                setDone(true)
            else if (response.data.error == 'user already exists')
                setUserExists(true)
        }).catch((error) => {
            alert(error)
        })
        setCreateMemberClicked(false)
    }

    function upgrade_user_to_member() {
        setCreateMemberClicked(true);

        const plan = document.getElementById('create_account_form_plan_input_admin_member').value
        const paymentMethod = document.getElementById('create_account_form_payment_method_input_admin_member').value

        // if any of the fields are incomplete then don't allow the user to be made and sent to firebase
        if (!selectedUserID) {
            setInvalidCredentials(true)
            setCreateMemberClicked(false)
            return;
        }

        axios.post('/upgrade_user_to_member', {
            uid: selectedUserID,
            plan: plan,
            paymentMethod: paymentMethod
        }).then((response) => {
            console.log(response)
            if (response.data.status == 200)
                setDone(true)
            // else if (response.data.error == 'user document not found in Firestore')
        }).catch((error) => {
            alert(error)
        })
        setCreateMemberClicked(false)
    }

    async function searchCompanyName(value) {

        if (!value) {
            const corporateRef = collection(db, "Corporate Members");
            // Create a query against the collection.
            const corporateQ = query(corporateRef);
            const corporateSnapshot = await getDocs(corporateQ);

            const corporateTemp = []
            corporateSnapshot.forEach((doc) => {
                corporateTemp.push(doc.data())
            });
            setCorporateAccounts(corporateTemp)
            return
        }

        const corpRef = collection(db, "Corporate Members");

        const corps = []
        // Create a query against the collection.
        var q = query(corpRef, where("companyName", ">=", value), where("companyName", "<=", value + '\uf8ff'));
        var querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => { corps.push(doc.data()) });

        setCorporateAccounts(corps)
    }

    return (
        <div className="Dashboard_Right_Pannel_whole Saira_Medium_Black PointRequestsAdminPannel">
            <h1>
                All Members
            </h1>


            {showCorporateAccounts == 'members' && (
                <div>
                    <button style={{
                        float: 'right', marginRight: '10%', border: '1px solid gray', fontSize: '16px',
                        padding: '0px 20px', cursor: 'pointer', backgroundColor: 'white'
                    }} onClick={() => setAddMemberModule(true)}>
                        <p className='Saira_Medium_Black'>Add Member</p>
                    </button>

                    <div className='Saira_Medium_Gray' style={{ margin: '50px 0px' }}>
                        <h2>Sort By</h2>
                        <div className='members_admin_panel_sort_members_div'>
                            <div>
                                <input type="checkbox" name="sort_members" value="Name" onClick={() => sortName()} id='all_member_admin_sort_name' />
                                {/* <input type="radio" name="sort_members" value="Name" onClick={() => sortName(true)} /> */}
                                <label htmlFor="Name">Name</label><br />
                            </div>
                            <div>
                                <input type="checkbox" name="sort_members" value="Date Added" defaultChecked={true} onClick={() => sortDate()} id='all_member_admin_sort_date' />
                                {/* <input type="radio" name="sort_members" value="Date Added" onClick={() => sortDate(true)} /> */}
                                <label htmlFor="Date Added">Date</label><br />
                            </div>
                            <div>
                                <input type="checkbox" name="sort_members" value="Corporate Members" onClick={() => sortCorporateMembers()} id='all_member_admin_sort_corp_mem' />
                                {/* <input type="radio" name="sort_members" value="Corporate Members" onClick={() => sortCorporateMembers(true)} /> */}
                                <label htmlFor="Corporate Members">Corporate Members</label>
                            </div>
                            <div>
                                <input type="checkbox" name="sort_members" value="Mileage Points" onClick={() => sortMileagPoint()} id='all_member_admin_sort_mileage' />
                                {/* <input type="radio" name="sort_members" value="Mileage Points" onClick={() => sortMileagPoint(true)} /> */}
                                <label htmlFor="Mileage Points">Mileage Points</label>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {showCorporateAccounts == 'corporate' && (
                <div>
                    <button style={{
                        float: 'right', marginRight: '10%', border: '1px solid gray', fontSize: '16px',
                        padding: '0px 20px', cursor: 'pointer', backgroundColor: 'white'
                    }} onClick={() => setShowCorpMemModal(true)}>
                        <p className='Saira_Medium_Black'>Add Corporate Member</p>
                    </button>

                    <div className='Saira_Medium_Gray' style={{ margin: '50px 0px' }}>
                        <h2>Search:</h2>
                        <div className='members_admin_panel_sort_members_div'>
                            <div>
                                <input className='Saira_Medium_Black members_admin_panel_search_input' onChange={(e) => searchCompanyName(e.target.value)}></input><BsSearch />
                            </div>
                        </div>
                    </div>
                    {showCorpMemModal && (<AddCorpMembership openStatus={true} />)}
                </div>
            )}

            <div style={{ marginBottom: '30px' }}>
                <button className='Saira_Medium_Black' onClick={() => setShowCorporateAccounts('members')}
                    style={{ backgroundColor: 'white', border: '0px', marginRight: '20px', borderBottom: showCorporateAccounts == 'members' ? '2px solid gray' : '0px', fontSize: '18px', cursor: 'pointer' }}>
                    All Members
                </button>
                <button className='Saira_Medium_Black' onClick={() => setShowCorporateAccounts('corporate')}
                    style={{ backgroundColor: 'white', border: '0px', marginRight: '20px', borderBottom: showCorporateAccounts == 'corporate' ? '2px solid gray' : '0px', fontSize: '18px', cursor: 'pointer' }}>
                    Corporate Accounts
                </button>
                <button className='Saira_Medium_Black' onClick={() => setShowCorporateAccounts('expired')}
                    style={{ backgroundColor: 'white', border: '0px', borderBottom: showCorporateAccounts == 'expired' ? '2px solid gray' : '0px', fontSize: '18px', cursor: 'pointer' }}>
                    Expired Memberships
                </button>
            </div>

            <div>
                {showCorporateAccounts == 'members' ? (
                    <ListAllMembers allMembers={allMembers} />
                ) : showCorporateAccounts == 'corporate' ? (
                    <ListCorporateMemberships corporateAccounts={corporateAccounts} />
                ) : (
                    <div>

                    </div>
                )}
            </div>

            <Modal
                isOpen={addMemberModule}
                onRequestClose={() => {
                    setAddMemberModule(false); setDone(false); setCreateNewUser(false)
                }}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div>
                    {done ? (
                        <div style={{ textAlign: 'center', color: 'green', padding: '50px' }}>
                            <div>
                                <BsCheck2Circle size={60} />
                            </div>
                            <h2 className='Saira_Bold_Black'>All Done!</h2>
                        </div>
                    ) : (createNewUser ? (
                        <div>
                            <div className='Saira_Medium_Black'>
                                <p style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setCreateNewUser(false)}>{"< back"}</p>

                                {invalidCredentials && (<p style={{ color: '#AD2225' }}>Invalid/Incomplete credentials</p>)}

                                <div className="login_landing_page_data_fields_two_in_row_div">
                                    <div>
                                        <label htmlFor="first_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <p className="data-center-contact-us-form-input-label">First Name</p>
                                        </label>
                                        <input className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                            maxLength="40" name="first_name" size="20" type="text" required="required" value={firstName} onChange={e => update_name_case(e.target.value, 1)} />
                                        <p className='Saira_Regular_Red' style={{ margin: '0px', fontSize: '12px' }}>required</p>
                                    </div>
                                    <div>
                                        <label htmlFor="last_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <p className="data-center-contact-us-form-input-label">Last Name</p>
                                        </label>
                                        <input className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                            maxLength="80" name="last_name" size="20" type="text" required="required" value={lastName} onChange={e => update_name_case(e.target.value, 2)} />
                                        <p className='Saira_Regular_Red' style={{ margin: '0px', fontSize: '12px' }}>required</p>
                                    </div>
                                </div>

                                <div className="login_landing_page_data_fields_two_in_row_div">
                                    <div>
                                        <label htmlFor="email" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <p className="data-center-contact-us-form-input-label">Email</p>
                                        </label>
                                        <input id="create_account_form_email_input_admin_member"
                                            className="Saira_Medium_Black data-center-contact-us-form-input-box" maxLength="80" name="email" size="20" type="email" required="required" />
                                        <p className='Saira_Regular_Red' style={{ margin: '0px', fontSize: '12px' }}>required</p>
                                    </div>

                                    <div>
                                        <label htmlFor="phone" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <p className="data-center-contact-us-form-input-label">Phone</p>
                                        </label>
                                        <input id="create_account_form_phone_input_admin_member"
                                            className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                            maxLength="20" name="phone" size="20" type="tel" />
                                    </div>
                                </div>


                                <div className="login_landing_page_data_fields_two_in_row_div">
                                    <div>
                                        <label htmlFor="email" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <p className="data-center-contact-us-form-input-label">Company</p>
                                        </label>
                                        <input id='create_account_form_company_input_admin_member'
                                            className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                            maxLength="80" name="middle_name" size="20" type="text" />
                                        <p className='Saira_Regular_Red' style={{ margin: '0px', fontSize: '12px' }}>required</p>
                                    </div>

                                    <div>
                                        <label htmlFor="title"><p className="data-center-contact-us-form-input-label">Title</p></label>
                                        <input id="create_account_form_title_input_admin_member"
                                            className="Saira_Medium_Black data-center-contact-us-form-input-box" maxLength="200" name="title" size="20" type="text" />

                                    </div>
                                </div>


                                <div className="login_landing_page_data_fields_two_in_row_div" style={{ marginTop: '30px' }}>
                                    <div>
                                        <label htmlFor="membership_plan_select_options" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>Plan</label>

                                        <select id="create_account_form_plan_input_admin_member" style={{ width: '190px' }} className="Saira_Medium_Black data-center-contact-us-form-input-box">
                                            <option value="1 Year" selected	>1 Year</option>
                                            <option value="3 Year">3 Year</option>
                                            <option value="5 Year">5 Year</option>
                                            <option value="IDCA Unlimited">IDCA Unlimited</option>
                                        </select>
                                        <p className='Saira_Regular_Red' style={{ margin: '0px', fontSize: '12px' }}>required</p>
                                    </div>

                                    <div >
                                        <label htmlFor="membership_plan_select_options" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>Payment Method</label>

                                        <select id="create_account_form_payment_method_input_admin_member" style={{ width: '190px' }} className="Saira_Medium_Black data-center-contact-us-form-input-box">
                                            <option value="Check" selected	>Check</option>
                                            <option value="Online Invoice">Online Invoice</option>
                                            <option value="IDCA Credit (free)">IDCA Credit (free)</option>
                                        </select>
                                        <p className='Saira_Regular_Red' style={{ margin: '0px', fontSize: '12px' }}>required</p>
                                    </div>
                                </div>


                            </div>

                            <div style={{ textAlign: 'center', margin: '30px' }}>
                                <p onClick={() => create_new_user_member_by_member_admin()} className='Saira_Medium_White'
                                    style={{ padding: '20px', backgroundColor: '#AD2225', cursor: !createMemberClicked && 'pointer' }}>
                                    Add
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className='Saira_Medium_Gray'>
                                <h3>Search For Existing User:</h3>
                                <div style={{ marginTop: '30px' }}>
                                    <div>
                                        <div style={{ display: 'flex', gap: '5px' }}>
                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                <input id='admin_course_list_find_user_input_id' onChange={() => searchUser()}
                                                    style={{ width: '175px', height: '30px' }}
                                                    className='Saira_Medium_Gray' />
                                                <p style={{ cursor: 'pointer', position: 'relative', bottom: '10px' }} onClick={() => unselect()}>X</p>
                                                {noUserFound && (
                                                    <p style={{ position: 'relative', bottom: '10px', marginLeft: '10px' }}>No user found...</p>
                                                )}
                                            </div>
                                        </div>
                                        <p style={{ fontSize: '12px', margin: '0px' }}>search by display name, last name, email</p>

                                        <div style={{ display: userSearch.length ? 'inherit' : 'none', position: 'absolute', background: 'white', padding: '20px', zIndex: '100' }}>
                                            {userSearch.map((user, i) => (
                                                <div key={i}>
                                                    {user?.idcaRole?.includes('member') ? (
                                                        <div key={i} style={{ borderRadius: '20px', padding: '15px 20px', backgroundColor: "rgba(86, 86, 86, 0.365)", color: 'white' }}
                                                            className={'Saira_Medium_Gray'}>
                                                            <div style={{ display: 'flex', gap: '20px', marginBottom: '10px' }}>
                                                                <p style={{ margin: '0px' }}><span style={{ textDecoration: 'underline' }}>Name:</span> {user.displayName}</p>
                                                                <p style={{ margin: '0px', wordBreak: 'break-all' }}><span style={{ textDecoration: 'underline' }}>Email:</span> {user.email}</p>
                                                                <p style={{ margin: '0px', wordBreak: 'break-all' }}><span style={{ textDecoration: 'underline' }}>uid:</span> {user.uid}</p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div key={i} style={{ cursor: 'pointer', borderRadius: '20px', padding: '15px 20px' }} onClick={() => userSelected(user)}
                                                            className={'Saira_Medium_Gray admin_event_list_individual_course_div_' + i % 2}
                                                            id={'admin_event_list_individual_course_div_id_' + i}
                                                            onMouseEnter={() => onMouseEnterDiv(i)} onMouseLeave={() => onMouseLeaveDiv(i)}>
                                                            <div style={{ display: 'flex', gap: '20px', marginBottom: '10px' }}>
                                                                <p style={{ margin: '0px' }}><span style={{ textDecoration: 'underline' }}>Name:</span> {user.displayName}</p>
                                                                <p style={{ margin: '0px', wordBreak: 'break-all' }}><span style={{ textDecoration: 'underline' }}>Email:</span> {user.email}</p>
                                                                <p style={{ margin: '0px', wordBreak: 'break-all' }}><span style={{ textDecoration: 'underline' }}>uid:</span> {user.uid}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="login_landing_page_data_fields_two_in_row_div" style={{ marginTop: '25px' }}>
                                        <div>
                                            <label htmlFor="membership_plan_select_options" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>Plan</label>

                                            <select id="create_account_form_plan_input_admin_member" style={{ width: '190px' }} className="Saira_Medium_Black data-center-contact-us-form-input-box">
                                                <option value="1 Year" selected	>1 Year</option>
                                                <option value="3 Year">3 Year</option>
                                                <option value="5 Year">5 Year</option>
                                                <option value="IDCA Unlimited">IDCA Unlimited</option>
                                            </select>
                                            <p className='Saira_Regular_Red' style={{ margin: '0px', fontSize: '12px' }}>required</p>
                                        </div>

                                        <div >
                                            <label htmlFor="membership_plan_select_options" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>Payment Method</label>

                                            <select id="create_account_form_payment_method_input_admin_member" style={{ width: '190px' }} className="Saira_Medium_Black data-center-contact-us-form-input-box">
                                                <option value="Check" selected	>Check</option>
                                                <option value="Online Invoice">Online Invoice</option>
                                                <option value="IDCA Credit (free)">IDCA Credit (free)</option>
                                            </select>
                                            <p className='Saira_Regular_Red' style={{ margin: '0px', fontSize: '12px' }}>required</p>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ margin: 'auto', textAlign: 'center', width: '150px' }}>
                                    <p onClick={() => upgrade_user_to_member()} className='Saira_Medium_White'
                                        style={{ padding: '20px', backgroundColor: '#AD2225', cursor: 'pointer' }}>
                                        Add
                                    </p>
                                </div>
                            </div>

                            <center style={{ border: '1px solid gray', width: '80%', marginLeft: '10%', marginTop: '40px' }}></center>

                            <center>
                                <button style={{
                                    width: '150px', height: '50px', background: 'white', border: '1px solid gray', fontSize: '16px',
                                    marginTop: '40px', cursor: 'pointer', paddingBottom: '60px'
                                }} onClick={() => setCreateNewUser(true)}>
                                    <p className="Saira_Medium_Black">Create User</p>
                                </button>
                            </center>
                        </div>
                    ))}
                </div>
            </Modal >

        </div >
    );
}


export default MemberAdminPanel

