import React, { useEffect, useState } from "react";
import { getFirestore, query, where, collection, getDocs, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import IntelligenceResourceIndiv from "../../../components/Components/IndivIntelegenceResources/IndivIntelegenceResources.js";
import MemberNavbar from "../../../components/Pages/MemberArea/Navbar/Navbar.js";
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet"

export default function IntelligenceResourcesIndiv() {
    const { IrId } = useParams();

    const [intelligenceResource, setintelligenceResource] = useState(null);
    const [user, setUser] = useState()
    const [userInfo, setUserInfo] = useState()

    useEffect(() => {
        const db = getFirestore();
        getAuth().onAuthStateChanged(async user => {
            if (user) {
                // set user
                setUser(user)
                // get user info
                const docRef = doc(db, "Users", user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setUserInfo(docSnap.data())
                    if (docSnap.data().idcaRole?.includes("membership-admin")
                        || docSnap.data().idcaRole?.includes("sales")
                        || docSnap.data().idcaRole?.includes("admin")
                        || docSnap.data().idcaRole?.includes("member")) {
                        setUserInfo(docSnap.data())
                        const qUser = query(collection(db, "Whitepapers"), where("dateAdded", "==", Number(IrId)));
                        const querySnapshot = await getDocs(qUser)
                        querySnapshot.forEach(async (data) => {
                            setintelligenceResource(data.data())
                        })
                    } else {
                        window.location.href = "/";
                    }
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            } else {
                window.location.href = "/login";
            }
        })

    }, [])

    return (
        <div>
            <Helmet>
                <title>{intelligenceResource ? intelligenceResource.title : ''}</title>
            </Helmet>
            <link rel="icon" href="/favicon.png" />
            <link rel="apple-touch-icon" href="/idca-logo-with-bg.jpg" />


            <nav>
                {userInfo &&
                    <MemberNavbar user={userInfo} page='IntelligenceResources' />
                }
            </nav>

            <main>
                <IntelligenceResourceIndiv intellegenceResource={intelligenceResource} />
            </main>
        </div >
    )
}
