import React, { useEffect, useState } from 'react';
import { getFirestore, doc, updateDoc, arrayUnion, setDoc, getDoc, orderBy, collection, query, getDocs } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import urlFor from '../../imageClientReact.js';
import axios from 'axios';
import { AiFillStar } from 'react-icons/ai'

function MemberMileage({ userProps, userInfoProps }) {
    const [user, setUser] = useState()
    const [userInfo, setUserInfo] = useState(null)
    const [requests, setRequests] = useState([]);
    const [requestsCurrentPage, setrequestsCurrentPage] = useState([]);
    const [requestsPageNum, setrequestsPageNum] = useState(0);

    const [requestsIE, setRequestsIE] = useState([]);
    const [requestsCurrentPageIE, setrequestsCurrentPageIE] = useState([]);
    const [requestsPageNumIE, setrequestsPageNumIE] = useState(0);

    const [mileageRewards, setmileageRewards] = useState([]);
    const db = getFirestore();
    const storage = getStorage();
    const numberFormatter = Intl.NumberFormat('en-US');



    useEffect(() => {
        setUser(userProps)
        setUserInfo(userInfoProps)
        if (userInfoProps?.pointRequests)
            userInfoProps.pointRequests = userInfoProps.pointRequests.reverse()

        console.log(userInfoProps?.pointRequests)

        const pointRequestSocial = userInfoProps?.pointRequests ? userInfoProps.pointRequests.filter(req => !req?.type || req?.type != 'Industry Event') : [];
        const pointRequestIE = userInfoProps?.pointRequests ? userInfoProps.pointRequests.filter(req => req?.type == 'Industry Event') : [];

        setRequests(pointRequestSocial)
        setrequestsCurrentPage(pointRequestSocial.slice(0, 5))

        setRequestsIE(pointRequestIE)
        setrequestsCurrentPageIE(pointRequestIE.slice(0, 5))

        getMileagePointRewards()
        if (userInfoProps?.mileageHistory)
            userInfoProps.mileageHistory = userInfoProps?.mileageHistory?.sort(compare);
    }, [userProps, userInfoProps])

    function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const bandA = a.date;
        const bandB = b.date;

        let comparison = 0;
        if (bandA > bandB) {
            comparison = -1;
        } else if (bandA < bandB) {
            comparison = 1;
        }
        return comparison;
    }

    async function getRequests(industry_event) {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);
        const result = docSnap.data().pointRequests.filter(req => (req?.type == 'Industry Event') == industry_event);
        console.log(result)

        if (industry_event) {
            setRequestsIE(result.reverse())
            setrequestsCurrentPageIE(result.slice(0, 5))
            setrequestsPageNumIE(0)
        } else {
            setRequests(result.reverse())
            setrequestsCurrentPage(result.slice(0, 5))
            setrequestsPageNum(0)
        }
    }

    async function submitFunction() {
        const link = document.getElementById("memberMileageLinkInputID")
        const platform = document.getElementById("memberMileagePlatformInputID")
        // const proof = document.getElementById("memberMileageProofInputID")
        const timeStamp = Date.now()
        if (link.value == "" || platform.value == "") {
            alert("All fields are required!")
        } else {
            const userRef = doc(db, "Users", userInfo.uid);

            const docData = {
                date: timeStamp,
                postLink: link.value,
                platform: platform.value,
                uid: userInfo.uid,
                status: "Pending"
            };
            await updateDoc(userRef, {
                pointRequests: arrayUnion(docData)
            });

            await setDoc(doc(db, "Social Media Posts", `${timeStamp}`), docData)
            getRequests()
            link.value = ""
            // proof.value = ""
            platform.value = []
            alert("Your request have been submitted")
        }
    }

    function requestsPageChange(nextpage) {
        if (nextpage) {
            if (((requestsPageNum + 1) * 5) < requests.length) {
                setrequestsCurrentPage(requests.slice((requestsPageNum + 1) * 5, ((requestsPageNum + 1) * 5) + 5))
                setrequestsPageNum(requestsPageNum + 1)

            }
        } else {
            if (requestsPageNum == 0) {
                return
            } else {
                setrequestsCurrentPage(requests.slice((requestsPageNum - 1) * 5, ((requestsPageNum - 1) * 5) + 5))
                setrequestsPageNum(requestsPageNum - 1)

            }
        }
    }


    function requestsPageChangeIE(nextpage) {
        if (nextpage) {
            if (((requestsPageNumIE + 1) * 5) < requestsIE.length) {
                setrequestsCurrentPageIE(requestsIE.slice((requestsPageNumIE + 1) * 5, ((requestsPageNumIE + 1) * 5) + 5))
                setrequestsPageNumIE(requestsPageNumIE + 1)

            }
        } else {
            if (requestsPageNumIE == 0) {
                return
            } else {
                setrequestsCurrentPageIE(requestsIE.slice((requestsPageNumIE - 1) * 5, ((requestsPageNumIE - 1) * 5) + 5))
                setrequestsPageNumIE(requestsPageNumIE - 1)

            }
        }
    }

    async function claimClicked(points, title, id) {
        const userRef = doc(db, "Users", userInfo.uid);
        const timeStamp = Date.now()
        var error = false

        if (id == '85ee4233-d79a-4eba-bf27-8319943b7f1c' || id == 'ab728a1a-60fb-4879-8df8-36488362ac76')
            axios.post('/getMemberMileageCoupon',
                { discountCodeID: id, discountName: title, member: true, email: userInfo.email, name: userInfo.firstName }
            ).then((response) => {
                console.log(response)
            }).catch((error) => {
                error = true;
                console.log(error)
            })

        if (!error) {
            await updateDoc(userRef, {
                mileageScore: userInfo.mileageScore - points,
                mileageHistory: arrayUnion({
                    date: new Date().toISOString(),
                    points: (-1 * points),
                    pointsName: "Claimed Reward " + title
                }),

            }).then(() => alert("You successfully claimed " + title + "."))

            var awardsClaimed = {
                uid: userInfo.uid,
                award_id: id,
                date: timeStamp,
                claimed: id == "ab728a1a-60fb-4879-8df8-36488362ac76" || id == "85ee4233-d79a-4eba-bf27-8319943b7f1c" ? true : false,
                displayName: userInfo.displayName,
                award_title: title,
                email: userInfo.email,
                phone: userInfo?.phone
            }

            await setDoc(doc(db, "Rewards Claimed", `${timeStamp}`), awardsClaimed)
        }
        window.location.reload();
    }

    async function getMileagePointRewards() {
        const MileageAwardsRef = collection(db, "Mileage Awards");
        const q = query(MileageAwardsRef, orderBy("Points"));
        const querySnapshot = await getDocs(q);
        var temp = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            temp.push(doc.data());
        });
        console.log(temp)
        setmileageRewards(temp)
    }

    async function submitIEFunction() {
        const name = document.getElementById("memberMileageIE_name_InputID")
        const date_temp = document.getElementById("memberMileageIE_date_InputID")
        const proof = document.getElementById("memberMileageIE_proof_InputID")

        if (!name.value || !date_temp.value || !proof?.files[0])
            alert("All fields are required!")
        else {
            const timeStamp = Date.now()

            const userRef = doc(db, "Users", userInfo.uid);
            const fileRef = ref(storage, 'gs://idca-f9873.appspot.com/pointsProofs/' + `${userInfoProps.uid}` + `/${timeStamp}`);
            uploadBytesResumable(fileRef, proof.files[0])
                .then((snapshot) => {
                    // Let's get a download URL for the file.
                    getDownloadURL(snapshot.ref)
                        .then(async (url) => {
                            const docData = {
                                date: timeStamp,
                                name: name.value,
                                eventDate: date_temp.value,
                                type: 'Industry Event',
                                proofLink: url,
                                uid: userInfo.uid,
                                status: "Pending"
                            };
                            await updateDoc(userRef, {
                                pointRequests: arrayUnion(docData)
                            });

                            await setDoc(doc(db, "Social Media Posts", `${timeStamp}`), docData)
                            getRequests()
                            name.value = ""
                            proof.value = ""
                            date_temp.value = null
                            alert("Your request have been submitted")
                        });
                }).catch((error) => {
                    console.error('Upload failed', error);
                });
        }
    }

    return (
        <div className="member_all_div_margin Saira_Medium_Black" style={{ marginTop: '150px' }}>

            <div className='member_setting_top_div'>
                <h2>{"You currently have "}
                    <span className='Saira_Bold_Red'>
                        {userInfo?.mileageScore ? numberFormatter.format(userInfo.mileageScore) : 0}
                    </span>
                    {" points"}
                </h2>
                <div className=''>
                    {
                        mileageRewards.map((reward, index) =>
                            <div key={index}>
                                <div className='mileagepointsRewardsRow'>
                                    <div className='mileagepointsRewardsRowInfo'>
                                        <img src={urlFor(reward.icon).url()} width={100} />
                                        <h4>{reward.title}</h4>
                                        <h4>{numberFormatter.format(reward.Points) + " Points"}</h4>
                                    </div>
                                    {
                                        reward.Points <= (userInfo?.mileageScore ? userInfo.mileageScore : 0) ?
                                            <button className='mileagepointsRewardsRowButton' onClick={() => claimClicked(reward.Points, reward.title, reward._id)}>
                                                Claim
                                            </button>

                                            :
                                            <div className='Saira_Medium_Red'>
                                                {numberFormatter.format(reward.Points - (userInfo?.mileageScore ? userInfo.mileageScore : 0)) + " Points Left"}
                                            </div>
                                    }

                                </div>
                                <hr width="100%" />
                            </div>)
                    }
                </div>
            </div>

            <div className='member_setting_top_div'>
                <h2 className='Saira_Medium_Black"'>
                    Request Social Media Points
                </h2>
                <div style={{ "display": "flex", "justifyContent": "space-between", "marginBottom": "20px" }}>
                    {"Page: " + (requestsPageNum + 1)}

                    <div>
                        <button className='memberMileageBackNextButtons' id='memberMileageBackButton' onClick={() => { requestsPageChange(false) }}>Back</button>
                        <button className='memberMileageBackNextButtons' onClick={() => { requestsPageChange(true) }}>Next</button>
                    </div>
                </div>

                <table className='memberMileageTable'>
                    <tr className='memberMileageTableRow'>
                        <th className='memberMileageTableHeader'>
                            Date
                        </th>
                        <th className='memberMileageTableHeader'>
                            Platform
                        </th>
                        <th className='memberMileageTableHeader'>
                            Post Link
                        </th>
                        <th className='memberMileageTableHeader'>
                            Status
                        </th>
                    </tr>
                    {requestsCurrentPage?.map((individual, i) =>
                        <tr className='memberMileageTableRow' key={i}>
                            <td className='memberMileageTableData '>
                                {new Date(individual.date).toDateString()}
                            </td>
                            <td className='memberMileageTableData '>
                                {individual.platform}
                            </td>
                            <td className='memberMileageTableData'>
                                <a href={individual.postLink} target="_blank" rel="noreferrer">Click here</a>
                            </td>
                            <td className='memberMileageTableData'>
                                {individual.status}
                            </td>
                        </tr>

                    )}
                    {!requestsCurrentPage?.length && (
                        <center>
                            <p style={{ fontSize: '17px', margin: '30px 0px' }}>No points have been requested</p>
                        </center>
                    )}
                </table>
                <hr />
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }} className='Saira_Medium_Black"'>
                    Submit a new request
                </h2>
                <div className='memberMileageRequestPointRow'>

                    <label htmlFor="" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <p className="data-center-contact-us-form-input-label">Link: </p>
                        <AiFillStar style={{ width: '7px', height: '7px', color: 'red' }} />
                        <input className="Saira_Medium_Black" id='memberMileageLinkInputID' />
                    </label>


                    <label htmlFor="" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <p className="data-center-contact-us-form-input-label">Platform: </p>
                        <AiFillStar style={{ width: '7px', height: '7px', color: 'red' }} />
                        <select id="memberMileagePlatformInputID">
                            <option value="Twitter">Twitter</option>
                            <option value="LinkedIn">LinkedIn</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Youtube">Youtube</option>
                        </select>
                    </label>

                    {/* <label>
                        Proof: <input type="file" id='memberMileageProofInputID' className="StandardsDownload_Form_input Saira_Medium_Black"
                            accept="image/*,.pdf" name="image" />
                    </label> */}
                    <label>
                        <input className='FeaturedIntelCardDownloadButton' type={"submit"} onClick={() => submitFunction()} />
                    </label>
                </div>
            </div>


            <div className='member_setting_top_div'>
                <h2 className='Saira_Medium_Black"'>
                    Industry Event Activity Points Request
                </h2>
                <p>If you have attended any industry event, you can request to gain IDCA loyalty points</p>
                <div style={{ "display": "flex", "justifyContent": "space-between", "marginBottom": "20px" }}>
                    {"Page: " + (requestsPageNum + 1)}

                    <div>
                        <button className='memberMileageBackNextButtons' id='memberMileageBackButton' onClick={() => { requestsPageChangeIE(false) }}>Back</button>
                        <button className='memberMileageBackNextButtons' onClick={() => { requestsPageChangeIE(true) }}>Next</button>
                    </div>
                </div>

                <table className='memberMileageTable'>
                    <tr className='memberMileageTableRow'>
                        <th className='memberMileageTableHeader'>
                            Event Name
                        </th>
                        <th className='memberMileageTableHeader'>
                            Event Date
                        </th>
                        <th className='memberMileageTableHeader'>
                            Date Posted
                        </th>
                        <th className='memberMileageTableHeader'>
                            Status
                        </th>
                    </tr>
                    {requestsCurrentPageIE.map((individual, i) =>
                        <tr className='memberMileageTableRow' key={i}>
                            <td className='memberMileageTableData '>
                                {individual.name}
                            </td>
                            <td className='memberMileageTableData '>
                                {new Date(individual.eventDate).toDateString()}
                            </td>
                            <td className='memberMileageTableData'>
                                {new Date(individual.date).toDateString()}
                            </td>
                            <td className='memberMileageTableData'>
                                {individual.status}
                            </td>
                        </tr>

                    )}
                    {!requestsCurrentPageIE.length && (
                        <center>
                            <p style={{ fontSize: '17px', margin: '30px 0px' }}>No points have been requested</p>
                        </center>
                    )}
                </table>
                <hr />
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }} className='Saira_Medium_Black"'>
                    Submit a new request
                </h2>
                <div className='memberMileageRequestPointRow'>

                    <label htmlFor="" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <p className="data-center-contact-us-form-input-label">Name of event: </p>
                        <AiFillStar style={{ width: '7px', height: '7px', color: 'red' }} />
                        <input className="Saira_Medium_Black" id='memberMileageIE_name_InputID' />
                    </label>


                    <label htmlFor="" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <p className="data-center-contact-us-form-input-label">Date: </p>
                        <AiFillStar style={{ width: '7px', height: '7px', color: 'red' }} />
                        <input type={'date'} className="Saira_Medium_Black" id='memberMileageIE_date_InputID' />
                    </label>

                    <label>
                        Proof:
                        <AiFillStar style={{ width: '7px', height: '20px', color: 'red', marginRight: '10px' }} />
                        <input type="file" id='memberMileageIE_proof_InputID' className="StandardsDownload_Form_input Saira_Medium_Black"
                            accept="image/*,.pdf" name="image" />
                    </label>
                    <label>
                        <input className='FeaturedIntelCardDownloadButton' type={"submit"} onClick={() => submitIEFunction()} />
                    </label>
                </div>
            </div>

            <div className='member_setting_top_div'>
                <h2 className='Saira_Medium_Black"'>
                    Points Breakdown
                </h2>

                <table className='memberMileageTable'>
                    <tr className='memberMileageTableRow'>
                        <th className='memberMileageTableHeader'>
                            Date
                        </th>
                        <th className='memberMileageTableHeader'>
                            Points
                        </th>
                        <th className='memberMileageTableHeader'>
                            Reason
                        </th>
                    </tr>
                    {
                        userInfo?.mileageHistory?.reverse().map((individual, i) => (
                            <tr className='memberMileageTableRow' key={i}>
                                <td className='memberMileageTableData '>
                                    {new Date(individual.date).toDateString()}
                                </td>
                                <td className='memberMileageTableData'>
                                    {numberFormatter.format(individual.points)}
                                </td>
                                <td className='memberMileageTableData'>
                                    {individual.pointsName}
                                </td>
                            </tr>

                        ))
                    }
                </table>
            </div>


        </div >
    );
}


export default MemberMileage

