import React, { useState, useEffect } from 'react';
import testImg from '../../../resources/DCISbg.png'
import Setting from '../Setting/Setting.js'
import Mileage from '../Mileage/Mileage.js'
import Home from '../Home/Home.js'
import IDCA_Admin from '../IDCA_Admin/IDCA_Admin';
import AdminEvents from '../AdminEventList/AdminEventList.js'
import WhitePaperAdmin from '../../WhitePaperAdmin/WhitePaperAdmin';
import PointRequests from '../../../Components/PointRequests/PointRequests';
import DashboardAnnouncementAdmin from '../../../Components/DashboardAnnouncementAdmin/DashboardAnnouncementAdmin';

import { getAuth, signOut } from "firebase/auth";

import { AiFillHome, AiFillCalendar, AiFillSetting, AiOutlineLogout, AiOutlineUser, AiOutlinePercentage } from "react-icons/ai"
import { BsNewspaper, BsFillAwardFill, BsFillPencilFill, BsCalendarCheckFill } from "react-icons/bs"
import { GiHouseKeys } from "react-icons/gi"
import { MdAnnouncement, MdOutlineEmojiPeople } from "react-icons/md"
import { HiUserGroup } from 'react-icons/hi'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { FaPeopleCarry } from "react-icons/fa"


import MemberCorpAdmin from '../../MemberCorpAdmin/MemberCorpAdmin';
import IDCA_Reward_Claims from '../../../Components/RewardClaims/RewardClaims';

import { removeCookie } from 'react-use-cookie';
import MemberAdminPanel from '../../../Components/MembersAdminPanel/MembersAdminPanel';
import SharedExperiencesAdmin from '../../../Components/SharedExperiencesAdmin/SharedExperiencesAdmin';
import MembershipEventRequests from '../../../Components/MembershipEventRequests/MembershipEventRequests';

function Dashboard_Left_Pannel({ user, certificates, userInfo, courses, companyMembershipInfo, page }) {
    const [currentMenu, set_current_menu] = useState(page ? page : "Home");
    const [openDashboard, setOpenDashboard] = useState(false)
    const [showDashboardButton, setShowDashboardButton] = useState(false)

    const allButtonNames = ['Home', 'Log_Out', 'Certificates',
        'Courses', 'News', 'Mileage', 'Upcoming_Courses',
        'Settings', 'Admin', 'White Papers', 'Announcement Requests',
        'Announcements', "Points Request", "Sanity", "Member_Users",
        'Reward Claims', 'All_Members', "Shared Experiences", "Membership Event Requests"]

    function logout() {
        if (window.confirm("Are you sure you want to log out?")) {
            const auth = getAuth();
            signOut(auth).then(() => {
                window.location.href = "/login";
                localStorage.removeItem('userInfo');
                removeCookie("member", { httpOnly: false, domain: '.idc-a.org' })
                // Sign-out successful.
            }).catch((error) => {
                // An error happened.
            });
        }
    }

    function clickRed(name) {
        allButtonNames.map(i => {
            if (i == name && document.getElementById('Dashboard_Left_Pannel_Button_' + name))
                document.getElementById('Dashboard_Left_Pannel_Button_' + name).style.color = '#AD2225';
            else if (document.getElementById('Dashboard_Left_Pannel_Button_' + i))
                document.getElementById('Dashboard_Left_Pannel_Button_' + i).style.color = 'black';
        })
        switch (name) {
            case 'Log_Out':
                logout()
                break;
            case 'Sanity':
                set_current_menu('Sanity')
                break;
            case 'Settings':
                window.location.href = `/admin?page=Setting`
                set_current_menu('Setting')
                break;
            case 'Admin':
                window.location.href = `/admin?page=IDCA Admin`
                set_current_menu("IDCA Admin")
                break;
            default:
                window.location.href = `/admin?page=${name}`
                set_current_menu(name)
                break;
        }
    }

    useEffect(() => {
        if (window.innerWidth <= 1050) {
            if (!showDashboardButton)
                setShowDashboardButton(true)
        } else {
            if (showDashboardButton)
                setShowDashboardButton(false)
            if (openDashboard)
                setOpenDashboard(false)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', function () {
            if (window.innerWidth <= 1050) {
                setShowDashboardButton(true)
            } else {
                setShowDashboardButton(false)
                setOpenDashboard(false)
            }
        });
    }, [])

    useEffect(() => {
        if (openDashboard && showDashboardButton) {
            document.getElementById('dashboard_left_pannel_button_and_menu_div_id').style.position = 'fixed'
            document.getElementById('dashboard_left_pannel_button_and_menu_div_id').style.alignItems = 'center'
            document.getElementById('Dashboard_Left_Pannel_whole_id').style.display = 'inherit'
        } else if (showDashboardButton) {
            document.getElementById('Dashboard_Left_Pannel_whole_id').style.display = 'none'
        } else {
            document.getElementById('dashboard_left_pannel_button_and_menu_div_id').style.alignItems = 'start'
            document.getElementById('Dashboard_Left_Pannel_whole_id').style.position = ''
            document.getElementById('dashboard_left_pannel_button_and_menu_div_id').style.position = ''
            document.getElementById('Dashboard_Left_Pannel_whole_id').style.display = 'inherit'

        }
    }, [showDashboardButton, openDashboard])


    return (
        <div className='Dashboard_Whole_Div'>
            <div style={{ display: showDashboardButton && !openDashboard ? 'inherit' : 'none', zIndex: '10', marginTop: '40px', position: 'fixed' }}
                onClick={() => setOpenDashboard(true)} className='dashboard_left_pannel_expond_button_div'>
                <IoIosArrowForward size={30} style={{ color: 'white', margin: 'auto' }} />
            </div>
            <div id='dashboard_left_pannel_button_and_menu_div_id'>
                <div className="Dashboard_Left_Pannel_whole" id='Dashboard_Left_Pannel_whole_id'>
                    <center>
                        <h2 className='Saira_Bold_Black' style={{ lineHeight: '30px', margin: '10px 0px', overflowWrap: 'break-word' }}>{userInfo.firstName + ' ' + userInfo.lastName}</h2>
                        <p className='Saira_Medium_Gray' style={{ lineHeight: '20px', margin: '0px', overflowWrap: 'break-word' }}>{userInfo.email}</p>
                    </center>
                    <center id='dashboard_left_pannel_user_image'>
                        {userInfo.photoURL ?
                            <img src={userInfo.photoURL} alt="User Profile Picture" className='Dashboard_Left_Pannel_Profile_Pic' style={{ width: '150px', height: '150px' }} />
                            : <img src={testImg} alt="User Profile Picture" className='Dashboard_Left_Pannel_Profile_Pic' width={150} height={150} />}
                    </center>

                    {/* <center className='Saira_Medium_Gray' style={{ fontSize: '15px' }}>
                    <p style={{ margin: '0px', lineHeight: '0px' }}>{userInfo.mileageScore ? userInfo.mileageScore : 0}</p>
                    <p style={{ marginTop: '20px', lineHeight: '0px' }}>Mileage Points</p>
                     </center> */}

                    <div className='Dashboard_Left_Pannel_buttons'>
                        <button style={{ color: '#AD2225' }} type="button" id='Dashboard_Left_Pannel_Button_Home' className="Dashboard_Left_Pannel_Button" onClick={e => clickRed('Home')} >
                            <AiFillHome size={20} />
                            <div>
                                Home
                            </div>
                        </button>

                        <a href={"/"} id='Dashboard_Left_Pannel_Button_Sanity' className="Dashboard_Left_Pannel_Button Saira_Regular_LGray"
                            style={{ "textDecoration": "none" }} >
                            <AiOutlineUser size={20} />
                            <div>
                                User Dashboard
                            </div>
                        </a>

                        {userInfo.idcaRole?.includes("admin") || userInfo.idcaRole?.includes('instructor') || userInfo.idcaRole?.includes('membership-admin') || userInfo.idcaRole?.includes('sales') ?
                            <span>
                                <hr color='#AD2225' style={{ "width": "100%" }} />
                                <h4 className='Saira_Bold_Black' style={{ "marginTop": "0px", "marginBottom": "2px" }}>IDCA Admin</h4>
                            </span>
                            : ""
                        }
                        {userInfo.idcaRole?.includes("admin") || userInfo.idcaRole?.includes('instructor') || userInfo.idcaRole?.includes('sales') ? (
                            <button type="button" id='Dashboard_Left_Pannel_Button_Admin' className="Dashboard_Left_Pannel_Button" onClick={e => clickRed('Admin')}>
                                <GiHouseKeys size={20} />
                                <div>
                                    Users
                                </div>
                            </button>) : ""}

                        {userInfo.idcaRole?.includes("admin") || userInfo.idcaRole?.includes('instructor') || userInfo.idcaRole?.includes('sales') ? (
                            <button type="button" id='Dashboard_Left_Pannel_Button_Upcoming_Courses' className="Dashboard_Left_Pannel_Button" onClick={e => clickRed('Upcoming_Courses')}>
                                <AiFillCalendar size={20} />
                                <div>
                                    Events
                                </div>
                            </button>) : ""}

                        {(userInfo.idcaRole?.includes("admin") || userInfo.idcaRole?.includes('membership-admin') || userInfo.idcaRole?.includes('instructor')) ?
                            <a id='Dashboard_Left_Pannel_Button_Sanity' className=" Saira_Regular_LGray Dashboard_Left_Pannel_Button"
                                target="_blank" rel="noreferrer" href={"https://idca-sanity.sanity.studio/desk"}
                                style={{ "textDecoration": "none" }} >
                                <BsFillPencilFill size={20} />
                                <div>
                                    IDCA CMS
                                </div>
                            </a>
                            : ""
                        }

                        {
                            ((userInfo.idcaRole?.includes('corporate-member-admin') || userInfo.idcaRole?.includes('admin')) || userInfo.idcaRole?.includes('membership-admin') || userInfo.idcaRole?.includes('sales')) &&
                            <span>
                                <hr color='#AD2225' style={{ "width": "100%" }} />
                                <h4 className='Saira_Bold_Black' style={{ "marginTop": "0px", "marginBottom": "2px" }}>Membership</h4>
                            </span>

                        }

                        {(userInfo.idcaRole?.includes("admin") || userInfo.idcaRole?.includes('membership-admin')) &&
                            <button type="button" id='Dashboard_Left_Pannel_Button_All_Members' className="Dashboard_Left_Pannel_Button" onClick={e => clickRed('All_Members')}>
                                <MdOutlineEmojiPeople size={20} />
                                <div>
                                    All Members
                                </div>
                            </button>
                        }

                        {(userInfo.idcaRole?.includes("admin") || userInfo.idcaRole?.includes('corporate-member-admin')) && companyMembershipInfo ? (
                            <button type="button" id='Dashboard_Left_Pannel_Button_Member_Users' className="Dashboard_Left_Pannel_Button" onClick={e => clickRed('Member_Users')}>
                                <HiUserGroup size={20} />
                                <div>
                                    Users
                                </div>
                            </button>) : ""}

                        {userInfo.idcaRole?.includes("admin") || userInfo.idcaRole?.includes('membership-admin') ? (
                            <button type="button" id='Dashboard_Left_Pannel_Button_White Papers' className="Dashboard_Left_Pannel_Button" onClick={e => clickRed('White Papers')}>
                                <BsNewspaper size={20} />
                                <div>
                                    White Papers
                                </div>
                            </button>) : ""}

                        {userInfo.idcaRole?.includes("admin") || userInfo.idcaRole?.includes('membership-admin') ? (
                            <button type="button" id='Dashboard_Left_Pannel_Button_Membership Event Requests' className="Dashboard_Left_Pannel_Button" onClick={e => clickRed('Membership Event Requests')}>
                                <BsCalendarCheckFill size={20} />
                                <div>
                                    Event Requests
                                </div>
                            </button>) : ""}

                        {userInfo.idcaRole?.includes("admin") || userInfo.idcaRole?.includes('membership-admin') ? (
                            <button type="button" id='Dashboard_Left_Pannel_Button_Shared Experiences' className="Dashboard_Left_Pannel_Button" onClick={e => clickRed('Shared Experiences')}>
                                <FaPeopleCarry size={20} />
                                <div>
                                    Experiences
                                </div>
                            </button>) : ""}

                        {userInfo.idcaRole?.includes("admin") || userInfo.idcaRole?.includes('membership-admin') ? (
                            <button type="button" id='Dashboard_Left_Pannel_Button_Reward Claims' className="Dashboard_Left_Pannel_Button" onClick={e => clickRed('Reward Claims')}>
                                <AiOutlinePercentage size={20} />
                                <div>
                                    Reward Claims
                                </div>
                            </button>) : ""}

                        {userInfo.idcaRole?.includes("admin") || userInfo.idcaRole?.includes('membership-admin') ? (
                            <button type="button" id='Dashboard_Left_Pannel_Button_Points Request' className="Dashboard_Left_Pannel_Button" onClick={e => clickRed('Points Request')}>
                                <BsFillAwardFill size={20} />
                                <div>
                                    Points Request
                                </div>
                            </button>) : ""}

                        {userInfo.idcaRole?.includes("sales") || userInfo.idcaRole?.includes("admin") || userInfo.idcaRole?.includes('membership-admin') || userInfo.idcaRole?.includes("corporate-member-admin") ? (
                            <button type="button" id='Dashboard_Left_Pannel_Button_Announcements' className="Dashboard_Left_Pannel_Button" onClick={e => clickRed('Announcements')} >
                                <MdAnnouncement size={20} />
                                <div>
                                    Announcements
                                </div>
                            </button>) : ""
                        }

                        <hr color='#AD2225' style={{ "width": "100%" }} />

                        <button id='Dashboard_Left_Pannel_Button_Settings' className="Dashboard_Left_Pannel_Button" onClick={e => clickRed('Settings')} >
                            <AiFillSetting size={20} />
                            <div>
                                Settings
                            </div>
                        </button>

                        <button type="button" id='Dashboard_Left_Pannel_Button_Log_Out' className="Dashboard_Left_Pannel_Button" onClick={e => clickRed('Log_Out')}>
                            <AiOutlineLogout size={20} />
                            <div>
                                Log out
                            </div>
                        </button>
                    </div>
                </div>
                <div style={{ display: openDashboard ? 'inherit' : 'none' }} onClick={() => setOpenDashboard(false)}
                    className='dashboard_left_pannel_expond_button_div'>
                    <IoIosArrowBack size={30} style={{ color: 'white', margin: 'auto' }} />
                </div>
            </div>

            {currentMenu == 'Home' && <Home cert_list={certificates} courses={courses} />}
            {currentMenu == 'IDCA Admin' && <IDCA_Admin />}
            {currentMenu == 'Announcements' && <DashboardAnnouncementAdmin user={user} />}
            {currentMenu == 'Upcoming_Courses' && <AdminEvents />}
            {currentMenu == 'Shared Experiences' && <SharedExperiencesAdmin />}
            {currentMenu == 'Membership Event Requests' && <MembershipEventRequests />}
            {currentMenu == 'All_Members' && <MemberAdminPanel />}
            {currentMenu == 'White Papers' && <WhitePaperAdmin />}
            {currentMenu == 'Reward Claims' && <IDCA_Reward_Claims />}
            {currentMenu == 'Points Request' && <PointRequests />}
            {currentMenu == 'Member_Users' && <MemberCorpAdmin
                user={user}
                userInfo={userInfo}
                companyMembershipInfo={companyMembershipInfo} />}
            {/* {currentMenu == 'Certificates' && <Certificate cert_list={certificates} courses={courses} />} */}
            {currentMenu == 'Setting' && <Setting user={user} />}
            {currentMenu == 'Mileage' && <Mileage user={user} />}

            {/* {currentMenu == 'Calculations' && <Calculations />} */}
        </div >
    );
}

export default Dashboard_Left_Pannel