import React, { useEffect, useState } from 'react';

import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import testImg from '../../../resources/DCISbg.png'

import { MdEdit } from 'react-icons/md'
import { ImCancelCircle } from 'react-icons/im'

function Setting({ user }) {
    const db = getFirestore();

    const storage = getStorage();

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const [uploadStarted, setUploadStarted] = useState(false)
    const [uid, setUID] = useState('')
    const [userInfo, setUserInfo] = useState(null)
    const [initialUserInfo, setInitialUserInfo] = useState(null)

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [country, setCountry] = useState("")

    const [editClicked, setEditClicked] = useState(false)

    useEffect(() => {
        function_use_effect()
    }, [])

    async function function_use_effect() {
        if (user == null)
            window.location.href = "/login";
        else {
            setUID(user.uid)
            const docRef = doc(db, "Users", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setUserInfo(docSnap.data())
                setInitialUserInfo(docSnap.data())
                setFirstName(docSnap.data()?.firstName)
                setLastName(docSnap.data()?.lastName)
                setCountry(docSnap.data()?.country)
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }
    }


    async function handleFileChange(e) {
        const filename = e?.target?.files[0]?.name
        if (!filename)
            return;
        setUploadStarted(true)
        setSubmitButtonDisabled(true)
        const imageRef = ref(storage, 'gs://idca-f9873.appspot.com/User Profiles/' + uid + `/${filename}`);
        uploadBytesResumable(imageRef, e.target.files[0])
            .then((snapshot) => {
                // Let's get a download URL for the file.
                getDownloadURL(snapshot.ref)
                    .then((url) => {
                        setUserInfo({ ...userInfo, photoURL: url })
                        setSubmitButtonDisabled(false)
                        // ...
                    });
            }).catch((error) => {
                console.error('Upload failed', error);
                // ...
            });
    }

    async function saveUserInfo() {
        if (submitButtonDisabled)
            return;
        const company = document.getElementById('dashboard_setting_company_input').value
        const title = document.getElementById('dashboard_setting_title_input').value
        const streetAddress = document.getElementById('dashboard_setting_street_address_input').value
        const city = document.getElementById('dashboard_setting_city_input').value
        const state = document.getElementById('dashboard_setting_state_input').value
        const country = document.getElementById('dashboard_setting_country_input').value
        const zipcode = document.getElementById('dashboard_setting_zip_code_input').value

        const userInfoFinal = {
            ...userInfo,
            firstName: firstName ? firstName : userInfo.firstName,
            lastName: lastName ? lastName : userInfo.lastName,
            company: company,
            title: title,
            streetAddress: streetAddress,
            city: city,
            state: state,
            country: country,
            zipcode: zipcode
        }

        try {
            await setDoc(doc(db, "Users", uid), userInfoFinal)
            window.location.reload();
        } catch (err) {
            console.log(err)
        }
    }


    function update_name_case(n, num) {
        var result = n.charAt(0).toUpperCase() +
            n.slice(1).toLowerCase();
        if (num == 1) {
            setFirstName(result)

        } else {
            setLastName(result)
        }
    }

    function cancelClicked() {
        setEditClicked(false)
        setUserInfo(initialUserInfo)
        setFirstName(initialUserInfo?.firstName)
        setLastName(initialUserInfo?.lastName)
        setCountry(initialUserInfo?.country)

        document.getElementById('dashboard_setting_company_input').value = initialUserInfo?.company
        document.getElementById('dashboard_setting_title_input').value = initialUserInfo?.title
        document.getElementById('dashboard_setting_street_address_input').value = initialUserInfo?.streetAddress
        document.getElementById('dashboard_setting_city_input').value = initialUserInfo?.city
        document.getElementById('dashboard_setting_state_input').value = initialUserInfo?.state
        document.getElementById('dashboard_setting_country_input').value = initialUserInfo?.country
        document.getElementById('dashboard_setting_zip_code_input').value = initialUserInfo?.zipcode
    }


    return (
        <div className="dashboard_setting_top_div Saira_Medium_Black">
            <h1>Profile Setting</h1>
            <div className='dashboard_setting_save_button_div' >
                <button style={{
                    display: editClicked ? 'none' : 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer',
                    width: 'fit-content', backgroundColor: 'transparent', borderWidth: '0px'
                }}
                    onClick={e => setEditClicked(true)}>
                    <p style={{ fontSize: '20px' }} className='Saira_Medium_Black'>Edit</p>
                    <MdEdit size='22' />
                </button>
                <button style={{
                    display: editClicked ? 'flex' : 'none', alignItems: 'center', gap: '10px', cursor: 'pointer',
                    width: 'fit-content', backgroundColor: 'transparent', borderWidth: '0px'
                }}
                    onClick={e => cancelClicked()}>
                    <p style={{ fontSize: '20px' }} className='Saira_Medium_Black'>Cancel</p>
                    <ImCancelCircle size='22' />
                </button>
                <button className='Saira_Regular_White dashboard_setting_save_button' style={{ display: !editClicked ? 'none' : '' }} onClick={e => saveUserInfo()}>Save</button>
            </div>

            <div className='dashboard_setting_fields_to_change_div'>
                <div className='dashboard_setting_fields_image_name_div' >
                    <center>
                        <h5 htmlFor="EmailContactUsFormPage" className="Saira_Medium_DGray StandardsDownload_Form_label_class" >Change Profile Image</h5>
                        <label>
                            <input type="file" id="EmailContactUsFormPage" className="StandardsDownload_Form_input Saira_Medium_Black"
                                disabled={!editClicked ? true : false}
                                accept="image/*" name="image" style={{ display: 'none', fontsize: "14px" }} onInput={(e) => handleFileChange(e)} />
                            <div style={{ borderRadius: '50%', width: 'fit-content', height: 'fit-content', cursor: editClicked ? 'pointer' : 'auto' }}>
                                {userInfo?.photoURL ? <img src={userInfo?.photoURL} alt="User Profile Picture"
                                    className='Dashboard_Left_Pannel_Profile_Pic' style={{ width: '150px', height: '150px', borderRadius: '50%' }} />
                                    : <img src={testImg} alt="User Profile Picture" width='150px' height='150px'
                                        className='Dashboard_Left_Pannel_Profile_Pic ' />}

                            </div>
                        </label>
                        <p className="Saira_Medium_Red">
                            {submitButtonDisabled && uploadStarted ? "Uploading Please wait ..." : ""}
                        </p>
                    </center>

                    <div className='dashboard_setting_fields_name_and_company_info_div'>
                        <div className='dashboard_setting_fields_name_div'>
                            <div>
                                <label htmlFor="first_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <p className="data-center-contact-us-form-input-label">First Name</p>
                                </label>
                                <input className="Saira_Medium_Black dashboard_setting_address_input"
                                    style={{ backgroundColor: !editClicked ? 'rgba(128, 128, 128, 0.148)' : '' }} disabled={!editClicked ? true : false}
                                    maxLength="40" name="first_name" size="20" type="text" value={firstName} onChange={e => update_name_case(e.target.value, 1)} />
                            </div>
                            <div>
                                <label htmlFor="last_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <p className="data-center-contact-us-form-input-label">Last Name</p>
                                </label>
                                <input className="Saira_Medium_Black dashboard_setting_address_input"
                                    style={{ backgroundColor: !editClicked ? 'rgba(128, 128, 128, 0.148)' : '' }} disabled={!editClicked ? true : false}
                                    maxLength="80" name="last_name" size="20" type="text" value={lastName} onChange={e => update_name_case(e.target.value, 2)} />
                            </div>
                        </div>

                        <div className='dashboard_setting_fields_name_div'>
                            <div>
                                <label htmlFor="company"><p className="data-center-contact-us-form-input-label">Company</p></label>
                                <input id='dashboard_setting_company_input'
                                    style={{ backgroundColor: !editClicked ? 'rgba(128, 128, 128, 0.148)' : '' }} disabled={!editClicked ? true : false}
                                    className="Saira_Medium_Black dashboard_setting_address_input" defaultValue={userInfo?.company}
                                    maxLength="40" name="company" size="20" type="text" />
                            </div>

                            <div>
                                <label htmlFor="title"><p className="data-center-contact-us-form-input-label">Title</p></label>
                                <input id='dashboard_setting_title_input'
                                    style={{ backgroundColor: !editClicked ? 'rgba(128, 128, 128, 0.148)' : '' }} disabled={!editClicked ? true : false}
                                    className="Saira_Medium_Black dashboard_setting_address_input" defaultValue={userInfo?.title}
                                    maxLength="40" name="title" size="20" type="text" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className='dashboard_setting_full_address_info_div'>
                    <div className='dashboard_setting_address_state_info_div'>
                        <div>
                            <label htmlFor="first_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <p className="data-center-contact-us-form-input-label">Street Address</p>
                            </label>
                            <input className="Saira_Medium_Black dashboard_setting_address_input" id='dashboard_setting_street_address_input'
                                style={{ backgroundColor: !editClicked ? 'rgba(128, 128, 128, 0.148)' : '' }} disabled={!editClicked ? true : false}
                                maxLength="40" name="first_name" size="20" type="text" defaultValue={userInfo?.streetAddress} />
                        </div>
                        <div>
                            <label htmlFor="last_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <p className="data-center-contact-us-form-input-label">City</p>
                            </label>
                            <input className="Saira_Medium_Black dashboard_setting_address_input" id='dashboard_setting_city_input'
                                style={{ backgroundColor: !editClicked ? 'rgba(128, 128, 128, 0.148)' : '' }} disabled={!editClicked ? true : false}
                                maxLength="80" name="last_name" size="20" type="text" defaultValue={userInfo?.city} />
                        </div>
                    </div>

                    <div className='dashboard_setting_address_state_info_div'>
                        <div>
                            <label htmlFor="title"><p className="data-center-contact-us-form-input-label">Zip Code</p></label>
                            <input id='dashboard_setting_zip_code_input'
                                style={{ backgroundColor: !editClicked ? 'rgba(128, 128, 128, 0.148)' : '' }} disabled={!editClicked ? true : false}
                                className="Saira_Medium_Black dashboard_setting_address_input" defaultValue={userInfo?.zipcode}
                                maxLength="40" name="title" size="20" type="number" />
                        </div>


                        <div>
                            <label htmlFor="company"><p className="data-center-contact-us-form-input-label">State</p></label>
                            <input id='dashboard_setting_state_input'
                                style={{ backgroundColor: !editClicked ? 'rgba(128, 128, 128, 0.148)' : '' }} disabled={!editClicked ? true : false}
                                className="Saira_Medium_Black dashboard_setting_address_input" defaultValue={userInfo?.state}
                                maxLength="40" name="company" size="20" type="text" />
                        </div>
                    </div>


                    <div>
                        <label htmlFor="title"><p className="data-center-contact-us-form-input-label">Country</p></label>

                        <select style={{ backgroundColor: !editClicked ? 'rgba(128, 128, 128, 0.148)' : '', width: '39%' }}
                            className="Saira_Medium_Black data-center-contact-us-form-two-input-box data-center-contact-us-form-input-box"
                            id="dashboard_setting_country_input" name="country" disabled={!editClicked ? true : false} value={country ? country : 'United States'}
                            onChange={e => setCountry(e.target.value)}>
                            <option value='Andorra'>Andorra</option>
                            <option value='United Arab Emirates'>United Arab Emirates</option>
                            <option value='Afghanistan'>Afghanistan</option>
                            <option value='Antigua and Barbuda'>Antigua and Barbuda</option>
                            <option value='Anguilla'>Anguilla</option>
                            <option value='Albania'>Albania</option>
                            <option value='Armenia'>Armenia</option>
                            <option value='Angola'>Angola</option>
                            <option value='Antarctica'>Antarctica</option>
                            <option value='Argentina'>Argentina</option>
                            <option value='Austria'>Austria</option>
                            <option value='Australia*'>Australia*</option>
                            <option value='Aruba'>Aruba</option>
                            <option value='Aland Islands'>Aland Islands</option>
                            <option value='Azerbaijan'>Azerbaijan</option>
                            <option value='Bosnia and Herzegovina'>Bosnia and Herzegovina</option>
                            <option value='Barbados'>Barbados</option>
                            <option value='Bangladesh'>Bangladesh</option>
                            <option value='Belgium'>Belgium</option>
                            <option value='Burkina Faso'>Burkina Faso</option>
                            <option value='Bulgaria'>Bulgaria</option>
                            <option value='Bahrain'>Bahrain</option>
                            <option value='Burundi'>Burundi</option>
                            <option value='Benin'>Benin</option>
                            <option value='Saint Barthélemy'>Saint Barthélemy</option>
                            <option value='Bermuda'>Bermuda</option>
                            <option value='Brunei Darussalam'>Brunei Darussalam</option>
                            <option value='Bolivia, Plurinational State of'>Bolivia, Plurinational State of</option>
                            <option value='Bonaire, Sint Eustatius and Saba'>Bonaire, Sint Eustatius and Saba</option>
                            <option value='Brazil*'>Brazil*</option>
                            <option value='Bahamas'>Bahamas</option>
                            <option value='Bhutan'>Bhutan</option>
                            <option value='Bouvet Island'>Bouvet Island</option>
                            <option value='Botswana'>Botswana</option>
                            <option value='Belarus'>Belarus</option>
                            <option value='Belize'>Belize</option>
                            <option value='Canada'>Canada</option>
                            <option value='Cocos (Keeling) Islands'>Cocos (Keeling) Islands</option>
                            <option value='Congo, the Democratic Republic of the'>Congo, the Democratic Republic of the</option>
                            <option value='Central African Republic'>Central African Republic</option>
                            <option value='Congo'>Congo</option>
                            <option value='Switzerland'>Switzerland</option>
                            <option value='Cote d’Ivoire'>Cote d’Ivoire</option>
                            <option value='Cook Islands'>Cook Islands</option>
                            <option value='Chile'>Chile</option>
                            <option value='Cameroon'>Cameroon</option>
                            <option value='China'>China</option>
                            <option value='Colombia'>Colombia</option>
                            <option value='Costa Rica'>Costa Rica</option>
                            <option value='Cuba'>Cuba</option>
                            <option value='Cape Verde'>Cape Verde</option>
                            <option value='Curaçao'>Curaçao</option>
                            <option value='Christmas Island'>Christmas Island</option>
                            <option value='Cyprus'>Cyprus</option>
                            <option value='Czech Republic'>Czech Republic</option>
                            <option value='Germany'>Germany</option>
                            <option value='Djibouti'>Djibouti</option>
                            <option value='Denmark'>Denmark</option>
                            <option value='Dominica'>Dominica</option>
                            <option value='Dominican Republic'>Dominican Republic</option>
                            <option value='Algeria'>Algeria</option>
                            <option value='Ecuador'>Ecuador</option>
                            <option value='Estonia'>Estonia</option>
                            <option value='Egypt'>Egypt</option>
                            <option value='Western Sahara'>Western Sahara</option>
                            <option value='Eritrea'>Eritrea</option>
                            <option value='Spain'>Spain</option>
                            <option value='Ethiopia'>Ethiopia</option>
                            <option value='Finland'>Finland</option>
                            <option value='Fiji'>Fiji</option>
                            <option value='Falkland Islands (Malvinas)'>Falkland Islands (Malvinas)</option>
                            <option value='Faroe Islands'>Faroe Islands</option>
                            <option value='France'>France</option>
                            <option value='Gabon'>Gabon</option>
                            <option value='United Kingdom'>United Kingdom</option>
                            <option value='Grenada'>Grenada</option>
                            <option value='Georgia'>Georgia</option>
                            <option value='French Guiana'>French Guiana</option>
                            <option value='Guernsey'>Guernsey</option>
                            <option value='Ghana'>Ghana</option>
                            <option value='Gibraltar'>Gibraltar</option>
                            <option value='Greenland'>Greenland</option>
                            <option value='Gambia'>Gambia</option>
                            <option value='Guinea'>Guinea</option>
                            <option value='Guadeloupe'>Guadeloupe</option>
                            <option value='Equatorial Guinea'>Equatorial Guinea</option>
                            <option value='Greece'>Greece</option>
                            <option value='South Georgia and the South Sandwich Islands'>South Georgia and the South Sandwich Islands</option>
                            <option value='Guatemala'>Guatemala</option>
                            <option value='Guinea-Bissau'>Guinea-Bissau</option>
                            <option value='Guyana'>Guyana</option>
                            <option value='Heard Island and McDonald Islands'>Heard Island and McDonald Islands</option>
                            <option value='Honduras'>Honduras</option>
                            <option value='Croatia'>Croatia</option>
                            <option value='Haiti'>Haiti</option>
                            <option value='Hungary'>Hungary</option>
                            <option value='Indonesia'>Indonesia</option>
                            <option value='Ireland'>Ireland</option>
                            <option value='Israel'>Israel</option>
                            <option value='Isle of Man'>Isle of Man</option>
                            <option value='India'>India</option>
                            <option value='British Indian Ocean Territory'>British Indian Ocean Territory</option>
                            <option value='Iraq'>Iraq</option>
                            <option value='Iran, Islamic Republic of'>Iran, Islamic Republic of</option>
                            <option value='Iceland'>Iceland</option>
                            <option value='Italy'>Italy</option>
                            <option value='Jersey'>Jersey</option>
                            <option value='Jamaica'>Jamaica</option>
                            <option value='Jordan'>Jordan</option>
                            <option value='Japan'>Japan</option>
                            <option value='Kenya'>Kenya</option>
                            <option value='Kyrgyzstan'>Kyrgyzstan</option>
                            <option value='Cambodia'>Cambodia</option>
                            <option value='Kiribati'>Kiribati</option>
                            <option value='Comoros'>Comoros</option>
                            <option value='Saint Kitts and Nevis'>Saint Kitts and Nevis</option>
                            <option value='Korea, Democratic People’s Republic of'>Korea, Democratic People’s Republic of</option>
                            <option value='Korea, Republic of'>Korea, Republic of</option>
                            <option value='Kuwait'>Kuwait</option>
                            <option value='Cayman Islands'>Cayman Islands</option>
                            <option value='Kazakhstan'>Kazakhstan</option>
                            <option value='Lao People’s Democratic Republic'>Lao People’s Democratic Republic</option>
                            <option value='Lebanon'>Lebanon</option>
                            <option value='Saint Lucia'>Saint Lucia</option>
                            <option value='Liechtenstein'>Liechtenstein</option>
                            <option value='Sri Lanka'>Sri Lanka</option>
                            <option value='Liberia'>Liberia</option>
                            <option value='Lesotho'>Lesotho</option>
                            <option value='Lithuania'>Lithuania</option>
                            <option value='Luxembourg'>Luxembourg</option>
                            <option value='Latvia'>Latvia</option>
                            <option value='Libya'>Libya</option>
                            <option value='Morocco'>Morocco</option>
                            <option value='Monaco'>Monaco</option>
                            <option value='Moldova, Republic of'>Moldova, Republic of</option>
                            <option value='Montenegro'>Montenegro</option>
                            <option value='Saint Martin (French part)'>Saint Martin (French part)</option>
                            <option value='Madagascar'>Madagascar</option>
                            <option value='Macedonia, the former Yugoslav Republic of'>Macedonia, the former Yugoslav Republic of</option>
                            <option value='Mali'>Mali</option>
                            <option value='Myanmar'>Myanmar</option>
                            <option value='Mongolia'>Mongolia</option>
                            <option value='Macao'>Macao</option>
                            <option value='Martinique'>Martinique</option>
                            <option value='Mauritania'>Mauritania</option>
                            <option value='Montserrat'>Montserrat</option>
                            <option value='Malta'>Malta</option>
                            <option value='Mauritius'>Mauritius</option>
                            <option value='Maldives'>Maldives</option>
                            <option value='Malawi'>Malawi</option>
                            <option value='Mexico'>Mexico</option>
                            <option value='Malaysia'>Malaysia</option>
                            <option value='Mozambique'>Mozambique</option>
                            <option value='Namibia'>Namibia</option>
                            <option value='New Caledonia'>New Caledonia</option>
                            <option value='Niger'>Niger</option>
                            <option value='Norfolk Island'>Norfolk Island</option>
                            <option value='Nigeria'>Nigeria</option>
                            <option value='Nicaragua'>Nicaragua</option>
                            <option value='Netherlands'>Netherlands</option>
                            <option value='Norway'>Norway</option>
                            <option value='Nepal'>Nepal</option>
                            <option value='Nauru'>Nauru</option>
                            <option value='Niue'>Niue</option>
                            <option value='New Zealand'>New Zealand</option>
                            <option value='Oman'>Oman</option>
                            <option value='Panama'>Panama</option>
                            <option value='Peru'>Peru</option>
                            <option value='French Polynesia'>French Polynesia</option>
                            <option value='Papua New Guinea'>Papua New Guinea</option>
                            <option value='Philippines'>Philippines</option>
                            <option value='Pakistan'>Pakistan</option>
                            <option value='Poland'>Poland</option>
                            <option value='Saint Pierre and Miquelon'>Saint Pierre and Miquelon</option>
                            <option value='Pitcairn'>Pitcairn</option>
                            <option value='Palestine'>Palestine</option>
                            <option value='Portugal'>Portugal</option>
                            <option value='Paraguay'>Paraguay</option>
                            <option value='Qatar'>Qatar</option>
                            <option value='Reunion'>Reunion</option>
                            <option value='Romania'>Romania</option>
                            <option value='Serbia'>Serbia</option>
                            <option value='Russian Federation'>Russian Federation</option>
                            <option value='Rwanda'>Rwanda</option>
                            <option value='Saudi Arabia'>Saudi Arabia</option>
                            <option value='Solomon Islands'>Solomon Islands</option>
                            <option value='Seychelles'>Seychelles</option>
                            <option value='Sudan'>Sudan</option>
                            <option value='Sweden'>Sweden</option>
                            <option value='Singapore'>Singapore</option>
                            <option value='Saint Helena, Ascension and Tristan da Cunha'>Saint Helena, Ascension and Tristan da Cunha</option>
                            <option value='Slovenia'>Slovenia</option>
                            <option value='Svalbard and Jan Mayen'>Svalbard and Jan Mayen</option>
                            <option value='Slovakia'>Slovakia</option>
                            <option value='Sierra Leone'>Sierra Leone</option>
                            <option value='San Marino'>San Marino</option>
                            <option value='Senegal'>Senegal</option>
                            <option value='Somalia'>Somalia</option>
                            <option value='Suriname'>Suriname</option>
                            <option value='South Sudan'>South Sudan</option>
                            <option value='Sao Tome and Principe'>Sao Tome and Principe</option>
                            <option value='El Salvador'>El Salvador</option>
                            <option value='Sint Maarten (Dutch part)'>Sint Maarten (Dutch part)</option>
                            <option value='Syrian Arab Republic'>Syrian Arab Republic</option>
                            <option value='Swaziland'>Swaziland</option>
                            <option value='Turks and Caicos Islands'>Turks and Caicos Islands</option>
                            <option value='Chad'>Chad</option>
                            <option value='French Southern Territories'>French Southern Territories</option>
                            <option value='Togo'>Togo</option>
                            <option value='Thailand'>Thailand</option>
                            <option value='Tajikistan'>Tajikistan</option>
                            <option value='Tokelau'>Tokelau</option>
                            <option value='Timor-Leste'>Timor-Leste</option>
                            <option value='Turkmenistan'>Turkmenistan</option>
                            <option value='Tunisia'>Tunisia</option>
                            <option value='Tonga'>Tonga</option>
                            <option value='Turkey'>Turkey</option>
                            <option value='Trinidad and Tobago'>Trinidad and Tobago</option>
                            <option value='Tuvalu'>Tuvalu</option>
                            <option value='Taiwan'>Taiwan</option>
                            <option value='Tanzania, United Republic of'>Tanzania, United Republic of</option>
                            <option value='Ukraine'>Ukraine</option>
                            <option value='Uganda'>Uganda</option>
                            <option value='United States'>United States</option>
                            <option value='Uruguay'>Uruguay</option>
                            <option value='Uzbekistan'>Uzbekistan</option>
                            <option value='Holy See (Vatican City State)'>Holy See (Vatican City State)</option>
                            <option value='Saint Vincent and the Grenadines'>Saint Vincent and the Grenadines</option>
                            <option value='Venezuela, Bolivarian Republic of'>Venezuela, Bolivarian Republic of</option>
                            <option value='Virgin Islands, British'>Virgin Islands, British</option>
                            <option value='Vietnam'>Vietnam</option>
                            <option value='Vanuatu'>Vanuatu</option>
                            <option value='Wallis and Futuna'>Wallis and Futuna</option>
                            <option value='Samoa'>Samoa</option>
                            <option value='Yemen'>Yemen</option>
                            <option value='Mayotte'>Mayotte</option>
                            <option value='South Africa'>South Africa</option>
                            <option value='Zambia'>Zambia</option>
                            <option value='Zimbabwe'>Zimbabwe</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* <div className='Saira_Bold_DGray dashboard_setting_profile_news_separate_line'></div>


            <div>
                <h2>News Interests</h2>
            </div> */}
        </div >
    );
}


export default Setting

