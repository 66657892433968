import { useEffect, useState } from "react";
import { BsFiletypePdf } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import courseJson from '../../../../components/Pages/All_Courses_Detail.json'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { MdContentCopy } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { CopyToClipboard } from 'react-copy-to-clipboard';


function UserPortalCourseBookLarge({ courseJsonInfo, num }) {
    const [courseImage, setCourseImage] = useState()
    const [downloadColor, setDownloadColor] = useState('#006197')
    const [pass_visible, setPass_visible] = useState(false)
    const [pass_arr, setPass_arr] = useState([])
    // Create a reference with an initial file path and name
    const storage = getStorage();

    function downloadPDF(course) {
        getDownloadURL(ref(storage, `gs://idca-f9873.appspot.com/CourseMaterial/${course.course_book_pdf}`))
            .then((url) => {
                window.open(url)
            })
            .catch((error) => {
                console.log(error)
            });
    }

    function getCourseJson(courseTitleAbbrev) {
        let course;
        courseJson.map(iCourse => {
            if (iCourse.title == courseTitleAbbrev)
                course = iCourse
        })
        return course
    }

    useEffect(() => {
        if (courseJsonInfo) {
            switch (courseJsonInfo.title) {
                case "DCIS":
                    setCourseImage([getCourseJson('DCIS')])
                    break;
                case "DCES":
                    setCourseImage([getCourseJson('DCES')])
                    break;
                case "DCOS":
                    setCourseImage([getCourseJson('DCOS')])
                    break;
                case "DCIE":
                    setCourseImage([getCourseJson('DCIS'), getCourseJson('DCES')])
                    break;
                case "DCOM":
                    setCourseImage([getCourseJson('DCIS'), getCourseJson('DCOS')])
                    break;
                case "DCTP":
                    setCourseImage([getCourseJson('DCTP')])
                    break;
                case "DCRP":
                    setCourseImage([getCourseJson('DCRP')])
                    break;
                default:
                    break;
            }

            setPass_arr(courseJsonInfo.book_pass.split(""))
        }
    }, [courseJsonInfo])

    function changeHoverStateLeave(i) {
        document.getElementById('dowload_pdf_' + i).style.backgroundColor = 'white'
        document.getElementById('dowload_pdf_p_' + i).style.color = '#006197'
        setDownloadColor('#006197')
    }

    function changeHoverStateEnter(i) {
        document.getElementById('dowload_pdf_' + i).style.backgroundColor = '#006197'
        document.getElementById('dowload_pdf_p_' + i).style.color = 'white'
        setDownloadColor('white')
    }

    function copyToClipboard() {
        const message = document.getElementById('clipboard_copy_message');
        message.style.display = 'block';
        setTimeout(() => {
            message.style.display = 'none';
        }, 2000); // Hide message after 2 seconds
    }

    return (
        <div className='user_dashboard_home_profile_div'>
            <div>
                <p className='user_dashboard_home_journey_title'>{courseJsonInfo.title} Course Material</p>
            </div>

            <div id="clipboard_copy_message" className="clipboard_copy_message_hidden">Copied to clipboard</div>

            <div className="user_portal_course_book_large_div">
                <div>
                    <img src={courseJsonInfo.book_img} className="user_portal_course_book_large_img_div" />
                </div>

                <div>
                    <p className="user_portal_course_book_large_title_div">{courseJsonInfo.full_title}</p>

                    <div className='user_portal_course_book_large_password_div'>
                        <p className="user_portal_course_book_large_password_text">Password:</p>
                        <div className="user_portal_indiv_course_exam_detail_div user_portal_course_book_large_password_inner_div">
                            {pass_visible ?
                                <p>{courseJsonInfo.book_pass}</p>
                                : <div className="user_portal_course_book_large_password_hidden_div">
                                    {pass_arr.map((char, i) => (
                                        <div key={i}>
                                            <GoDotFill size={10} />
                                        </div>
                                    ))}
                                </div>}


                            <div className="user_portal_course_book_large_password_action_div">
                                <div onClick={() => setPass_visible(!pass_visible)}>
                                    {pass_visible ?
                                        <IoEyeOff />
                                        : <IoEye />}
                                </div>

                                <CopyToClipboard text={courseJsonInfo.book_pass}
                                    onCopy={() => copyToClipboard()}>
                                    <MdContentCopy />
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>

                    <div className='user_portal_indiv_course_exam_detail_indiv_div'>
                        <div className='user_dashboard_home_certifications_indiv_cert_download' id={'dowload_pdf_' + num}
                            onClick={() => downloadPDF(courseJsonInfo)}
                            onMouseEnter={() => changeHoverStateEnter(num)}
                            onMouseLeave={() => changeHoverStateLeave(num)}>
                            <BsFiletypePdf size={17} color={downloadColor} />
                            <p id={'dowload_pdf_p_' + num}>View PDF</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserPortalCourseBookLarge
