import React, { useEffect, useState } from 'react';

import UrlFor from '../../../imgClient.js'

function Home({ cert_list, courses }) {

    const [courseNotTaken, setCourseNotTaken] = useState()
    const [inProgressCourses, setInProgressCourses] = useState()

    const allCourses = [{ abbr: 'DCIS', full: 'data-center-infrastructure-specialist' }, { abbr: 'DCES', full: 'data-center-engineering-specialist' },
    { abbr: 'DCIE', full: 'data-center-infrastructure-expert' }, { abbr: 'DCOS', full: 'data-center-operations-specialist' },
    { abbr: 'DCOM', full: 'data-center-operations-manager' }, { abbr: 'DCTP', full: 'data-center-technology-professional' },
    { abbr: 'DCE', full: 'data-center-expert' }, { abbr: 'DCM', full: 'data-center-manager' }, { abbr: 'DCA', full: 'data-center-authority' }]

    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        var allCourseTemp = []
        cert_list.map(i => {
            allCourseTemp.push(i.group_name?.replace('®', ''))
        })

        var inProgressCoursesTemp = []
        courses.map(i => {
            if (!allCourseTemp.includes(i.titleAbbrev) && isACourse(i.titleAbbrev)) {
                inProgressCoursesTemp.push(i.titleAbbrev)
                allCourseTemp.push(i.titleAbbrev)
            }
        })
        setInProgressCourses(inProgressCoursesTemp)
        var courseNotTakenTemp = []

        allCourses.map(i => {
            if (!allCourseTemp.includes(i.abbr))
                courseNotTakenTemp.push(i)
        })
        setCourseNotTaken(courseNotTakenTemp)
    }, [courses])

    function isACourse(event) {
        var result = false
        allCourses.map(i => {
            if (i.abbr == event)
                result = true
        })
        return result
    }

    return (
        <div className="dashboard_home_user_top_div Saira_Medium_Black">
            <h1 style={{ margin: '40px 5%' }}>Upcoming Events</h1>
            {courses.length == 0 && (
                <p className='Saira_Medium_Red' style={{ margin: '40px 5%' }}>You are currently not enrolled for any event.</p>
            )}
            <div className='dashboard_home_user_courses_list_div'>
                {courses.length != 0 && courses.map((event, i) => (
                    <a href={'/users/courses/' + event._id} style={{ textDecoration: 'none', width: 'fit-content', width: '100%' }} key={i}>
                        <div key={i} className='dashboard_home_user__individual_courses_div Saira_Medium_Black'>
                            <UrlFor source={event.mainImage} />
                            <h3>{event.title}</h3>
                            <p>{new Date(event.startDateTime).toDateString()}</p>
                        </div>
                    </a>
                ))}
            </div>

        </div >
    );
}


export default Home

