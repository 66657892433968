import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BsCopy } from "react-icons/bs";
import { CopyToClipboard } from 'react-copy-to-clipboard';

function UserPortalEmailSignature({ certificates }) {
    const [emailSig, setEmailSig] = useState([])

    useEffect(() => {
        const emailSigTmp = []
        if (certificates)
            certificates.map(cert => {
                const cert_name = cert.group_name.replace('®', '')
                console.log(cert_name)
                switch (cert_name) {
                    case "DCIS":
                        emailSigTmp.push('/dcis_email_signature.png')
                        break;
                    case "DCES" || "DCIE":
                        emailSigTmp.push('/dcie_email_signature.png')
                        break;
                    case "DCOS" || "DCOM":
                        emailSigTmp.push('/dcom_email_signature.png')
                        break;
                    case ("DCTP" && ("DCOS" || "DCOM")) || "DCM":
                        emailSigTmp.push('/dcm_email_signature.png')
                        break;
                    case ("DCTP" && ("DCES" || "DCIE")) || "DCE":
                        emailSigTmp.push('/dce_email_signature.png')
                        break;
                    case "DCA":
                        emailSigTmp.push('/dca_email_signature.png')
                        break;
                    default:
                        break;
                }
            })
        setEmailSig(emailSigTmp)
    }, [certificates])

    function CopiedSign() {
        const message = document.getElementById('clipboard_copy_message');
        message.style.display = 'block';
        setTimeout(() => {
            message.style.display = 'none';
        }, 2000); // Hide message after 2 seconds
    }

    return (
        <div className='user_portal_right_top_all_div'>
            <div>
                <p className='user_dashboard_home_journey_title'>Email Signature</p>
            </div>

            <div id="clipboard_copy_message" className="clipboard_copy_message_hidden">Copied to clipboard</div>

            <div className='user_portal_right_email_inner_div'>
                <div className='user_portal_right_email_sig_gmail_html_div'>
                    <div className='user_portal_right_email_sig_gmail_html_indiv_div'>
                        <p>Gmail & Outlook</p>
                    </div>
                    {/* <div className='user_portal_right_email_sig_gmail_html_indiv_div'>
                        <p>HTML</p>
                    </div> */}
                </div>
                <div className='user_portal_right_email_sig_content_div'>
                    <Carousel showStatus={false} className='user_portal_right_email_sig_carousel'>
                        {emailSig.map((sign, i) => (
                            <div key={i} className='user_portal_right_email_sig_content_inner_div'>
                                <div className='user_portal_right_email_sig_content_inner_div_img'>
                                    <img src={sign} fill />
                                </div>

                                <CopyToClipboard text={"https://idc-a.org" + sign}
                                    onCopy={() => CopiedSign()}>
                                    <div className='user_portal_right_email_sig_content_inner_copy_div'>
                                        <BsCopy color='#888888' />
                                    </div>
                                </CopyToClipboard>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
}

export default UserPortalEmailSignature
