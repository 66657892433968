import axios from "axios";

export default async function UpdateZoomEvent({ meeting_id, update_info, titleAbbrev, timeStampDCRP }) {
    var response = 'fail'

    const res = await axios.post('/zooom_auth_token', {}, {
        headers: {
            Authorization: 'Basic VWVHd0ZGSDhSWXlSb1ZING11R2hUdzozWWhZaTc2NXZmYW9JRlpjWngxeFl1bkZaU0U2TFpVZA==',
            Host: 'zoom.us'
        }
    })
    const zoom_access_token = res?.data?.access_token ? res?.data?.access_token : null

    var recurrence = 5
    switch (titleAbbrev) {
        case 'DCIS':
            recurrence = 2; break;
        case 'DCES':
            recurrence = 3; break;
        case 'DCOS':
            recurrence = 3; break;
        case 'DCTP':
            recurrence = 3; break;
        case 'DCIE':
            recurrence = 5; break;
        case 'DCOM':
            recurrence = 5; break;
        case 'DCRP':
            recurrence = 1; break;
    }

    // Set Start Date and Time
    const startDateTime_temp = new Date(update_info.start_time)
    const startDateTime_array_temp = startDateTime_temp.toISOString().split("T");
    var startDateTime = startDateTime_array_temp[0] + 'T'

    if (titleAbbrev == 'DCRP') {
        const hour = timeStampDCRP.hour < 10 ? ('0' + timeStampDCRP.hour) : timeStampDCRP.hour
        const min = timeStampDCRP.min < 10 ? ('0' + timeStampDCRP.min) : timeStampDCRP.min
        startDateTime = startDateTime + hour + ':' + min + ':00'
    } else {
        startDateTime = startDateTime + '09:00:00'
    }

    const data = {
        meetingData: {
            "start_time": startDateTime,
            "recurrence": {
                "end_times": recurrence,
                "repeat_interval": 1,
                "type": 1
            },
            "timezone": update_info.timezone,
        },
        zoom_access_token: zoom_access_token,
        meeting_id: meeting_id ? meeting_id : null
    }

    try {
        // This is the API on Next JS
        await axios.post('/api/update_meeting_info', data)
            .then((res) => {
                console.log(res)
                if (!res.data.errorCode)
                    response = 'Success'
                else if (res.data.errorCode == '429')
                    response = 'more than 3 attempts to register'
            })
            .catch((error) => {
                console.log(error)
            });
    } catch (error) {
        console.log(error)
    }

    return response
}