
import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import MemberNavbar from "../../components/Pages/MemberArea/Navbar/Navbar.js";
import MemberMileage from "../../components/Pages/MemberMileage/MemberMileage.js";
import { Helmet } from "react-helmet"

export default function UserMileage() {

    const [user, setUser] = useState()
    const [userInfo, setUserInfo] = useState()

    useEffect(() => {
        const db = getFirestore();
        getAuth().onAuthStateChanged(async user => {
            if (user) {
                // set user
                setUser(user)
                // get user info
                const docRef = doc(db, "Users", user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setUserInfo(docSnap.data())
                } else {
                    // doc.data() will be undefined in this case
                    window.location.href = "/";
                }
            } else {
                window.location.href = "/login";
            }
        })
    }, [])

    return (
        <div>
            <Helmet>
                <title>Mileage Program</title>
            </Helmet>
            <link rel="icon" href="/favicon.png" />
            <link rel="apple-touch-icon" href="/idca-logo-with-bg.jpg" />

            <nav>
                {userInfo &&
                    <MemberNavbar user={userInfo} page='mileage' />
                }
            </nav>

            <main>
                <MemberMileage userProps={user} userInfoProps={userInfo} />
            </main>
        </div >
    )
}