import React from 'react';
import { BsQuestionCircle } from "react-icons/bs";
import { TfiTimer } from "react-icons/tfi";
import { LuCheckCircle } from "react-icons/lu";
import { GrPowerCycle } from "react-icons/gr";

function UserPortalCourseExam({ courseJsonInfo }) {

    function getTime(time) {
        const hr = Math.floor(time / 60)
        const min = time % 60
        var returnString = hr + ' hours ' + (min ? min + ' minutes' : '')

        return returnString
    }

    return (
        <div className='user_dashboard_home_profile_div'>
            <div>
                <p className='user_dashboard_home_journey_title'>{courseJsonInfo.title} Exam</p>
            </div>

            <div>
                <div className='user_portal_indiv_course_exam_img_title_div'>
                    <img src={courseJsonInfo.badge_img_2024} height='80' width='80' />
                    <p>{courseJsonInfo.full_title}</p>
                </div>

                <div className='user_portal_indiv_course_exam_detail_div'>
                    <div className='user_portal_indiv_course_exam_detail_indiv_div'>
                        <BsQuestionCircle size={23} />
                        <p>{courseJsonInfo.exam.numQuestions} Questions</p>
                    </div>
                    <div className='user_portal_indiv_course_exam_detail_indiv_div'>
                        <TfiTimer size={25} />
                        <p>{getTime(courseJsonInfo.exam.duration)}</p>
                    </div>
                    <div className='user_portal_indiv_course_exam_detail_indiv_div'>
                        <LuCheckCircle size={23} />
                        <p>{courseJsonInfo.exam.passingPercentage}% Passing Grade</p>
                    </div>
                    <div className='user_portal_indiv_course_exam_detail_indiv_div'>
                        <div>
                            <GrPowerCycle size={24} />
                        </div>
                        <p className='user_portal_indiv_course_exam_detail_retake_p'>May retake in case you dont pass <span className='user_portal_indiv_course_exam_detail_retake_span'>(up to {courseJsonInfo.exam.numRetakes} times)</span></p>
                    </div>
                </div>

                <div className='user_portal_indiv_course_exam_take_div'>
                    <a href={courseJsonInfo.exam.link} target='_blank' rel="noreferrer">
                        <p>Take Exam</p>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default UserPortalCourseExam
