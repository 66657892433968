import React, { useState, useEffect } from 'react';

function CourseJourney({ certificates, courses }) {
    const [courseNotTaken, setCourseNotTaken] = useState()
    const [inProgressCourses, setInProgressCourses] = useState()
    const [completedCourses, setCompletedCourses] = useState([])
    const [nextStepUsed, setNextStepUsed] = useState(false)

    const allCoursesArr = [
        { abbr: 'DCIS', title: 'Data Center Infrastructure Specialist', full: 'data-center-infrastructure-specialist' },
        { abbr: 'DCES', title: 'Data Center Engineering Specialist', full: 'data-center-engineering-specialist' },
        { abbr: 'DCIE', title: 'Data Center Infrastructure Expert', full: 'data-center-infrastructure-expert' },
        { abbr: 'DCOS', title: 'Data Center Operations Specialist', full: 'data-center-operations-specialist' },
        { abbr: 'DCOM', title: 'Data Center Operations Manager', full: 'data-center-operations-manager' },
        { abbr: 'DCTP', title: 'Data Center Technology Professional', full: 'data-center-technology-professional' },
        { abbr: 'DCE', title: 'Data Center Expert', full: 'data-center-expert' },
        { abbr: 'DCM', title: 'Data Center Manager', full: 'data-center-manager' },
        { abbr: 'DCA', title: 'Data Center Authority', full: 'data-center-authority' }]

    useEffect(() => {
        var allCourseTemp = []
        certificates?.map(i => {
            if (i.group_name == 'DCOM®') {
                if (!allCourseTemp.includes('DCIS')) {
                    allCourseTemp.push('DCIS')
                }
                if (!allCourseTemp.includes('DCOS')) {
                    allCourseTemp.push('DCOS')
                }
            } else if (i.group_name == 'DCIE®') {
                if (!allCourseTemp.includes('DCIS')) {
                    allCourseTemp.push('DCIS')
                }
                if (!allCourseTemp.includes('DCES')) {
                    allCourseTemp.push('DCES')
                }
            }
            allCourseTemp.push(i.group_name?.replace('®', ''))
        })

        var inProgressCoursesTemp = []
        courses?.map(i => {
            if (!allCourseTemp.includes(i.titleAbbrev) && isACourse(i.titleAbbrev)) {
                if (i.titleAbbrev == 'DCOM') {
                    if (!allCourseTemp.includes('DCIS')) {
                        inProgressCoursesTemp.push('DCIS')
                        allCourseTemp.push('DCIS')
                    }
                    if (!allCourseTemp.includes('DCOS')) {
                        inProgressCoursesTemp.push('DCOS')
                        allCourseTemp.push('DCOS')
                    }
                    inProgressCoursesTemp.push(i.titleAbbrev)
                    allCourseTemp.push(i.titleAbbrev)
                } else if (i.titleAbbrev == 'DCIE') {
                    if (!allCourseTemp.includes('DCIS')) {
                        inProgressCoursesTemp.push('DCIS')
                        allCourseTemp.push('DCIS')
                    }
                    if (!allCourseTemp.includes('DCES')) {
                        inProgressCoursesTemp.push('DCES')
                        allCourseTemp.push('DCES')
                    }
                    inProgressCoursesTemp.push(i.titleAbbrev)
                    allCourseTemp.push(i.titleAbbrev)
                } else {
                    inProgressCoursesTemp.push(i.titleAbbrev)
                    allCourseTemp.push(i.titleAbbrev)
                }
            }
        })
        inProgressCoursesTemp.sort((a, b) => {
            if (a == 'DCIS') return -1
            else if ((a == 'DCOS' || a == 'DCES') && (b == 'DCOM' || b == 'DCIE' || b == 'DCTP' || b == 'DCA' || b == 'DCM' || b == 'DCE')) return -1
            else if ((a == 'DCOM' || a == 'DCIE') && (b == 'DCTP' || b == 'DCA' || b == 'DCM' || b == 'DCE')) return -1
            else if ((a == 'DCTP') && (b == 'DCA' || b == 'DCM' || b == 'DCE')) return -1
            else if ((a == 'DCM' || a == 'DCE') && (b == 'DCA')) return -1
        })

        // Complete Courses
        const completedCourses = []
        if (certificates) {
            certificates.map(i => {
                // if (new Date().getTime() <= new Date(i.expired_on).getTime()) {
                if (!completedCourses.includes(i.group_name.replace('®', '')))
                    completedCourses.push(i.group_name.replace('®', ''))
                // }
            })

            if (document.getElementById('user_dashboard_home_journey_bar_green_div_id'))
                document.getElementById('user_dashboard_home_journey_bar_green_div_id').style.width = (100 * (completedCourses.length / allCoursesArr.length)) + '%'

            setCompletedCourses(completedCourses)
        }
        const inProgressCoursesRemoveCompleted = []
        inProgressCoursesTemp.map(i => {
            if (!completedCourses.includes(i))
                inProgressCoursesRemoveCompleted.push(i)
        })

        // In Progress Courses
        if (document.getElementById('user_dashboard_home_journey_bar_gray_div_id'))
            document.getElementById('user_dashboard_home_journey_bar_gray_div_id').style.width = (100 * ((inProgressCoursesRemoveCompleted.length + completedCourses.length) / allCoursesArr.length)) + '%'

        setInProgressCourses(inProgressCoursesRemoveCompleted)


        // Set Next Step Display
        if (completedCourses && inProgressCoursesRemoveCompleted) {
            var done = false
            allCoursesArr.map(course => {
                if (!completedCourses.includes(course.abbr) && !inProgressCoursesRemoveCompleted.includes(course.abbr) && !done) {
                    if (document.getElementById('user_dashboard_home_journey_next_step_' + course.abbr)) {
                        document.getElementById('user_dashboard_home_journey_next_step_' + course.abbr).style.display = 'inherit'
                        done = true
                    }
                }
            })
        } else {
            if (document.getElementById('user_dashboard_home_journey_next_step_' + allCoursesArr[0].abbr))
                document.getElementById('user_dashboard_home_journey_next_step_' + allCoursesArr[0].abbr).style.display = 'inherit'
        }


        var courseNotTakenTemp = []

        allCoursesArr.map(i => {
            if (!allCourseTemp.includes(i.abbr))
                courseNotTakenTemp.push(i)
        })
        setCourseNotTaken(courseNotTakenTemp)
    }, [courses, certificates])

    function isACourse(event) {
        var result = false
        allCoursesArr.map(i => {
            if (i.abbr == event)
                result = true
        })
        return result
    }

    return (
        <div className='user_dashboard_home_profile_div'>
            <div>
                <p className='user_dashboard_home_journey_title'>Your Journey</p>
            </div>
            {(certificates && inProgressCourses && courseNotTaken) ?
                <div>
                    <div className='user_dashboard_home_journey_div'>
                        <div className='user_dashboard_home_journey_inner_div'>
                            {allCoursesArr.map((course, i) => (
                                <div key={i} className={`user_dashboard_home_journey_div_individual_div  user_course_progress_bar_courses_individual_${completedCourses.includes(course.abbr) ? (course.abbr) : inProgressCourses.includes(course.abbr) ? 'in_progress' : `not_taken`}`}
                                    id={i == 0 ? 'user_dashboard_home_journey_div_individual_div_first' : ((i == allCoursesArr.length - 1) ? 'user_dashboard_home_journey_div_individual_div_last' : '')}
                                    style={{ zIndex: 0 - i }}>
                                    <p id={i == 0 ? 'user_dashboard_home_journey_div_individual_div_individual_name_id_first' : ((i == allCoursesArr.length - 1) ? 'user_dashboard_home_journey_div_individual_div_individual_name_id_last' : '')}
                                        className='user_dashboard_home_journey_div_individual_div_individual_name Saira_Bold_White'
                                        style={{ color: (completedCourses.includes(course.abbr) || inProgressCourses.includes(course.abbr)) && 'white' }}>{course.abbr}
                                        <span className='user_course_progress_bar_courses_individual_name_r_sign'>®</span></p>
                                    <p id={i == 0 ? 'user_dashboard_home_journey_div_individual_div_individual_full_name_id_first' : ((i == allCoursesArr.length - 1) ? 'user_dashboard_home_journey_div_individual_div_individual_full_name_id_last' : '')}
                                        className='user_dashboard_home_journey_div_individual_div_individual_full_name Saira_Bold_White'
                                        style={{ color: (completedCourses.includes(course.abbr) || inProgressCourses.includes(course.abbr)) && 'white' }}>{course.title}</p>

                                    {/* <div className='user_dashboard_home_journey_next_step' id={'user_dashboard_home_journey_next_step_' + course.abbr}>
                                        <p>Your Next Step</p>
                                    </div> */}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='user_dashboard_home_journey_bar_div'>
                        <div className='user_dashboard_home_journey_bar_green_div' id='user_dashboard_home_journey_bar_green_div_id'>
                        </div>
                        <div className='user_dashboard_home_journey_bar_gray_div' id='user_dashboard_home_journey_bar_gray_div_id'>
                        </div>
                    </div>
                </div>
                : null}
        </div>
    );
}

export default CourseJourney
