import React, { useState, useEffect } from 'react';
import { getFirestore, doc, deleteDoc, getDoc, getDocs, setDoc, query, collection, where } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { getAuth } from "firebase/auth";

function WhitePaperAdmin() {
    const db = getFirestore();
    const storage = getStorage();

    const [newWhitePaper, setnewWhitePaper] = useState(false);
    const [whitePapers, setWhitepapers] = useState([]);
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)

    const [editTitle, setEditTitle] = useState("")
    const [editDescription, setEditDescription] = useState("")
    const [editWriter, setEditWriter] = useState("")
    const [tags, settags] = useState([]);
    const [categories, setCategories] = useState([]);


    function submitWhitepaper() {

        setLoading(true)
        const title = document.getElementById("WhitePaperAdminNewUploadIDTitle").value
        const writer = document.getElementById("WhitePaperAdminNewUploadIDWriter").value
        const description = document.getElementById("WhitePaperAdminNewUploadIDDescription").value
        const filename = document.getElementById("WhitePaperAdminNewUploadIDFile").files[0]?.name
        const category = document.getElementById("WhitePaperAdminNewUploadIDCategory").value
        const numberOfPages = document.getElementById("WhitePaperAdminNewUploadIDNumberPages").value
        const language = document.getElementById("WhitePaperAdminNewUploadIDLanguages").value

        if (title == "" || writer == "" || description == "" || filename == "") {
            alert("All the fields are required.")
            return
        }

        if (!filename) {
            alert("Please select a file first")
            return
        } else {
            const pathReference = ref(storage, 'gs://idca-f9873.appspot.com/WhitePapers' + `/${title}`);

            getDownloadURL(pathReference)
                .then((url) => {
                    alert("This Whitepaper already exist")
                    return
                })
                .catch((error) => {
                    const imageRef = ref(storage, 'gs://idca-f9873.appspot.com/WhitePapers' + `/${title}`);
                    uploadBytesResumable(imageRef, document.getElementById("WhitePaperAdminNewUploadIDFile").files[0])
                        .then((snapshot) => {
                            // Let's get a download URL for the file.
                            getDownloadURL(snapshot.ref)
                                .then(async (url) => {

                                    setnewWhitePaper(false)
                                    const docData = {
                                        downloads: 0,
                                        fileLink: url,
                                        title: title,
                                        writer: writer,
                                        description: description,
                                        tags: tags,
                                        category: category,
                                        dateAdded: new Date().getTime(),
                                        numberOfPages: numberOfPages,
                                        language: language
                                    };
                                    await setDoc(doc(db, "Whitepapers", title), docData);
                                    getWhitePapers()
                                    setLoading(false)
                                    // ...
                                });
                        }).catch((error) => {
                            console.error('Upload failed', error);
                        });
                });

        }
    }

    async function editSubmitFunction() {
        const filename = document.getElementById("WhitePaperAdminNewEditIDFile").files[0]?.name
        const title = document.getElementById("WhitePaperAdminNewEditIDTitle").value
        const writer = document.getElementById("WhitePaperAdminNewEditIDWriter").value
        const description = document.getElementById("WhitePaperAdminNewEditIDDescription").value


        if (title == "" || writer == "" || description == "") {
            alert("All the fields are required.")
            return
        }

        if (!filename) {
            alert("You need to select a file")
        } else {

            var desertRef = ref(storage, 'WhitePapers/' + editTitle);
            // Delete the file
            deleteObject(desertRef)

            const docRef = doc(db, "Whitepapers", editTitle);
            const docSnap = await getDoc(docRef);

            const imageRef = ref(storage, 'gs://idca-f9873.appspot.com/WhitePapers' + `/${title}`);
            uploadBytesResumable(imageRef, document.getElementById("WhitePaperAdminNewEditIDFile").files[0])
                .then((snapshot) => {
                    // Let's get a download URL for the file.
                    getDownloadURL(snapshot.ref)
                        .then(async (url) => {
                            const docData = {
                                fileLink: url,
                                title: title,
                                writer: writer,
                                description: description,

                                downloads: docSnap.data().downloads ? docSnap.data().downloads : 0,
                                tags: docSnap.data().tags ? docSnap.data().tags : null,
                                category: docSnap.data().category ? docSnap.data().category : null,
                                dateAdded: docSnap.data().dateAdded ? docSnap.data().dateAdded : new Date().getTime(),
                                numberOfPages: docSnap.data().numberOfPages ? docSnap.data().numberOfPages : 1,
                                language: docSnap.data().language ? docSnap.data().language : null
                            };
                            await deleteDoc(doc(db, "Whitepapers", editTitle))
                            await setDoc(doc(db, "Whitepapers", title), docData);
                            setEdit(false)
                            getWhitePapers()
                            // ...
                        });
                }).catch((error) => {
                    console.error('Upload failed', error);
                });

        }
    }

    function editWhitepaper(title, description, writer, link) {
        setEdit(true)
        setEditTitle(title)
        setEditWriter(writer)
        setEditDescription(description)
    }

    function deleteWhitepaper(title) {
        if (window.confirm("Are you sure you want to delete this Whitepaper?") == true) {
            const desertRef = ref(storage, 'WhitePapers/' + title);
            // Delete the file
            deleteObject(desertRef).then(async () => {
                await deleteDoc(doc(db, "Whitepapers", title));
                getWhitePapers()
            }).catch((error) => {
                alert("There was an error deleteing the file")
            });
        }

    }



    useEffect(() => {
        getAuth().onAuthStateChanged(async user => {
            const db = getFirestore();
            const qUser = query(collection(db, "Users"), where("uid", "==", user.uid));
            const querySnapshot = await getDocs(qUser)

            querySnapshot.forEach(async (data) => {
                if (data.data().idcaRole?.includes("admin") || data.data().idcaRole?.includes('membership-admin')) {
                    getWhitePapers()
                } else {
                    window.location.reload();
                }

            })
        })
    }, [])

    async function getWhitePapers() {
        setWhitepapers([])
        const q = query(collection(db, "Whitepapers"));
        const querySnapshot = await getDocs(q);
        var temp = []
        querySnapshot.forEach((doc) => {
            temp.push(doc.data())
        });
        setWhitepapers(temp)
        loadWhitePaperCategories()
    }

    async function loadWhitePaperCategories() {
        const q = query(collection(db, "Whitepaper Categories"));
        const querySnapshot = await getDocs(q);
        var temp = []
        querySnapshot.forEach((doc) => {
            temp.push(doc.data())
        });
        setCategories(temp)
    }

    function addTag() {
        var newTag = document.getElementById("WhitePaperAdminNewUploadIDTags").value
        settags([...tags, newTag])
        document.getElementById("WhitePaperAdminNewUploadIDTags").value = ""
    }

    function clearTags() {
        document.getElementById("WhitePaperAdminNewUploadIDTags").value = ""
        settags([])
    }


    return (
        <div className="Dashboard_Right_Pannel_whole WhitePaperAdminMainDiv">

            {
                edit &&
                <div className='WhitePaperAdminNewUploadFormEdit Saira_Regular_Black'>
                    <label className='WhitePaperAdminNewUploadLabel'>
                        Title:
                        <input className="Saira_Medium_Black data-center-contact-us-form-input-box"
                            maxLength="5" name="Title" size="5" type="text" id='WhitePaperAdminNewEditIDTitle' defaultValue={editTitle}></input>
                    </label>
                    <label className='WhitePaperAdminNewUploadLabel'>
                        Writer:
                        <input className="Saira_Medium_Black data-center-contact-us-form-input-box" name='Writer' id='WhitePaperAdminNewEditIDWriter' defaultValue={editWriter}></input>
                    </label>
                    <label className='WhitePaperAdminNewUploadLabel'>
                        Description:
                        <textarea name='Description' id='WhitePaperAdminNewEditIDDescription' className='Saira_Regular_Black' defaultValue={editDescription}></textarea>
                    </label>

                    <input type="file" id='WhitePaperAdminNewEditIDFile' className="StandardsDownload_Form_input Saira_Medium_Black"
                        accept="application/pdf" name="editFile" />

                    <button className='WhitePaperAdminSingleDeleteButton' onClick={() => { editSubmitFunction() }}>Submit</button>
                    <button className='WhitePaperAdminSingleDeleteButton' onClick={() => { setEdit(false) }}>Close</button>
                </div>
            }

            <h1 className="Saira_Bold_Black">
                White Paper Admin Panel
            </h1>

            <button className="WhitePaperAdminSingleNewButton" onClick={() => { setnewWhitePaper(true) }}>
                New White Paper
            </button>


            {loading &&
                <div className="payment-course-before-load-spinner-container" style={{ marginLeft: '50px', width: '50px', height: '50px' }}>
                    <div className="payment-course-before-loading-spinner">
                    </div>
                </div>
            }

            {
                newWhitePaper &&
                <div className='WhitePaperAdminNewUploadForm Saira_Regular_Black'>
                    <label className='WhitePaperAdminNewUploadLabel'>
                        Title:
                        <input className="Saira_Medium_Black data-center-contact-us-form-input-box" name='Title' id='WhitePaperAdminNewUploadIDTitle'></input>
                    </label>
                    <label className='WhitePaperAdminNewUploadLabel'>
                        Writer:
                        <input className="Saira_Medium_Black data-center-contact-us-form-input-box" name='Writer' id='WhitePaperAdminNewUploadIDWriter'></input>
                    </label>
                    <label className='WhitePaperAdminNewUploadLabelVertical'>
                        Description:
                        <textarea name='Description' id='WhitePaperAdminNewUploadIDDescription' className='Saira_Regular_Black'></textarea>
                    </label>


                    <label className='WhitePaperAdminNewUploadLabel'>
                        Category:
                        <select name="category" id="WhitePaperAdminNewUploadIDCategory">
                            {
                                categories.map((c, i) =>
                                    <option value={c.title} key={i}>{c.title}</option>

                                )
                            }

                        </select>
                    </label>
                    <label className='WhitePaperAdminNewUploadLabel'>
                        Number of Pages:
                        <input className="Saira_Medium_Black data-center-contact-us-form-input-box" type={"number"} name='Writer' id='WhitePaperAdminNewUploadIDNumberPages'></input>
                    </label>
                    <label className='WhitePaperAdminNewUploadLabel'>
                        Language:
                        <select id="WhitePaperAdminNewUploadIDLanguages" name="languages">
                            <option value="af">Afrikaans</option>
                            <option value="sq">Albanian</option>
                            <option value="am">Amharic</option>
                            <option value="ar">Arabic</option>
                            <option value="an">Aragonese</option>
                            <option value="hy">Armenian</option>
                            <option value="ast">Asturian</option>
                            <option value="az">Azerbaijani</option>
                            <option value="eu">Basque</option>
                            <option value="be">Belarusian</option>
                            <option value="bn">Bengali</option>
                            <option value="bs">Bosnian</option>
                            <option value="br">Breton</option>
                            <option value="bg">Bulgarian</option>
                            <option value="ca">Catalan</option>
                            <option value="ckb">Central Kurdish</option>
                            <option value="zh">Chinese</option>
                            <option value="zh-HK">Chinese (Hong Kong)</option>
                            <option value="zh-CN">Chinese (Simplified)</option>
                            <option value="zh-TW">Chinese (Traditional)</option>
                            <option value="co">Corsican</option>
                            <option value="hr">Croatian</option>
                            <option value="cs">Czech</option>
                            <option value="da">Danish</option>
                            <option value="nl">Dutch</option>
                            <option value="en">English</option>
                            <option value="en-AU">English (Australia)</option>
                            <option value="en-CA">English (Canada)</option>
                            <option value="en-IN">English (India)</option>
                            <option value="en-NZ">English (New Zealand)</option>
                            <option value="en-ZA">English (South Africa)</option>
                            <option value="en-GB">English (United Kingdom)</option>
                            <option value="en-US" selected>English (United States)</option>
                            <option value="eo">Esperanto</option>
                            <option value="et">Estonian</option>
                            <option value="fo">Faroese</option>
                            <option value="fil">Filipino</option>
                            <option value="fi">Finnish</option>
                            <option value="fr">French</option>
                            <option value="fr-CA">French (Canada)</option>
                            <option value="fr-FR">French (France)</option>
                            <option value="fr-CH">French (Switzerland)</option>
                            <option value="gl">Galician</option>
                            <option value="ka">Georgian</option>
                            <option value="de">German</option>
                            <option value="de-AT">German (Austria)</option>
                            <option value="de-DE">German (Germany)</option>
                            <option value="de-LI">German (Liechtenstein)</option>
                            <option value="de-CH">German (Switzerland)</option>
                            <option value="el">Greek</option>
                            <option value="gn">Guarani</option>
                            <option value="gu">Gujarati</option>
                            <option value="ha">Hausa</option>
                            <option value="haw">Hawaiian</option>
                            <option value="he">Hebrew</option>
                            <option value="hi">Hindi</option>
                            <option value="hu">Hungarian</option>
                            <option value="is">Icelandic</option>
                            <option value="id">Indonesian</option>
                            <option value="ia">Interlingua</option>
                            <option value="ga">Irish</option>
                            <option value="it">Italian</option>
                            <option value="it-IT">Italian (Italy)</option>
                            <option value="it-CH">Italian (Switzerland)</option>
                            <option value="ja">Japanese</option>
                            <option value="kn">Kannada</option>
                            <option value="kk">Kazakh</option>
                            <option value="km">Khmer</option>
                            <option value="ko">Korean</option>
                            <option value="ku">Kurdish</option>
                            <option value="ky">Kyrgyz</option>
                            <option value="lo">Lao</option>
                            <option value="la">Latin</option>
                            <option value="lv">Latvian</option>
                            <option value="ln">Lingala</option>
                            <option value="lt">Lithuanian</option>
                            <option value="mk">Macedonian</option>
                            <option value="ms">Malay</option>
                            <option value="ml">Malayalam</option>
                            <option value="mt">Maltese</option>
                            <option value="mr">Marathi</option>
                            <option value="mn">Mongolian</option>
                            <option value="ne">Nepali</option>
                            <option value="no">Norwegian</option>
                            <option value="nb">Norwegian Bokmål</option>
                            <option value="nn">Norwegian Nynorsk</option>
                            <option value="oc">Occitan</option>
                            <option value="or">Oriya</option>
                            <option value="om">Oromo</option>
                            <option value="ps">Pashto</option>
                            <option value="fa">Persian</option>
                            <option value="pl">Polish</option>
                            <option value="pt">Portuguese</option>
                            <option value="pt-BR">Portuguese (Brazil)</option>
                            <option value="pt-PT">Portuguese (Portugal)</option>
                            <option value="pa">Punjabi</option>
                            <option value="qu">Quechua</option>
                            <option value="ro">Romanian</option>
                            <option value="mo">Romanian (Moldova)</option>
                            <option value="rm">Romansh</option>
                            <option value="ru">Russian</option>
                            <option value="gd">Scottish Gaelic</option>
                            <option value="sr">Serbian</option>
                            <option value="sh">Serbo</option>
                            <option value="sn">Shona</option>
                            <option value="sd">Sindhi</option>
                            <option value="si">Sinhala</option>
                            <option value="sk">Slovak</option>
                            <option value="sl">Slovenian</option>
                            <option value="so">Somali</option>
                            <option value="st">Southern Sotho</option>
                            <option value="es">Spanish</option>
                            <option value="es-AR">Spanish (Argentina)</option>
                            <option value="es-419">Spanish (Latin America)</option>
                            <option value="es-MX">Spanish (Mexico)</option>
                            <option value="es-ES">Spanish (Spain)</option>
                            <option value="es-US">Spanish (United States)</option>
                            <option value="su">Sundanese</option>
                            <option value="sw">Swahili</option>
                            <option value="sv">Swedish</option>
                            <option value="tg">Tajik</option>
                            <option value="ta">Tamil</option>
                            <option value="tt">Tatar</option>
                            <option value="te">Telugu</option>
                            <option value="th">Thai</option>
                            <option value="ti">Tigrinya</option>
                            <option value="to">Tongan</option>
                            <option value="tr">Turkish</option>
                            <option value="tk">Turkmen</option>
                            <option value="tw">Twi</option>
                            <option value="uk">Ukrainian</option>
                            <option value="ur">Urdu</option>
                            <option value="ug">Uyghur</option>
                            <option value="uz">Uzbek</option>
                            <option value="vi">Vietnamese</option>
                            <option value="wa">Walloon</option>
                            <option value="cy">Welsh</option>
                            <option value="fy">Western Frisian</option>
                            <option value="xh">Xhosa</option>
                            <option value="yi">Yiddish</option>
                            <option value="yo">Yoruba</option>
                            <option value="zu">Zulu</option>
                        </select>
                    </label>

                    <div>
                        <label className='WhitePaperAdminNewUploadLabel'>
                            Tags:
                            <input id="WhitePaperAdminNewUploadIDTags" style={{ "width": "200px" }} type="text" className='data-center-contact-us-form-input-box' />
                            <input
                                type="button"
                                className='WhitePaperAdminSingleDeleteButton'
                                value={"Add"}
                                onClick={() => {
                                    addTag()
                                }}
                            />
                            <input
                                type="button"
                                className='WhitePaperAdminSingleDeleteButton'
                                value={"Clear"}
                                onClick={() => clearTags()}
                            />
                        </label>


                        <div style={{ "display": "flex", gap: "7px" }}>
                            {
                                tags?.map((t, i) =>
                                    <p key={i}>
                                        {t}
                                    </p>
                                )

                            }
                        </div>

                    </div>




                    <input type="file" id='WhitePaperAdminNewUploadIDFile' className="StandardsDownload_Form_input Saira_Medium_Black"
                        accept="application/pdf" name="image" />

                    <div>
                        <button className='WhitePaperAdminSingleDeleteButton' onClick={() => { submitWhitepaper() }}>Submit</button>
                        <button className='WhitePaperAdminSingleDeleteButton' onClick={() => { setnewWhitePaper(false) }}>Close</button>
                    </div>

                </div>
            }


            <h2 className="Saira_Bold_Black">
                White Papers
            </h2>

            <table className='memberMileageTable'>
                <thead>
                    <tr className='memberMileageTableRow'>
                        <th className='memberMileageTableHeader'>
                            Title
                        </th>
                        <th className='memberMileageTableHeader'>
                            Writer
                        </th>
                        <th className='memberMileageTableHeader'>
                            Link
                        </th>
                        <th className='memberMileageTableHeader'>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        whitePapers?.map((wp, i) => (
                            <tr className='memberMileageTableRow' key={i}>

                                <td className="Saira_Regular_Black memberMileageTableData" id={'WhitePaperAdminSingleWhitepaperTitle' + wp.title}>
                                    {wp.title}
                                </td>

                                <td className="Saira_Regular_Black memberMileageTableData" id={'WhitePaperAdminSingleWhitepaperWriter' + wp.title}>
                                    {wp.writer}
                                </td>

                                <td className='memberMileageTableData'>
                                    <a className="Saira_Regular_Black" href={wp.fileLink} target="_blank" rel="noreferrer" id={'WhitePaperAdminSingleWhitepaperLink' + wp.title}>
                                        Click here
                                    </a>
                                </td>

                                <td className='WhitePaperAdminSingleWhitepaperButtons'>
                                    <button className="WhitePaperAdminSingleDeleteButton" onClick={() => { deleteWhitepaper(wp.title) }}>
                                        Delete
                                    </button>
                                    <button className="WhitePaperAdminSingleEditButton" onClick={() => { editWhitepaper(wp.title, wp.description, wp.writer, wp.fileLink) }}>
                                        Edit
                                    </button>
                                </td>

                            </tr>

                        ))
                    }
                </tbody>

            </table>


        </div >
    );
}



export default WhitePaperAdmin