// import './Indiv_news_card.css'
import React, { useState, useEffect } from 'react';
import { FaLanguage, FaCalendarAlt } from "react-icons/fa";
import { ImPageBreak } from "react-icons/im";
import { BiCategory } from "react-icons/bi";
import pdfImage from '../../resources/PDFICON.png'
import { getFirestore, doc, setDoc, updateDoc, getDoc, query, collection, where, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import AddMileageToUser from '../AddMileageToUser/AddMileageToUser';


function IntelligenceResourceIndiv({ intellegenceResource }) {
    const db = getFirestore();
    const [userInfo, setuserInfo] = useState(null);

    useEffect(() => {
        getAuth().onAuthStateChanged(async user => {
            if (user) {
                const db = getFirestore();
                const qUser = query(collection(db, "Users"), where("uid", "==", user.uid));
                const querySnapshot = await getDocs(qUser)

                querySnapshot.forEach(async (data) => {
                    setuserInfo(data.data())
                    if (data.data().idcaRole?.includes("admin")
                        || data.data().idcaRole?.includes("member")
                        || data.data().idcaRole?.includes("membership-admin")
                        || data.data().idcaRole?.includes("sales")) {
                    } else {
                        window.location.href = '/'
                    }

                })
            } else {
                window.location.href = '/login'
            }
        })
    }, [])

    async function updateDownloadCount(title, downloadCount) {
        const timeStamp = Date.now()
        const wpDocRef = doc(db, "Whitepapers", title);
        const wpDocRecRef = doc(db, "Intelligent Resources Downloads", `${timeStamp}` + userInfo.uid);

        const docSnap = await getDoc(wpDocRef);



        if (docSnap.exists()) {
            var data = {
                uid: userInfo.uid,
                displayName: userInfo.displayName,
                title: title,
                date: timeStamp
            }
            await updateDoc(wpDocRef, {
                downloads: docSnap.data().downloads + 1
            });
            setDoc(wpDocRecRef, data)
        } else {
            console.log("No such document!");
        }

        // Add Mileage To User
        AddMileageToUser(userInfo.uid, title, 'Intelligence Resources')
    }
    return (
        <div className='all_div_margin intellegenceResourceIndivWhole' style={{ "marginTop": "150px" }}>
            <a href={"/intelligence-resources"} className='Saira_Regular_Gray' style={{ "textDecoration": "none" }}>
                {"Back"}
            </a>
            <div className='intellegenceResourceIndivFirstPart'>
                <div>
                    <img src={pdfImage} className='intellegenceResourceIndivFirstPartImage' />
                </div>
                <div >
                    <h1 className='Saira_Regular_Gray' style={{ "paddingBottom": "0px", marginBottom: "0px" }}>{intellegenceResource?.title}</h1>
                    <div style={{ "display": "flex", "gap": "10px" }}>
                        <h5 className='Saira_Regular_Gray' style={{ marginTop: "10px" }}>
                            {"By " + intellegenceResource?.writer}
                        </h5>
                        <h5 className='Saira_Regular_Gray' style={{ marginTop: "10px" }}>
                            Date:
                            <span className='Saira_Regular_Black'>
                                {" " + new Date(intellegenceResource?.dateAdded).toDateString()}
                            </span>
                        </h5>
                    </div>

                    <div className='intellegenceResourceIndivTags'>
                        {
                            intellegenceResource?.tags?.map((ir, index) =>
                                <p key={index} style={{ "backgroundColor": "lightgray" }}>{ir}</p>
                            )
                        }
                    </div>

                    <hr />

                    <p className='Saira_Regular_Gray'>
                        {intellegenceResource?.description}
                    </p>
                    <div className='FeaturedIntelCardButtons'>
                        <a className='Saira_Bold_White FeaturedIntelCardDownloadButton' onClick={() => { updateDownloadCount(intellegenceResource?.title, intellegenceResource?.downloads) }} href={intellegenceResource?.fileLink} download={intellegenceResource?.title + ".pdf"} target="_blank" rel="noreferrer" style={{ "textDecoration": "none", "textAlign": "center" }}>
                            Download
                        </a>
                    </div>
                </div>
            </div >

            <br />
            <br />


            <div className='Saira_Regular_Gray intellegenceResourceIndivInfoBox'>

                <div className='intellegenceResourceIndivInfoBoxSingle'>
                    <p>
                        Page Length
                    </p>
                    <ImPageBreak size={40} />
                    <p>
                        {intellegenceResource?.numberOfPages}
                    </p>
                </div>

                <div className='intellegenceResourceIndivInfoBoxSingle'>
                    <p>
                        Language
                    </p>
                    <FaLanguage size={40} />
                    <p>
                        {intellegenceResource?.language}
                    </p>
                </div>


                <div className='intellegenceResourceIndivInfoBoxSingle'>
                    <p>
                        Published Date
                    </p>
                    <FaCalendarAlt size={35} />
                    <p>
                        {new Date(intellegenceResource?.dateAdded).toDateString()}
                    </p>
                </div>


                <div className='intellegenceResourceIndivInfoBoxSingle'>
                    <p>
                        Category
                    </p>
                    <BiCategory size={40} />
                    <p>
                        {intellegenceResource?.category}
                    </p>
                </div>

            </div>


            <br />
            <br />

        </div >
    );
}

export default IntelligenceResourceIndiv;