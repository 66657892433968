/* HOME */
import './App.css'
import './components/Pages/Homepage/Digital_Economy/DigitalEconomy.css'
import './components/Components/Footer/Footer.css'
import './components/Pages/Homepage/HomePage.css';
import './components/Pages/Homepage/Digital_Economy/DigitalEconomy.css';
import './components/Pages/Homepage/Top_Three_Services/TopThreeServices.css'
import './components/Pages/Homepage/Globe/Globe_hp.css'
import './components/Pages/Homepage/Certification/Certification.css'
import './components/Pages/Homepage/Standards/Standards.css'
import './components/Pages/Homepage/IDCA_Services_Cards/IDCA_Services_Cards.css'
import './components/Components/IDCA_Service_Card/IDCA_Service_Card.css'
import './components/Pages/Homepage/Testimonial/Testimonial_section.css'
import './components/Components/Testimonial/Testimonial_Card.css'
import './components/Pages/Data_Center_Training/Additional_videos/Additional_videos.css'
import './components/Components/Additional_Videos/Video_Card.css'
import './components/Components/News_Letter_Sub/NewsLetterSub.css'
import './components/Pages/Homepage/companies/idca_partners_refer.css'
import './components/Components/Header/Navbar.css'
import './components/Components/Header2_0/Navbar.css'
import './components/Components/Header2_0/EventDropdown/EventDropdown.css'

/* HOME New */
import './components/Pages/HomePage_2023/HomePageTop/HomePageTop.css'
import './components/Pages/HomePage_2023/Services/Services.css'
import './components/Pages/HomePage_2023/LatestNews/LatestNews.css'
import './components/Pages/HomePage_2023/DUEvents/DUEvents.css'
import './components/Pages/HomePage_2023/UpcomingEvents/UpcomingEvents.css'

import './components/Pages/Homepage/TopBanner/TopBanner.css'
import './components/Pages/Homepage/ServiceOfferings/ServiceOfferings.css'
import './components/Pages/Homepage/UpcomingWebinar/UpcomingWebinar.css'

/* Digital Economy*/
import './components/Pages/DigitalEconomy/DigitalEconomy.css'
import './components/Pages/DigitalEconomy/DigitalEconomyTop/DigitalEconomyTop.css'
import './components/Pages/DigitalEconomy/DigitalEconomyWhy/DigitalEconomyWhy.css'
import './components/Pages/DigitalEconomy/DigitalEconomyBottom/DigitalEconomyBottom.css'



/* Digital Economy New */
import './components/Pages/DigitalEconomy/AboutDigitalEcon/AboutDigitalEcon.css'
import './components/Pages/DigitalEconomy/Method/Method.css'
import './components/Pages/DigitalEconomy/DigitalEconomyInitiatives/DigitalEconomyInitiatives.css'
import './components/Pages/DigitalEconomy/DigitalEconomyServices/DigitalEconomyServices.css'
import './components/Pages/DigitalEconomy/Taskforce/Taskforce.css'
import './components/Pages/DigitalEconomy/RelatedMedia/RelatedMedia.css'



/* Audit */
import './components/Pages/Audit/Audit.css'
import './components/Pages/Audit/AuditTop/AuditTop.css'
import './components/Pages/Audit/Audit and Certification/AuditAndCertification.css'
import './components/Pages/Audit/Audit and Certification/ContactForm/ContactForm.css'
import './components/Pages/Audit/Audit_Services/Audit_Services.css'

/* Audit Contact Us */
import './components/Pages/AuditContactForm/AuditContact.css'
import './components/Pages/AuditContactForm/Contact_Us_Top/Contact_Us_Page_Top.css'

/* Audit Data Center */
import './components/Pages/AuditDataCenter/AuditDataCenter.css'
import './components/Pages/AuditDataCenter/Top/Top.css'
import './components/Pages/AuditDataCenter/OurServices/OurServices.css'
import './components/Pages/AuditDataCenter/Phases/Phase.css'
import './components/Pages/AuditDataCenter/DC_Audit_Principles/DC_Audit_Principles.css'
import './components/Pages/AuditDataCenter/RightLeftRowsAudit/RigthLeftRowsAudit.css'
import './components/Components/Audit_Contact_Us/contact_us_audit.css'

/* Audit Cloud */
import './components/Pages/AuditCloud/AuditCloud.css'
import './components/Pages/AuditCloud/Top/Top.css'
import './components/Pages/AuditCloud/Phases/Phase.css'
import './components/Pages/AuditCloud/RightLeftRowsAudit/RigthLeftRowsAudit.css'

/* Audit Application */
import './components/Pages/AuditApplication/AuditApplication.css'
import './components/Pages/AuditApplication/Top/Top.css'
import './components/Pages/AuditApplication/OurServices/OurServices.css'
import './components/Pages/AuditApplication/Phases/Phase.css'
import './components/Pages/AuditApplication/RightLeftRowsAudit/RigthLeftRowsAudit.css'

/* Audit IT */
import './components/Pages/AuditIT/AuditIT.css'
import './components/Pages/AuditIT/Top/Top.css'
import './components/Pages/AuditIT/Phases/Phase.css'
import './components/Pages/AuditIT/RightLeftRowsAudit/RigthLeftRowsAudit.css'

/* Advisory */
import './components/Pages/Consulting/Top/Top.css'
import './components/Pages/Consulting/Services/Services.css'

/* Training */
import './components/Pages/Training/training.css'
import './components/Components/Contact_Us_Form/DataCenterTrainingContactForm/DCTrainingContactUsForm.css'
import './components/Pages/Training/Training_stats/Traning_stats.css'
import './components/Pages/Training/Training_Top/Training_TopNew.css'
import './components/Pages/Training/Three_training_types/Training_types.css'
import './components/Pages/Training/Training_Courses_Slides/Training_Courses_Slide.css'
import './components/Components/Top_Courses_Carousel/TopCoursesCard.css'
import './components/Pages/Training/Training_developers/Training_developers.css'
import './components/Pages/Training/Board_indiv_card/training_board_memeber.css'
import './components/Components/Upcoming_Training_Events/Upcoming_Training_Events.css'
import './components/Pages/All_Events/Individual_Events/Individual_Events.css'
import './components/Pages/Data_Center_Training/Testimonial/Testimonial_Data_Center_Training.css'
import './components/Components/Testimonial_v2/Testimonial_new.css'


/* Data Center Training */
import './components/Pages/Data_Center_Training/Data_Center_Training.css'
import './components/Pages/Data_Center_Training/DataCenterTrainingTop/DataCenterTrainingTop.css'
import './components/Pages/Data_Center_Training/About_Training_and_Contact/AboutTrainingAndContact.css'
import './components/Pages/Data_Center_Training/about_our_courses/About_Our_Courses.css'
import './components/Pages/Data_Center_Training/CoursesCarousel/CoursesCarousel.css'
import './components/Pages/Data_Center_Training/ForWhoByWho/ForWhoByWho.css'
import './components/Pages/Data_Center_Training/DataCenterTrainingCourseMap/DataCenterTrainingCourseMap.css'
import './components/Pages/Data_Center_Training/DataCenterTrainingCourseMap/DCRoadMapCourses/DCRoadMapCourses.css'


/* Data Center Training  NEW */
import './components/Pages/DataCenterTraining_2/DataCenterTraining_2.css'
import './components/Pages/DataCenterTraining_2/BodyDescription/BodyDescription.css'
import './components/Pages/DataCenterTraining_2/Courses/Courses.css'

/* Cloud Training */
import './components/Pages/Cloud_Training/Cloud_Training.css'
import './components/Pages/Cloud_Training/Cloud_Top/Clout_topNew.css'
import './components/Pages/Cloud_Training/About_Training_And_Contact/Cloud_Training_And_Contact.css'
import './components/Pages/Cloud_Training/About_Our_Courses/Cloud_About_Our_Courses.css'
import './components/Pages/Cloud_Training/Courses_Carousel/Cloud_Courses_carousel.css'
import './components/Pages/Cloud_Training/CloudTrainingCourseMap/CloudTrainingCourseMap.css'
import './components/Pages/Cloud_Training/CloudTrainingCourseMap/CloudTrainingCourseMap/CloudTrainingCourseMap.css'

/* Cyber Training */
import './components/Pages/Cyber_Training/Cyber_Training.css'
import './components/Pages/Cyber_Training/Cyber_Top/Cyber_Top.css'
import './components/Pages/Cyber_Training/About_and_Contact/About_and_Contact_Cyber.css'
import './components/Pages/Cyber_Training/CyberAbout_Our_Courses/CyberAbout_Our_Course.css'
import './components/Pages/Cyber_Training/Courses_Carousel/Cyber_Courses_Carousel.css'
import './components/Pages/Cyber_Training/CyberTrainingCourseMap/CyberTrainingCourseMap.css'
import './components/Pages/Cyber_Training/CyberTrainingCourseMap/CyberTrainingCourseMap/DCRoadMapCourses.css'

/* Individual Courses */
import './components/Pages/Individual_Courses/individual_courses.css'
import './components/Pages/Individual_Courses/Top/Top.css'
import './components/Components/WhatWillLearn/WhatWillLearn.css'
import './components/Pages/Individual_Courses/Syllabus/Syllabus.css'
import './components/Components/WhyTakeThisCourse/WhyTakeThisCourse.css'
// import './components/Pages/Individual_Courses/BuyCourseForm/BuyCourseForm.css'


/* Individual Courses 2.0 */
import './components/Pages/Individual_Courses_2/Top/Top.css'
import './components/Pages/Individual_Courses_2/GetInTouchForm/GetInTouchForm.css'
import './components/Pages/Individual_Courses_2/individual_courses.css'
import './components/Pages/Individual_Courses_2/Description/Description.css'
import './components/Pages/Individual_Courses_2/CourseDetails/CourseDetails.css'
import './components/Pages/Individual_Courses_2/Syllabus/Syllabus.css'
import './components/Pages/Individual_Courses_2/DownloadBorchure/DownloadBorchure.css'
import './components/Pages/Individual_Courses_2/RegisterForCourse/RegisterForCourse.css'
import './components/Pages/Individual_Courses_2/UpcomingEvents/UpcomingEvents.css'
import './components/Pages/Individual_Courses_2/CourseReview/CourseReview.css'


/* Events */
import './components/Pages/All_Events/All_Events.css'

/* Individual Event */
import './components/Pages/Individual_Event/Individual_Event.css'
import './components/Pages/Individual_Event/Syllabus/Syllabus.css'
import './components/Pages/Individual_Event/TopDesc/TopDesc.css'

/* Standard */
import './components/Pages/Standard/TheSevenLayers/TheSevenLayers.css'
import './components/Pages/Standard/Standard.css';
import './components/Pages/Standard/Standard_Top/Standard_Top.css'
import './components/Pages/Standard/GetInvolved/GetInvolved.css';
import './components/Pages/Standard/StandardAboutVideo/StandardAboutVideo.css';
import './components/Pages/Standard/AboutCommittee/AboutCommittee.css';
import './components/Pages/Standard/StandardsFeatures/StandardsFeatures.css';
import './components/Pages/Standard/ESR/ESR.css'
import './components/Pages/Standard/GradingSystem/GradingSystem.css';
import './components/Pages/StandardsDownload/StandardsDownload.css';


/* About Us */
import './components/Pages/AboutUs/TechnicalCommittee/TechnicalCommittee.css';
import './components/Components/AboutUsPeopleCard/AboutUsPeopleCard.css';
import './components/Pages/AboutUs/BoardOfDirectors/BoardOfDirectors.css';


//Certification 
import './components/Pages/Certification/Certification.css'
import './components/Pages/Certification/Certification_Main/Certification_Main.css'
import './components/Pages/Certification/Certification_Top/Certification_Top.css'
import './components/Pages/Individual_Courses/Become_IDCA_Certified/Become_IDCA_Certified.css'

// Certification 2.0
import './components/Pages/Certification_2_0/Certification_2_0.css'
import './components/Pages/Certification_2_0/Certification_Top/Certification_Top.css'
import './components/Pages/Certification_2_0/Professional_Cert/Professional_Cert.css'
import './components/Pages/Certification_2_0/G_Levels/G_Levels.css'
import './components/Pages/Certification_2_0/Cert_SDS/Certification_SDS.css'
import './components/Pages/Certification_2_0/Videos/Videos.css'
import './components/Pages/Certification_2_0/Cert_Process/Cert_Process.css'

// Professional Certificaiton
import './components/Pages/Certification_Professional/Certification_Professional.css'
import './components/Pages/Certification_Professional/Certification_Professional_top/Certification_Professional_top.css'
import './components/Pages/Certification_Professional/Certification_Professional_Main/Certification_NewProfessional.css'

// Certification List
import './components/Pages/Certification/Certification_list/Certification_list.css'
import './components/Pages/Certification/Certification_list/Certification_list_main/Certification_list_main.css'
import './components/Pages/Certification/Certification_list/Certification_list_top/Certification_list_top.css'

// About Us
import './components/Pages/AboutUs/AboutUs.css'
import './components/Components/Testimonial_v3/Testimonial_new_v3.css'
import './components/Pages/AboutUs/AboutUsDescription/AboutUsDescription.css';
import './components/Pages/AboutUs/AboutUsTop/AboutUsTop.css';
import './components/Pages/AboutUs/ExecutiveTeam/ExecutiveTeam.css';
import './components/Pages/AboutUs/Testimonial/Testimonial_About_Us.css';


// Join Elite V2
import './components/Components/JoinTheEliteV2/JoinTheEliteV2.css'


// Podcasts
import './components/Pages/Podcast/Podcast.css';
import './components/Pages/Podcast/Podcast_guests/Podcasts_guests.css';
import './components/Pages/Podcast/Top/Top.css'
import './components/Pages/Podcast/Upcoming_Podcast/Upcoming_Podcast.css'
import './components/Pages/Podcast/Podcast_Speaker_indiv/Podcast_Speaker_indiv.css'
import './components/Pages/Individual_Podcast/Individual_Podcast.css'
import './components/Pages/Podcast/UpcomingHost/UpcomingHost.css'


// Contact US
import './components/Pages/ContactUsPage/ContactUsPage.css'
import './components/Pages/ContactUsPage/Contact_Us_Top/Contact_Us_Page_Top.css'


// News
import './components/Pages/News/News.css'
import './components/Pages/News/idca_news/idca_news.css'
import './components/Pages/News/top_news/top_news.css'
import './components/Pages/News/NewsLetterSignUp/NewsLetterSignUp.css'
import './components/Pages/News/news_subsection/news_subsection.css'
import './components/Pages/News/news_subsection/subsection_component/subsection_component.css'

// News New
import './components/Pages/News_2/News.css'
import './components/Pages/News_2/news_section/news_section.css'
import './components/Pages/News_2/YoutubeShorts/YoutubeShorts.css'
import './components/Pages/News_2/PoscastWebinar/PodcastWebinar.css'
import './components/Pages/News_2/Events/Events.css'
import './components/Pages/News_2/NewsLetterSub/NewsLetterSub.css'


// News All
import './components/Pages/All_News/All_News.css'
import './components/Components/All_News_Filter_Box/All_News_Filter_Box.css'
import './components/Components/All_News_Card/All_News_Card.css'


// Webinar 
import './components/Pages/Webinar/Webinar.css'
import './components/Pages/Webinar/Webianr_Top/Webinar_Top.css'
import './components/Pages/Webinar/Webinar_Guests/Webinar_Guests.css'
import './components/Pages/Webinar/Webinar_List/Webinar_List.css'
import './components/Pages/Indiv_webinar/Indiv_Webinar.css'

// Webinar New
import './components/Pages/Webinar_2_0/Webinar_2_0.css'
import './components/Pages/Webinar_2_0/Webinar_Top/Webinar_Top.css'
import './components/Pages/Webinar_2_0/DescriptionFirstWebinar/DescriptionFirstWebinar.css'
import './components/Pages/Webinar_2_0/UpcomingEvents/UpcomingEvents.css'

import './components/Pages/Webinar_2_0/Educational/Educational.css'
import './components/Pages/Webinar_2_0/Description/Description.css'


// Indiv News
import './components/Pages/Individual_News/individual_news.css'
import './components/Pages/Individual_News/individual_top_news.css'
import './components/Pages/Individual_News/related_news.css'
import './components/Pages/Indiv_Webinar_Speaker/Indiv_Webinar_Speaker.css'
import './components/Components/indiv_news_card/Indiv_news_card.css'



// indiv Board Member
import './components/Pages/AboutUs/BoardOfDirectors/BoardOfDirectors.css'
import './components/Pages/IndividualBoerdMember/IndividualBoardMember.css'

// standard Download
import './components/Pages/StandardsDownload/Contact_Us_Top/Contact_Us_Page_Top.css'
import './components/Pages/StandardsDownload/StandardsDownload.css'

// Digital Readiness Report
import './components/Pages/DigitalReadinessReport/Contact_Us_Top/Contact_Us_Page_Top.css'

// Advisory Contact
import './components/Pages/AdivisoryContactForm/AdivisoryContactForm.css'
import './components/Pages/AdivisoryContactForm/Contact_Us_Top/Contact_Us_Page_Top.css'


// Request Submitted
import './components/Pages/Success_sub/Top/Top.css'

// IDCA Football LATAM League
import './components/Pages/LATAM_Football_League/League_Top/Leage_Top.css'
import './components/Pages/LATAM_Football_League/Description/Description.css'
import './components/Pages/LATAM_Football_League/Register/Register.css'

import './components/Components/FootballPlayerHeader/FootballPlayerHeader.css'
import './components/Components/FootballPlayerRegister/FootballPlayerRegister.css'

import './components/Components/FootballSponsorRegister/FootballSponsorRegister.css'
import './components/Components/FootballSponsorHeader/FootballSponsorHeader.css'

import './components/Pages/LATAM_Football_Cup_Results/CupTop/CupTop.css'
import './components/Pages/LATAM_Football_Cup_Results/About/About.css'
import './components/Pages/LATAM_Football_Cup_Results/Sponsors/Sponsors.css'

import './components/Pages/LATAM_Football_Cup_Results/Photos/Photos.css'
import './components/Pages/LATAM_Football_Cup_Results/ResultTable/ResultTable.css'


// Podcast Host
import './components/Pages/PodcastHost/PodcastHost.css'


// Login/Sign-up
import './components/Pages/Login/Login.css'
import './components/Pages/CreateAccount/CreateAccount.css'

// Login Landing Page
import './components/Pages/LoginLandingPage/LoginLandingPage.css'

// User Dashboard
import './components/Pages/Dashboard/Dashboard.css'
import './components/Pages/Dashboard/Dashboard_Left_Pannel/Dashboard_Left_Pannel.css'
import './components/Pages/Dashboard/Dashboard_Right_Pannel/Dashboard_Right_Pannel.css'
import './components/Pages/Dashboard/IDCA_Admin/IDCA_Admin.css'
import './components/Pages/Dashboard/Setting/Setting.css'
import './pages/users/Indiv_User.css'
import './pages/users/events/indiv_course.css'
import './components/Pages/Dashboard/Calculations/Calculations.css'
import './components/Pages/Dashboard/Home/Home.css'
import './components/Pages/Dashboard/AdminEventList/AdminEventList.css'
import './components/Pages/AdminCourseList/AdminCourseList.css'
import './components/Pages/Dashboard/Home/ProgressBar.css'
import './components/Components/DashboardAnnouncementAdmin/DashboardAnnouncementAdmin.css'
import './components/Pages/IndividualCourse/indiv_course.css'

// User Dashboard 2024
import './components/Pages/UserDashboard/Home/Home.css'
import './components/Pages/UserDashboard/Home/ProfileDetail/ProfileDetail.css'
import './components/Pages/UserDashboard/Home/CourseJourney/CourseJourney.css'
import './components/Pages/UserDashboard/Home/Certifications/Certifications.css'
import './components/Pages/UserDashboard/Home/NextStep/NextStep.css'
import './components/Pages/UserDashboard/Home/UpcomingClasses/UpcomingClasses.css'


// User Dashboard Indiv Course 2024
import './components/Pages/UserPortalCourse/UserPortalCourse.css'
import './components/Pages/UserPortalCourse/CourseDetail/CourseDetail.css'
import './components/Pages/UserPortalCourse/CourseDetail/Syllabus.css'
import './components/Pages/UserPortalCourse/Exam/Exam.css'
import './components/Pages/UserPortalCourse/EmailSignature/EmailSignature.css'
import './components/Pages/UserPortalCourse/LinkedinJoin/LinkedinJoin.css'
import './components/Pages/UserPortalCourse/Social/Social.css'
import './components/Pages/UserPortalCourse/CourseBook/CourseBook.css'
import './components/Pages/UserPortalCourse/CourseBookLarge/CourseBookLarge.css'

// User Dashboard All Courses 2024
import './components/Pages/UserPortalAllCourses/UpcomingCourses/UpcomingCourses.css'
import './components/Pages/UserPortalAllCourses/CourseMaterial/CourseMaterial.css'

// Payment
import './components/Pages/Payment/Payment.css'
import './components/Pages/Payment/CheckoutForm.css'
import './components/Pages/Payment/PaymentCreateAccount.css'


// Course Payment
import './components/Pages/PaymentCourse/PaymentCourse.css'


// African Webinar
import './components/Pages/AfricanWebinar/AfricanWebinar.css'

// WhitePaper Admin Pannel
import './components/Pages/WhitePaperAdmin/WhitePaperAdmin.css'

// Member Area
import './components/Pages/MemberArea/MemberArea.css'
import './components/Pages/MemberArea/UpcomingEvents/UpcomingEvents.css'
import './components/Components/FeaturedIntelligence/FeaturedIntelligence.css'
import './components/Components/IndivIntelegenceResources/IndivIntelegenceResources.css'
import './components/Pages/IntelligenceResources/IntelligenceResources.css'
import './components/Pages/MemberMileage/MemberMileage.css'
import './components/Components/PointRequests/PointRequests.css'
import './components/Pages/MembershipSignup/MembershipSignup.css'
import './components/Components/NonmemberMessage/NonmemberMessage.css'
import './components/Components/ShareExperience/ShareExperience.css'
import './components/Components/SharedExperiencesAdmin/SharedExperiencesAdmin.css'
import './components/Components/MembersAdminPanel/MembersAdminPanel.css'
import './components/Pages/MemberAdminIndividualMem/MemberAdminIndividualMem.css'
import './components/Pages/MemberEventIndividual/MemberEventIndividual.css'
import './components/Pages/MemberAdminCorporateMem/MemberAdminCorporateMem.css'
import './components/Pages/MemberArea/Navbar/Navbar.css'

// Member Setting
import './components/Pages/MemberSetting/MemberSetting.css'


// Forum Recent Posts
import './components/Components/ForumRecentPosts/ForumRecentPosts.css'


// Admin Area
import './components/Pages/AdminPannel/Coupon/Coupon.css'
import './components/Pages/MemberCorpAdmin/MemberCorpAdmin.css'

// Member Benefits
import './components/Pages/MemberBenefit/MemberBenefit.css'
import './components/Pages/MemberBenefit/PricingAndBenefit/PricingAndBenefit.css'
import './components/Pages/MemberBenefit/TopBenefits/TopBenefits.css'
import './components/Components/Membership_Top/Membership_Top.css'
import './components/Pages/MemberBenefit/MembershipCommunity/MemberhsipCommunity.css'
import './components/Pages/MemberBenefit/MembershipEmailSignUp/MembershipEmailSignUp.css'
import './components/Pages/MemberBenefit/MembershipCommunityInfo/MembershipCommunityInfo.css'
import './components/Pages/MemberBenefit/MembershipSetUpCall/MembershipSetUpCall.css'
import './components/Pages/MemberBenefit/MembershipBenefitsCategories/MembershipBenefitsCategories.css'
import './components/Pages/MemberBenefit/MembershipCommunityLocalGathering/MembershipCommunityLocalGathering.css'
import './components/Pages/MemberBenefit/GlobalMembershipBenefits/GlobalMembershipBenefits.css'
import './components/Pages/MemberBenefit/MembershipIDCAExclusives/MembershipIDCAExclusives.css'
import './components/Pages/MemberBenefit/MembershipExclusiveEvents/MembershipExclusiveEvents.css'
import './components/Pages/MemberBenefit/MemberBenefitCoutUp/MemberBenefitCoutUp.css'

// Member Notifications
import './components/Pages/MemberNotification/MemberNotification.css'
import './components/Pages/MemberNotification/ListOfNotifications/ListOfNotifications.css'
import './components/Pages/MemberNotification/IndividualChat/IndividualChat.css'


// Membership Sub Pages
import './components/Pages/MembershipDetailSubPages/Forum/MembershipForumDetail.css'
import './components/Pages/MembershipDetailSubPages/Top.css'
import './components/Pages/MembershipDetailSubPages/AboutSection.css'
import './components/Pages/MembershipDetailSubPages/Login.css'
import './components/Pages/MembershipDetailSubPages/CommunitySkillsBoard/CommunitySkillsBoard.css'
import './components/Pages/MembershipDetailSubPages/ForumTopics.css'
import './components/Pages/MembershipDetailSubPages/ExclusiveMemberContent/ExclusiveMemberContent.css'
import './components/Pages/MembershipDetailSubPages/LoyaltyProgram/WhoEarnsPoints/WhoEarnsPoints.css'
import './components/Pages/MembershipDetailSubPages/LoyaltyProgram/HowToEarn/HowToEarn.css'
import './components/Pages/MembershipDetailSubPages/LoyaltyProgram/Countup/Countup.css'
import './components/Pages/MemberJobs/MemberJobs.css'
import './components/Pages/MemberList/MemberList.css'
import './components/Pages/MemberViewOfProfile/MemberViewOfProfile.css'

// Global Research

import './components/Components/GlobalDigitalResearch/GlobalDigitalResearch.css'
import './components/Components/GlobalDigitalResearch/GlobalDigitalResearchTop/GlobalDigitalResearchTop.css'
import './components/Pages/GlobalDigitalResearchPrices/GlobalDigitalResearchPrices.css'
import './components/Pages/GlobalDigitalResearchPrices/PriceRangeSection/PriceRangeSection.css'
import './components/Pages/GlobalDigitalResearchPrices/Top/Top.css'

// Symposia

import './components/Pages/Symposia/SymposiaMain/SymposiaMainEvent.css'
import './components/Pages/Symposia/SymposiaMain/Components/ServingTheWorld.css'
import './components/Pages/SymposiaLandingPage/WhoHowWhen/WhoHowWhen.css'
import './components/Pages/SymposiaLandingPage/SymposiaLandingPage.css'
import './components/Pages/SymposiaWorld/SymposiaWorld.css'
import './components/Pages/ExecutiveBoardrooms/ExecutiveBoardrooms.css'
import './components/Pages/TechnicalCharrettes/TechnicalCharrettes.css'
import './components/Pages/SymposiaLandingPage/CalendarOfEvents/CalendarOfEvents.css'

// Symposia Event Individual

import './components/Pages/Symposia/IndividualEvents/IndividualEvents.css'
import './components/Pages/Symposia/IndividualEvents/Components/subscribe.css'
import './components/Pages/Symposia/IndividualEvents/Components/WhoHowWhen.css'
import './components/Pages/Symposia/IndividualEvents/Components/ExclusiveSpeakers.css'
import './components/Pages/Symposia/IndividualEvents/Components/Partners.css'
import './components/Pages/Symposia/IndividualEvents/Components/Gallary.css'
import './components/Pages/Symposia/IndividualEvents/Components/IndividualEventTestimonials.css'
import './components/Pages/Symposia/IndividualEvents/Components/IndividualEventsUpcomingEvents.css'
import './components/Pages/TechnicalCharrettes/UpcomingEvents/UpcomingEvents.css'
import './components/Pages/SymposiaLandingPage/GetInvolved/GetInvolved.css'
import './components/Pages/AtPlay/AtPlay.css'

import './components/Pages/MeetGreet/MeetGreet.css'

// Individual Event

import './components/Pages/IndividualEvent/IndividualEvent.css'


// Training Calendar
import './components/Pages/TrainingCalendar/TrainingCalendar.css'
import './components/Pages/TrainingCalendar/Top/Top.css'



//Job Portal
import './components/Pages/JobPortal/JobPortal.css'

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { initializeApp } from "firebase/app";

import UserDashboard from "./pages/user-dashboard";
import LoginPage from "./pages/login";
import IntelligenceResources from './pages/user-dashboard/intelligence-resources'
import UserAllCourses from './pages/user-dashboard/all-courses'
import UserCourse from './pages/user-dashboard/course'
import UserMileage from './pages/user-dashboard/mileage'
import AdminPannel from './pages/user-dashboard/admin'
import AdminEventIndiv from './pages/admin/events/course'
import AdminUsers from './pages/users/[id]'
import IntelligenceResourcesIndiv from './pages/user-dashboard/intelligence-resources/[indiv]'
import ResetPassword from './pages/user-dashboard/reset-password'
import CreateAccount from './pages/create-an-idca-account copy'
import Error from './pages/_error';


function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyC6vzqDF4jjdn6Yvgi131ZTHuEvf9oOEWU",
    authDomain: "idca-f9873.firebaseapp.com",
    databaseURL: "https://idca-f9873-default-rtdb.firebaseio.com",
    projectId: "idca-f9873",
    storageBucket: "idca-f9873.appspot.com",
    messagingSenderId: "726124802450",
    appId: "1:726124802450:web:c0ee86cd1fea2bef89d14b",
    measurementId: "G-KQVJBPGW9G"
  };
  initializeApp(firebaseConfig);


  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<UserDashboard />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/create-an-idca-account" element={<CreateAccount />} />
          <Route path="/intelligence-resources" element={<IntelligenceResources />} />
          <Route path="/all-courses" element={<UserAllCourses />} />
          <Route path="/course" element={<UserCourse />} />
          <Route path="/mileage" element={<UserMileage />} />
          <Route path="/admin" element={<AdminPannel />} />
          <Route path="/admin/events/course" element={<AdminEventIndiv />} />
          <Route path="/users/:user_id" element={<AdminUsers />} />
          <Route path="/intelligence-resources/:IrId" element={<IntelligenceResourcesIndiv />} />

          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </div>

  );
}



export default App;