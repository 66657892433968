function UserPortalSocial() {

    return (
        <div className='user_portal_right_social_media_div'>
            <div className="user_portal_right_social_inner_media_div">
                <a href={'https://twitter.com/idcaorg?lang=en'} target={'_blank'} rel="noreferrer" style={{ textDecoration: 'none' }}>
                    <img src={'/Group 427320613.png'} className="user_portal_right_social_media_indiv" />
                </a>
                <a href={'https://www.linkedin.com/company/international-data-center-authority-idca/'} target={'_blank'} rel="noreferrer" style={{ textDecoration: 'none' }}>
                    <img src={'/Group 427320612.png'} className="user_portal_right_social_media_indiv" />
                </a>
                <a href={'https://www.facebook.com/idcaorg/'} target={'_blank'} rel="noreferrer" style={{ textDecoration: 'none' }}>
                    <img src={'/Group 427320615.png'} className="user_portal_right_social_media_indiv" />
                </a>
                <a href={'https://www.instagram.com/idcaorg/?hl=en'} target={'_blank'} rel="noreferrer" style={{ textDecoration: 'none' }}>
                    <img src={'/Group 427320616.png'} className="user_portal_right_social_media_indiv" />
                </a>
                <a href={'https://www.youtube.com/channel/UCYiHZ2ODWV6p0wSZp_loGQw'} target={'_blank'} rel="noreferrer" style={{ textDecoration: 'none' }}>
                    <img src={'/Group 427320614.png'} className="user_portal_right_social_media_indiv" />
                </a>
            </div>
        </div>
    );
}

export default UserPortalSocial
