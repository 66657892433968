import { IoIosArrowUp } from 'react-icons/io'
import { BsDot } from 'react-icons/bs'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'

import syllabusJSON from '../../All_Courses_json.json'
import { useEffect, useState } from 'react'

function CourseSyllabus({ courseTitle }) {
    const [daySelect, setDaySelect] = useState(0)

    let syllabus;
    syllabusJSON.map((iSyllabus, i) => {
        if (iSyllabus.name == courseTitle)
            syllabus = iSyllabus
    })

    function createArray(n) {
        const result = [];
        for (let i = 1; i <= n; i++) {
            switch (i) {
                case 1:
                    result.push('Day One')
                    break;
                case 2:
                    result.push('Day Two')
                    break;
                case 3:
                    result.push('Day Three')
                    break;
                case 4:
                    result.push('Day Four')
                    break;
                case 5:
                    result.push('Day Five')
                    break;
                default:
                    break;
            }
        }
        return result;
    }
    const numDayArray = createArray(syllabus.numDays);


    function handleDayClick(index, i) {
        if (document.getElementById("individual_training_syllabus_day_" + index + '_' + i).style.display == "none") {
            document.getElementById("individual_training_syllabus_day_" + index + '_' + i).style.display = "initial";
            document.getElementById("individual_training_syllabus_arrow_icon" + index + '_' + i).style.transform = "rotate(0deg)";

        }
        else {
            document.getElementById("individual_training_syllabus_day_" + index + '_' + i).style.display = "none";
            document.getElementById("individual_training_syllabus_arrow_icon" + index + '_' + i).style.transform = "rotate(180deg)";

        }
    }

    const handleDaySyllabus = (individual, index) => {
        return (
            <div>
                <div className='user_portal_indiv_course_syllabus_day_detail_div'>
                    <div>
                        {individual.map((iSyllabus, i) => {
                            if (i < individual.length / 2)
                                return (
                                    <div key={i}>
                                        <div className='user_portal_indiv_course_syllabus_day_detail_title_div'>
                                            <p>{i + 1}.</p>
                                            <h5>{iSyllabus.title}</h5>
                                        </div>
                                        <div className='user_portal_indiv_course_syllabus_day_detail_bullet_div'>
                                            {iSyllabus.detail.map((iDetail, indexDetail) =>
                                                <div style={{ display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }} key={i + indexDetail} >
                                                    <BsDot size={20} />
                                                    <h6 className='user_portal_indiv_course_syllabus_day_h6 Saira_Regular_Gray'>{iDetail}</h6>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                        })}
                    </div>

                    <div>
                        {individual.map((iSyllabus, i) => {
                            if (i >= individual.length / 2)
                                return (
                                    <div key={i}>
                                        <div className='user_portal_indiv_course_syllabus_day_detail_title_div'>
                                            <p>{i + 1}.</p>
                                            <h5>{iSyllabus.title}</h5>
                                        </div>
                                        <div className='user_portal_indiv_course_syllabus_day_detail_bullet_div'>
                                            {iSyllabus.detail.map((iDetail, indexDetail) =>
                                                <div style={{ display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }} key={i + indexDetail} >
                                                    <BsDot size={20} />
                                                    <h6 style={{ fontSize: '16px', margin: '0px' }} className='Saira_Regular_Gray'>{iDetail}</h6>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                        })}
                    </div>
                </div >
            </div >
        );
    }

    function handleSyllabus(day) {
        let returnDiv
        switch (day) {
            case 0:
                returnDiv = handleDaySyllabus(syllabus.day1, day)
                break;
            case 1:
                returnDiv = handleDaySyllabus(syllabus.day2, day)
                break;
            case 2:
                returnDiv = handleDaySyllabus(syllabus.day3, day)
                break;
            case 3:
                returnDiv = handleDaySyllabus(syllabus.day4, day)
                break;
            case 4:
                returnDiv = handleDaySyllabus(syllabus.day5, day)
                break;
            default:
                break;
        }
        numDayArray.map((day_tmp, i) => {
            if (document.getElementById('user_portal_indiv_course_syllabus_day_' + i))
                if (i == day) {
                    document.getElementById('user_portal_indiv_course_syllabus_day_' + i).style.backgroundColor = '#006197'
                    document.getElementById('user_portal_indiv_course_syllabus_day_' + i).style.color = 'white'
                } else {
                    document.getElementById('user_portal_indiv_course_syllabus_day_' + i).style.backgroundColor = '#D9D9D9'
                    document.getElementById('user_portal_indiv_course_syllabus_day_' + i).style.color = '#7D7D7D'
                }
        })
        return (
            returnDiv
        )
    }

    useEffect(() => {
        if (document.getElementById('user_portal_indiv_course_syllabus_day')) {
            var gridTemplate = ''
            switch (syllabus.numDays) {
                case 1:
                    gridTemplate = '50%'
                    break;
                case 2:
                    gridTemplate = '45% 45%'
                    break;
                case 3:
                    gridTemplate = '30% 30% 30%'
                    break;
                case 4:
                    gridTemplate = '22% 22% 22% 22%'
                    break;
                case 5:
                    gridTemplate = '18% 18% 18% 18% 18%'
                    break;
                default:
                    break;
            }
            document.getElementById('user_portal_indiv_course_syllabus_day').style.gridTemplateColumns = gridTemplate
        }
    }, [])

    return (
        <div className='user_portal_indiv_course_syllabus_div'>
            <div className='user_portal_indiv_course_syllabus_day_div' id='user_portal_indiv_course_syllabus_day'>
                {numDayArray.map((day, i) => (
                    <div key={i} className='user_portal_indiv_course_syllabus_day_indiv_div' id={'user_portal_indiv_course_syllabus_day_' + i} onClick={() => setDaySelect(i)}>
                        <p>{day}</p>
                    </div>
                ))}
            </div>
            <div id={"individual_training_syllabus_day"}>
                <div className='user_portal_indiv_course_syllabus_detail_top_div'>
                    <div>
                        {handleSyllabus(daySelect)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseSyllabus