// import './DCTrainingContactUsForm.css'
import React, { useRef, useEffect, useState } from "react"

function GoogleMapsAPI({ address, apt, city, state, zipcode, country }) {

    const CONFIGURATION = {
        "ctaTitle": "Continue to Payment",
        "mapOptions": { "center": { "lat": 37.4221, "lng": -122.0841 }, "fullscreenControl": true, "mapTypeControl": false, "streetViewControl": true, "zoom": 11, "zoomControl": true, "maxZoom": 22, "mapId": "" },
        "mapsApiKey": "AIzaSyDxoG5t5eY7tg6Jnphel0cbgfs8yklODmU",
        "capabilities": { "addressAutocompleteControl": true, "mapDisplayControl": false, "ctaControl": true }
    };

    const SHORT_NAME_ADDRESS_COMPONENT_TYPES =
        new Set(['street_number', 'administrative_area_level_1', 'postal_code']);

    const ADDRESS_COMPONENT_TYPES_IN_FORM = [
        'location',
        'locality',
        'administrative_area_level_1',
        'postal_code',
        'country',
    ];

    useEffect(() => {
        function getFormInputElement(componentType) {
            return document.getElementById(`${componentType}-input`);
        }

        const loadGoogleMapsScript = () => {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDxoG5t5eY7tg6Jnphel0cbgfs8yklODmU&libraries=places,marker`;
            script.defer = true;
            script.async = true;

            script.addEventListener('load', initMap);
            document.head.appendChild(script);
        };

        const initMap = () => {
            if (window.google && window.google.maps) {
                const { Autocomplete } = window.google.maps.places;

                const autocomplete = new Autocomplete(document.getElementById('location-input'), {
                    fields: ['address_components', 'geometry', 'name'],
                    types: ['address'],
                });

                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (!place.geometry) {
                        window.alert(`No details available for input: '${place.name}'`);
                        return;
                    }
                    fillInAddress(place);
                });
            } else {
                console.error('Google Maps API not loaded.');
            }
        };

        const fillInAddress = (place) => {
            function getComponentName(componentType) {
                for (const component of place.address_components || []) {
                    if (component.types[0] === componentType) {
                        return SHORT_NAME_ADDRESS_COMPONENT_TYPES.has(componentType) ?
                            component.short_name :
                            component.long_name;
                    }
                }
                return '';
            }

            function getComponentText(componentType) {
                return (componentType === 'location') ?
                    `${getComponentName('street_number')} ${getComponentName('route')}` :
                    getComponentName(componentType);
            }

            for (const componentType of ADDRESS_COMPONENT_TYPES_IN_FORM) {
                getFormInputElement(componentType).value = getComponentText(componentType);
            }
        };

        if (!window.google || !window.google.maps) {
            loadGoogleMapsScript();
        } else {
            initMap();
        }
    }, []);


    return (
        <div>
            <div>
                <label htmlFor="address"><p className="data-center-contact-us-form-input-label">Address</p></label>
                <input type="text" placeholder="Address" id="location-input" className="training_get_in_touch_form_individual_input" defaultValue={address} />
            </div>

            <div>
                <label htmlFor="apt"><p className="data-center-contact-us-form-input-label">Apt, Suite, etc (optional)</p></label>
                <input type="text" placeholder="Apt, Suite, etc (optional)" id="apt-input" className="training_get_in_touch_form_individual_input" defaultValue={apt} />
            </div>

            <div>
                <label htmlFor="city"><p className="data-center-contact-us-form-input-label">City</p></label>
                <input type="text" placeholder="City" id="locality-input" className="training_get_in_touch_form_individual_input" defaultValue={city} />
            </div>

            <div className="login_landing_page_data_fields_two_in_row_div">
                <div>
                    <label htmlFor="state"><p className="data-center-contact-us-form-input-label">State/Province</p></label>
                    <input type="text" placeholder="State/Province" id="administrative_area_level_1-input"
                        className="training_get_in_touch_form_individual_input" defaultValue={state} />
                </div>
                <div>
                    <label htmlFor="zip code"><p className="data-center-contact-us-form-input-label">Zip/Postal code</p></label>
                    <input type="text" placeholder="Zip/Postal code" id="postal_code-input"
                        className="training_get_in_touch_form_individual_input" defaultValue={zipcode} />
                </div>
            </div>

            <div>
                <label htmlFor="country"><p className="data-center-contact-us-form-input-label">Country</p></label>
                <input type="text" placeholder="Country" id="country-input" className="training_get_in_touch_form_individual_input" defaultValue={country} />
            </div>

        </div >
    );
}

export default GoogleMapsAPI