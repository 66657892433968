import React, { useState, useEffect } from 'react';
import { getFirestore, doc, query, setDoc, updateDoc, where, orderBy, collection, getDocs, getDoc, limit } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import AddMileageToUser from '../../Components/AddMileageToUser/AddMileageToUser';
function IntelligenceResourcesPage() {

    const [whitePapers, setWhitepapers] = useState([]);
    const [allWhitePapers, setallWhitePapers] = useState([]);
    const [userInfo, setuserInfo] = useState(null);

    const db = getFirestore();

    useEffect(() => {
        getAuth().onAuthStateChanged(async user => {
            if (user) {
                const db = getFirestore();
                const qUser = query(collection(db, "Users"), where("uid", "==", user.uid));
                const querySnapshot = await getDocs(qUser)

                querySnapshot.forEach(async (data) => {
                    setuserInfo(data.data())
                    if (data.data().idcaRole?.includes("admin")
                        || data.data().idcaRole?.includes("member")
                        || data.data().idcaRole?.includes("membership-admin")
                        || data.data().idcaRole?.includes("sales")) {
                        getWhitePapers()
                    } else {
                        window.location.href = '/'
                    }

                })
            } else {
                window.location.href = '/'
            }
        })
    }, [])

    async function getWhitePapers() {
        setWhitepapers([])
        setallWhitePapers([])
        const q = query(collection(db, "Whitepapers"), orderBy("dateAdded", "desc")
            // , limit(5)
        );
        const querySnapshot = await getDocs(q);
        var temp = []
        querySnapshot.forEach((doc) => {
            console.log(doc.data())
            temp.push(doc.data())
        });
        setWhitepapers(temp)
        setallWhitePapers(temp)
    }

    async function getWhitePapersWithFilter(filter) {
        setWhitepapers([])
        setallWhitePapers([])
        const q = query(collection(db, "Whitepapers"), orderBy(filter, "desc")
            // , limit(5)
        );
        const querySnapshot = await getDocs(q);
        var temp = []
        querySnapshot.forEach((doc) => {
            temp.push(doc.data())
        });
        setWhitepapers(temp)
        setallWhitePapers(temp)
    }


    function sortByOptionClicked(id) {

        const button = document.getElementById(id)
        var otherBotton

        if (id == "IntelligenceResoucesSortByOptionsButtonDateAdded") {
            otherBotton = document.getElementById("IntelligenceResoucesSortByOptionsButtonViews")

            if (button.style.fontWeight == "normal")
                getWhitePapersWithFilter("dateAdded")
        } else {
            otherBotton = document.getElementById("IntelligenceResoucesSortByOptionsButtonDateAdded")
            if (button.style.fontWeight == "normal")
                getWhitePapersWithFilter("downloads")
        }

        if (button.style.fontWeight == "normal") {
            button.style.fontWeight = "bold"
            otherBotton.style.fontWeight = "normal"
        }

    }

    async function updateDownloadCount(title, downloadCount) {
        const timeStamp = Date.now()
        const wpDocRef = doc(db, "Whitepapers", title);
        const wpDocRecRef = doc(db, "Intelligent Resources Downloads", `${timeStamp}` + userInfo.uid);

        const docSnap = await getDoc(wpDocRef);



        if (docSnap.exists()) {
            var data = {
                uid: userInfo.uid,
                displayName: userInfo.displayName,
                title: title,
                date: timeStamp
            }
            await updateDoc(wpDocRef, {
                downloads: docSnap.data().downloads + 1
            });
            setDoc(wpDocRecRef, data)
        } else {
            console.log("No such document!");
        }

        // Add Mileage To User
        AddMileageToUser(userInfo.uid, title, 'Intelligence Resources')
    }

    function findSearchResults(searchText) {
        var temp = allWhitePapers
        temp = temp.filter((wp) => wp.title.toLowerCase().includes(searchText.toLowerCase()))
        setWhitepapers(temp)
    }

    return (
        <div className='member_all_div_margin IntelligenceResoucesCompeleteDivParent' style={{ "marginTop": "150px", "marginBottom": "50px" }}>
            <div>
                <h3 className='Saira_Bold_Red'>
                    Search
                </h3>
                <input className='data-center-contact-us-form-input-box' id={"IntelligenceResoucesSearchInput"}
                    onChange={(e) => findSearchResults(e.target.value)} />
                <button className='IntelligenceResoucesSearchClearButton Saira_Regular_White'
                    style={{ "textDecoration": "none", "textAlign": "center", "cursor": "pointer" }}
                    onClick={() => {
                        document.getElementById("IntelligenceResoucesSearchInput").value = ""
                        findSearchResults("")
                    }}>
                    Clear
                </button>
            </div>

            <div className='IntelligenceResoucesListOfWhitePapers'>
                <div className='IntelligenceResoucesSortBy'>
                    <h4 className='Saira_Bold_Red'>
                        {"Sort By: "}
                    </h4>
                    <div className='IntelligenceResoucesSortByOptions'>
                        <button className='Saira_Regular_DGray IntelligenceResoucesSortByOptionsButton' style={{ "fontWeight": "bold" }} id='IntelligenceResoucesSortByOptionsButtonDateAdded' onClick={() => sortByOptionClicked("IntelligenceResoucesSortByOptionsButtonDateAdded")}>
                            {"Date Added"}
                        </button>
                        <button className='Saira_Regular_DGray IntelligenceResoucesSortByOptionsButton' style={{ "fontWeight": "normal" }} id='IntelligenceResoucesSortByOptionsButtonViews' onClick={() => sortByOptionClicked("IntelligenceResoucesSortByOptionsButtonViews")}>
                            {"Downloads"}
                        </button>
                    </div>
                </div>
                {
                    whitePapers.map((ir, i) =>
                        <div className='IntelligenceResoucesSingleWhitepaper' key={i}>
                            <div>
                                <h3 className='Saira_Bold_Black' >
                                    <a href={`intelligence-resources/${ir.dateAdded}`} className='Saira_Bold_Black' style={{ "textDecoration": "none" }}>
                                        {ir.title}
                                    </a>
                                </h3>
                                <p style={{ "marginBottom": "5px", "paddingBottom": "0px" }}>
                                    <span className='Saira_Bold_LGray'>
                                        {"Date: "}
                                    </span>
                                    <span className="Saira_Regular_LGray">
                                        {(new Date(ir.dateAdded)).toDateString()}
                                    </span>
                                </p>
                                <p className='Saira_Bold_LGray' style={{ "marginTop": "5px" }}>
                                    <span className='Saira_Bold_LGray'>
                                        {"Writer: "}
                                    </span>
                                    <span className="Saira_Regular_LGray">
                                        {ir.writer}
                                    </span>
                                </p>
                                <p className='Saira_Regular_DGray'>
                                    {
                                        ir.description
                                    }
                                </p>

                                <div style={{ "display": "flex", gap: "7px" }} className="Saira_Regular_DGray">
                                    {
                                        ir?.tags?.map((t, i) =>
                                            <p key={i} style={{ backgroundColor: "lightgray" }}>
                                                {t}
                                            </p>
                                        )

                                    }
                                </div>

                                <div className='FeaturedIntelCardButtons'>
                                    <a className='Saira_Bold_White FeaturedIntelCardDownloadButton' onClick={() => { updateDownloadCount(ir.title, ir.downloads) }} href={ir.fileLink} download={ir.title + ".pdf"} target="_blank" rel="noreferrer" style={{ "textDecoration": "none", "textAlign": "center" }}>
                                        Download
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div >
    );
}

export default IntelligenceResourcesPage
