import React, { useState } from 'react';

function Mileage() {
    const allButtonNames = ['availability', 'power', 'battery_calculation', 'sizing', 'cooling']
    const [calculationType, setCalculationType] = useState('availability')

    function headerClick(name) {
        allButtonNames.map(i => {
            if (i == name)
                document.getElementById('dashboard_calculations_' + i + '_button').style.color = '#AD2225';
            else
                document.getElementById('dashboard_calculations_' + i + '_button').style.color = 'black';
        })
        setCalculationType(name)
    }

    return (
        <div className="dashboard_setting_top_div Saira_Medium_Black">
            <h1>Your Mileage Program</h1>
        </div >
    );
}


export default Mileage

