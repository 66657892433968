import React, { useState, useEffect } from "react";

import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import MemberNavbar from "../../components/Pages/MemberArea/Navbar/Navbar.js";
import UserPortalCourse from "../../components/Pages/UserPortalCourse/UserPortalCourse.js";
import courseJson from '../../components/Pages/All_Courses_Detail.json'
import { Helmet } from "react-helmet"

export default function UserCourse() {
    const queryParameters = new URLSearchParams(window.location.search)
    let _id = queryParameters.get("_id")

    const [userInfo, setUserInfo] = useState()
    const [course, setCourse] = useState()
    const [courseJsonInfo, setCourseJsonInfo] = useState()
    const [certificates, setCertificates] = useState([])
    const [inPerson, setInPerson] = useState()
    const [errorAccured, setErrorAccured] = useState()

    function getCourseJson(courseTitleAbbrev) {
        let course;
        courseJson.map(iCourse => {
            if (iCourse.title == courseTitleAbbrev)
                course = iCourse
        })
        return course
    }
    useEffect(() => {
        const db = getFirestore();
        getAuth().onAuthStateChanged(async user => {
            if (user) {
                // get user info
                const docRef = doc(db, "Users", user.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setUserInfo(docSnap.data())


                    // get certifications
                    var request = new XMLHttpRequest();
                    request.open('POST', 'https://api.accredible.com/v1/credentials/search');
                    request.setRequestHeader('Content-Type', 'application/json');
                    request.setRequestHeader('Authorization', 'Token token=54dc431a507f5f49a059b8b5d1f96427');
                    request.onreadystatechange = function () {
                        if (this.readyState === 4) {
                            var credentials_temp = JSON.parse(this.responseText).credentials
                            var cert_list = []
                            credentials_temp.map((cred) => {
                                const cred_name = cred.group_name.replace('®', '')
                                if (cred_name == 'DCIS' || cred_name == 'DCES'
                                    || cred_name == 'DCOS' || cred_name == 'DCIE'
                                    || cred_name == 'DCOM' || cred_name == 'DCTP'
                                    || cred_name == 'DCM' || cred_name == 'DCE'
                                    || cred_name == 'DCA')
                                    cert_list.push(cred)
                            })

                            setCertificates(cert_list)
                        } else setCertificates([])
                    };
                    var body = {
                        'recipient': {
                            'email': user?.email
                        }
                    };
                    request.send(JSON.stringify(body));


                    // Registered Courses
                    const eventDocRef = doc(db, "Event", _id);
                    const eventDocSnap = await getDoc(eventDocRef);
                    if (eventDocSnap.exists()) {
                        const event = eventDocSnap.data()
                        var registered = false
                        var inPerson = false
                        event.attendees.map(attendee => {
                            if (attendee._id === user.uid) {
                                registered = true
                                inPerson = attendee.inPerson ? attendee.inPerson : false
                            }
                        })

                        if (registered) {
                            setCourse(event)
                            setInPerson(inPerson)

                            setCourseJsonInfo(getCourseJson(event.titleAbbrev))
                        }
                    } else {
                        // docSnap.data() will be undefined in this case
                        console.log("No such document!");
                    }
                } else {
                    // doc.data() will be undefined in this case
                    setErrorAccured('Member Document Not Found')
                }
            } else {
                window.location.href = "/login";
            }
        })
    }, [])

    return (
        <div>
            <Helmet>
                <title>Course {course ? course.titleAbbrev : ''}</title>
            </Helmet>
            <link rel="icon" href="/favicon.png" />
            <link rel="apple-touch-icon" href="/idca-logo-with-bg.jpg" />

            <nav>
                {userInfo &&
                    <MemberNavbar user={userInfo} page='home' />
                }
            </nav>

            <main className="Saira_Medium_Black">
                <UserPortalCourse userInfo={userInfo} course={course} courseJsonInfo={courseJsonInfo} certificates={certificates} inPerson={inPerson} />
            </main>
        </div >
    )
}