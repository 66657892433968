
import React from "react";
import Login from '../components/Pages/Login/Login.js'
import MemberNavbar from "../components/Pages/MemberArea/Navbar/Navbar.js";
import { Helmet } from "react-helmet"


export default function LoginPage() {

    return (
        <div>
            <Helmet>
                <title>Login to IDCA Account</title>
            </Helmet>
            <meta name="description" content="IDCA portal login" />
            <link rel="icon" href="/favicon.png" />
            <link rel="apple-touch-icon" href="/idca-logo-with-bg.jpg" />

            <nav>
                <MemberNavbar not_signed_in={true} />
            </nav>

            <main>
                <Login />
            </main>
        </div >
    )
}