import React, { useEffect, useState } from 'react';
import { getFirestore, query, collection, getDoc, getDocs, doc, orderBy, updateDoc, arrayUnion } from "firebase/firestore";

function MembershipEventRequests() {

    const [sharedE, setsharedE] = useState([]);
    const db = getFirestore();

    useEffect(() => {
        getSharedExperiences()
    }, [])

    useEffect(() => {
        // getEventsInfo()
    }, [sharedE])

    async function getSharedExperiences() {
        const q = query(collection(db, "Requested Events"), orderBy("date", "desc"));
        var querySnapshot = await getDocs(q)
        var temp = []
        querySnapshot.forEach((data) => {
            temp.push(data.data())
            console.log(data.data())
        })

        var temp2 = []
        for (let i of temp) {
            var event = await getDoc(doc(db, "Event", `${i.eventID}`))
            if (event.data())
                temp2.push({ ...i, attendees: event.data().attendees ? event.data().attendees.length : 0, limit: event.data().RegistrationLimit ? event.data().RegistrationLimit : 1000 })
        }

        setsharedE(temp2)
    }

    async function approve(uid, email, name, eventId, eventName, id) {

        if (window.confirm("Are you sure you want to approve this?") == true) {
            const userRef = doc(db, "Users", uid);
            const eventRef = doc(db, "Event", eventId);
            const reqRef = doc(db, "Requested Events", id);

            var userData = {
                _id: uid,
                email: email,
                name: name
            }

            var eventData = {
                eventID: eventId,
                eventName: eventName,
            }

            await updateDoc(eventRef, {
                attendees: arrayUnion(userData)
            });

            await updateDoc(userRef, {
                courses: arrayUnion(eventData)
            });

            await updateDoc(reqRef, {
                status: "Registered"
            });
            getSharedExperiences()
        }
    }

    async function reject(id) {
        if (window.confirm("Are you sure you want to reject this?") == true) {
            const reqRef = doc(db, "Requested Events", id);
            await updateDoc(reqRef, {
                status: "Rejected"
            });
            getSharedExperiences()
        }
    }



    return (
        <div className="Dashboard_Right_Pannel_whole Saira_Medium_Black PointRequestsAdminPannel" style={{ marginTop: '150px' }}>

            <h1>
                Event Requests
            </h1>

            <table className='memberMileageTable'>
                <thead>
                    <tr className='memberMileageTableRow'>
                        <th className='memberMileageTableHeader'>
                            Date
                        </th>
                        <th className='memberMileageTableHeader'>
                            Member Name
                        </th>
                        <th className='memberMileageTableHeader'>
                            Event Name
                        </th>
                        <th className='memberMileageTableHeader'>
                            Event Date
                        </th>
                        <th className='memberMileageTableHeader'>
                            # Attendees
                        </th>
                        <th className='memberMileageTableHeader'>
                            Event Limit
                        </th>
                        <th className='memberMileageTableHeader'>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sharedE?.map((wp, i) => (
                            <tr className='memberMileageTableRow' key={i}>

                                <td className="Saira_Regular_Black memberMileageTableData" >
                                    {new Date(wp.date).toLocaleString()}
                                </td>

                                <td className="Saira_Regular_Black memberMileageTableData" >
                                    {wp.displayName}
                                </td>

                                <td className='memberMileageTableData'>
                                    <button onClick={() => alert(wp.eventName)} >View Name</button>
                                </td>

                                <td className="Saira_Regular_Black memberMileageTableData" >
                                    {wp.eventDate}
                                </td>

                                <td className="Saira_Regular_Black memberMileageTableData" >
                                    {wp.attendees}
                                </td>

                                <td className="Saira_Regular_Black memberMileageTableData" >
                                    {wp.limit}
                                </td>
                                {
                                    wp?.status == "Registered" || wp?.status == "Rejected" ?
                                        <p style={{ "textAlign": "center" }}>
                                            {
                                                wp?.status
                                            }
                                        </p>
                                        :
                                        <td className='WhitePaperAdminSingleWhitepaperButtons'>
                                            <button className="WhitePaperAdminSingleDeleteButton" onClick={() => { approve(wp.uid, wp.email, wp.displayName, wp.eventID, wp.eventName, wp._id) }}>
                                                Approve
                                            </button>
                                            <button className="WhitePaperAdminSingleDeleteButton" onClick={() => { reject(wp._id) }}>
                                                Reject
                                            </button>
                                        </td>

                                }

                            </tr>

                        ))
                    }
                </tbody>

            </table>

        </div >
    );
}


export default MembershipEventRequests

