import React, { useState, useEffect } from 'react';

import IDCALogo from '../../../resources/idca-logo.png'

import {
    getAuth, GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, sendEmailVerification,
} from "firebase/auth";

import { getFirestore, setDoc, getDoc, doc, updateDoc } from "firebase/firestore";

import GoogleButton from 'react-google-button'
import createUserAndEventFirestore from '../../Components/CreateUserAndEventFirestore/CreateUserAndEventFirestore';
import AccountCreated from '../../Components/EmailServer/AccountCreated';


function Login({ eventID }) {

    const db = getFirestore();

    const [invalidCredentials, setInvalidCredentials] = useState(false)
    const [somethingWrong, setSomethingWrong] = useState(false)


    const [passConfirmCorrect, setPassConfirmCorrect] = useState(false)
    const [passConfirmColor, setPassConfirmColor] = useState('')

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")

    const [initialEmail, setInitialEmail] = useState('')

    const [emailSent, setEmailSent] = useState(false)

    const [checkEmailToVerify, setCheckEmailToVerify] = useState(false)

    const [accountExist, setAccountExist] = useState(false)

    const [disableCreateAccount, setDisableCreateAccount] = useState(false)

    const [resetEmailSent, setResetEmailSent] = useState(false)

    const [email, setEmail] = useState()


    useEffect(() => {
        getAuth().onAuthStateChanged(async user => {
            if (user && user.emailVerified) {
                const docRef = doc(db, "Users", user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    console.log('logging in')
                    window.location.href = "/";
                } else {
                    console.log('Doesn Exist')
                }
            }
        })
    }, [])

    async function regularLogin() {
        var email = document.getElementById('create_account_form_email_input').value;
        const password = document.getElementById('create_account_form_password_input').value

        const company = document.getElementById('create_account_form_company_input').value
        const title = document.getElementById('create_account_form_title_input').value
        const state = document.getElementById('create_account_form_state_input').value
        const phone = document.getElementById('create_account_form_phone_input').value
        const country = document.getElementById('create_account_form_country_input').value


        email = email.toLowerCase();

        var filter = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        // if any of the fields are incomplete then don't allow the user to be made and sent to firebase
        if (!firstName || !lastName || !email || !filter.test(email)
            || !passConfirmCorrect || !company || !title || !state || !phone) {
            setInvalidCredentials(true)
            return;
        }
        setInvalidCredentials(false)
        setEmail(email)


        // Authenticate the user and create an account for them
        const actionCodeSettings = {
            url: 'http://portal.idc-a.org/login',
            // This must be true.x
            handleCodeInApp: true,
        };

        // Link the credential to the current user.
        var auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                sendEmailVerification(user, actionCodeSettings)
                    .then(() => {
                        // Email verification sent!
                        // ...
                        setCheckEmailToVerify(true)
                        const response = createUserAndEventFirestore({ user: user, signInMethod: 'EmailPass', email: email, firstName: firstName, lastName: lastName, company: company, title: title, state: state, phone: phone, country: country })
                        if (response == 'somethingWrong')
                            setSomethingWrong(true)
                    })
                    .catch(() => { setSomethingWrong(true) })

            })
            .catch((error) => {
                if (error.code == 'auth/email-already-in-use') {
                    setAccountExist(true)
                } else { setSomethingWrong(true) }
            })
            .finally(() => setDisableCreateAccount(true))
    }


    function googleSignUp() {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();

        signInWithPopup(auth, provider)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;

                const nameArray = user.displayName.split(" ");
                var firstNameGoogle = nameArray[0];
                var lastNameGoogle = nameArray[nameArray.length - 1];

                firstNameGoogle = firstNameGoogle.charAt(0).toUpperCase() + firstNameGoogle.slice(1);
                lastNameGoogle = lastNameGoogle.charAt(0).toUpperCase() + lastNameGoogle.slice(1);

                const email = user.email.toLowerCase();

                const response = createUserAndEventFirestore({ user: user, signInMethod: 'Google', email: email, firstName: firstNameGoogle, lastName: lastNameGoogle, displayName: user.displayName })
                if (response == 'somethingWrong')
                    setSomethingWrong(true)
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);

                alert(errorMessage)
                // ...
            });
    }



    function passwordCheck(value, id) {
        let passwordStrong = false
        const confirmPassValue = document.getElementById('create_account_form_password_confirm_input').value;
        var hasNumber = /\d/;

        var fname = document.getElementById(id);
        if (value.length < 6 || !hasNumber.test(value)) {
            fname.style.borderColor = '#AD2225'
            setPassConfirmCorrect(false)
        } else {
            fname.style.borderColor = '#1f850bb4'
            passwordStrong = true
            setPassConfirmCorrect(true)
        }

        if (passwordStrong && confirmPassValue == value) {
            setPassConfirmColor('#1f850bb4')
        } else if (!confirmPassValue.length)
            setPassConfirmColor('')
        else {
            setPassConfirmColor('#AD2225')
        }
    }


    function update_name_case(n, num) {
        var result = n.charAt(0).toUpperCase() +
            n.slice(1).toLowerCase();
        if (num == 1) {
            setFirstName(result)

        } else {
            setLastName(result)
        }
    }

    function passwordConfirmCheck(value, id) {
        var mainPass = document.getElementById('create_account_form_password_input');
        if (mainPass.value == value && passConfirmCorrect)
            setPassConfirmColor('#1f850bb4')
        else if (!value.length)
            setPassConfirmColor('')
        else
            setPassConfirmColor('#AD2225')
    }


    return (
        <div className='Saira_Regular_Black all_div_margin' style={{
            marginTop: '150px', marginBottom: '100px', padding: '50px 100px',
            boxShadow: '0px 3.42257px 23.1023px rgba(0, 0, 0, 0.1)'
        }}>
            <div>
                <h1 className='Saira_Medium_Black'>Create An Account</h1>
                <p className='Saira_Medium_Black' style={{ display: checkEmailToVerify ? 'inherit' : 'none', color: 'green' }}>Please check your email to verify your account</p>
                <p className='Saira_Medium_Red' style={{ display: accountExist ? 'inherit' : 'none' }}>User already exists. Either
                    <a href='/login'> login</a> or <a href='/reset-password'>reset</a> your password.
                    <br /><span className='Saira_Medium_Gray' style={{ fontSize: '13px' }}>resetting your password would also verify your email</span></p>
            </div>


            <div className='create_account_top_div_frame'>
                <div>

                    <div className='login_landing_page_data_fields__top_div Saira_Regular_Black'>
                        <div className='login_landing_page_data_fields_div Saira_Regular_DGray'>
                            <p className='Saira_Medium_Red' style={{ display: invalidCredentials ? 'initial' : 'none' }}>Incomplete credentials</p>
                            <br style={{ display: somethingWrong ? 'initial' : 'none' }}></br>
                            <p className='Saira_Medium_Red' style={{ display: somethingWrong ? 'initial' : 'none' }}>Something went wrong</p>
                            <br style={{ display: somethingWrong ? 'initial' : 'none' }}></br>

                            <div className="login_landing_page_data_fields_two_in_row_div">
                                <div>
                                    <label htmlFor="first_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <p className="data-center-contact-us-form-input-label">First Name</p>
                                    </label>
                                    <input className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                        maxLength="40" name="first_name" size="20" type="text" required="required" value={firstName} onChange={e => update_name_case(e.target.value, 1)} />
                                </div>
                                <div>
                                    <label htmlFor="last_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <p className="data-center-contact-us-form-input-label">Last Name</p>
                                    </label>
                                    <input className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                        maxLength="80" name="last_name" size="20" type="text" required="required" value={lastName} onChange={e => update_name_case(e.target.value, 2)} />
                                </div>
                            </div>

                            <div className="login_landing_page_data_fields_two_in_row_div">
                                <div>
                                    <label htmlFor="email" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <p className="data-center-contact-us-form-input-label">Email</p>
                                    </label>
                                    <input id="create_account_form_email_input" defaultValue={initialEmail}
                                        className="Saira_Medium_Black data-center-contact-us-form-input-box" maxLength="35" name="email" size="20" type="email" required="required" />
                                </div>

                                <div>
                                    <label htmlFor="phone" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <p className="data-center-contact-us-form-input-label">Phone</p>
                                    </label>
                                    <input id="create_account_form_phone_input"
                                        className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                        maxLength="20" name="phone" size="20" type="tel" />
                                </div>
                            </div>


                            <div>
                                <label htmlFor="email" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <p className="data-center-contact-us-form-input-label">Password</p>
                                </label>
                                <div style={{ marginLeft: '10px' }}>
                                    <p style={{ fontSize: '13px', textAlign: 'left', margin: '0px' }}>&#8226; minimum of 6 characters</p>
                                    <p style={{ fontSize: '13px', textAlign: 'left', margin: '0px' }}>&#8226; must include a number</p>
                                </div>
                                <input id="create_account_form_password_input"
                                    onChange={e => passwordCheck(e.target.value, e.target.id)}
                                    className="Saira_Medium_Black data-center-contact-us-form-input-box" maxLength="80" name="password" size="20" type="password" required="required" />


                                <label htmlFor="email" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <p className="data-center-contact-us-form-input-label">Confirm Password</p>
                                </label>
                                <input id="create_account_form_password_confirm_input" style={{
                                    borderColor: passConfirmColor
                                }}
                                    onChange={e => passwordConfirmCheck(e.target.value, e.target.id)}
                                    className="Saira_Medium_Black data-center-contact-us-form-input-box" maxLength="80" name="password" size="20" type="password" required="required" />
                            </div>


                            <div className="login_landing_page_data_fields_two_in_row_div">
                                <div>
                                    <label htmlFor="company"><p className="data-center-contact-us-form-input-label">Company</p></label>
                                    <input id="create_account_form_company_input"
                                        className="Saira_Medium_Black data-center-contact-us-form-input-box" maxLength="40" name="company" size="20" type="text" />
                                </div>

                                <div>
                                    <label htmlFor="title"><p className="data-center-contact-us-form-input-label">Title</p></label>
                                    <input id="create_account_form_title_input"
                                        className="Saira_Medium_Black data-center-contact-us-form-input-box" maxLength="40" name="title" size="20" type="text" />

                                </div>
                            </div>

                            <div className="login_landing_page_data_fields_two_in_row_div">
                                <div>
                                    <label htmlFor="StateContactUsFormPage"><p className="data-center-contact-us-form-input-label">State/Province</p></label>
                                    <input id="create_account_form_state_input"
                                        className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                        maxLength="40" name="state" size="20" type="text" />
                                </div>

                                <div>
                                    <label htmlFor="country" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <p className="data-center-contact-us-form-input-label">Country</p>
                                    </label>
                                    <select className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                        id="create_account_form_country_input" name="country" required>
                                        <option value='Andorra'>Andorra</option>
                                        <option value='United Arab Emirates'>United Arab Emirates</option>
                                        <option value='Afghanistan'>Afghanistan</option>
                                        <option value='Antigua and Barbuda'>Antigua and Barbuda</option>
                                        <option value='Anguilla'>Anguilla</option>
                                        <option value='Albania'>Albania</option>
                                        <option value='Armenia'>Armenia</option>
                                        <option value='Angola'>Angola</option>
                                        <option value='Antarctica'>Antarctica</option>
                                        <option value='Argentina'>Argentina</option>
                                        <option value='Austria'>Austria</option>
                                        <option value='Australia*'>Australia*</option>
                                        <option value='Aruba'>Aruba</option>
                                        <option value='Aland Islands'>Aland Islands</option>
                                        <option value='Azerbaijan'>Azerbaijan</option>
                                        <option value='Bosnia and Herzegovina'>Bosnia and Herzegovina</option>
                                        <option value='Barbados'>Barbados</option>
                                        <option value='Bangladesh'>Bangladesh</option>
                                        <option value='Belgium'>Belgium</option>
                                        <option value='Burkina Faso'>Burkina Faso</option>
                                        <option value='Bulgaria'>Bulgaria</option>
                                        <option value='Bahrain'>Bahrain</option>
                                        <option value='Burundi'>Burundi</option>
                                        <option value='Benin'>Benin</option>
                                        <option value='Saint Barthélemy'>Saint Barthélemy</option>
                                        <option value='Bermuda'>Bermuda</option>
                                        <option value='Brunei Darussalam'>Brunei Darussalam</option>
                                        <option value='Bolivia, Plurinational State of'>Bolivia, Plurinational State of</option>
                                        <option value='Bonaire, Sint Eustatius and Saba'>Bonaire, Sint Eustatius and Saba</option>
                                        <option value='Brazil*'>Brazil*</option>
                                        <option value='Bahamas'>Bahamas</option>
                                        <option value='Bhutan'>Bhutan</option>
                                        <option value='Bouvet Island'>Bouvet Island</option>
                                        <option value='Botswana'>Botswana</option>
                                        <option value='Belarus'>Belarus</option>
                                        <option value='Belize'>Belize</option>
                                        <option value='Canada'>Canada</option>
                                        <option value='Cocos (Keeling) Islands'>Cocos (Keeling) Islands</option>
                                        <option value='Congo, the Democratic Republic of the'>Congo, the Democratic Republic of the</option>
                                        <option value='Central African Republic'>Central African Republic</option>
                                        <option value='Congo'>Congo</option>
                                        <option value='Switzerland'>Switzerland</option>
                                        <option value='Cote d’Ivoire'>Cote d’Ivoire</option>
                                        <option value='Cook Islands'>Cook Islands</option>
                                        <option value='Chile'>Chile</option>
                                        <option value='Cameroon'>Cameroon</option>
                                        <option value='China'>China</option>
                                        <option value='Colombia'>Colombia</option>
                                        <option value='Costa Rica'>Costa Rica</option>
                                        <option value='Cuba'>Cuba</option>
                                        <option value='Cape Verde'>Cape Verde</option>
                                        <option value='Curaçao'>Curaçao</option>
                                        <option value='Christmas Island'>Christmas Island</option>
                                        <option value='Cyprus'>Cyprus</option>
                                        <option value='Czech Republic'>Czech Republic</option>
                                        <option value='Germany'>Germany</option>
                                        <option value='Djibouti'>Djibouti</option>
                                        <option value='Denmark'>Denmark</option>
                                        <option value='Dominica'>Dominica</option>
                                        <option value='Dominican Republic'>Dominican Republic</option>
                                        <option value='Algeria'>Algeria</option>
                                        <option value='Ecuador'>Ecuador</option>
                                        <option value='Estonia'>Estonia</option>
                                        <option value='Egypt'>Egypt</option>
                                        <option value='Western Sahara'>Western Sahara</option>
                                        <option value='Eritrea'>Eritrea</option>
                                        <option value='Spain'>Spain</option>
                                        <option value='Ethiopia'>Ethiopia</option>
                                        <option value='Finland'>Finland</option>
                                        <option value='Fiji'>Fiji</option>
                                        <option value='Falkland Islands (Malvinas)'>Falkland Islands (Malvinas)</option>
                                        <option value='Faroe Islands'>Faroe Islands</option>
                                        <option value='France'>France</option>
                                        <option value='Gabon'>Gabon</option>
                                        <option value='United Kingdom'>United Kingdom</option>
                                        <option value='Grenada'>Grenada</option>
                                        <option value='Georgia'>Georgia</option>
                                        <option value='French Guiana'>French Guiana</option>
                                        <option value='Guernsey'>Guernsey</option>
                                        <option value='Ghana'>Ghana</option>
                                        <option value='Gibraltar'>Gibraltar</option>
                                        <option value='Greenland'>Greenland</option>
                                        <option value='Gambia'>Gambia</option>
                                        <option value='Guinea'>Guinea</option>
                                        <option value='Guadeloupe'>Guadeloupe</option>
                                        <option value='Equatorial Guinea'>Equatorial Guinea</option>
                                        <option value='Greece'>Greece</option>
                                        <option value='South Georgia and the South Sandwich Islands'>South Georgia and the South Sandwich Islands</option>
                                        <option value='Guatemala'>Guatemala</option>
                                        <option value='Guinea-Bissau'>Guinea-Bissau</option>
                                        <option value='Guyana'>Guyana</option>
                                        <option value='Heard Island and McDonald Islands'>Heard Island and McDonald Islands</option>
                                        <option value='Honduras'>Honduras</option>
                                        <option value='Croatia'>Croatia</option>
                                        <option value='Haiti'>Haiti</option>
                                        <option value='Hungary'>Hungary</option>
                                        <option value='Indonesia'>Indonesia</option>
                                        <option value='Ireland'>Ireland</option>
                                        <option value='Israel'>Israel</option>
                                        <option value='Isle of Man'>Isle of Man</option>
                                        <option value='India'>India</option>
                                        <option value='British Indian Ocean Territory'>British Indian Ocean Territory</option>
                                        <option value='Iraq'>Iraq</option>
                                        <option value='Iran, Islamic Republic of'>Iran, Islamic Republic of</option>
                                        <option value='Iceland'>Iceland</option>
                                        <option value='Italy'>Italy</option>
                                        <option value='Jersey'>Jersey</option>
                                        <option value='Jamaica'>Jamaica</option>
                                        <option value='Jordan'>Jordan</option>
                                        <option value='Japan'>Japan</option>
                                        <option value='Kenya'>Kenya</option>
                                        <option value='Kyrgyzstan'>Kyrgyzstan</option>
                                        <option value='Cambodia'>Cambodia</option>
                                        <option value='Kiribati'>Kiribati</option>
                                        <option value='Comoros'>Comoros</option>
                                        <option value='Saint Kitts and Nevis'>Saint Kitts and Nevis</option>
                                        <option value='Korea, Democratic People’s Republic of'>Korea, Democratic People’s Republic of</option>
                                        <option value='Korea, Republic of'>Korea, Republic of</option>
                                        <option value='Kuwait'>Kuwait</option>
                                        <option value='Cayman Islands'>Cayman Islands</option>
                                        <option value='Kazakhstan'>Kazakhstan</option>
                                        <option value='Lao People’s Democratic Republic'>Lao People’s Democratic Republic</option>
                                        <option value='Lebanon'>Lebanon</option>
                                        <option value='Saint Lucia'>Saint Lucia</option>
                                        <option value='Liechtenstein'>Liechtenstein</option>
                                        <option value='Sri Lanka'>Sri Lanka</option>
                                        <option value='Liberia'>Liberia</option>
                                        <option value='Lesotho'>Lesotho</option>
                                        <option value='Lithuania'>Lithuania</option>
                                        <option value='Luxembourg'>Luxembourg</option>
                                        <option value='Latvia'>Latvia</option>
                                        <option value='Libya'>Libya</option>
                                        <option value='Morocco'>Morocco</option>
                                        <option value='Monaco'>Monaco</option>
                                        <option value='Moldova, Republic of'>Moldova, Republic of</option>
                                        <option value='Montenegro'>Montenegro</option>
                                        <option value='Saint Martin (French part)'>Saint Martin (French part)</option>
                                        <option value='Madagascar'>Madagascar</option>
                                        <option value='Macedonia, the former Yugoslav Republic of'>Macedonia, the former Yugoslav Republic of</option>
                                        <option value='Mali'>Mali</option>
                                        <option value='Myanmar'>Myanmar</option>
                                        <option value='Mongolia'>Mongolia</option>
                                        <option value='Macao'>Macao</option>
                                        <option value='Martinique'>Martinique</option>
                                        <option value='Mauritania'>Mauritania</option>
                                        <option value='Montserrat'>Montserrat</option>
                                        <option value='Malta'>Malta</option>
                                        <option value='Mauritius'>Mauritius</option>
                                        <option value='Maldives'>Maldives</option>
                                        <option value='Malawi'>Malawi</option>
                                        <option value='Mexico'>Mexico</option>
                                        <option value='Malaysia'>Malaysia</option>
                                        <option value='Mozambique'>Mozambique</option>
                                        <option value='Namibia'>Namibia</option>
                                        <option value='New Caledonia'>New Caledonia</option>
                                        <option value='Niger'>Niger</option>
                                        <option value='Norfolk Island'>Norfolk Island</option>
                                        <option value='Nigeria'>Nigeria</option>
                                        <option value='Nicaragua'>Nicaragua</option>
                                        <option value='Netherlands'>Netherlands</option>
                                        <option value='Norway'>Norway</option>
                                        <option value='Nepal'>Nepal</option>
                                        <option value='Nauru'>Nauru</option>
                                        <option value='Niue'>Niue</option>
                                        <option value='New Zealand'>New Zealand</option>
                                        <option value='Oman'>Oman</option>
                                        <option value='Panama'>Panama</option>
                                        <option value='Peru'>Peru</option>
                                        <option value='French Polynesia'>French Polynesia</option>
                                        <option value='Papua New Guinea'>Papua New Guinea</option>
                                        <option value='Philippines'>Philippines</option>
                                        <option value='Pakistan'>Pakistan</option>
                                        <option value='Poland'>Poland</option>
                                        <option value='Saint Pierre and Miquelon'>Saint Pierre and Miquelon</option>
                                        <option value='Pitcairn'>Pitcairn</option>
                                        <option value='Palestine'>Palestine</option>
                                        <option value='Portugal'>Portugal</option>
                                        <option value='Paraguay'>Paraguay</option>
                                        <option value='Qatar'>Qatar</option>
                                        <option value='Reunion'>Reunion</option>
                                        <option value='Romania'>Romania</option>
                                        <option value='Serbia'>Serbia</option>
                                        <option value='Russian Federation'>Russian Federation</option>
                                        <option value='Rwanda'>Rwanda</option>
                                        <option value='Saudi Arabia'>Saudi Arabia</option>
                                        <option value='Solomon Islands'>Solomon Islands</option>
                                        <option value='Seychelles'>Seychelles</option>
                                        <option value='Sudan'>Sudan</option>
                                        <option value='Sweden'>Sweden</option>
                                        <option value='Singapore'>Singapore</option>
                                        <option value='Saint Helena, Ascension and Tristan da Cunha'>Saint Helena, Ascension and Tristan da Cunha</option>
                                        <option value='Slovenia'>Slovenia</option>
                                        <option value='Svalbard and Jan Mayen'>Svalbard and Jan Mayen</option>
                                        <option value='Slovakia'>Slovakia</option>
                                        <option value='Sierra Leone'>Sierra Leone</option>
                                        <option value='San Marino'>San Marino</option>
                                        <option value='Senegal'>Senegal</option>
                                        <option value='Somalia'>Somalia</option>
                                        <option value='Suriname'>Suriname</option>
                                        <option value='South Sudan'>South Sudan</option>
                                        <option value='Sao Tome and Principe'>Sao Tome and Principe</option>
                                        <option value='El Salvador'>El Salvador</option>
                                        <option value='Sint Maarten (Dutch part)'>Sint Maarten (Dutch part)</option>
                                        <option value='Syrian Arab Republic'>Syrian Arab Republic</option>
                                        <option value='Swaziland'>Swaziland</option>
                                        <option value='Turks and Caicos Islands'>Turks and Caicos Islands</option>
                                        <option value='Chad'>Chad</option>
                                        <option value='French Southern Territories'>French Southern Territories</option>
                                        <option value='Togo'>Togo</option>
                                        <option value='Thailand'>Thailand</option>
                                        <option value='Tajikistan'>Tajikistan</option>
                                        <option value='Tokelau'>Tokelau</option>
                                        <option value='Timor-Leste'>Timor-Leste</option>
                                        <option value='Turkmenistan'>Turkmenistan</option>
                                        <option value='Tunisia'>Tunisia</option>
                                        <option value='Tonga'>Tonga</option>
                                        <option value='Turkey'>Turkey</option>
                                        <option value='Trinidad and Tobago'>Trinidad and Tobago</option>
                                        <option value='Tuvalu'>Tuvalu</option>
                                        <option value='Taiwan'>Taiwan</option>
                                        <option value='Tanzania, United Republic of'>Tanzania, United Republic of</option>
                                        <option value='Ukraine'>Ukraine</option>
                                        <option value='Uganda'>Uganda</option>
                                        <option value='United States' selected>United States</option>
                                        <option value='Uruguay'>Uruguay</option>
                                        <option value='Uzbekistan'>Uzbekistan</option>
                                        <option value='Holy See (Vatican City State)'>Holy See (Vatican City State)</option>
                                        <option value='Saint Vincent and the Grenadines'>Saint Vincent and the Grenadines</option>
                                        <option value='Venezuela, Bolivarian Republic of'>Venezuela, Bolivarian Republic of</option>
                                        <option value='Virgin Islands, British'>Virgin Islands, British</option>
                                        <option value='Vietnam'>Vietnam</option>
                                        <option value='Vanuatu'>Vanuatu</option>
                                        <option value='Wallis and Futuna'>Wallis and Futuna</option>
                                        <option value='Samoa'>Samoa</option>
                                        <option value='Yemen'>Yemen</option>
                                        <option value='Mayotte'>Mayotte</option>
                                        <option value='South Africa'>South Africa</option>
                                        <option value='Zambia'>Zambia</option>
                                        <option value='Zimbabwe'>Zimbabwe</option>
                                    </select>
                                </div>
                            </div>


                            <center>
                                <input className="Saira_Bold_White" id="data-center-contact-us-form-submit-button" type="submit" name="submit"
                                    value="Create Account" disabled={disableCreateAccount} onClick={() => { regularLogin() }}
                                    style={{ cursor: disableCreateAccount ? 'default' : 'pointer', backgroundColor: disableCreateAccount ? '#ad2224b5' : '' }} />
                            </center>
                        </div>
                    </div>

                </div>




                <div className="login_to_create_account_line_div"></div>


                <div className='create_account_google_and_facebook_login_div'>
                    <center>
                        <img src={IDCALogo} />
                    </center>
                    <h1>Sign Up Using</h1>
                    <GoogleButton label='Continue with Google' onClick={googleSignUp} />
                </div>
            </div>
        </div>
    );
}


export default Login

