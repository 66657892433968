import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, signInWithEmailAndPassword } from "firebase/auth";


import { getFirestore, setDoc, getDoc, updateDoc, doc } from "firebase/firestore";

import { AiFillEyeInvisible } from 'react-icons/ai'
import { AiFillEye } from 'react-icons/ai'

import GoogleButton from 'react-google-button'

import IDCALogo from '../../../resources/idca-logo.png'
import AccountCreated from '../../Components/EmailServer/AccountCreated';


function Login() {
    const db = getFirestore();

    useEffect(() => {
        getAuth().onAuthStateChanged(async user => {
            if (user && user.emailVerified) {
                const docRef = doc(db, "Users", user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    console.log('logging in')
                    window.location.href = '/';
                } else {
                    console.log('Doesn Exist')
                }
            }
        })
    }, [])

    const [passHidden, setPassHidden] = useState(true)
    const [invalidCredentials, setInvalidCredentials] = useState(false)
    const [userNotVerified, setUserNotVerified] = useState(false)


    function googleSignUp() {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();

        signInWithPopup(auth, provider)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;

                const nameArray = user.displayName.split(" ");
                var firstNameGoogle = nameArray[0];
                var lastNameGoogle = nameArray[nameArray.length - 1];

                firstNameGoogle = firstNameGoogle.charAt(0).toUpperCase() + firstNameGoogle.slice(1);
                lastNameGoogle = lastNameGoogle.charAt(0).toUpperCase() + lastNameGoogle.slice(1);

                const email = user.email.toLowerCase();

                try {
                    const docRef = doc(db, "Users", user.uid);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        await setDoc(doc(db, "Users", user.uid), {
                            ...docSnap.data(),
                            accountCreateDetail: {
                                createdAt: user?.metadata?.createdAt,
                                creationTime: user?.metadata?.creationTime,
                                lastLoginAt: user?.metadata?.lastLoginAt,
                                lastSignInTime: user?.metadata?.lastSignInTime
                            },
                            signedInBy: 'Google'
                        })
                    } else {
                        await setDoc(doc(db, "Users", user.uid), {
                            displayName: user.displayName,
                            firstName: firstNameGoogle,
                            lastName: lastNameGoogle,
                            email: email,
                            phone: user.phoneNumber,
                            photoURL: user.photoURL,
                            uid: user.uid,
                            accountCreateDetail: {
                                createdAt: user?.metadata?.createdAt,
                                creationTime: user?.metadata?.creationTime,
                                lastLoginAt: user?.metadata?.lastLoginAt,
                                lastSignInTime: user?.metadata?.lastSignInTime
                            },
                            signedInBy: 'Google',
                            _createdAt: user?.metadata?.createdAt
                        }).then(() => {
                            console.log('created user')
                            AccountCreated({ userEmail: email, userName: user.displayName ? user.displayName : '' })
                            window.location.href = "/"
                        })
                    }
                } catch (err) {
                    console.log(err)
                }

                window.location.href = '/';
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);

                alert(errorMessage)
                // ...
            });
    }


    function changeHiddenStatus() {
        var password = document.getElementById('create_account_form_password_input')
        if (passHidden) {
            setPassHidden(false)
            password.type = 'text'
        }
        else {
            setPassHidden(true)
            password.type = 'password'
        }
    }

    function loginClick() {
        var email = document.getElementById('create_account_form_email_input').value
        var password = document.getElementById('create_account_form_password_input').value

        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                console.log(userCredential)
                // Signed in 
                const user = userCredential.user;
                if (user.emailVerified) {
                    const docRef = doc(db, 'Users', user.uid);
                    try {
                        await updateDoc(docRef, {
                            accountCreateDetail: {
                                createdAt: user?.metadata?.createdAt,
                                creationTime: user?.metadata?.creationTime,
                                lastLoginAt: user?.metadata?.lastLoginAt,
                                lastSignInTime: user?.metadata?.lastSignInTime
                            }
                        });
                    } catch (error) {
                        console.log(error)
                    }
                    window.location.href = '/';
                    // ...
                } else {
                    setUserNotVerified(true)
                    signOut(auth).then(() => {
                        // Sign-out successful.
                    }).catch((error) => {
                        // An error happened.
                    });
                    console.log('User Not Verified')
                }
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setInvalidCredentials(true)
            });
    }

    return (
        <div className='all_div_margin login_and_create_account_top_div_frame' style={{ marginTop: '150px', marginBottom: '100px' }}>
            <div>
                <h1 className='Saira_Medium_Black'>Login To Your Account</h1>
            </div>

            <div className='Saira_Regular_Black login_and_create_account_top_div'>
                <div>
                    <div className="login_to_account_email_pass_div">
                        <p className='Saira_Medium_Red' style={{ display: invalidCredentials ? 'initial' : 'none' }}>Invalid email or password</p>
                        <p className='Saira_Medium_Red' style={{ display: userNotVerified ? 'initial' : 'none' }}>Account has been created but not verified.</p>
                        <div>
                            <label htmlFor="email" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <p className="data-center-contact-us-form-input-label">Email</p>
                            </label>
                            <input id="create_account_form_email_input"
                                className="Saira_Medium_Black data-center-contact-us-form-input-box" maxLength="100" name="email" size="20" type="email" required="required" />
                        </div>

                        <div>
                            <label htmlFor="email" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <p className="data-center-contact-us-form-input-label">Password</p>
                            </label>
                            <div className='login_to_account_pass_div'>
                                <input id="create_account_form_password_input" type="password"
                                    className="Saira_Medium_Black data-center-contact-us-form-input-box" maxLength="80" name="password" size="20" required="required" />
                                <AiFillEyeInvisible className='login_to_account_pass_hidden' onClick={e => changeHiddenStatus()}
                                    style={{ display: passHidden ? 'initial' : 'none', cursor: 'pointer', width: '20px', height: '20px' }} />
                                <AiFillEye className='login_to_account_pass_hidden' onClick={e => changeHiddenStatus()}
                                    style={{ display: passHidden ? 'none' : 'initial', cursor: 'pointer', width: '20px', height: '20px' }} />
                            </div>
                        </div>
                    </div>
                    <div className='login_to_account_login_div'>
                        <button className='login_to_account_login_button Saira_Regular_White' id="data-center-contact-us-form-submit-button" onClick={e => loginClick()}>Login</button>
                        <center style={{ marginBottom: '30px' }}>
                            <p style={{ fontSize: '14px', lineHeight: '10px', margin: '5px' }}>have you forgotten your password?</p>
                            {/* Giving Build Error HERE */}
                            <a href="/reset-password" style={{ fontSize: '14px', textDecoration: 'underline' }}>
                                reset password
                            </a>
                        </center>
                    </div>

                    <p style={{ textAlign: 'center' }}>Or</p>

                    <div className='login_google_and_facebook_login_div'>
                        <GoogleButton label='Continue with Google' onClick={googleSignUp} />
                    </div>
                </div>

                <div className="login_to_create_account_line_div"></div>

                <div className='login_create_account_button_logo_div'>
                    <img src={IDCALogo} />
                    <div className='login_create_account_button_right_div'>
                        <a href='/create-an-idca-account'>
                            <a className='login_create_account_button_right Saira_Regular_White'
                                id="data-center-contact-us-form-submit-button" style={{
                                    textDecoration: 'none'
                                }} >Create an Account</a>
                        </a>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default Login

