import React, { useEffect, useState } from 'react';
import { getFirestore, query, collection, getDocs, doc, updateDoc, where, arrayUnion, getDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import axios from 'axios';

function PointRequests() {

    const [requests, setRequests] = useState([]);
    const db = getFirestore();

    useEffect(() => {
        getRequests()
    }, [])


    async function getRequests() {
        var reqs = []
        const q1 = query(collection(db, "Social Media Posts"));
        const querySnapshot = await getDocs(q1)
        querySnapshot.forEach((data) => {
            reqs.push(data.data())
        });
        setRequests(reqs.reverse())
    }

    async function changeStatus(status, postID, uid) {

        const userRef = doc(db, "Users", uid);
        const postRef = doc(db, "Social Media Posts", `${postID}`);
        const pointsRef = collection(db, "Mileage Score");
        var points = 0
        var q = query(pointsRef, where('title', '==', "Social Media Posts"));
        var querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            points = doc.data().score
        });

        const docSnap = await getDoc(userRef).then(async (docSnap) => {

            const userRequests = docSnap.data().pointRequests

            const temp = []
            var temp2 = []

            if (status == "accept") {
                var reqType = ''

                temp2 = await userRequests.forEach(request => {
                    if (request.date == postID) {
                        const result = request
                        result.status = "Approved"

                        reqType = request.type ? request.type : 'Social Media Post'

                        temp.push(result)
                        // temp.push({
                        //     date: request.date,
                        //     platform: request.platform,
                        //     postLink: request.postLink,
                        //     proofLink: request.proofLink,
                        //     uid: request.uid,
                        //     status: "Approved"
                        // })
                    } else {
                        temp.push(request)
                    }
                    return temp
                })

                await updateDoc(postRef, {
                    status: "Approved"
                });

                const previousMileageScore = docSnap.data().mileageScore ? docSnap.data().mileageScore : 0

                // Update User Req
                await updateDoc(userRef, {
                    pointRequests: temp2 ? temp2 : userRequests,
                    mileageScore: previousMileageScore + points,
                    mileageHistory: arrayUnion({
                        date: new Date().toISOString(),
                        points: points,
                        pointsName: reqType
                    })
                });

                axios.post('/PointRequestApproveEmail', {
                    email: docSnap.data().email,
                    name: docSnap.data().firstName
                }).catch(() => console.log('error sending approve email'))

            } else if (status == "decline") {
                userRequests.forEach(request => {
                    if (request.date == postID) {
                        const result = request
                        result.status = "Declined"

                        temp.push(result)

                        // temp.push({
                        //     date: request.date,
                        //     platform: request.platform,
                        //     postLink: request.postLink,
                        //     proofLink: request.proofLink,
                        //     uid: request.uid,
                        //     status: "Declined"
                        // })
                    } else {
                        temp.push(request)
                    }
                });

                await updateDoc(postRef, {
                    status: "Declined"
                });

                await updateDoc(userRef, {
                    pointRequests: temp
                });

                axios.post('/PointRequestRejectEmail', {
                    email: docSnap.data().email,
                    name: docSnap.data().firstName
                }).catch(() => console.log('error sending approve email'))

            }
        });

        getRequests()
    }

    return (
        <div className="Dashboard_Right_Pannel_whole Saira_Medium_Black PointRequestsAdminPannel" style={{ marginTop: '150px' }}>
            <div style={{ "display": "flex", "justifyContent": "space-between", "alignItems": "center" }}>
                <h2 className='Saira_Medium_Black"'>
                    Mileage Point Requests
                </h2>
                <button onClick={() => {
                    getRequests()
                }} style={{ "border": "none", "cursor": "pointer", "fontSize": "15px" }}
                    className="PointRequestsAdminPannelReloadButton">
                    Reload
                </button>
            </div>

            <table className='memberMileageTable'>
                <thead>
                    <tr className='memberMileageTableRow'>
                        <th className='memberMileageTableHeader'>
                            Date
                        </th>
                        <th className='memberMileageTableHeader'>
                            User ID
                        </th>
                        <th className='memberMileageTableHeader'>
                            Request Type
                        </th>
                        <th className='memberMileageTableHeader'>
                            Platform/Event Name
                        </th>
                        <th className='memberMileageTableHeader'>
                            Proof Link
                        </th>
                        <th className='memberMileageTableHeader'>
                            Event Date
                        </th>
                        <th className='memberMileageTableHeader'>
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        requests.map((individual, i) => (
                            <tr className='memberMileageTableRow' key={i}>
                                <td className='memberMileageTableData '>
                                    {new Date(individual.date).toDateString()}
                                </td>
                                <td className='memberMileageTableData '>
                                    <a href={'/users/' + individual.uid} style={{ textDecoration: 'none' }}>
                                        {individual.uid}
                                    </a>
                                </td>
                                <td className='memberMileageTableData '>
                                    {individual.type ? individual.type : 'Social Post'}
                                </td>
                                <td className='memberMileageTableData'>
                                    {individual.type == 'Industry Event' ? individual.name : individual.platform}
                                </td>
                                <td className='memberMileageTableData'>
                                    {individual.type == 'Industry Event' ?
                                        <a target="_blank" rel="noreferrer" href={individual.proofLink}>
                                            Click Here
                                        </a> :
                                        <a target="_blank" rel="noreferrer" href={individual.postLink}>
                                            Click Here
                                        </a>
                                    }
                                </td>
                                <td className='memberMileageTableData'>
                                    {individual.type == 'Industry Event' && new Date(individual.eventDate).toDateString()}

                                </td>
                                <td className='memberMileageTableData'>
                                    {
                                        individual.status == "Pending" ?
                                            <div style={{ "display": "flex", "flexDirection": "column", "gap": "10px" }}>
                                                <button onClick={() => { changeStatus("accept", individual.date, individual.uid) }}>
                                                    Approve
                                                </button>
                                                <button onClick={() => { changeStatus("decline", individual.date, individual.uid) }}>
                                                    Decline
                                                </button>
                                            </div> : individual.status
                                    }

                                </td>
                            </tr>

                        ))
                    }
                </tbody>

            </table>
        </div >
    );
}


export default PointRequests

