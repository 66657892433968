import React, { useEffect, useState } from 'react';
import { getFirestore, doc, deleteDoc, getDoc, getDocs, setDoc, query, collection, updateDoc, orderBy } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import axios from 'axios';

function DashboardAnnouncementAdmin({ user }) {

    const [userInfo, setUserInfo] = useState(null)
    const [newAnnouncementPresses, setNewAnnouncementPresses] = useState(false)
    const [announcements, setannouncements] = useState([]);
    const [announcementsToShow, setAnnouncementsToShow] = useState([]);
    const [searchText, setSearchText] = useState("");

    const [loading, setLoading] = useState(false)
    const [editing, setEditing] = useState(false);

    const [editTitle, setEditTitle] = useState("")
    const [editDescription, setEditDescription] = useState("")
    const [editId, setEditId] = useState("")

    const db = getFirestore();
    const storage = getStorage();

    useEffect(() => {
        function_use_effect()
    }, [])

    async function function_use_effect() {

        if (user == null)
            window.location.href = "/login";
        else {
            const docRef = doc(db, "Users", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setUserInfo(docSnap.data())
                loadAnnouncements()
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }
    }

    function newAnnouncementClicked() {
        setNewAnnouncementPresses(!newAnnouncementPresses)
    }


    async function loadAnnouncements() {
        setannouncements([])
        const q = query(collection(db, "Announcements"), orderBy("dateAdded", "desc"));
        const querySnapshot = await getDocs(q);
        var temp = []
        querySnapshot.forEach((doc) => {
            temp.push(doc.data())
        });
        setannouncements(temp)

        var temp2 = []
        temp.forEach(i => {
            if (i.title.toLowerCase().includes(searchText)) {
                temp2.push(i)
            }
        });
        setAnnouncementsToShow(temp2)
        setLoading(false)
    }

    function editWhitepaper(dateAdded, title, description) {
        setEditing(true)
        setEditTitle(title)
        setEditDescription(description)
        setEditId(dateAdded)
    }

    async function deleteWhitepaper(id) {
        if (window.confirm("Are you sure you want to delete this announcement?") == true) {
            setLoading(true)
            await deleteDoc(doc(db, "Announcements", String(id)));
            loadAnnouncements()
        }
    }

    async function submitAnnoucement() {
        setLoading(true)

        newAnnouncementClicked()

        const title = document.getElementById("DashboardAnnouncementAdminFormTitleID").value
        const description = document.getElementById("DashboardAnnouncementAdminFormDescriptionID").value
        const filename = document.getElementById("DashboardAnnouncementAdminFormFileID").files[0]?.name

        const date = new Date().getTime()

        if (title == "" || description == "" || !filename) {
            if (!filename) {
                alert("Please select a file first")
                setLoading(false)
            } else {
                alert("All the fields are required.")
            }
            return
        }

        const imageRef = ref(storage, 'gs://idca-f9873.appspot.com/Announcements' + `/${date}`);
        uploadBytesResumable(imageRef, document.getElementById("DashboardAnnouncementAdminFormFileID").files[0])
            .then((snapshot) => {
                // Let's get a download URL for the file.
                getDownloadURL(snapshot.ref)
                    .then(async (url) => {

                        const docData = {
                            title: title,
                            description: description,
                            imageLink: url,
                            company: userInfo.company,
                            uid: userInfo.uid,
                            dateAdded: date,
                        };

                        await setDoc(doc(db, "Announcements", String(date)), docData);
                        loadAnnouncements()
                    });
            }).catch((error) => {
                console.error('Upload failed', error);
            });
    }

    async function editSubmitFunction() {
        setLoading(true)
        const title = document.getElementById("WhitePaperAdminNewEditIDTitle").value
        const description = document.getElementById("WhitePaperAdminNewEditIDDescription").value
        if (title == "" || description == "") {
            alert("Title and Description cannot be empty!")
        } else {
            const ref = doc(db, "Announcements", String(editId));

            await updateDoc(ref, {
                title: title,
                description: description
            });
            loadAnnouncements()
            setEditing(false)
        }

    }

    async function approveAnnouncement(status, id) {
        const ref = doc(db, "Announcements", String(id));
        await updateDoc(ref, {
            status: status
        });
        const announcmentDoc = await getDoc(ref);
        const announcementOwner = doc(db, "Users", announcmentDoc.data().uid)
        const announcmentOwnerDoc = await getDoc(announcementOwner);
        if (status)
            axios.post('/AnnouncementApproveEmail', {
                email: announcmentOwnerDoc.data().email,
                name: announcmentOwnerDoc.data().firstName
            }).catch(() => console.log('failed to send approve email'))
        else
            axios.post('/AnnouncementRejectEmail', {
                email: announcmentOwnerDoc.data().email,
                name: announcmentOwnerDoc.data().firstName
            }).catch(() => console.log('failed to send approve email'))

        loadAnnouncements()
    }

    return (
        < div className='Dashboard_Right_Pannel_whole WhitePaperAdminMainDiv' >
            <h1 className="Saira_Bold_Black">
                Announcements Admin Panel
            </h1>

            <button className='WhitePaperAdminSingleNewButton' onClick={() => newAnnouncementClicked()}>
                New Announcement
            </button >

            {
                editing &&
                <div className='WhitePaperAdminNewUploadFormEdit Saira_Regular_Black'>
                    <label className='WhitePaperAdminNewUploadLabel'>
                        Title:
                        <input name='Title' id='WhitePaperAdminNewEditIDTitle' defaultValue={editTitle}></input>
                    </label>
                    <label className='WhitePaperAdminNewUploadLabel'>
                        Description:
                        <textarea name='Description' id='WhitePaperAdminNewEditIDDescription' className='Saira_Regular_Black' defaultValue={editDescription}></textarea>
                    </label>

                    <button className='WhitePaperAdminSingleDeleteButton' onClick={() => { editSubmitFunction() }}>Submit</button>
                    <button className='WhitePaperAdminSingleDeleteButton' onClick={() => { setEditing(false) }}>Close</button>
                </div>
            }


            {
                newAnnouncementPresses ?
                    <div className='WhitePaperAdminNewUploadForm Saira_Regular_Black'>

                        <label>
                            {"Title: "}
                            <input className="Saira_Medium_Black data-center-contact-us-form-input-box" type={"text"} id="DashboardAnnouncementAdminFormTitleID" />
                        </label>

                        <label className='DashboardAnnouncementAdminFormDesscription'>
                            {"Description: "}
                            <textarea

                                id="DashboardAnnouncementAdminFormDescriptionID"
                            />
                        </label>

                        <label>
                            {"Image: "}
                            <input
                                type={"file"}
                                id="DashboardAnnouncementAdminFormFileID"
                            />
                        </label>

                        <input type={"submit"}
                            className="DashboardAnnouncementAdminFormSubmitButton"
                            onClick={() => submitAnnoucement()}
                        />

                    </div> : ""
            }
            <hr />
            <h2 className="Saira_Bold_Black">
                Announcements
            </h2>

            <div className='DashboardAnnouncementAdminFormSearchDiv Saira_Regular_Black'>
                <label>
                    {"Filter: "}
                    <input name="Search" type={"text"} className="Saira_Regular_Black" id="DashboardAnnouncementAdminFormSearchDivSearchText" />

                </label>
                <input type={"submit"} value={"Search"} onClick={() => {
                    var sText = document.getElementById("DashboardAnnouncementAdminFormSearchDivSearchText").value
                    sText = sText.toLowerCase()
                    setSearchText(sText)
                    var temp2 = []
                    announcements.forEach(i => {
                        console.log(sText)
                        if (i.title.toLowerCase().includes(sText)) {
                            temp2.push(i)
                        }
                    });
                    setAnnouncementsToShow(temp2)
                }} />
                <input type={"submit"} value={"Clear"} onClick={() => {
                    setSearchText("")
                    document.getElementById("DashboardAnnouncementAdminFormSearchDivSearchText").value = ""
                    setAnnouncementsToShow(announcements)
                }} />
            </div>

            {
                loading
                    ?
                    <div className="payment-course-before-load-spinner-container" style={{ marginLeft: '50px', width: '50px', height: '50px' }}>
                        <div className="payment-course-before-loading-spinner">
                        </div>
                    </div> :

                    <table className='memberMileageTable'>
                        <thead>
                            <tr className='memberMileageTableRow'>
                                <th className='memberMileageTableHeader'>
                                    Date
                                </th>
                                <th className='memberMileageTableHeader'>
                                    Title
                                </th>
                                <th className='memberMileageTableHeader'>
                                    Image
                                </th>
                                <th className='memberMileageTableHeader'>
                                    Status
                                </th>
                                <th className='memberMileageTableHeader'>
                                    Actions
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                announcementsToShow.map((individual, i) => (
                                    <tr className='memberMileageTableRow' key={i}>
                                        <td className='memberMileageTableData '>
                                            {new Date(individual.dateAdded).toDateString()}
                                        </td>

                                        <td className='memberMileageTableData '>
                                            {individual.title}
                                        </td>

                                        <td className='memberMileageTableData'>
                                            <a target="_blank" rel="noreferrer" href={individual.imageLink}>
                                                Click Here
                                            </a>
                                        </td>
                                        <td className='memberMileageTableData'>
                                            {individual.status == null ? "Pending" : individual.status ? "Approved" : "Rejected"}
                                        </td>
                                        <td className='WhitePaperAdminSingleWhitepaperButtons'>
                                            <button className="WhitePaperAdminSingleDeleteButton" onClick={() => { deleteWhitepaper(individual.dateAdded) }}>
                                                Delete
                                            </button>
                                            <button className="WhitePaperAdminSingleEditButton" onClick={() => { editWhitepaper(individual.dateAdded, individual.title, individual.description) }}>
                                                Edit
                                            </button>
                                            {
                                                userInfo?.idcaRole?.includes("admin") ?
                                                    <span>

                                                        <button className="WhitePaperAdminSingleDeleteButton" onClick={() => { approveAnnouncement(true, individual.dateAdded) }}>
                                                            Approve
                                                        </button>
                                                        <button className="WhitePaperAdminSingleEditButton" onClick={() => { approveAnnouncement(false, individual.dateAdded) }}>
                                                            Reject
                                                        </button>
                                                    </span> : ""
                                            }
                                        </td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
            }
        </div >

    );
}



export default DashboardAnnouncementAdmin

