import React, { useState, useEffect, useRef } from 'react';
import courseJson from '../../../../../components/Pages/All_Courses_Detail.json'
import { CiCalendar, CiParking1 } from "react-icons/ci";
import { SlLocationPin } from "react-icons/sl";
import { MdAccessTime } from "react-icons/md";
import { PiCertificateLight } from "react-icons/pi";
import { PiVideoCameraThin } from "react-icons/pi";
import { LiaMapMarkedAltSolid } from "react-icons/lia";
import { FaChalkboardTeacher } from "react-icons/fa";
import { TfiNewWindow } from "react-icons/tfi";
import { doc, getDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

function UpcomingClasses({ courses, email }) {
    const today_date = new Date()
    const [course, setCourse] = useState()
    const [inPerson, setInPerson] = useState(false)
    const [courseJsonInfo, setCourseJsonInfo] = useState()
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [courseViewColor, setCourseViewColor] = useState('#006197')
    const [twoZoomLinks, setTwoZoomLinks] = useState()
    const db = getFirestore();

    useEffect(() => {
        if (courses) {
            var tmp
            var inPersonTmp = false
            courses.map(course => {
                let course_duration = 0
                if (course.titleAbbrev === 'DCIS') {
                    course_duration = 1
                } else if (course.titleAbbrev === 'DCOS' || course.titleAbbrev === 'DCES' || course.titleAbbrev === 'DCTP') {
                    course_duration = 2
                } else if (course.titleAbbrev === 'DCOM' || course.titleAbbrev === 'DCIE') {
                    course_duration = 4
                }
                // Calculate the end date of the course
                const endDate = new Date(course.startDateTime);
                endDate.setDate(endDate.getDate() + course_duration);

                // Check if the course has started but is still ongoing
                if (endDate >= today_date) {
                    if (tmp) {
                        if (course.startDateTime < tmp.startDateTime)
                            tmp = course
                    }
                    else
                        tmp = course
                }
            })

            if (tmp) {
                tmp.attendees.map(attendee => {
                    if (attendee.email === email) {
                        inPersonTmp = attendee.inPerson ? attendee.inPerson : false
                    }
                })
                setInPerson(inPersonTmp)
                setCourse(tmp)
                setCourseJsonInfo(getCourseJson(tmp.titleAbbrev))
            }
        }
    }, [courses])

    function getCourseJson(courseTitleAbbrev) {
        let course;
        courseJson.map(iCourse => {
            if (iCourse.title == courseTitleAbbrev)
                course = iCourse
        })
        return course
    }

    function getStringDate(uo_date) {
        const date = new Date(uo_date)
        const month_str = month[date.getMonth()]
        const day_str = date.getDate()
        const day_ordinal_str = ((day_str == 1 || day_str == 21 || day_str == 31) ? 'st'
            : (day_str == 2 || day_str == 22) ? 'nd' : (day_str == 3) ? 'rd' : 'th')
        const year_str = date.getFullYear()
        var return_date = month_str + ' ' + day_str + day_ordinal_str + ', ' + year_str
        return return_date
    }

    function changeHoverStateEnter() {
        document.getElementById('view_course_id').style.backgroundColor = '#006197'
        document.getElementById('view_course_id_p').style.color = 'white'
        setCourseViewColor("white")
    }
    function changeHoverStateLeave() {
        document.getElementById('view_course_id').style.backgroundColor = 'white'
        document.getElementById('view_course_id_p').style.color = '#006197'
        setCourseViewColor("#006197")
    }

    function getStringTime(uo_date) {
        var return_date = ''
        if (courseJsonInfo.title == 'DCRP') {
            const date_tmp = new Date(uo_date)
            return_date += (date_tmp.getHours() - 12) + ':' + (date_tmp.getMinutes() > 9 ? date_tmp.getMinutes() : ('0' + date_tmp.getMinutes()))
            if (date_tmp.getHours() >= 12)
                return_date += ' PM '
            else
                return_date += ' AM '
        } else {
            return_date = '9:00 AM '
        }
        return return_date
    }

    useEffect(() => {
        if (course && (course.titleAbbrev === 'DCOM' || course.titleAbbrev === 'DCIE'))
            getCourseZoomLink(course.titleAbbrev)
    }, [course])


    async function getCourseZoomLink(course_title) {
        const zoom_link = []
        const docRef = doc(db, "Event", (course._id + '-DCIS'));
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            zoom_link.push({ name: "DCIS", link: docSnap.data().zoom_link })
        }

        if (course_title === 'DCOM') {
            const docRef = doc(db, "Event", (course._id + '-DCOS'));
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                zoom_link.push({ name: 'DCOS', link: docSnap.data().zoom_link })
            }
        } else if (course_title === 'DCIE') {
            const docRef = doc(db, "Event", (course._id + '-DCES'));
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                zoom_link.push({ name: 'DCES', link: docSnap.data().zoom_link })
            }
        }
        setTwoZoomLinks(zoom_link)
    }

    if (course)
        return (
            <div className='user_dashboard_home_profile_div'>
                <div>
                    {new Date(course.startDateTime) <= today_date ?
                        <p className='user_dashboard_home_journey_title'>Ongoing Class</p>
                        : <p className='user_dashboard_home_journey_title'>Your Upcoming Class</p>
                    }
                </div>
                {(course && courseJsonInfo) ?
                    <div className='user_dashboard_home_upcoming_class_all_div'>
                        <div>
                            <div className='user_dashboard_home_upcoming_class_image_div' id={'portal_home_upcoming_img_' + course.titleAbbrev}>
                                <div>
                                    <img src={courseJsonInfo.badge_img_2024} className='user_dashboard_home_upcoming_class_image_inner_div' />
                                </div>
                            </div>
                            <a href={'/course?_id=' + course._id}>
                                <div className='user_dashboard_home_certifications_indiv_cert_download' id={'view_course_id'}
                                    onMouseEnter={() => changeHoverStateEnter()}
                                    onMouseLeave={() => changeHoverStateLeave()}>
                                    <TfiNewWindow size={17} color={courseViewColor} />
                                    <p id={'view_course_id_p'}>View Course</p>
                                </div>
                            </a>
                        </div>

                        <div className='user_dashboard_home_upcoming_class_detail_all_div'>
                            <div className='user_dashboard_home_upcoming_class_detail_div'>
                                <div className='user_dashboard_home_upcoming_class_detail_icon_div'>
                                    <CiCalendar size={23} />
                                    <p>Date & Time</p>
                                </div>
                                <p>{course.titleAbbrev !== 'DCRP' ?
                                    getStringTime(course.startDateTime) :
                                    (course.time.startTime ? course.time.startTime : course.time.full)}
                                    - {getStringDate(course.startDateTime)}</p>
                            </div>
                            <div className='user_dashboard_home_upcoming_class_detail_div'>
                                <div className='user_dashboard_home_upcoming_class_detail_icon_div'>
                                    <SlLocationPin size={22} />
                                    <p>Location</p>
                                </div>
                                <p>{course.location}</p>
                            </div>
                            <div className='user_dashboard_home_upcoming_class_detail_div'>
                                <div className='user_dashboard_home_upcoming_class_detail_icon_div'>
                                    <MdAccessTime size={22} />
                                    <p>Duration</p>
                                </div>
                                <p>{courseJsonInfo.duration}</p>
                            </div>
                            {(course.instructor_name) ?
                                <div className='user_dashboard_home_upcoming_class_detail_div'>
                                    <div className='user_dashboard_home_upcoming_class_detail_icon_div'>
                                        <FaChalkboardTeacher size={21} />
                                        <p>Instructor</p>
                                    </div>

                                    <p>{course.instructor_name}</p>
                                </div> : null}
                            <div className='user_dashboard_home_upcoming_class_detail_div'>
                                <div className='user_dashboard_home_upcoming_class_detail_icon_div'>
                                    <PiCertificateLight size={23} />
                                    <p>Certifications</p>
                                </div>
                                <p>{courseJsonInfo.professionalCertifications} Certifications</p>
                            </div>
                            {(!inPerson && course.zoom_link && (course.titleAbbrev != 'DCIE' && course.titleAbbrev != 'DCOM')) ?
                                <div className='user_dashboard_home_upcoming_class_detail_div'>
                                    <div className='user_dashboard_home_upcoming_class_detail_icon_div'>
                                        <PiVideoCameraThin size={22} />
                                        <p>Zoom Link</p>
                                    </div>

                                    <a href={course.zoom_link} target='_blank' rel='noreferrer'>
                                        <p className='user_dashboard_home_upcoming_class_zoom_link'>{course.zoom_link}</p>
                                    </a>
                                </div> : null}
                            {(!inPerson && twoZoomLinks && twoZoomLinks.length > 0) ?
                                <div className='user_dashboard_home_upcoming_class_detail_div'>
                                    <div className='user_dashboard_home_upcoming_class_detail_icon_div'>
                                        <PiVideoCameraThin size={22} />
                                        <p>{twoZoomLinks[0].name} Zoom Link</p>
                                    </div>

                                    <a href={twoZoomLinks[0].link} target='_blank' rel='noreferrer'>
                                        <p className='user_dashboard_home_upcoming_class_zoom_link'>{twoZoomLinks[0].link}</p>
                                    </a>
                                </div> : null}
                            {(!inPerson && twoZoomLinks && twoZoomLinks.length > 1) ?
                                <div className='user_dashboard_home_upcoming_class_detail_div'>
                                    <div className='user_dashboard_home_upcoming_class_detail_icon_div'>
                                        <PiVideoCameraThin size={22} />
                                        <p>{twoZoomLinks[1].name} Zoom Link</p>
                                    </div>

                                    <a href={twoZoomLinks[1].link} target='_blank' rel='noreferrer'>
                                        <p className='user_dashboard_home_upcoming_class_zoom_link'>{twoZoomLinks[1].link}</p>
                                    </a>
                                </div> : null}
                            {(inPerson && course.venue && (course.venue.firstLineAddress || course.venue.secondLineAddress)) ?
                                <div className='user_dashboard_home_upcoming_class_detail_div'>
                                    <div className='user_dashboard_home_upcoming_class_detail_icon_div'>
                                        <LiaMapMarkedAltSolid size={23} />
                                        <p>Venue</p>
                                    </div>
                                    <div>
                                        <p style={{ marginBottom: '0px' }}>{course.venue?.firstLineAddress}</p>
                                        <p style={{ marginTop: '0px' }}>{course.venue?.secondLineAddress}</p>
                                    </div>
                                </div> : null}
                            {(inPerson && course.parking_detail) ?
                                <div className='user_dashboard_home_upcoming_class_detail_div'>
                                    <div className='user_dashboard_home_upcoming_class_detail_icon_div'>
                                        <CiParking1 size={22} />
                                        <p>Parking Detail</p>
                                    </div>

                                    <p className='user_dashboard_home_upcoming_class_parking_detail'>{course.parking_detail}</p>
                                </div> : null}
                            {/* <div className='user_dashboard_home_upcoming_class_detail_div'>
                                <div className='user_dashboard_home_upcoming_class_detail_icon_div'>
                                    <FaRegHandshake size={22} />
                                    <p>Sponsors</p>
                                </div>
                                <p>{course.startDateTime}</p>
                            </div> */}
                        </div>
                    </div> : null}
            </div>
        );
}

export default UpcomingClasses
