import { getFirestore, setDoc, getDoc, doc, updateDoc } from "firebase/firestore";
import AccountCreated from "../EmailServer/AccountCreated";


export default async function createUserAndEventFirestore({ user, signInMethod, email, firstName, lastName, company, title, state, phone, country, displayName }) {

    const db = getFirestore();
    var responseMessage = null;

    try {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            await setDoc(doc(db, "Users", user.uid), {
                ...docSnap.data(),
                accountCreateDetail: {
                    createdAt: user?.metadata?.createdAt,
                    creationTime: user?.metadata?.creationTime,
                    lastLoginAt: user?.metadata?.lastLoginAt,
                    lastSignInTime: user?.metadata?.lastSignInTime
                },
                signedInBy: signInMethod
            })
        } else {
            await setDoc(doc(db, "Users", user.uid), {
                firstName: firstName,
                lastName: lastName,
                displayName: displayName ? displayName : firstName + ' ' + lastName,
                email: email ? email : null,
                phone: phone ? phone : (user.phoneNumber ? user.phoneNumber : null),
                company: company ? company : null,
                title: title ? title : null,
                state: state ? state : null,
                country: country ? country : null,
                photoURL: user.photoURL ? user.photoURL : null,
                accountCreateDetail: {
                    createdAt: user?.metadata?.createdAt,
                    creationTime: user?.metadata?.creationTime,
                    lastLoginAt: user?.metadata?.lastLoginAt,
                    lastSignInTime: user?.metadata?.lastSignInTime
                },
                signedInBy: signInMethod,
                uid: user.uid,
                _createdAt: user?.metadata?.createdAt
            }).then(() => {
                console.log('created user')
                try {
                    AccountCreated({ userEmail: email, userName: firstName && lastName ? firstName + ' ' + lastName : displayName })
                } catch (error) { console.log(error) }

                if (signInMethod == 'Google') {
                    window.location.href = "/user-dashboard"
                }
            })
        }
    } catch (err) {
        console.log(err)
        responseMessage = 'somethingWrong'
    }
    return responseMessage
}