// import { useNextSanityImage } from 'next-sanity-image';

// import sanityClient from './client.js'
import sanityClient from '@sanity/client'

const configuredSanityClient = sanityClient({
    projectId: "bpki07jj",
    dataset: "production",
    useCdn: true
});

function UrlFor({ source, name, width, height, ar = 1, trainingPage, alt }) {
    // const image = useNextSanityImage(
    //     configuredSanityClient,
    //     source
    // );
    if (source) {
        if (trainingPage) {
            return <img src={source} className={name} width={width} height={width / ar} alt={alt ? alt : ''} />;
        }
        return <img src={source} className={name} width={width} height={width / ar} alt={alt ? alt : ''} />;
        // if (width && height)
        //     return <Image {...image} className={name} width={width} height={height} alt={alt ? alt : ''} />;
        // if (width) {
        //     return <Image {...image} className={name} width={width} height={width / ar} alt={alt ? alt : ''} />;
        // }
        // else {
        //     return <Image {...image} className={name} layout="responsive" alt={alt ? alt : ''} />;
        // }
    }
    return <div></div>
}

export default UrlFor;