
import React from "react";
import SignUp from '../components/Pages/CreateAccount/CreateAccount.js'
import MemberNavbar from "../components/Pages/MemberArea/Navbar/Navbar.js";
import { Helmet } from "react-helmet"



export default function CreateAccount() {
    const queryParameters = new URLSearchParams(window.location.search)
    let eventID = queryParameters.get("event")

    return (
        <div>
            <Helmet>
                <title>Create an Account</title>
            </Helmet>
            <meta name="description" content="IDCA portal login" />
            <link rel="icon" href="/favicon.png" />
            <link rel="apple-touch-icon" href="/idca-logo-with-bg.jpg" />

            <nav>
                <MemberNavbar not_signed_in={true} />
            </nav>

            <main>
                <SignUp eventID={eventID} />
            </main>
        </div >
    )
}