
import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import MemberNavbar from "../../components/Pages/MemberArea/Navbar/Navbar.js";
import IntelligenceResourcesPage from "../../components/Pages/IntelligenceResources/IntelligenceResources.js"
import { Helmet } from "react-helmet"


export default function IntelligenceResources() {

    const [user, setUser] = useState()
    const [userInfo, setUserInfo] = useState()

    useEffect(() => {
        const db = getFirestore();
        getAuth().onAuthStateChanged(async user => {
            if (user) {
                // set user
                setUser(user)
                // get user info
                const docRef = doc(db, "Users", user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    if (docSnap.data().idcaRole?.includes("membership-admin")
                        || docSnap.data().idcaRole?.includes("sales")
                        || docSnap.data().idcaRole?.includes("admin")
                        || docSnap.data().idcaRole?.includes("member")
                        || docSnap.data().idcaRole?.includes("corporate-member-admin")) {
                        setUserInfo(docSnap.data())
                    } else {
                        window.location.href = "/";
                    }
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            } else {
                window.location.href = "/login";
            }
        })

    }, [])

    return (
        <div>
            <Helmet>
                <title>Intelligence Resources</title>
            </Helmet>
            <link rel="icon" href="/favicon.png" />
            <link rel="apple-touch-icon" href="/idca-logo-with-bg.jpg" />

            <nav>
                {userInfo &&
                    <MemberNavbar user={userInfo} page='IntelligenceResources' />
                }
            </nav>

            <main>
                {userInfo ?
                    (<IntelligenceResourcesPage />)
                    : (
                        <div className="payment-course-before-load-spinner-container" style={{ marginTop: '200px', marginBottom: '100px' }}>
                            <div className="payment-course-before-loading-spinner">
                            </div>
                        </div >
                    )
                }

            </main >
        </div >
    )
}