import React, { useEffect, useState } from 'react';
import { BsCheck2Circle, BsSearch } from 'react-icons/bs'
import Modal from 'react-modal';
import axios from 'axios';

import { getFirestore, query, collection, getDocs, doc, updateDoc, where, arrayUnion, getDoc } from "firebase/firestore";

function AddCorpMembership({ openStatus }) {

    const db = getFirestore();

    const [done, setDone] = useState(false)
    const [open, setOpen] = useState(openStatus)
    const [addClicked, setAddClicked] = useState(false)
    const [incompleteCredentials, setIncompleteCredentials] = useState(false)
    const [userSearch, setUserSearch] = useState([])
    const [userSelected, setUserSelected] = useState()

    function onMouseEnterDiv(i) {
        document.getElementById('admin_event_list_individual_course_div_id_' + i).style.backgroundColor = 'rgba(183, 35, 35, 0.519)'
    }
    function onMouseLeaveDiv(i) {
        if (i % 2 == 0)
            document.getElementById('admin_event_list_individual_course_div_id_' + i).style.backgroundColor = 'rgba(220, 220, 220, 0.491)'
        else
            document.getElementById('admin_event_list_individual_course_div_id_' + i).style.backgroundColor = 'white'
    }

    async function searchUserByEmail(value) {
        if (!value) {
            setUserSearch([])
            return
        }
        const valueLower = value.toLowerCase();
        const userRef = collection(db, "Users");

        const userTemp = []
        // Create a query against the collection.
        var q = query(userRef, where("email", ">=", valueLower), where("email", "<=", valueLower + '\uf8ff'));
        var querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => { userTemp.push(doc.data()) });

        setUserSearch(userTemp)
    }

    function create_new_corp_member_by_member_admin() {
        const companyName = document.getElementById('create_account_form_company_input_admin_member').value;
        const phone = document.getElementById('create_account_form_phone_input_admin_member').value;
        const adminUID = userSelected?.uid;
        const plan = document.getElementById('create_account_form_plan_input_admin_member').value;
        const paymentMethod = document.getElementById('create_account_form_payment_method_input_admin_member').value;
        const country = document.getElementById('create_account_form_country_input_admin_member').value;
        const numLicenses = document.getElementById('create_account_form_num_licenses_input_admin_member').value;

        if (companyName && phone && adminUID && plan && paymentMethod)
            axios.post('/create_new_corp_member_by_member_admin', {
                companyName: companyName,
                phone: phone,
                adminUID: adminUID,
                plan: plan,
                paymentMethod: paymentMethod,
                country: country,
                numLicenses: numLicenses
            }).then((response) => {
                console.log(response)
                if (response.data.status == 200) {
                    alert("Corporate Member added.")
                    window.location.reload();
                } else {
                    alert("Error: " + response.data.error)
                    window.location.reload();
                }
            }).catch((error) => {
                alert(error)
                window.location.reload();
            })
        else {
            setIncompleteCredentials(true)
        }
    }


    function setUserSelectedFunction(user) {
        setUserSelected(user)
        document.getElementById('create_account_form_admin_user_input_admin_member').value = user.firstName + " " + user.lastName
        setUserSearch([])
    }

    return (
        <div>
            <Modal
                isOpen={true}
                onRequestClose={() => {
                    setOpen(false)
                }}
                style={{
                    content: {
                        marginTop: '40px',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderWidth: '0px',
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 27px rgba(0, 0, 0, 0.25)',
                        borderRadius: '20px',
                        padding: '40px 60px',
                        width: '500px',
                        height: '500px'
                    },
                }}
                contentLabel="Example Modal"
            >
                <div>
                    {done ? (
                        <div style={{ textAlign: 'center', color: 'green', padding: '50px' }}>
                            <div>
                                <BsCheck2Circle size={60} />
                            </div>
                            <h2 className='Saira_Bold_Black'>All Done!</h2>
                        </div>
                    ) : (
                        <div>
                            <div className='Saira_Medium_Black'>

                                {incompleteCredentials && <p className='Saira_Medium_Red'>Some fields are incomplete</p>}
                                <div className="login_landing_page_data_fields_two_in_row_div">
                                    <div>
                                        <label htmlFor="first_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <p className="data-center-contact-us-form-input-label">Company Name</p>
                                        </label>
                                        <input id='create_account_form_company_input_admin_member'
                                            className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                            maxLength="40" name="first_name" size="20" type="text" required="required" />
                                    </div>

                                    <div>
                                        <label htmlFor="phone" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <p className="data-center-contact-us-form-input-label">Company Phone</p>
                                        </label>
                                        <input id="create_account_form_phone_input_admin_member"
                                            className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                            maxLength="20" name="phone" size="20" type="tel" />
                                    </div>
                                </div>


                                <div className="login_landing_page_data_fields_two_in_row_div">
                                    <div>
                                        <label htmlFor="first_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <p className="data-center-contact-us-form-input-label">Number of Licenses</p>
                                        </label>
                                        <input id='create_account_form_num_licenses_input_admin_member'
                                            className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                            maxLength="40" name="first_name" size="20" type="number" required="required" />
                                    </div>

                                    <div>
                                        <label htmlFor="phone" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <p className="data-center-contact-us-form-input-label">Country</p>
                                        </label>
                                        <select id="create_account_form_country_input_admin_member" style={{ width: '190px' }} className="Saira_Medium_Black data-center-contact-us-form-input-box" name="country" required>
                                            <option value='Andorra'>Andorra</option>
                                            <option value='United Arab Emirates'>United Arab Emirates</option>
                                            <option value='Afghanistan'>Afghanistan</option>
                                            <option value='Antigua and Barbuda'>Antigua and Barbuda</option>
                                            <option value='Anguilla'>Anguilla</option>
                                            <option value='Albania'>Albania</option>
                                            <option value='Armenia'>Armenia</option>
                                            <option value='Angola'>Angola</option>
                                            <option value='Antarctica'>Antarctica</option>
                                            <option value='Argentina'>Argentina</option>
                                            <option value='Austria'>Austria</option>
                                            <option value='Australia*'>Australia*</option>
                                            <option value='Aruba'>Aruba</option>
                                            <option value='Aland Islands'>Aland Islands</option>
                                            <option value='Azerbaijan'>Azerbaijan</option>
                                            <option value='Bosnia and Herzegovina'>Bosnia and Herzegovina</option>
                                            <option value='Barbados'>Barbados</option>
                                            <option value='Bangladesh'>Bangladesh</option>
                                            <option value='Belgium'>Belgium</option>
                                            <option value='Burkina Faso'>Burkina Faso</option>
                                            <option value='Bulgaria'>Bulgaria</option>
                                            <option value='Bahrain'>Bahrain</option>
                                            <option value='Burundi'>Burundi</option>
                                            <option value='Benin'>Benin</option>
                                            <option value='Saint Barthélemy'>Saint Barthélemy</option>
                                            <option value='Bermuda'>Bermuda</option>
                                            <option value='Brunei Darussalam'>Brunei Darussalam</option>
                                            <option value='Bolivia, Plurinational State of'>Bolivia, Plurinational State of</option>
                                            <option value='Bonaire, Sint Eustatius and Saba'>Bonaire, Sint Eustatius and Saba</option>
                                            <option value='Brazil*'>Brazil*</option>
                                            <option value='Bahamas'>Bahamas</option>
                                            <option value='Bhutan'>Bhutan</option>
                                            <option value='Bouvet Island'>Bouvet Island</option>
                                            <option value='Botswana'>Botswana</option>
                                            <option value='Belarus'>Belarus</option>
                                            <option value='Belize'>Belize</option>
                                            <option value='Canada'>Canada</option>
                                            <option value='Cocos (Keeling) Islands'>Cocos (Keeling) Islands</option>
                                            <option value='Congo, the Democratic Republic of the'>Congo, the Democratic Republic of the</option>
                                            <option value='Central African Republic'>Central African Republic</option>
                                            <option value='Congo'>Congo</option>
                                            <option value='Switzerland'>Switzerland</option>
                                            <option value='Cote d’Ivoire'>Cote d’Ivoire</option>
                                            <option value='Cook Islands'>Cook Islands</option>
                                            <option value='Chile'>Chile</option>
                                            <option value='Cameroon'>Cameroon</option>
                                            <option value='China'>China</option>
                                            <option value='Colombia'>Colombia</option>
                                            <option value='Costa Rica'>Costa Rica</option>
                                            <option value='Cuba'>Cuba</option>
                                            <option value='Cape Verde'>Cape Verde</option>
                                            <option value='Curaçao'>Curaçao</option>
                                            <option value='Christmas Island'>Christmas Island</option>
                                            <option value='Cyprus'>Cyprus</option>
                                            <option value='Czech Republic'>Czech Republic</option>
                                            <option value='Germany'>Germany</option>
                                            <option value='Djibouti'>Djibouti</option>
                                            <option value='Denmark'>Denmark</option>
                                            <option value='Dominica'>Dominica</option>
                                            <option value='Dominican Republic'>Dominican Republic</option>
                                            <option value='Algeria'>Algeria</option>
                                            <option value='Ecuador'>Ecuador</option>
                                            <option value='Estonia'>Estonia</option>
                                            <option value='Egypt'>Egypt</option>
                                            <option value='Western Sahara'>Western Sahara</option>
                                            <option value='Eritrea'>Eritrea</option>
                                            <option value='Spain'>Spain</option>
                                            <option value='Ethiopia'>Ethiopia</option>
                                            <option value='Finland'>Finland</option>
                                            <option value='Fiji'>Fiji</option>
                                            <option value='Falkland Islands (Malvinas)'>Falkland Islands (Malvinas)</option>
                                            <option value='Faroe Islands'>Faroe Islands</option>
                                            <option value='France'>France</option>
                                            <option value='Gabon'>Gabon</option>
                                            <option value='United Kingdom'>United Kingdom</option>
                                            <option value='Grenada'>Grenada</option>
                                            <option value='Georgia'>Georgia</option>
                                            <option value='French Guiana'>French Guiana</option>
                                            <option value='Guernsey'>Guernsey</option>
                                            <option value='Ghana'>Ghana</option>
                                            <option value='Gibraltar'>Gibraltar</option>
                                            <option value='Greenland'>Greenland</option>
                                            <option value='Gambia'>Gambia</option>
                                            <option value='Guinea'>Guinea</option>
                                            <option value='Guadeloupe'>Guadeloupe</option>
                                            <option value='Equatorial Guinea'>Equatorial Guinea</option>
                                            <option value='Greece'>Greece</option>
                                            <option value='South Georgia and the South Sandwich Islands'>South Georgia and the South Sandwich Islands</option>
                                            <option value='Guatemala'>Guatemala</option>
                                            <option value='Guinea-Bissau'>Guinea-Bissau</option>
                                            <option value='Guyana'>Guyana</option>
                                            <option value='Heard Island and McDonald Islands'>Heard Island and McDonald Islands</option>
                                            <option value='Honduras'>Honduras</option>
                                            <option value='Croatia'>Croatia</option>
                                            <option value='Haiti'>Haiti</option>
                                            <option value='Hungary'>Hungary</option>
                                            <option value='Indonesia'>Indonesia</option>
                                            <option value='Ireland'>Ireland</option>
                                            <option value='Israel'>Israel</option>
                                            <option value='Isle of Man'>Isle of Man</option>
                                            <option value='India'>India</option>
                                            <option value='British Indian Ocean Territory'>British Indian Ocean Territory</option>
                                            <option value='Iraq'>Iraq</option>
                                            <option value='Iran, Islamic Republic of'>Iran, Islamic Republic of</option>
                                            <option value='Iceland'>Iceland</option>
                                            <option value='Italy'>Italy</option>
                                            <option value='Jersey'>Jersey</option>
                                            <option value='Jamaica'>Jamaica</option>
                                            <option value='Jordan'>Jordan</option>
                                            <option value='Japan'>Japan</option>
                                            <option value='Kenya'>Kenya</option>
                                            <option value='Kyrgyzstan'>Kyrgyzstan</option>
                                            <option value='Cambodia'>Cambodia</option>
                                            <option value='Kiribati'>Kiribati</option>
                                            <option value='Comoros'>Comoros</option>
                                            <option value='Saint Kitts and Nevis'>Saint Kitts and Nevis</option>
                                            <option value='Korea, Democratic People’s Republic of'>Korea, Democratic People’s Republic of</option>
                                            <option value='Korea, Republic of'>Korea, Republic of</option>
                                            <option value='Kuwait'>Kuwait</option>
                                            <option value='Cayman Islands'>Cayman Islands</option>
                                            <option value='Kazakhstan'>Kazakhstan</option>
                                            <option value='Lao People’s Democratic Republic'>Lao People’s Democratic Republic</option>
                                            <option value='Lebanon'>Lebanon</option>
                                            <option value='Saint Lucia'>Saint Lucia</option>
                                            <option value='Liechtenstein'>Liechtenstein</option>
                                            <option value='Sri Lanka'>Sri Lanka</option>
                                            <option value='Liberia'>Liberia</option>
                                            <option value='Lesotho'>Lesotho</option>
                                            <option value='Lithuania'>Lithuania</option>
                                            <option value='Luxembourg'>Luxembourg</option>
                                            <option value='Latvia'>Latvia</option>
                                            <option value='Libya'>Libya</option>
                                            <option value='Morocco'>Morocco</option>
                                            <option value='Monaco'>Monaco</option>
                                            <option value='Moldova, Republic of'>Moldova, Republic of</option>
                                            <option value='Montenegro'>Montenegro</option>
                                            <option value='Saint Martin (French part)'>Saint Martin (French part)</option>
                                            <option value='Madagascar'>Madagascar</option>
                                            <option value='Macedonia, the former Yugoslav Republic of'>Macedonia, the former Yugoslav Republic of</option>
                                            <option value='Mali'>Mali</option>
                                            <option value='Myanmar'>Myanmar</option>
                                            <option value='Mongolia'>Mongolia</option>
                                            <option value='Macao'>Macao</option>
                                            <option value='Martinique'>Martinique</option>
                                            <option value='Mauritania'>Mauritania</option>
                                            <option value='Montserrat'>Montserrat</option>
                                            <option value='Malta'>Malta</option>
                                            <option value='Mauritius'>Mauritius</option>
                                            <option value='Maldives'>Maldives</option>
                                            <option value='Malawi'>Malawi</option>
                                            <option value='Mexico'>Mexico</option>
                                            <option value='Malaysia'>Malaysia</option>
                                            <option value='Mozambique'>Mozambique</option>
                                            <option value='Namibia'>Namibia</option>
                                            <option value='New Caledonia'>New Caledonia</option>
                                            <option value='Niger'>Niger</option>
                                            <option value='Norfolk Island'>Norfolk Island</option>
                                            <option value='Nigeria'>Nigeria</option>
                                            <option value='Nicaragua'>Nicaragua</option>
                                            <option value='Netherlands'>Netherlands</option>
                                            <option value='Norway'>Norway</option>
                                            <option value='Nepal'>Nepal</option>
                                            <option value='Nauru'>Nauru</option>
                                            <option value='Niue'>Niue</option>
                                            <option value='New Zealand'>New Zealand</option>
                                            <option value='Oman'>Oman</option>
                                            <option value='Panama'>Panama</option>
                                            <option value='Peru'>Peru</option>
                                            <option value='French Polynesia'>French Polynesia</option>
                                            <option value='Papua New Guinea'>Papua New Guinea</option>
                                            <option value='Philippines'>Philippines</option>
                                            <option value='Pakistan'>Pakistan</option>
                                            <option value='Poland'>Poland</option>
                                            <option value='Saint Pierre and Miquelon'>Saint Pierre and Miquelon</option>
                                            <option value='Pitcairn'>Pitcairn</option>
                                            <option value='Palestine'>Palestine</option>
                                            <option value='Portugal'>Portugal</option>
                                            <option value='Paraguay'>Paraguay</option>
                                            <option value='Qatar'>Qatar</option>
                                            <option value='Reunion'>Reunion</option>
                                            <option value='Romania'>Romania</option>
                                            <option value='Serbia'>Serbia</option>
                                            <option value='Russian Federation'>Russian Federation</option>
                                            <option value='Rwanda'>Rwanda</option>
                                            <option value='Saudi Arabia'>Saudi Arabia</option>
                                            <option value='Solomon Islands'>Solomon Islands</option>
                                            <option value='Seychelles'>Seychelles</option>
                                            <option value='Sudan'>Sudan</option>
                                            <option value='Sweden'>Sweden</option>
                                            <option value='Singapore'>Singapore</option>
                                            <option value='Saint Helena, Ascension and Tristan da Cunha'>Saint Helena, Ascension and Tristan da Cunha</option>
                                            <option value='Slovenia'>Slovenia</option>
                                            <option value='Svalbard and Jan Mayen'>Svalbard and Jan Mayen</option>
                                            <option value='Slovakia'>Slovakia</option>
                                            <option value='Sierra Leone'>Sierra Leone</option>
                                            <option value='San Marino'>San Marino</option>
                                            <option value='Senegal'>Senegal</option>
                                            <option value='Somalia'>Somalia</option>
                                            <option value='Suriname'>Suriname</option>
                                            <option value='South Sudan'>South Sudan</option>
                                            <option value='Sao Tome and Principe'>Sao Tome and Principe</option>
                                            <option value='El Salvador'>El Salvador</option>
                                            <option value='Sint Maarten (Dutch part)'>Sint Maarten (Dutch part)</option>
                                            <option value='Syrian Arab Republic'>Syrian Arab Republic</option>
                                            <option value='Swaziland'>Swaziland</option>
                                            <option value='Turks and Caicos Islands'>Turks and Caicos Islands</option>
                                            <option value='Chad'>Chad</option>
                                            <option value='French Southern Territories'>French Southern Territories</option>
                                            <option value='Togo'>Togo</option>
                                            <option value='Thailand'>Thailand</option>
                                            <option value='Tajikistan'>Tajikistan</option>
                                            <option value='Tokelau'>Tokelau</option>
                                            <option value='Timor-Leste'>Timor-Leste</option>
                                            <option value='Turkmenistan'>Turkmenistan</option>
                                            <option value='Tunisia'>Tunisia</option>
                                            <option value='Tonga'>Tonga</option>
                                            <option value='Turkey'>Turkey</option>
                                            <option value='Trinidad and Tobago'>Trinidad and Tobago</option>
                                            <option value='Tuvalu'>Tuvalu</option>
                                            <option value='Taiwan'>Taiwan</option>
                                            <option value='Tanzania, United Republic of'>Tanzania, United Republic of</option>
                                            <option value='Ukraine'>Ukraine</option>
                                            <option value='Uganda'>Uganda</option>
                                            <option value='United States' selected>United States</option>
                                            <option value='Uruguay'>Uruguay</option>
                                            <option value='Uzbekistan'>Uzbekistan</option>
                                            <option value='Holy See (Vatican City State)'>Holy See (Vatican City State)</option>
                                            <option value='Saint Vincent and the Grenadines'>Saint Vincent and the Grenadines</option>
                                            <option value='Venezuela, Bolivarian Republic of'>Venezuela, Bolivarian Republic of</option>
                                            <option value='Virgin Islands, British'>Virgin Islands, British</option>
                                            <option value='Vietnam'>Vietnam</option>
                                            <option value='Vanuatu'>Vanuatu</option>
                                            <option value='Wallis and Futuna'>Wallis and Futuna</option>
                                            <option value='Samoa'>Samoa</option>
                                            <option value='Yemen'>Yemen</option>
                                            <option value='Mayotte'>Mayotte</option>
                                            <option value='South Africa'>South Africa</option>
                                            <option value='Zambia'>Zambia</option>
                                            <option value='Zimbabwe'>Zimbabwe</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="login_landing_page_data_fields_two_in_row_div" style={{ alignItems: 'center' }}>
                                    <div>
                                        <label htmlFor="last_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <p className="data-center-contact-us-form-input-label">Admin Email</p>
                                        </label>
                                        <div style={{ display: 'flex' }}>
                                            <input id='create_account_form_admin_user_input_admin_member'
                                                className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                                maxLength="80" name="last_name" size="20" type="text" required="required" onChange={(e) => searchUserByEmail(e.target.value)} />
                                            <BsSearch />
                                        </div>
                                        {userSearch.map((user, i) => (
                                            <div key={i}>
                                                {user?.idcaRole?.includes('corporate-member-admin') || user?.idcaRole?.includes('member') ? (
                                                    <div key={i} style={{ borderRadius: '20px', padding: '15px 20px', backgroundColor: "rgba(86, 86, 86, 0.365)", color: 'white' }}
                                                        className={'Saira_Medium_Gray'}>
                                                        <div style={{ display: 'flex', gap: '20px', marginBottom: '10px' }}>
                                                            <p style={{ margin: '0px' }}><span style={{ textDecoration: 'underline' }}>Name:</span> {user.displayName}</p>
                                                            <p style={{ margin: '0px', wordBreak: 'break-all' }}><span style={{ textDecoration: 'underline' }}>Email:</span> {user.email}</p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div key={i} style={{ cursor: 'pointer', borderRadius: '20px', padding: '15px 20px' }} onClick={() => setUserSelectedFunction(user)}
                                                        className={'Saira_Medium_Gray admin_event_list_individual_course_div_' + i % 2}
                                                        id={'admin_event_list_individual_course_div_id_' + i}
                                                        onMouseEnter={() => onMouseEnterDiv(i)} onMouseLeave={() => onMouseLeaveDiv(i)}>
                                                        <div style={{ display: 'flex', gap: '20px', marginBottom: '10px' }}>
                                                            <p style={{ margin: '0px' }}><span style={{ textDecoration: 'underline' }}>Name:</span> {user.displayName}</p>
                                                            <p style={{ margin: '0px', wordBreak: 'break-all' }}><span style={{ textDecoration: 'underline' }}>Email:</span> {user.email}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>


                                <div className="login_landing_page_data_fields_two_in_row_div" style={{ marginTop: '30px' }}>
                                    <div>
                                        <label htmlFor="membership_plan_select_options" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>Plan</label>

                                        <select id="create_account_form_plan_input_admin_member" style={{ width: '190px' }} className="Saira_Medium_Black data-center-contact-us-form-input-box">
                                            <option value="1 Year" selected	>1 Year</option>
                                            <option value="3 Year">3 Year</option>
                                            <option value="5 Year">5 Year</option>
                                            <option value="IDCA Unlimited">IDCA Unlimited</option>
                                        </select>
                                    </div>

                                    <div >
                                        <label htmlFor="membership_plan_select_options" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>Payment Method</label>

                                        <select id="create_account_form_payment_method_input_admin_member" style={{ width: '190px' }} className="Saira_Medium_Black data-center-contact-us-form-input-box">
                                            <option value="Check" selected	>Check</option>
                                            <option value="Online Invoice">Online Invoice</option>
                                            <option value="IDCA Credit (free)">IDCA Credit (free)</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div style={{ textAlign: 'center', margin: '30px' }}>
                                <p onClick={() => { create_new_corp_member_by_member_admin(); }} className='Saira_Medium_White'
                                    style={{ padding: '20px', backgroundColor: '#AD2225', cursor: 'pointer' }}>
                                    Add
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </Modal >
        </div >
    );
}


export default AddCorpMembership

