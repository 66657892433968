import React, { useState, useEffect } from 'react';
import { GrDown } from 'react-icons/gr'
import { BsCheck2Circle } from 'react-icons/bs'
import Modal from 'react-modal';

import axios from 'axios'
import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";

function MemberCorpAdmin({ user, userInfo, companyMembershipInfo }) {
    const [companyMembership, setCompanyMembership] = useState()
    const [numOfActiveUsers, setNumOfActiveUsers] = useState(0)
    const [modalOpen, setModalOpen] = useState(false)

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [middleName, setMiddleName] = useState("")

    const [done, setDone] = useState(false)

    const [selectedIndex, setSelectedIndex] = useState()

    const [showPending, setShowPending] = useState(false)

    const [invalidCredentials, setInvalidCredentials] = useState(false)
    const [somethingWrong, setSomethingWrong] = useState(false)
    const [accountExist, setAccountExist] = useState(false)
    const [emailDomainNotSame, setEmailDomainNotSame] = useState(false)
    const [userHasBeenInvited, setUserHasBeenInvited] = useState(false)
    const [userIsAlreadyAnMember, setUserIsAlreadyAnMember] = useState(false)
    const [userDoesntExist, setUserDoesntExist] = useState(false)
    const [inviteButtonDisable, setInviteButtonDisable] = useState(false)
    const [userIsUnderCorpMembership, setUserIsUnderCorpMembership] = useState(false)

    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('')

    const db = getFirestore();

    useEffect(() => {
        setCompanyMembership(companyMembershipInfo)
        numOfActiveUsersFunction(companyMembershipInfo)
    }, [companyMembershipInfo])

    const customStyles = {
        content: {
            marginTop: '40px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderWidth: '0px',
            backgroundColor: 'white',
            boxShadow: '0px 4px 27px rgba(0, 0, 0, 0.25)',
            borderRadius: '20px',
            padding: '40px 60px'
        },
    };

    function update_name_case(n, num) {
        var result = n.charAt(0).toUpperCase() +
            n.slice(1).toLowerCase();
        if (num == 1) {
            setFirstName(result)

        } else if (num == 2) {
            setLastName(result)
        } else if (num == 3) {
            setMiddleName(result)
        }
    }

    function editMember(index) {
        if (selectedIndex == index &&
            document.getElementById('member_corp_admin_list_of_members_edit_options' + index) &&
            document.getElementById('member_corp_admin_list_of_members_edit_options' + index)) {
            document.getElementById('member_corp_admin_list_of_members_edit_options' + index).style.display = 'none'
            document.getElementById('member_corp_admin_list_of_members_edit_arrow' + index).style.transform = 'rotate(0deg)';
            setSelectedIndex()
        } else {
            if (document.getElementById('member_corp_admin_list_of_members_edit_options' + index) &&
                document.getElementById('member_corp_admin_list_of_members_edit_arrow' + index)) {
                document.getElementById('member_corp_admin_list_of_members_edit_options' + index).style.display = 'inherit'
                document.getElementById('member_corp_admin_list_of_members_edit_arrow' + index).style.transform = 'rotate(180deg)';
            }

            if (document.getElementById('member_corp_admin_list_of_members_edit_options' + selectedIndex) &&
                document.getElementById('member_corp_admin_list_of_members_edit_arrow' + selectedIndex)) {
                document.getElementById('member_corp_admin_list_of_members_edit_options' + selectedIndex).style.display = 'none'
                document.getElementById('member_corp_admin_list_of_members_edit_arrow' + selectedIndex).style.transform = 'rotate(0deg)';
            }
            setSelectedIndex(index)
        }
    }

    function numOfActiveUsersFunction(compMemb) {
        var activeUsers = 0
        compMemb?.members?.map(member => {
            if (member.isActive)
                activeUsers++;
        })
        setNumOfActiveUsers(activeUsers)
    }

    async function activateDeactivateMember(id, index, is_member) {
        const docRefCorp = doc(db, "Corporate Members", companyMembership._id);
        var docSnapCorp = await getDoc(docRefCorp);
        if (docSnapCorp.exists()) {
            var members = []
            docSnapCorp.data().members.map(i => {
                if (i.uid == id)
                    members.push({ ...i, isActive: i?.isActive ? false : true })
                else
                    members.push(i)
            })
            if (members) {
                await updateDoc(docRefCorp, {
                    members: members
                });
                docSnapCorp = await getDoc(docRefCorp);
                console.log(docSnapCorp.data())
                setCompanyMembership(docSnapCorp.data())
            }
            else
                console.log('user doesnt exist under corporation')
        }

        // get user info
        const docRef = doc(db, "Users", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const updateRol = docSnap.data().idcaRole
            if (is_member) {
                const index = updateRol?.indexOf('member');
                if (index > -1) { // only splice array when item is found
                    updateRol.splice(index, 1); // 2nd parameter means remove one item only
                }
            } else if (!updateRol.includes('member')) {
                updateRol.push('member')
            }
            console.log(updateRol)
            await updateDoc(docRef, {
                idcaRole: updateRol,
                membershipDetail: {
                    corporateMember: docSnapCorp.data().companyName,
                    active: false,
                    removed: false
                }
            });
        }
        editMember(index)
        numOfActiveUsersFunction(docSnapCorp.data())
    }

    async function delecteMember(id, name, index) {
        if (window.confirm("Are you sure you want to remove " + name + " from your account? ")) {
            const docRefCorp = doc(db, "Corporate Members", companyMembership._id);
            var docSnapCorp = await getDoc(docRefCorp);
            if (docSnapCorp.exists()) {
                var members = []
                docSnapCorp.data().members.map(i => {
                    if (i.uid != id)
                        members.push(i)
                })
                await updateDoc(docRefCorp, {
                    members: members ? members : null
                });
                docSnapCorp = await getDoc(docRefCorp);
                console.log(docSnapCorp.data())
                setCompanyMembership(docSnapCorp.data())
            }

            // get user info
            const docRef = doc(db, "Users", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const updateRol = docSnap.data().idcaRole
                const index = updateRol?.indexOf('member');
                if (index > -1) { // only splice array when item is found
                    updateRol.splice(index, 1); // 2nd parameter means remove one item only
                }
                await updateDoc(docRef, {
                    idcaRole: updateRol,
                    membershipDetail: {
                        corporateMember: docSnapCorp.data().companyName,
                        active: false,
                        removed: true
                    }
                });
            }
            editMember(index)
            numOfActiveUsersFunction(docSnapCorp.data())
            window.location.reload();
        }
    }

    function pendingUserExists(pendingArray, email) {
        var result = false
        pendingArray.map((user, i) => {
            if (user.email == email)
                result = true
        })
        return result
    }


    function memberUserExists(memberArray, email) {
        var result = false
        memberArray.map((user, i) => {
            if (user.email == email)
                result = true
        })
        return result
    }


    async function addUserAsMember() {
        const email = document.getElementById('add_to_corporate_membership_form_email_input').value
        const phone = document.getElementById('add_to_corporate_membership_form_phone_input').value
        const title = document.getElementById('add_to_corporate_membership_form_title_input').value
        setEmail(email)

        const newUserDomain = email.substring(email.lastIndexOf("@") + 1);
        const adminDomain = userInfo?.email.substring(userInfo?.email.lastIndexOf("@") + 1);

        if (companyMembershipInfo?.pendingMembers?.length && pendingUserExists(companyMembershipInfo.pendingMembers, email)) {
            setUserHasBeenInvited(true)
        }
        else if (memberUserExists(companyMembershipInfo.members, email))
            setUserIsUnderCorpMembership(true)
        else if (newUserDomain != adminDomain) {
            setEmailDomainNotSame(true)
        }
        else {
            setEmailDomainNotSame(false)
            axios.post('/corporateMemberAddingUser', {
                firstName: firstName,
                lastName: lastName,
                middleName: middleName,
                email: email,
                phone: phone,
                company: companyMembershipInfo?.companyName,
                title: title,
                corporateID: companyMembershipInfo?._id
            }).then((response) => {
                console.log(response)
                if (response.data?.status != 200) {
                    if (response.data.error == 'User exists on firebase auth but not on firestore')
                        setSomethingWrong(true)
                    else if (response.data.error == 'User is already an IDCA member')
                        setUserIsAlreadyAnMember(true)
                    else if (response.data.error == 'User does not exist')
                        setUserDoesntExist(true)
                    else
                        setSomethingWrong(true)
                } else {
                    setDone(true)
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            }).catch((error) => {
                setSomethingWrong(true)
                console.log(error)
            })
        }
    }

    async function inviteToBecomeIDCAUser() {
        axios.post('/inviteToBecomeIDCAUser', {
            firstName: firstName,
            email: email,
            company: companyMembershipInfo?.companyName,
        }).then((response) => {
            console.log(response)
            if (response.data?.status != 200) {
                setSomethingWrong(true)
            } else {
                setDone(true)
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }).catch((error) => {
            setSomethingWrong(true)
            console.log(error)
        })
    }

    async function generateIndividualReport(uid) {
        setLoading(true)
        axios.post('/individualReporting', {
            uid: uid,
            displayname: userInfo?.displayName,
            email: userInfo?.email
        }).then((response) => {
            setLoading(false)
            alert("You will recieve an email shortly.")
        }).catch((error) => {
            // alert("Something went wrong. Please contact IDCA Support.")
            alert(error)
        })
    }

    async function generateCoarporateReporting() {
        setLoading(true)
        axios.post('/coarporateReporting', {
            cid: userInfo?.corporateMemberID,
            displayname: userInfo?.displayName,
            email: userInfo?.email
        }).then((response) => {
            setLoading(false)
            alert("You will recieve an email shortly.")
        }).catch((error) => {
            alert(error)
        })
    }
    async function removePending(id, name) {
        if (window.confirm("Are you sure you want to remove " + name + " from your account? ")) {
            const docRefCorp = doc(db, "Corporate Members", companyMembership._id);
            var docSnapCorp = await getDoc(docRefCorp);
            if (docSnapCorp.exists()) {
                var pending = []
                docSnapCorp.data().pendingMembers.map(i => {
                    if (i.uid != id)
                        pending.push(i)
                })
                await updateDoc(docRefCorp, {
                    pendingMembers: pending ? pending : null
                });
                window.location.reload();
            }
        }
    }


    return (
        <div className='all_div_admin_member_margin WhitePaperAdminMainDiv'>

            {
                loading && <div className="payment-course-before-load-spinner-container"
                    style={{ "margin": "auto", height: "100%" }}>
                    <div className="payment-course-before-loading-spinner">
                    </div>
                </div>
            }

            {/* <AdminMenu menuPage='Members' userInfo={userInfo} /> */}
            {
                !loading &&
                <div className='Saira_Medium_Black' style={{ width: '100%' }}>
                    <h2>List of members</h2>

                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 20px' }}>
                        <p style={{ border: '1px solid gray', padding: '10px 20px', cursor: 'pointer' }}
                            onClick={() => { setModalOpen(true) }}>Add Member</p>
                        <p>Members: {numOfActiveUsers} / {companyMembership?.numLicenses}</p>
                    </div>

                    <div style={{ marginBottom: '30px' }}>
                        <button className='Saira_Medium_Black' onClick={() => setShowPending(false)}
                            style={{ backgroundColor: 'white', border: '0px', borderBottom: !showPending ? '2px solid gray' : '0px', fontSize: '18px', cursor: 'pointer' }}>
                            Members
                        </button>
                        <button className='Saira_Medium_Black' onClick={() => setShowPending(true)}
                            style={{ fontStyle: 'italic', backgroundColor: 'white', border: '0px', borderBottom: showPending ? '2px solid gray' : '0px', fontSize: '18px', cursor: 'pointer' }}>
                            Pending...
                        </button>
                    </div>
                    {showPending ? (
                        <div>
                            <div className='member_corp_admin_list_of_members_div' style={{ width: '90%', padding: '0px 20px' }}>
                                <p>Name</p>
                                <p>Email</p>
                            </div>
                            {companyMembership?.pendingMembers?.map((member, i) => (
                                <div key={i} style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center', position: 'relative' }}>
                                    <div className={'Saira_Medium_Gray member_corp_admin_list_of_members_div admin_event_list_individual_course_div_' + i % 2} style={{ padding: '10px 20px', width: '90%' }}>
                                        <p>{member.name}</p>
                                        <p>{member.email}</p>
                                    </div>
                                    <div style={{ display: member.role == 'account admin' && 'none', position: 'relative' }}>
                                        <div style={{ display: 'none', position: 'absolute', bottom: '10px', right: '40px' }} id={'member_corp_admin_list_of_members_edit_options' + i} className='member_corp_admin_list_of_members_edit_options'>
                                            <p onClick={() => removePending(member.uid, member.name, i)}>Remove</p>
                                        </div>
                                        <div id={'member_corp_admin_list_of_members_edit_arrow' + i} className='member_corp_admin_list_of_members_edit_button' onClick={() => editMember(i)}>
                                            <GrDown />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div>
                            <div className='member_corp_admin_list_of_members_div' style={{ width: '90%', padding: '0px 20px' }}>
                                <p>Name</p>
                                <p>Email</p>
                                <p>Type</p>
                                <p>Mileage points</p>
                                <p>Status</p>
                            </div>
                            {companyMembership?.members?.map((member, i) => (
                                <div key={i} style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center', position: 'relative' }}>
                                    <div className={'Saira_Medium_Gray member_corp_admin_list_of_members_div admin_event_list_individual_course_div_' + i % 2} style={{ padding: '10px 20px', width: '90%' }}>
                                        <p>{member.name}</p>
                                        <p>{member.email}</p>
                                        <p>{member.role}</p>
                                        <p>{member.mileagePoints}</p>
                                        <p>{member.isActive ? 'Active' : 'Deactived'}</p>
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <div style={{ display: 'none', position: 'absolute', bottom: '10px', right: '40px' }} id={'member_corp_admin_list_of_members_edit_options' + i} className='member_corp_admin_list_of_members_edit_options'>
                                            <p style={{ display: member.role == 'account admin' && 'none' }} onClick={() => activateDeactivateMember(member.uid, i, member.isActive)}>{member.isActive ? 'Deactivate' : 'Activate'}</p>

                                            <div style={{ border: '1px solid gray', display: member.role == 'account admin' && 'none' }}></div>

                                            <p style={{ display: member.role == 'account admin' && 'none' }} onClick={() => delecteMember(member.uid, member.name, i)}>Remove</p>

                                            <div style={{ border: '1px solid gray', display: member.role == 'account admin' && 'none' }}></div>

                                            <p onClick={() => generateIndividualReport(member.uid)} >Full Report</p>

                                        </div>
                                        <div id={'member_corp_admin_list_of_members_edit_arrow' + i} className='member_corp_admin_list_of_members_edit_button' onClick={() => editMember(i)}>
                                            <GrDown />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 20px' }}>
                                <p style={{ border: '1px solid gray', padding: '10px 20px', cursor: 'pointer' }} onClick={() => generateCoarporateReporting()}>Generate Company Report</p>
                            </div>
                        </div>
                    )}
                </div>

            }

            <Modal
                isOpen={modalOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={() => {
                    setInviteButtonDisable(false); setUserDoesntExist(false); setSomethingWrong(false); setUserIsUnderCorpMembership(false);
                    setModalOpen(false); setDone(false); setUserIsAlreadyAnMember(false); setUserHasBeenInvited(false);
                }}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {done ? (
                    <div style={{ textAlign: 'center', color: 'green', padding: '50px' }}>
                        <div>
                            <BsCheck2Circle size={60} />
                        </div>
                        <h2 className='Saira_Bold_Black'>All Done!</h2>
                    </div>
                ) : (userDoesntExist ? (
                    <center className='Saira_Medium_LGray' style={{ maxWidth: '350px', fontSize: '19px' }}>
                        {somethingWrong && (<p style={{ fontSize: '13px' }} className='Saira_Medium_Red'>Something went wrong.</p>)}

                        <p style={{ color: 'green' }}><span className='Saira_Medium_DGray'>{email}</span> does not exist as an IDCA user.</p>
                        <p style={{ color: 'green', lineHeight: '30px' }}>Invite <span className='Saira_Medium_DGray'>{email}</span> to become an IDCA user then you will be able to add them as a member.</p>
                        <button disabled={inviteButtonDisable} style={{ backgroundColor: 'white', border: '1px solid gray', padding: '0px 40px', cursor: inviteButtonDisable ? 'default' : 'pointer' }}
                            onClick={() => { setInviteButtonDisable(true); inviteToBecomeIDCAUser() }}>
                            <p className='Saira_Medium_Black' style={{ fontSize: '18px' }}>Invite</p>
                        </button>
                    </center>
                ) : (
                    <div>
                        <div className='Saira_Medium_Black'>
                            {invalidCredentials && (<p style={{ fontSize: '13px' }} className='Saira_Medium_Red'>Invalid credentials.</p>)}
                            {somethingWrong && (<p style={{ fontSize: '13px' }} className='Saira_Medium_Red'>Something went wrong.</p>)}
                            {accountExist && (<p style={{ fontSize: '13px' }} className='Saira_Medium_Red'>Account already exists.</p>)}
                            {emailDomainNotSame && (<p style={{ fontSize: '13px' }} className='Saira_Medium_Red'>User is not under the same domain as your company.</p>)}
                            {userHasBeenInvited && (<p style={{ fontSize: '13px' }} className='Saira_Medium_Red'>This user has already been invited</p>)}
                            {userIsAlreadyAnMember && (<p style={{ fontSize: '13px' }} className='Saira_Medium_Red'>User is already an IDCA member</p>)}
                            {userIsUnderCorpMembership && (<p style={{ fontSize: '13px' }} className='Saira_Medium_Red'>User is already under your corporate membership</p>)}



                            <div className="login_landing_page_data_fields_two_in_row_div">
                                <div>
                                    <label htmlFor="first_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <p className="data-center-contact-us-form-input-label">First Name</p>
                                    </label>
                                    <input className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                        maxLength="40" name="first_name" size="20" type="text" required="required" value={firstName} onChange={e => update_name_case(e.target.value, 1)} />
                                    <p className='Saira_Regular_Red' style={{ margin: '0px', fontSize: '12px' }}>required</p>
                                </div>
                                <div>
                                    <label htmlFor="last_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <p className="data-center-contact-us-form-input-label">Last Name</p>
                                    </label>
                                    <input className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                        maxLength="80" name="last_name" size="20" type="text" required="required" value={lastName} onChange={e => update_name_case(e.target.value, 2)} />
                                    <p className='Saira_Regular_Red' style={{ margin: '0px', fontSize: '12px' }}>required</p>
                                </div>
                            </div>

                            <div className="login_landing_page_data_fields_two_in_row_div">
                                <div>
                                    <label htmlFor="email" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <p className="data-center-contact-us-form-input-label">Middle Name</p>
                                    </label>
                                    <input className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                        maxLength="80" name="middle_name" size="20" type="text" value={middleName} onChange={e => update_name_case(e.target.value, 3)} />
                                </div>

                                <div>
                                    <label htmlFor="title"><p className="data-center-contact-us-form-input-label">Title</p></label>
                                    <input id="add_to_corporate_membership_form_title_input"
                                        className="Saira_Medium_Black data-center-contact-us-form-input-box" maxLength="40" name="title" size="20" type="text" />

                                </div>
                            </div>

                            <div className="login_landing_page_data_fields_two_in_row_div">
                                <div>
                                    <label htmlFor="email" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <p className="data-center-contact-us-form-input-label">Email</p>
                                    </label>
                                    <input id="add_to_corporate_membership_form_email_input"
                                        className="Saira_Medium_Black data-center-contact-us-form-input-box" maxLength="80" name="email" size="20" type="email" required="required" />
                                    <p className='Saira_Regular_Red' style={{ margin: '0px', fontSize: '12px' }}>required</p>
                                </div>

                                <div>
                                    <label htmlFor="phone" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <p className="data-center-contact-us-form-input-label">Phone</p>
                                    </label>
                                    <input id="add_to_corporate_membership_form_phone_input"
                                        className="Saira_Medium_Black data-center-contact-us-form-input-box"
                                        maxLength="20" name="phone" size="20" type="tel" />
                                    <p className='Saira_Regular_Red' style={{ margin: '0px', fontSize: '12px' }}>required</p>
                                </div>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', margin: '30px' }}>
                            <p onClick={() => addUserAsMember()} className='Saira_Medium_White'
                                style={{ padding: '20px', backgroundColor: '#AD2225', cursor: 'pointer' }}>
                                Add
                            </p>
                        </div>
                    </div>
                ))}
            </Modal>
        </div >
    );
}

export default MemberCorpAdmin