

export default function AccountCreated({ userEmail, userName }) {

    fetch("/account-created-email", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            email: userEmail, name: userName,
        }),
    })
        .then((res) => res.json())
        .then((data) => console.log(data));
}