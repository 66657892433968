import React, { useState, useEffect } from 'react';
import { getFirestore, doc, query, where, orderBy, collection, getDocs, getDoc, limit, updateDoc, arrayUnion } from "firebase/firestore";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { getStorage, ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";

import testImg from '../../../../resources/DCISbg.png'

import { MdOutlinePhotoSizeSelectActual, MdOutlineCancel } from "react-icons/md";
import { BsCheck2Circle } from "react-icons/bs"
import { IoIosArrowDown } from "react-icons/io";

import axios from 'axios';


function ProfileDetail({ user_info_param, certificates_param }) {
    const api_url = process.env.REACT_APP_FIREBASE_FUNCTION_URL_PRODUCTION

    const storage = getStorage();

    const userInfo = user_info_param
    const certificates = certificates_param
    const [showMore, setShowMore] = useState(false)

    const [errorMessage, setErrorMessage] = useState()

    const [detailSaveButton, setDetailSaveButton] = useState(false)

    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [middleName, setMiddleName] = useState()
    const [phone, setPhone] = useState()
    const [secondaryPhone, setSecondaryPhone] = useState()
    const [company, setCompany] = useState()
    const [title, setTitle] = useState()
    const [photoUrl, setPhotoUrl] = useState()

    function refreshPage() {
        window.location.reload(false);
    }

    function getCertificationString() {
        var cert_string = ''
        if (certificates && certificates.length) {
            certificates.map((cert, i) => {
                const cert_name = cert.group_name.replace('®', '');

                if (certificates.length - 1 == i)
                    cert_string += cert_name
                else if (certificates.length - 2 == i) {
                    if (certificates.length > 2)
                        cert_string += cert_name + ', & '
                    else
                        cert_string += cert_name + ' & '
                } else
                    cert_string += cert_name + ', '
            })
        } else cert_string = 'No certificates'

        return cert_string
    }

    function triggerImageInput() {
        document.getElementById('imageInput').click();
    }
    async function handleFileChange(e) {
        const filename = e?.target?.files[0]?.name
        if (!filename)
            return;
        const imageRef = ref(storage, 'gs://idca-f9873.appspot.com/User Profiles/' + userInfo.uid + `/${filename}`);
        uploadBytesResumable(imageRef, e.target.files[0])
            .then((snapshot) => {
                // Let's get a download URL for the file.
                getDownloadURL(snapshot.ref)
                    .then((url) => {
                        setPhotoUrl(url)
                        // ...
                    });
            }).catch((error) => {
                console.error('Upload failed', error);
                // ...
            });
    }


    function update_info() {
        axios.post(api_url + '/update_user', {
            uid: userInfo.uid, phone: phone ? phone : userInfo.phone,
            mobile: secondaryPhone ? secondaryPhone : userInfo.mobile,
            photoURL: photoUrl ? photoUrl : userInfo.photoURL,
            firstName: firstName ? firstName : userInfo.firstName,
            lastName: lastName ? lastName : userInfo.lastName,
            middleName: middleName ? middleName : userInfo.middleName,
            company: company ? company : userInfo.company,
            title: title ? title : userInfo.title,
            address: userInfo.address, apt: userInfo.apt, city: userInfo.city,
            state: userInfo.state, zipcode: userInfo.zipcode, country: userInfo.country,
        }).then(async (response) => {
            // Updated Successfully
            console.log(response)

            document.getElementById('add_user_event_spinner').style.display = 'initial'
            document.getElementById('adding_attendee_all_done').style.display = 'initial'
            document.getElementById('adding_attendee_all_done').style.width = '180px'
            document.getElementById('adding_attendee_all_done').style.height = '120px'

            setTimeout(() => {
                // Refresh Page
                refreshPage()
            }, 2000);
        }).catch((error) => {
            // Error Updating
            console.log(error)

            document.getElementById('add_user_event_spinner').style.display = 'initial'
            document.getElementById('adding_attendee_error').style.display = 'inherit'
            document.getElementById('adding_attendee_all_done').style.width = '180px'
            document.getElementById('adding_attendee_all_done').style.height = '150px'
            setErrorMessage(error)

            setTimeout(() => {
                // Refresh Page
                refreshPage()
            }, 2000);
        })
    }

    useEffect(() => {
        if (firstName || lastName || middleName || phone || secondaryPhone || company || title || photoUrl)
            setDetailSaveButton(true)
    }, [firstName, lastName, middleName, phone, secondaryPhone, company, title, photoUrl])

    function resetPassword() {
        if (window.confirm('Reset password.')) {
            let redirectURL = 'login'
            const actionCodeSettings = {
                url: 'http://idc-a.org/' + redirectURL,
                // This must be true.x
                handleCodeInApp: true,
            };

            const auth = getAuth();
            sendPasswordResetEmail(auth, userInfo.email, actionCodeSettings)
                .then(() => {
                    // Password reset email sent!
                    // ..
                    document.getElementById('add_user_event_spinner').style.display = 'initial'
                    document.getElementById('user_dashboard_home_profile_password_reset').style.display = 'initial'
                    document.getElementById('user_dashboard_home_profile_password_reset').style.width = '220px'
                    document.getElementById('user_dashboard_home_profile_password_reset').style.height = '190px'

                    setTimeout(() => {
                        // Refresh Page
                        refreshPage()
                    }, 5000);
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorMessage)
                    // ..
                    document.getElementById('add_user_event_spinner').style.display = 'initial'
                    document.getElementById('adding_attendee_error').style.display = 'inherit'
                    document.getElementById('adding_attendee_all_done').style.width = '180px'
                    document.getElementById('adding_attendee_all_done').style.height = '150px'
                    setErrorMessage('An error occurred')

                    setTimeout(() => {
                        // Refresh Page
                        refreshPage()
                    }, 2000);
                });
        }
    }

    function cancel_update() {
        setFirstName()
        document.getElementById('first_name').value = userInfo.firstName
        setLastName()
        document.getElementById('last_name').value = userInfo.lastName
        setMiddleName()
        document.getElementById('middle_name').value = userInfo.middleName
        setPhone()
        document.getElementById('mobile').value = userInfo.phone
        setSecondaryPhone()
        setCompany()
        document.getElementById('user_dashboard_home_profile_detail_input_company').value = userInfo.company
        setTitle()
        document.getElementById('user_dashboard_home_profile_detail_input_title').value = userInfo.title
        setPhotoUrl()
        setDetailSaveButton(false)
    }

    function showMoreLess() {
        document.getElementById('user_dashboard_home_profile_top_show_more_less_icon').style.transform = showMore ? 'rotate(180deg)' : 'rotate(0deg)'
        document.getElementById('user_dashboard_home_profile_detail_id').style.display = showMore ? 'none' : 'inherit'
        setShowMore(!showMore)
    }

    return (
        <div>
            {userInfo ?
                <div className='user_dashboard_home_profile_div'>
                    <div className='user_dashboard_home_profile_top_info_div'>
                        <div className='user_dashboard_home_profile_top_img_upload_all_div'>
                            {(userInfo.photoURL || photoUrl) ?
                                <img src={photoUrl ? photoUrl : userInfo.photoURL} alt="User Profile Picture" className='user_dashboard_home_profile_top_img user_dashboard_home_profile_top_img_div' />
                                :
                                <img src={testImg} alt="User Profile Picture" className='user_dashboard_home_profile_top_img_div user_dashboard_home_profile_top_img' />
                            }
                            <input type="file" id="imageInput" className="StandardsDownload_Form_input Saira_Medium_Black"
                                accept="image/*" name="image" style={{ display: 'none', fontsize: "14px" }} onInput={(e) => handleFileChange(e)} />

                            <div className='user_dashboard_home_profile_top_img_upload_div' onClick={triggerImageInput}>
                                <MdOutlinePhotoSizeSelectActual size={22} />
                                <p className='user_dashboard_home_profile_top_img_upload_p'>Upload new photo</p>
                            </div>
                        </div>

                        <div className='user_dashboard_home_profile_top_personal_info_div'>
                            <p className='user_dashboard_home_profile_top_name'>{userInfo.firstName + ' ' + userInfo.lastName}</p>
                            <p className='user_dashboard_home_profile_top_company'>{userInfo?.company}</p>
                            <p className='user_dashboard_home_profile_top_title'>{userInfo?.title}</p>
                        </div>

                        {certificates && certificates.length ? <div className='user_dashboard_home_profile_divider'></div> : null}

                        {certificates && certificates.length ? <div className='user_dashboard_home_profile_certifs_div'>
                            {certificates.map((cert, i) => {
                                if (i < 3) return (
                                    <img key={i} src={cert.badge.image.preview} alt="User Profile Picture" className='user_dashboard_home_profile_certifs_img' />
                                )
                            })}
                        </div> : null}

                        {detailSaveButton ?
                            <div>
                                <div className='user_dashboard_home_profile_top_detail_save_div' onClick={update_info}>
                                    <p>Save</p>
                                </div>
                                <div className='user_dashboard_home_profile_top_detail_save_div' onClick={cancel_update}>
                                    <p>Cancel</p>
                                </div>
                            </div>
                            : null}

                        <div className='user_dashboard_home_profile_top_show_more_less' onClick={showMoreLess}>
                            <div id='user_dashboard_home_profile_top_show_more_less_icon'>
                                <IoIosArrowDown />
                            </div>
                            <p>{showMore ? 'Hide' : 'Show more'}</p>
                        </div>
                    </div>


                    <div className='user_dashboard_home_profile_detail' id='user_dashboard_home_profile_detail_id'>

                        <div className='user_dashboard_home_profile_top_detail_divider'></div>


                        <div className='user_dashboard_home_profile_detail_line_div'>
                            <div>
                                <p>First Name</p>
                                <input id='first_name' defaultValue={userInfo.firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                            <div>
                                <p>Last Name</p>
                                <input id='last_name' defaultValue={userInfo.lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                            <div>
                                <p>Email</p>
                                <input value={userInfo.email} disabled={true} />
                            </div>
                        </div>
                        <div className='user_dashboard_home_profile_detail_line_div'>
                            <div>
                                <p>Middle Name</p>
                                <input id='middle_name' defaultValue={userInfo.middleName} onChange={(e) => setMiddleName(e.target.value)} />
                            </div>
                            <div>
                                <p>Mobile</p>
                                <input id='mobile' defaultValue={userInfo.phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div>
                                <p>Work Phone</p>
                                <input id='work_phone' defaultValue={userInfo.mobile} onChange={(e) => setSecondaryPhone(e.target.value)} />
                            </div>
                        </div>
                        <div className='user_dashboard_home_profile_detail_line_div'>
                            <div>
                                <p>Company</p>
                                <input defaultValue={userInfo.company} onChange={(e) => setCompany(e.target.value)}
                                    id='user_dashboard_home_profile_detail_input_company' />
                            </div>
                            <div>
                                <p>Title</p>
                                <input defaultValue={userInfo.title} onChange={(e) => setTitle(e.target.value)}
                                    id='user_dashboard_home_profile_detail_input_title' />
                            </div>
                        </div>
                        <div className='user_dashboard_home_profile_detail_line_div'>
                            <div>
                                <p>Certification</p>
                                <input value={getCertificationString()} disabled={true} />
                            </div>
                            <div>
                                <p>Score</p>
                                <input value={userInfo.mileagePoints ? userInfo.mileagePoints : 0} disabled={true} />
                            </div>
                            <div>
                                <p>Password</p>
                                <button className='user_dashboard_home_profile_detail_reset_password' onClick={resetPassword}>Reset Password</button>
                            </div>
                        </div>
                    </div>


                    <center id="add_user_event_spinner">
                        <div id='adding_attendee_all_done'>
                            <div>
                                <BsCheck2Circle size={60} />
                            </div>
                            <h2 className='Saira_Bold_Black'>Done!</h2>
                        </div>
                        <div id='user_dashboard_home_profile_password_reset'>
                            <div>
                                <BsCheck2Circle size={60} />
                            </div>
                            <h2 className='Saira_Bold_Black'>A password reset link has been sent to your email!</h2>
                        </div>
                        <div id='adding_attendee_error'>
                            <div>
                                <MdOutlineCancel size={60} />
                            </div>
                            <h2 className='Saira_Bold_Black'>{errorMessage}</h2>
                        </div>
                    </center >
                </div> : null
            }
        </div >
    );
}

export default ProfileDetail
