import React, { useState, useEffect } from 'react';

import { MdEdit } from 'react-icons/md'
import { IoMdAdd } from "react-icons/io"
import { ImCancelCircle } from 'react-icons/im'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { BsCheck2Circle, BsClipboard2CheckFill } from 'react-icons/bs'
import { MdOutlineCancel } from 'react-icons/md'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { TiClipboard } from 'react-icons/ti'
import { BsPlusLg } from 'react-icons/bs'

import { CopyToClipboard } from "react-copy-to-clipboard";


import { getStorage, ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { getFirestore, doc, getDoc, updateDoc, where, collection, query, getDocs } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import SuccessfulRegistration from '../../Components/EmailServer/SuccessfulRegistration';

import generator from 'generate-password-ts';

import Modal from 'react-modal';
import axios from 'axios';
// import sendEmailAboutEventUpdate from './Functions/SendEventUpdateEmails';
import RegisterUserForZoomEvent from '../../Components/RegisterZoomEvent/RegisterZoomEvent';
import UpdateZoomEvent from '../../Components/RegisterZoomEvent/UpdateZoomEvent';
import GoogleMapsAPI from '../PaymentCourse/Payment/GoogleMapsAPI';
import addAttendeeToEventModule from '../../Components/AddUserToCourse/AddUserToCourse2024Automation';

function AdminCourseList(props) {
    const api_url = process.env.REACT_APP_FIREBASE_FUNCTION_URL_PRODUCTION

    const { id } = props
    const [event, setEvent] = useState()
    const [userInfo, setUserInfo] = useState()
    const db = getFirestore()
    const storage = getStorage();

    const [editClicked, setEditClicked] = useState(false)
    const [addAttendee, setAddAttendee] = useState(false)
    const [userDoesntExist, setUserDoesntExist] = useState(false)
    const [userRegisteredBefore, setUserRegisteredBefore] = useState(false)

    const [userSearch, setUserSearch] = useState([])

    const [selectedUserID, setSelectedUserID] = useState()
    const [selectedUser, setSelectedUser] = useState()
    const [displayDetails, setDisplayDetails] = useState(false)

    const [modalOpen, setModalOpen] = useState(false)

    const [adminEmail, setAdminEmail] = useState(null)

    const listOfAllAccessRoles = ['admin', 'instructor']
    const listOfLimitedAccessRoles = ['sales', 'admin', 'instructor']

    const [saveClicked, setSaveClicked] = useState(false)
    const [copiedEmail, setCopiedEmail] = useState()

    const [eventDate, setEventDate] = useState()
    const [eventLocation, setEventLocation] = useState()
    const [parkingDetail, setParkingDetail] = useState()
    const [eventSeatsRemaining, setEventSeatsRemaining] = useState()
    const [eventVenue, setEventVenue] = useState()
    const [virtual, setVirtual] = useState(true)
    const [sponsors, setSponsors] = useState()

    const google_api_key = 'AIzaSyC6vzqDF4jjdn6Yvgi131ZTHuEvf9oOEWU'


    useEffect(() => {
        getAuth().onAuthStateChanged(async user => {
            if (user) {
                const listOfValidRoles = ['admin', 'instructor', 'sales']

                const docRef = doc(db, "Users", user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    if (docSnap.data().idcaRole?.some(i => listOfValidRoles.includes(i))) {
                        setUserInfo(docSnap.data())

                        setAdminEmail(user.email)
                        const docRefEvent = doc(db, "Event", id);
                        const docSnapEvent = await getDoc(docRefEvent);

                        if (docSnapEvent.exists()) {
                            setEvent(docSnapEvent.data())

                            setEventDate(docSnapEvent.data()?.startDateTime)
                            setEventLocation(docSnapEvent.data()?.location)
                            setParkingDetail(docSnapEvent.data()?.parking_detail)
                            setEventInstructor(docSnapEvent.data()?.instructor_name)
                            setEventVenue(docSnapEvent.data()?.venue)
                            setEventSeatsRemaining(docSnapEvent.data()?.seatsRemaining)
                            setVirtual(docSnapEvent.data()?.virtual)
                            setSponsors(docSnapEvent.data()?.sponsors)
                        } else {
                            // doc.data() will be undefined in this case
                            console.log("No such document!");
                        }
                    } else {
                        window.location.href = "/";
                    }
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            } else {
                window.location.href = "/login";
            }
        })
    }, []);

    console.log(event)

    async function saveEventUpdateHelper(id, last_add_reload) {

        const eventZoomRef = doc(db, "Event", id);
        const eventDoc = await getDoc(eventZoomRef);
        const event = eventDoc.data()
        const initial_instructor_email = event.instructor_email
        var eventDateTemp = new Date(eventDate)
        if (id.includes('DCOS') || id.includes('DCES')) {
            eventDateTemp.setDate(eventDateTemp.getDate() + 2);
        }
        eventDateTemp = eventDateTemp.toISOString()

        axios({
            method: 'get',
            url: 'https://maps.googleapis.com/maps/api/geocode/json?address=' + eventLocation + '&key=' + google_api_key
        }).then((result) => {
            const geocode_res = result.data.results[0]
            const location_long_lat = geocode_res.geometry.location
            var city
            var state
            var country
            geocode_res.address_components.map((address) => {
                if (address.types.includes("locality"))
                    city = address.long_name
                else if (address.types.includes("administrative_area_level_1"))
                    state = address.short_name
                else if (address.types.includes("country"))
                    country = address.long_name
            })
            axios({
                method: 'get',
                url: 'https://maps.googleapis.com/maps/api/timezone/json?location=' + location_long_lat.lat + '%2C' + location_long_lat.lng + '&timestamp=1331161200&key=' + google_api_key
            }).then(async (result) => {
                const timeZoneID = result.data.timeZoneId
                const timeZoneName = result.data.timeZoneName
                const rawOffset = ((result.data.rawOffset / 3600 + 1) >= 0 ? '+' : '') + (result.data.rawOffset / 3600 + 1)

                var virtual_status = false
                if ((virtual == true || virtual == 'true') && event.hasInPersonAttendee)
                    alert('Alert: event has in-person attendees')
                else if (virtual == true || virtual == 'true')
                    virtual_status = true

                const fileURL = await setSponsorsFunction()

                var allSponsors = (sponsors && sponsors.length) ? sponsors : []
                if (fileURL) {
                    if (allSponsors.length)
                        allSponsors = [...allSponsors, fileURL]
                    else
                        allSponsors = [fileURL]
                }

                var timeStamp = null
                if (event.titleAbbrev == 'DCRP') {
                    const eventDateTempTime = new Date(eventDateTemp)
                    const hours = eventDateTempTime.getHours()
                    const mins = eventDateTempTime.getMinutes()
                    timeStamp = {
                        hour: hours,
                        min: mins,
                        full: (hours > 12 ? hours - 12 : hours) + ':' +
                            (mins >= 10 ? mins : '0' + mins) + (hours >= 12 ? 'PM' : 'AM')
                            + '-' +
                            ((hours + 4) > 12 ? (hours + 4) - 12 : (hours + 4)) + ':' +
                            (mins >= 10 ? mins : '0' + mins) + ((hours + 4) >= 12 ? 'PM' : 'AM'),
                        startTime: (hours > 12 ? hours - 12 : hours) + ':' +
                            (mins >= 10 ? mins : '0' + mins) + (hours >= 12 ? 'PM' : 'AM')
                    }
                }

                let instructor_email = ''
                if (eventInstructor === 'Dikran Kechichian')
                    instructor_email = 'dikran.k@idc-a.org'
                else if (eventInstructor === 'Rich Banta')
                    instructor_email = 'rbanta@idc-a.org'
                else if (eventInstructor === 'Victor Banuelos')
                    instructor_email = 'victor@idc-a.org'

                // Update Event Document
                await updateDoc(eventZoomRef, {
                    country: country ? country : null,
                    city: city ? city : null,
                    state: state ? state : null,
                    location: eventLocation,
                    zone: rawOffset,
                    timeZoneID: timeZoneID,
                    timeZoneName: timeZoneName,

                    parking_detail: parkingDetail ? parkingDetail : null,
                    instructor_name: eventInstructor ? eventInstructor : null,
                    instructor_email: instructor_email,

                    time: timeStamp,

                    sponsors: allSponsors ? allSponsors : null,

                    virtual: virtual_status ? virtual_status : false,
                    startDateTime: eventDateTemp,
                    venue: eventVenue ? eventVenue : null,
                    seatsRemaining: eventSeatsRemaining ? eventSeatsRemaining : null
                });

                // Add or update zoom
                if (event.titleAbbrev != 'DCOM' && event.titleAbbrev != 'DCIE') {
                    // if instructor has changed then create new zoom otherwise update
                    if (instructor_email === initial_instructor_email) {
                        // Update Zoom Event
                        try {
                            const update_info = {
                                start_time: eventDateTemp,
                                timezone: timeZoneID
                            }
                            UpdateZoomEvent({ meeting_id: event.zoom_id, update_info: update_info, titleAbbrev: event.titleAbbrev, timeStampDCRP: timeStamp })
                        } catch (error) {
                            console.log(error)
                            alert('An Error Occurred Updating Zoom Info')

                            if (last_add_reload)
                                window.location.reload();
                        }
                    } else {
                        // Update Event Document
                        await updateDoc(eventZoomRef, {
                            zoom_id: null,
                            zoom_link: null
                        });

                        // Add zoom
                        addZoomToEvent(id)
                    }
                }

                /* DONT WANT TO ASK TO SEND UPDATE EMAILS TO ATTENDEES */
                if (last_add_reload)
                    window.location.reload();

                // if (window.confirm("Send email updates to class attendees?")) {
                //     // Send email about updated event info
                //     try {
                //         axios.post(api_url + '/event_detail_update_email', {
                //             event: event, changes: { eventLocation, eventDateTemp, eventVenue }
                //         }).then((response) => {
                //             if (response.data == 'All Done!') {
                //                 if (last_add_reload) {
                //                     setModalOpen(true)
                //                     setDone(true)
                //                     setTimeout(function () {
                //                         window.location.reload();
                //                     }, 1000);
                //                 }
                //             } else {
                //                 alert('An Error Occurred Sending Updates')
                //                 if (last_add_reload)
                //                     window.location.reload();
                //             }
                //         })
                //     } catch (error) {
                //         console.log(error)
                //         alert('An Error Occurred Sending Out Update Emails')
                //         if (last_add_reload)
                //             window.location.reload();
                //     }
                // } else {
                // if (last_add_reload)
                //     window.location.reload();
                // }

            }).catch((error) => {
                console.log(error)
                alert('An Error Occurred Updating General Info')
                if (last_add_reload)
                    window.location.reload();
            })
        }).catch((error) => {
            console.log(error)
            alert('An Error Occurred Converting Finding The Location')
            if (last_add_reload)
                window.location.reload();
        })

    }

    async function saveUserInfo() {
        setEditClicked(false)

        if (event.titleAbbrev == 'DCOM') {
            await saveEventUpdateHelper(id)
            await saveEventUpdateHelper(id + '-DCIS')
            await saveEventUpdateHelper(id + '-DCOS', true)
        } else if (event.titleAbbrev == 'DCIE') {
            await saveEventUpdateHelper(id)
            await saveEventUpdateHelper(id + '-DCIS')
            await saveEventUpdateHelper(id + '-DCES', true)
        } else {
            await saveEventUpdateHelper(id, true)
        }

        setSaveClicked(false)
        // window.location.reload();
    }

    function cancelClicked() {
        if (document.getElementById('admin_course_list_attendee_add_input_id'))
            document.getElementById('admin_course_list_attendee_add_input_id').value = ''
        if (document.getElementById('admin_course_list_attendee_add_input_virtual'))
            document.getElementById('admin_course_list_attendee_add_input_virtual').checked = true

        if (document.getElementById('admin_course_zoom_link_id_1'))
            document.getElementById('admin_course_zoom_link_id_1').value = ''
        if (document.getElementById('admin_course_zoom_link_id_2'))
            document.getElementById('admin_course_zoom_link_id_2').value = ''

        setEditClicked(false)
        setAddAttendee(false)
        setUserDoesntExist(false)
        setUserRegisteredBefore(false)

        setEventDate(event.startDateTime)
        setEventLocation(event.location)
        setParkingDetail(event.parking_detail)
        setEventInstructor(event.instructor_name)
        setEventSeatsRemaining(event.seatsRemaining)
    }

    async function deleteUserFromEvent(user_id, under_parent_event, email) {
        if (under_parent_event) {
            alert(email + " has been added under a parent event. Please remove attendee from parent event.")
        }
        else if (window.confirm('Are you sure you want delete this course from the user account?')) {
            const docRef = doc(db, "Users", user_id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                // If From DCOM or DCIE then DELETE from child events too
                if (event.titleAbbrev == 'DCOM' || event.titleAbbrev == 'DCIE') {
                    var second_course_abbr = 'DCES'
                    if (event.titleAbbrev == 'DCOM')
                        second_course_abbr = 'DCOS'
                    // DELETE EVENT FROM USER
                    const editedCourses = []
                    docSnap.data().courses?.map(i => {
                        if (i.eventID != id && (i.eventID != (id + '-DCIS')) && (i.eventID != (id + '-' + second_course_abbr)))
                            editedCourses.push(i)
                    })
                    await updateDoc(docRef, {
                        courses: editedCourses
                    });

                    await remove_user_from_event(id, user_id)
                    await remove_user_from_event((id + '-DCIS'), user_id)
                    await remove_user_from_event((id + '-' + second_course_abbr), user_id)

                    async function remove_user_from_event(id_param, user_id) {
                        // DELETE USER FROM EVENT
                        const eventRef = doc(db, "Event", id_param);
                        const eventDocSnap = await getDoc(eventRef);
                        const eventAttendees = []
                        // to make sure the event still has in person attendees
                        var stillHasInPerson = false
                        eventDocSnap.data().attendees?.map(i => {
                            if (i._id != user_id) {
                                eventAttendees.push(i)
                                // to make sure the event still has in person attendees
                                if (i.inPerson)
                                    stillHasInPerson = true
                            }
                        })
                        await updateDoc(eventRef, {
                            hasInPersonAttendee: stillHasInPerson,
                            attendees: eventAttendees
                        });
                    }

                }
                // Delete from STAND ALONE EVENT 
                else {
                    // DELETE EVENT FROM USER
                    const editedCourses = []
                    docSnap.data().courses?.map(i => {
                        if (i.eventID != id)
                            editedCourses.push(i)
                    })
                    await updateDoc(docRef, {
                        courses: editedCourses
                    });

                    // DELETE USER FROM EVENT
                    const eventRef = doc(db, "Event", id);
                    const eventDocSnap = await getDoc(eventRef);
                    const eventAttendees = []
                    // to make sure the event still has in person attendees
                    var stillHasInPerson = false
                    eventDocSnap.data().attendees?.map(i => {
                        if (i._id != user_id) {
                            eventAttendees.push(i)
                            // to make sure the event still has in person attendees
                            if (i.inPerson)
                                stillHasInPerson = true
                        }
                    })
                    await updateDoc(eventRef, {
                        hasInPersonAttendee: stillHasInPerson,
                        attendees: eventAttendees
                    });
                }
                window.location.reload();
            } else {
                setUserDoesntExist(true)
            }
        }
    }


    async function searchUser() {
        var userValue = document.getElementById('admin_course_list_find_user_input_id').value
        setSelectedUser()

        if (!userValue) {
            setUserSearch([])
            return
        }

        const userRef = collection(db, "Users");

        const users = []
        // Create a query against the collection.
        var q = query(userRef, where("email", ">=", userValue), where("email", "<=", userValue + '\uf8ff'));
        var querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => { users.push(doc.data()) });

        q = query(userRef, where("displayName", ">=", userValue), where("displayName", "<=", userValue + '\uf8ff'));
        querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => { if (!arrayContains(doc.data(), users)) users.push(doc.data()) });


        q = query(userRef, where("lastName", ">=", userValue), where("lastName", "<=", userValue + '\uf8ff'));
        querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => { if (!arrayContains(doc.data(), users)) users.push(doc.data()) });

        q = query(userRef, where("uid", ">=", userValue), where("uid", "<=", userValue + '\uf8ff'));
        querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => { if (!arrayContains(doc.data(), users)) users.push(doc.data()) });

        const finalUser = []
        users.map(user => {
            // don't put admin in user list
            if (user.email != 'admin@idc-a.org') finalUser.push(user)
        })

        setUserSearch(finalUser)
    }

    function arrayContains(data, array) {
        var result = false
        array.map(i => { if (i.uid == data.uid) result = true })
        return result
    }

    function userSelected(user) {
        document.getElementById('admin_course_list_find_user_input_id').value = user.displayName
        setSelectedUserID(user.uid)
        setSelectedUser(user)
        setUserSearch([])
    }


    function unselect() {
        document.getElementById('admin_course_list_find_user_input_id').value = ''
        setSelectedUserID()
        setSelectedUser()
        setUserSearch([])
    }

    function onMouseEnterDiv(i) {
        document.getElementById('admin_event_list_individual_course_div_id_' + i).style.backgroundColor = 'rgba(183, 35, 35, 0.519)'
    }
    function onMouseLeaveDiv(i) {
        if (i % 2 == 0)
            document.getElementById('admin_event_list_individual_course_div_id_' + i).style.backgroundColor = 'rgba(220, 220, 220, 0.491)'
        else
            document.getElementById('admin_event_list_individual_course_div_id_' + i).style.backgroundColor = 'white'
    }

    const customStyles = {
        content: {
            marginTop: '40px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderWidth: '0px',
            backgroundColor: 'white',
            boxShadow: '0px 4px 27px rgba(0, 0, 0, 0.25)',
            borderRadius: '20px',
            padding: '40px 60px'
        },
    };

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [secondPhone, setSecondPhone] = useState()
    const [company, setCompany] = useState()
    const [title, setTitle] = useState()

    const [address, setAddress] = useState('')
    const [apt, setApt] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [country, setCountry] = useState('')

    const [paymentType, setPaymentType] = useState('Website')
    const [attendeeVirtual, setAttendeeVirtual] = useState(true)

    const [displaySelectUserValidEmailMessage, setDisplaySelectUserValidEmailMessage] = useState(false)
    const [nameRequired, setNameRequired] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [eventInstructor, setEventInstructor] = useState()

    const [done, setDone] = useState(false)
    const [somethingWrong, setSomethingWrong] = useState(false)
    const [accountExist, setAccountExist] = useState(false)
    const [invalidCredentials, setInvalidCredentials] = useState(false)

    const [password, setPassword] = useState('')
    const [passHidden, setPassHidden] = useState(true)

    const generatePassword = () => {
        const pwd = generator.generate({
            length: 12,
            lowercase: true,
            uppercase: true,
            numbers: true,
            symbols: false
        });
        setPassword(pwd);
    }

    function update_name_case(n, num) {
        var result = n.charAt(0).toUpperCase() +
            n.slice(1).toLowerCase();
        if (num == 1) {
            setFirstName(result)

        } else if (num == 2) {
            setLastName(result)
        } else if (num == 3) {
            setMiddleName(result)
        }
    }


    async function onAddUser() {
        var email = document.getElementById('create_account_form_email_input').value;
        var children_event_exist = true
        var children_event_zoom_exist = true
        var zoom_id_object

        const company = document.getElementById('create_account_form_company_input').value
        const title = document.getElementById('create_account_form_title_input').value
        const phone = document.getElementById('create_account_form_phone_input').value
        const virtual = document.getElementById('admin_course_list_attendee_add_input_virtual_create_user').checked

        email = email.toLowerCase();

        var filter = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;


        // if any of the fields are incomplete then don't allow the user to be made and sent to firebase
        if (!firstName || !lastName || !email || !filter.test(email) || !phone) {
            setInvalidCredentials(true)
            return;
        }

        // Set Zoom Event IDs
        if (event.titleAbbrev == 'DCOM') {
            const dcisRef = doc(db, "Event", event._id + '-DCIS');
            const dcisDocSnap = await getDoc(dcisRef);
            if (!dcisDocSnap.exists()) {
                children_event_exist = false
            } else {
                if (dcisDocSnap.data().zoomMeetingID)
                    zoom_id_object = { one: dcisDocSnap.data().zoomMeetingID }
                else children_event_zoom_exist = false
            }

            const dcosRef = doc(db, "Event", event._id + '-DCOS');
            const dcosDocSnap = await getDoc(dcosRef);
            if (!dcosDocSnap.exists()) {
                children_event_exist = false
            } else {
                if (dcosDocSnap.data().zoomMeetingID)
                    zoom_id_object = { ...zoom_id_object, two: dcosDocSnap.data().zoomMeetingID }
                else children_event_zoom_exist = false
            }
        } else if (event.titleAbbrev == 'DCIE') {
            const dcisRef = doc(db, "Event", event._id + '-DCIS');
            const dcisDocSnap = await getDoc(dcisRef);
            if (!dcisDocSnap.exists()) {
                children_event_exist = false
            } else {
                if (dcisDocSnap.data().zoomMeetingID)
                    zoom_id_object = { one: dcisDocSnap.data().zoomMeetingID }
                else children_event_zoom_exist = false
            }

            const dcesRef = doc(db, "Event", event._id + '-DCES');
            const dcesDocSnap = await getDoc(dcesRef);
            if (!dcesDocSnap.exists()) {
                children_event_exist = false
            } else {
                if (dcesDocSnap.data().zoomMeetingID)
                    zoom_id_object = { ...zoom_id_object, two: dcesDocSnap.data().zoomMeetingID }
                else children_event_zoom_exist = false
            }
        }

        if (children_event_exist && children_event_zoom_exist)
            axios.post(api_url + '/create_user_from_admin_dashboard', {
                firstName: firstName,
                lastName: lastName,
                middleName: middleName,
                email: email,
                password: password,
                phone: phone,
                company: company,
                title: title,
                dcrpTime: (event.titleAbbrev == 'DCRP' && event?.time?.full) ? event.time.full : null,
                eventID: event._id,
                user_virtual: virtual,
                eventName: event.title,
                eventNameAbbrev: event.titleAbbrev,
                eventMileagePoints: event.mileagePoints,
                eventDate: event.startDateTime,
                event_has_in_person: event.hasInPersonAttendee ? event.hasInPersonAttendee : false,
                venue: eventVenue ? eventVenue : null,
                location: event.location ? event.location : ''
            }).then(async (response) => {
                console.log(response)
                // Get User
                const q = query(collection(db, "Users"), where("email", "==", email));
                const querySnapshot = await getDocs(q);
                var userInfo
                querySnapshot.forEach((doc) => {
                    userInfo = doc.data()
                });
                const docRef = doc(db, "Users", userInfo.uid);
                const docSnap = await getDoc(docRef);

                if (response.data == 'All Done!') {
                    try {
                        const editedCourseWZoom = []
                        var event_id_two
                        // Set Event ID
                        if (event.titleAbbrev == 'DCOM')
                            event_id_two = event._id + '-DCOS'
                        else
                            event_id_two = event._id + '-DCES'
                        // Invite to Zoom
                        if (event.titleAbbrev == 'DCOM' || event.titleAbbrev == 'DCIE') {
                            const [zoomRes, registrant_id, join_url] = await RegisterUserForZoomEvent({
                                email: email,
                                firstName: firstName,
                                lastName: lastName,
                                meeting_id: zoom_id_object.one
                            })
                            // Add url to course
                            docSnap.data().courses.map(course => {
                                // Add DCIS Course
                                if (course.eventID == (event._id + '-DCIS'))
                                    editedCourseWZoom.push({ ...course, zoom_registrant_id: registrant_id, zoom_join_url: join_url })
                                // Add Parent Course
                                else if (course.eventID == event._id)
                                    editedCourseWZoom.push(course)
                            })
                            if (zoomRes == 'Success') {
                                const [zoomRes, registrant_id, join_url] = await RegisterUserForZoomEvent({
                                    email: email,
                                    firstName: firstName,
                                    lastName: lastName,
                                    meeting_id: zoom_id_object.two
                                })
                                // Add url to course
                                docSnap.data().courses.map(course => {
                                    // Add Secondary Course
                                    if (course.eventID == event_id_two)
                                        editedCourseWZoom.push({ ...course, zoom_registrant_id: registrant_id, zoom_join_url: join_url })
                                })

                                // All Successful
                                if (zoomRes == 'Success') {
                                    // Update User Courses With Zoom Link
                                    await updateDoc(docRef, {
                                        courses: editedCourseWZoom
                                    });

                                    setModalOpen(true)
                                    setDone(true)
                                    setTimeout(function () {
                                        window.location.reload();
                                    }, 1000);
                                } else if (zoomRes == 'no zoom meeting id') {
                                    alert(email + ' has been added and email sent but No Zoom Meeting has been set up for this event - ' + event.titleAbbrev)
                                    window.location.reload();
                                } else if (zoomRes == 'more than 3 attempts to register') {
                                    alert(email + ' has been added and email sent but can not add Zoom Registrant More Than 3 Times - ' + event.titleAbbrev)
                                    window.location.reload();
                                } else {
                                    alert(email + ' has been added and email sent but there has been an issue sending Zoom invite - ' + event.titleAbbrev)
                                    window.location.reload();
                                }
                            } else if (zoomRes == 'no zoom meeting id') {
                                alert(email + ' has been added and email sent but No Zoom Meeting has been set up for this event - DCIS')
                                window.location.reload();
                            } else if (zoomRes == 'more than 3 attempts to register') {
                                alert(email + ' has been added and email sent but can not add Zoom Registrant More Than 3 Times - DCIS')
                                window.location.reload();
                            } else {
                                alert(email + ' has been added and email sent but there has been an issue sending Zoom invite - DCIS')
                                window.location.reload();
                            }
                        } else {
                            const [zoomRes, registrant_id, join_url] = await RegisterUserForZoomEvent({
                                email: email,
                                firstName: firstName,
                                lastName: lastName,
                                meeting_id: event.zoomMeetingID
                            })
                            if (zoomRes == 'Success') {
                                const editedCourseWZoom = []
                                // Add url to course
                                docSnap.data().courses.map(course => {
                                    if (course.eventID == event._id)
                                        editedCourseWZoom.push({ ...course, zoom_registrant_id: registrant_id, zoom_join_url: join_url })
                                    else
                                        editedCourseWZoom.push(course)
                                })
                                // Update User Courses With Zoom Link
                                await updateDoc(docRef, {
                                    courses: editedCourseWZoom
                                });

                                setModalOpen(true)
                                setDone(true)
                                setTimeout(function () {
                                    window.location.reload();
                                }, 1000);
                            } else if (zoomRes == 'no zoom meeting id') {
                                alert(email + ' has been added and email sent but No Zoom Meeting has been set up for this event')
                                window.location.reload();
                            } else if (zoomRes == 'more than 3 attempts to register') {
                                alert(email + ' has been added and email sent but can not add Zoom Registrant More Than 3 Times')
                                window.location.reload();
                            } else {
                                alert(email + ' has been added and email sent but there has been an issue sending Zoom invite')
                                window.location.reload();
                            }
                        }
                    } catch (error) {
                        console.log(error)
                        alert(email + ' has been added and email sent but there has been an issue sending Zoom invite: error')
                        window.location.reload();
                    }
                } else if (response.data == 'Error Sending Email!') {
                    alert(email + ' has been added but there has been an issue sending him an email')
                    window.location.reload();
                } else {
                    alert('An Error Occurred')
                    window.location.reload();
                }
            }).catch((error) => {
                setSomethingWrong(true)
                console.log(error)
            })
        else if (children_event_zoom_exist) {
            alert('Children Events of this course do not exist!')
            window.location.reload();
        } else {
            alert('Children Events of this course do not have Zoom ID!')
            window.location.reload();
        }
    }

    async function addExistingUserToEvent() {
        /*
        
        Add user to all events
        
        */

        if (event.titleAbbrev == 'DCOM') {
            await addExistingUserToCourse_helper(id)
            await addExistingUserToCourse_helper(id + '-DCIS', false, true)
            await addExistingUserToCourse_helper(id + '-DCOS', true, true)
        } else if (event.titleAbbrev == 'DCIE') {
            await addExistingUserToCourse_helper(id)
            await addExistingUserToCourse_helper(id + '-DCIS', false, true)
            await addExistingUserToCourse_helper(id + '-DCES', true, true)
        } else {
            await addExistingUserToCourse_helper(id, true)
        }

        window.location.reload();
    }

    // Helper Function
    async function addExistingUserToCourse_helper(event_id, last_add_reload, under_parent_event) {
        const user_id = selectedUserID
        const user_virtual = document.getElementById('admin_course_list_attendee_add_input_virtual').checked
        const eventRef = doc(db, "Event", event_id);
        const eventDocSnap = await getDoc(eventRef);

        var registeredBefore = false
        eventDocSnap.data().attendees?.map(i => {
            if (i._id == user_id)
                registeredBefore = true
        })
        if (!user_id) {
            if (last_add_reload)
                window.location.reload();
        }
        else if (!registeredBefore) {
            const docRef = doc(db, "Users", user_id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                // ADD EVENT TO USER
                const editedCourses = []
                var registered = false
                docSnap.data().courses?.map(i => {
                    if (i.eventID == event_id)
                        registered = true
                    editedCourses.push(i)
                })
                if (!registered)
                    editedCourses.push({ eventID: event_id })

                // Don't Add Mileage point if child of parent event
                if (under_parent_event)
                    await updateDoc(docRef, {
                        courses: editedCourses
                    });
                // Add Mileage point if child of parent event
                else
                    await updateDoc(docRef, {
                        courses: editedCourses,
                        mileageScore: docSnap.data().mileageScore && eventDocSnap.data().mileagePoints ? docSnap.data().mileageScore + eventDocSnap.data().mileagePoints : eventDocSnap.data().mileagePoints ? eventDocSnap.data().mileagePoints : 0,
                        mileageHistory: docSnap.data().mileageHistory ? [...docSnap.data().mileageHistory, {
                            date: new Date().toISOString(),
                            points: eventDocSnap.data().mileagePoints ? eventDocSnap.data().mileagePoints : 0,
                            pointsName: eventDocSnap.data().titleAbbrev
                        }] : [{
                            date: new Date().toISOString(),
                            points: eventDocSnap.data().mileagePoints ? eventDocSnap.data().mileagePoints : 0,
                            pointsName: eventDocSnap.data().titleAbbrev
                        }],
                    });

                // ADD USER TO EVENT
                const eventAttendees = eventDocSnap.data().attendees ? eventDocSnap.data().attendees : []

                eventAttendees.push({
                    _id: user_id,
                    name: docSnap.data().firstName && docSnap.data().lastName ? docSnap.data().firstName + ' ' + docSnap.data().lastName : docSnap.data().displayName,
                    email: docSnap.data().email,
                    inPerson: !user_virtual,
                    under_parent_event: under_parent_event ? under_parent_event : false
                })
                await updateDoc(eventRef, {
                    hasInPersonAttendee: !user_virtual ? true : eventDocSnap.data().hasInPersonAttendee ? eventDocSnap.data().hasInPersonAttendee : false,
                    attendees: eventAttendees
                });

                if (!registered) {
                    const name = docSnap.data().firstName && docSnap.data().lastName ? (docSnap.data().firstName + ' ' + docSnap.data().lastName) : docSnap.data().displayName
                    // Send Registered Email
                    if (eventDocSnap.data().titleAbbrev != 'DCOM' && eventDocSnap.data().titleAbbrev != 'DCIE') {
                        console.log((eventDocSnap.data().titleAbbrev == 'DCRP' && eventDocSnap.data()?.time?.full) ? eventDocSnap.data().time.full : null)
                        const response = await SuccessfulRegistration({
                            userEmail: docSnap.data().email,
                            userName: docSnap.data().firstName,
                            dcrpTime: (eventDocSnap.data().titleAbbrev == 'DCRP' && eventDocSnap.data()?.time?.full) ? eventDocSnap.data().time.full : null,
                            eventData: eventDocSnap.data(),
                            user_virtual: user_virtual
                        })
                        if (response == 'Success') {
                            // Invite to Zoom
                            const [zoomRes, registrant_id, join_url] = await RegisterUserForZoomEvent({
                                email: docSnap.data().email,
                                firstName: docSnap.data().firstName,
                                lastName: docSnap.data().lastName,
                                meeting_id: eventDocSnap.data().zoomMeetingID
                            })

                            if (zoomRes == 'Success') {
                                const editedCourseWZoom = []
                                // Add url to course
                                editedCourses.map(course => {
                                    if (course.eventID == event_id)
                                        editedCourseWZoom.push({ ...course, zoom_registrant_id: registrant_id, zoom_join_url: join_url })
                                    else
                                        editedCourseWZoom.push(course)
                                })
                                // Update User Courses With Zoom Link
                                await updateDoc(docRef, {
                                    courses: editedCourseWZoom
                                });

                                // Reload if last reload
                                if (last_add_reload) {
                                    setModalOpen(true)
                                    setDone(true)
                                    setTimeout(function () {
                                        window.location.reload();
                                    }, 1000);
                                }
                            } else if (zoomRes == 'no zoom meeting id') {
                                alert(docSnap.data().email + ' has been added and email sent but No Zoom Meeting has been set up for this event - ' + eventDocSnap.data().titleAbbrev)
                                if (last_add_reload)
                                    window.location.reload();
                            } else if (zoomRes == 'more than 3 attempts to register') {
                                alert(docSnap.data().email + ' has been added and email sent but can not add Zoom Registrant More Than 3 Times - ' + eventDocSnap.data().titleAbbrev)
                                if (last_add_reload)
                                    window.location.reload();
                            } else {
                                alert(docSnap.data().email + ' has been added and email sent but there has been an issue sending Zoom invite - ' + eventDocSnap.data().titleAbbrev)
                                if (last_add_reload)
                                    window.location.reload();
                            }
                        } else {
                            alert(docSnap.data().email + ' has been added but there has been an issue sending an email - ' + eventDocSnap.data().titleAbbrev)
                            if (last_add_reload)
                                window.location.reload();
                        }
                    }
                }
            } else {
                setUserDoesntExist(true)
            }
        } else {
            setUserRegisteredBefore(true)
        }
    }


    async function setSponsorsFunction() {
        const filename = document.getElementById("TrainingEventAdminNewEditIDFile").files[0]?.name

        if (filename) {
            const pathReference = ref(storage, 'gs://idca-f9873.appspot.com/TrainingEventSponsorLogos' + `/${filename}`);

            return new Promise((resolve, reject) => {
                getDownloadURL(pathReference)
                    .then((url) => {
                        alert("This Sponsor Logo Name already exist")
                        return
                    })
                    .catch((error) => {
                        const imageRef = ref(storage, 'gs://idca-f9873.appspot.com/TrainingEventSponsorLogos' + `/${filename}`);
                        uploadBytesResumable(imageRef, document.getElementById("TrainingEventAdminNewEditIDFile").files[0])
                            .then((snapshot) => {
                                // Let's get a download URL for the file.
                                getDownloadURL(snapshot.ref)
                                    .then(async (url) => {

                                        // Return URL
                                        resolve(url)
                                    });
                            }).catch((error) => {
                                console.error('Upload failed', error);
                            });
                    });
            })
        }
    }
    function refreshPage() {
        window.location.reload(false);
    }


    function userSelectedNext() {
        setDisplaySelectUserValidEmailMessage(false)
        if (selectedUser) {
            setDisplayDetails(true)
            // Update Details
            setFirstName(selectedUser.firstName)
            setLastName(selectedUser.lastName)
            setPhone(selectedUser.phone)
            setSecondPhone(selectedUser.mobile)
            setEmail(selectedUser.email)
            setCompany(selectedUser.company)
            setTitle(selectedUser.title)

            setAddress(selectedUser.address)
            setApt(selectedUser.apt)
            setCity(selectedUser.city)
            setState(selectedUser.state)
            setZipcode(selectedUser.zipcode)
            setCountry(selectedUser.country)
        } else {
            function validateEmail(email) {
                const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return re.test(email);
            }

            const email = document.getElementById('admin_course_list_find_user_input_id').value
            if (validateEmail(email)) {
                // Update Fields to null
                setFirstName('')
                setLastName('')
                setPhone('')
                setSecondPhone('')
                setEmail('')
                setCompany('')
                setTitle('')
                setAddress('')
                setApt('')
                setCity('')
                setState('')
                setZipcode('')
                setCountry('')
                // Update Address
                document.getElementById('location-input').value = ''
                document.getElementById('apt-input').value = ''
                document.getElementById('locality-input').value = ''
                document.getElementById('administrative_area_level_1-input').value = ''
                document.getElementById('postal_code-input').value = ''
                document.getElementById('country-input').value = ''

                // Set Next Stage
                setDisplayDetails(true)
                setEmail(email)

                // Generate password
                generatePassword()
            } else {
                setDisplaySelectUserValidEmailMessage(true)
            }
        }
    }

    function userSelectedBack() {
        setDisplayDetails(false)
        setNameRequired(false)
    }

    async function addAttendeeToEvent() {
        setNameRequired(false)

        // Update Address
        const address = document.getElementById('location-input').value
        const apt = document.getElementById('apt-input').value
        const city = document.getElementById('locality-input').value
        const state = document.getElementById('administrative_area_level_1-input').value
        const zipcode = document.getElementById('postal_code-input').value
        const country = document.getElementById('country-input').value

        if (firstName && lastName) {
            // Enable Spinner
            document.getElementById('add_user_event_spinner').style.display = 'inherit'

            const res = await addAttendeeToEventModule(
                firstName, lastName, email, phone, secondPhone, company, title, password, selectedUser,
                event, attendeeVirtual, paymentType, address, apt, city, state, zipcode, country)

            if (res == 'All Done!') {
                document.getElementById('adding_attendee_all_done').style.display = 'inherit'

                setTimeout(() => {
                    // Refresh Page
                    refreshPage()
                }, 2000);
            } else {
                document.getElementById('adding_attendee_error').style.display = 'inherit'
                setErrorMessage(res)
            }
        } else {
            setNameRequired(true)
        }
    }

    function addZoomToEvent(event_id_param) {
        axios.post(api_url + '/add_zoom_to_event', {
            eventID: event_id_param ? event_id_param : event._id
        })
    }


    return (
        <div className="admin_course_list_top_div  Saira_Medium_Black" style={{ marginTop: '150px', marginBottom: '70px' }}>
            <a href={'/admin?page=Upcoming_Courses'} style={{ textDecoration: 'none' }}>
                <p className='Saira_Medium_Red'>{'< '}Back</p>
            </a>
            {!event && (
                <div className="payment-course-before-load-spinner-container">
                    <div className="payment-course-before-loading-spinner">
                    </div>
                </div>
            )}
            {event && (
                <div>
                    <div className='dashboard_setting_save_button_div' >
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h1>{event.title}</h1>
                        <button style={{
                            display: editClicked ? 'none' : 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer',
                            width: 'fit-content', backgroundColor: 'transparent', borderWidth: '0px'
                        }}
                            onClick={() => setEditClicked(true)}>
                            <p style={{ fontSize: '20px' }} className='Saira_Medium_Black'>Edit</p>
                            <MdEdit size='22' />
                        </button>
                        <button style={{
                            display: editClicked ? 'flex' : 'none', alignItems: 'center', gap: '10px', cursor: 'pointer',
                            width: 'fit-content', backgroundColor: 'transparent', borderWidth: '0px'
                        }}
                            onClick={e => cancelClicked()}>
                            <p style={{ fontSize: '20px' }} className='Saira_Medium_Black'>Cancel</p>
                            <ImCancelCircle size='22' />
                        </button>
                        <button className='Saira_Regular_White dashboard_setting_save_button' style={{ display: !editClicked ? 'none' : '', cursor: saveClicked ? 'default' : 'pointer' }} disabled={saveClicked} onClick={() => { saveUserInfo(); }}>Save</button>
                    </div>

                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                        <h2 style={{ textDecoration: 'underline' }}>Date:</h2>
                        <span style={{ display: editClicked ? 'none' : '' }} className="admin_event_detail_input" contentEditable={editClicked}>{new Date(eventDate).toDateString()}</span>
                        <input style={{ display: !editClicked ? 'none' : '' }} className="admin_event_detail_input_edit" value={eventDate} type="datetime-local"
                            onChange={(e) => setEventDate(new Date(e.target.value).toISOString())} />
                    </div>
                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                        <h2 style={{ textDecoration: 'underline' }}>Location:</h2>
                        <span style={{ display: editClicked ? 'none' : '' }} className="admin_event_detail_input" contentEditable={editClicked}>{eventLocation}</span>
                        <input style={{ display: !editClicked ? 'none' : '' }} className="admin_event_detail_input_edit" value={eventLocation} type="text" onChange={(e) => setEventLocation(e.target.value)} />
                    </div>
                    <div style={{ display: 'flex', gap: '30px', alignItems: 'flex-start' }}>
                        <h2 style={{ textDecoration: 'underline', marginTop: '5px' }}>Venue:</h2>
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <p>Address Line 1: </p>
                                <span style={{ display: editClicked ? 'none' : '', minWidth: '150px', height: '28px' }} className="admin_event_detail_input" contentEditable={editClicked}>{eventVenue?.firstLineAddress}</span>
                                <input style={{ display: !editClicked ? 'none' : '' }} className="admin_event_detail_input_edit"
                                    value={eventVenue?.firstLineAddress} type="text" onChange={(e) => setEventVenue({ firstLineAddress: e.target.value, secondLineAddress: eventVenue?.secondLineAddress ? eventVenue?.secondLineAddress : null })} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <p>Address Line 2: </p>
                                <span style={{ display: editClicked ? 'none' : '', minWidth: '150px', height: '28px' }} className="admin_event_detail_input" contentEditable={editClicked}>{eventVenue?.secondLineAddress}</span>
                                <input style={{ display: !editClicked ? 'none' : '' }} className="admin_event_detail_input_edit"
                                    value={eventVenue?.secondLineAddress} type="text" onChange={(e) => setEventVenue({ firstLineAddress: eventVenue?.firstLineAddress ? eventVenue?.firstLineAddress : null, secondLineAddress: e.target.value })} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                        <h2 style={{ textDecoration: 'underline' }}>Seats Remaining:</h2>
                        <span style={{ display: editClicked ? 'none' : '', minWidth: '150px', height: '28px' }} className="admin_event_detail_input" contentEditable={editClicked}>{eventSeatsRemaining}</span>
                        <select style={{ display: !editClicked ? 'none' : '' }} className='admin_event_detail_input_edit' onChange={(e) => setEventSeatsRemaining(e.target.value)}>
                            <option value="">--</option>
                            <option value="Full">Full</option>
                            <option value="Limited">Limited</option>
                            <option value="Available">Available</option>
                            <option value="Generate Random">Generate Random</option>
                        </select>
                    </div>

                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                        <h2 style={{ textDecoration: 'underline' }}>Instructor:</h2>
                        <span style={{ display: editClicked ? 'none' : '', minWidth: '150px', height: '28px' }} className="admin_event_detail_input" contentEditable={editClicked}>{eventInstructor}</span>
                        <select style={{ display: !editClicked ? 'none' : '' }} className='admin_event_detail_input_edit' onChange={(e) => setEventInstructor(e.target.value)}>
                            <option value="">--</option>
                            <option value="Dikran Kechichian">Dikran Kechichian</option>
                            <option value="Rich Banta">Rich Banta</option>
                        </select>
                    </div>

                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                        <h2 style={{ textDecoration: 'underline' }}>Parking Detail:</h2>
                        <span style={{ display: editClicked ? 'none' : '' }} className="admin_event_detail_input" contentEditable={editClicked}>{parkingDetail}</span>
                        <input style={{ display: !editClicked ? 'none' : '' }} className="admin_event_detail_input_edit" value={parkingDetail} type="text" onChange={(e) => setParkingDetail(e.target.value)} />
                    </div>

                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                        <h2 style={{ textDecoration: 'underline' }}>Virtual:</h2>
                        <span style={{ display: editClicked ? 'none' : '', minWidth: '150px', height: '28px' }} className="admin_event_detail_input" contentEditable={editClicked}>{virtual ? 'All Virtual' : 'In-Person'}</span>
                        <select style={{ display: !editClicked ? 'none' : '' }} className='admin_event_detail_input_edit' onChange={(e) => setVirtual(e.target.value)}>
                            <option value="">--</option>
                            <option value={false}>In-Person</option>
                            <option value={true}>All Virtual</option>
                        </select>
                    </div>

                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                        <h2 style={{ textDecoration: 'underline' }}>Sponsors:</h2>
                        {(sponsors && sponsors.length) ?
                            sponsors.map((sponsor, i) => (
                                <div key={i} className='admin_event_detail_sponsors_div'>
                                    <img src={sponsor} layout='fill' objectFit="contain" />
                                    <div className='admin_event_detail_sponsors_delete_div'
                                        style={{ display: editClicked ? '' : 'none' }}
                                        onClick={() => {
                                            const updatedSponsors = [...sponsors]; // Create a new array
                                            updatedSponsors.splice(i, 1); // Modify the new array
                                            setSponsors(updatedSponsors); // Update the state
                                        }}>
                                        <ImCancelCircle />
                                    </div>
                                </div>
                            )) : null}

                        <input style={{ display: editClicked ? '' : 'none', }} type="file" id='TrainingEventAdminNewEditIDFile' className="StandardsDownload_Form_input Saira_Medium_Black"
                            accept="image/png, image/jpeg" name="editFile" />
                    </div>
                    <div>
                        <h2 style={{ display: 'flex', gap: '15px' }}><span style={{ color: '#AD2225' }}>{event.titleAbbrev}</span> Zoom Meeting ID:
                            <span style={{ textDecoration: 'underline' }}>{event.zoom_id}</span>
                        </h2>
                        {(!event.zoom_id && event.titleAbbrev !== 'DCOM' && event.titleAbbrev !== 'DCIE') ?
                            <button onClick={() => addZoomToEvent()}>Add Zoom</button>
                            : null}
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        <h2>Attendees List</h2>
                        <p>Number of Attendees: {event.attendees ? event.attendees.length : 0}</p>
                        <p>Has In Person Attendee: <span style={{ textDecoration: 'underline' }}>{event.hasInPersonAttendee ? 'Yes' : 'No'}</span></p>
                        <div className='Saira_Bold_Black admin_event_attendee_list_div'>
                            <h4>Name</h4>
                            <h4>Email</h4>
                            <h4>Virtual Status</h4>
                            <h4>Under Parent Event</h4>
                        </div>

                        {event.attendees?.map((attendee, i) => (
                            <div key={i} style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '10px' }}>
                                <a href={'/users/' + attendee._id} style={{ textDecoration: 'none', width: '100%' }}>
                                    <div key={i} className={'Saira_Medium_Gray admin_event_attendee_list_div admin_event_list_individual_course_div_' + i % 2}>
                                        <h4 style={{ overflowWrap: 'break-word' }}>{attendee.name}</h4>
                                        <h4 style={{ overflowWrap: 'break-word' }}>{attendee.email}</h4>
                                        <h4 style={{ overflowWrap: 'break-word' }}>{attendee.inPerson ? 'In Person' : 'Virtual'}</h4>
                                        <h4 style={{ overflowWrap: 'break-word' }}>{attendee.under_parent_event ? 'Course Combo' : ''}</h4>
                                    </div>
                                </a>
                                <CopyToClipboard
                                    text={attendee.email}
                                    onCopy={() => alert("Copied email: " + attendee.email)}
                                >
                                    <button>
                                        <TiClipboard size={20} />
                                    </button>
                                </CopyToClipboard>
                                <RiDeleteBin6Line size={20} style={{ display: editClicked && (userInfo.idcaRole?.some(i => listOfAllAccessRoles.includes(i))) ? 'initial' : 'none', cursor: 'pointer' }} onClick={() => deleteUserFromEvent(attendee._id, attendee.under_parent_event, attendee.email)} />
                            </div>
                        ))}


                        <div className='admin_course_add_user_to_course_divider_div'>
                        </div>


                        <h2>Add Attendee To Event</h2>
                        <div className='admin_course_add_user_top_div' style={{ display: (addAttendee && !displayDetails) ? 'flex' : 'none', marginTop: '60px', marginBottom: '70px' }}>
                            <p style={{ display: displaySelectUserValidEmailMessage ? 'inherit' : 'none', color: 'red' }}>Either select an existing user or enter a valid email address</p>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <input id='admin_course_list_find_user_input_id' onChange={() => searchUser()} style={{ width: '225px', height: '30px', fontSize: '18px' }}
                                        className='Saira_Medium_Gray' />
                                    <p style={{ cursor: 'pointer', position: 'relative', bottom: '10px' }} onClick={() => unselect()}>X</p>
                                </div>
                                <p style={{ fontSize: '12px', margin: '0px' }}>search by display name, last name, email or uid</p>

                                <div style={{
                                    display: userSearch.length ? 'flex' : 'none', flexDirection: 'column',
                                    position: 'absolute', top: '50px', background: 'white', padding: '20px', zIndex: '100', width: '400px'
                                }}>
                                    {userSearch.map((user, i) => (
                                        <div key={i} style={{ cursor: 'pointer', borderRadius: '20px', padding: '15px 20px' }} onClick={() => userSelected(user)}
                                            className={'Saira_Medium_Gray admin_event_list_individual_course_div_' + i % 2}
                                            id={'admin_event_list_individual_course_div_id_' + i}
                                            onMouseEnter={() => onMouseEnterDiv(i)} onMouseLeave={() => onMouseLeaveDiv(i)}>
                                            <div style={{ display: 'flex', gap: '15px', marginBottom: '10px' }}>
                                                <p style={{ margin: '0px' }}><span style={{ textDecoration: 'underline' }}>Name:</span> {user.displayName}</p>
                                                <p style={{ margin: '0px', wordBreak: 'break-all' }}><span style={{ textDecoration: 'underline' }}>Email:</span> {user.email}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className='admin_course_add_att_next_div' onClick={() => userSelectedNext()}>
                                    <p>Next</p>
                                </div>
                            </div>
                        </div>


                        <div style={{ display: displayDetails ? 'inherit' : 'none' }}>
                            <div className="payment_course_information_top_div">

                                <div className='admin_course_add_att_next_div' style={{ margin: 'auto' }} onClick={() => userSelectedBack()}>
                                    <p>Back</p>
                                </div>

                                <div>
                                    <p className="payment_course_information_header">Details</p>

                                    <p style={{ backgroundColor: 'rgb(178, 178, 178)', width: 'fit-content', padding: '5px 10px', fontSize: '20px' }} className='Saira_Medium_Gray'>{email}</p>

                                    <div className="login_landing_page_data_fields_two_in_row_div">
                                        <div>
                                            <label htmlFor="first_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                <p className="data-center-contact-us-form-input-label">First Name</p>
                                            </label>
                                            <input className="Saira_Medium_Black training_get_in_touch_form_individual_input" placeholder="First Name"
                                                maxLength="80" name="first_name" size="20" type="text" required="required" value={firstName}
                                                onChange={e => setFirstName(e.target.value)} />
                                        </div>
                                        <div>
                                            <label htmlFor="last_name" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                <p className="data-center-contact-us-form-input-label">Last Name</p>
                                            </label>
                                            <input className="Saira_Medium_Black training_get_in_touch_form_individual_input" placeholder="Last Name"
                                                maxLength="80" name="last_name" size="20" type="text" required="required" value={lastName} onChange={e => setLastName(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="login_landing_page_data_fields_two_in_row_div">
                                        <div>
                                            <label htmlFor="phone" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                <p className="data-center-contact-us-form-input-label">Phone</p>
                                            </label>
                                            <input id="payment_form_phone_input" value={phone} onChange={(e) => setPhone(e.target.value)}
                                                className="Saira_Medium_Black training_get_in_touch_form_individual_input" placeholder="Phone"
                                                maxLength="20" name="phone" size="20" type="tel" />
                                        </div>


                                        <div>
                                            <label htmlFor="phone" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                <p className="data-center-contact-us-form-input-label">Secondary Phone</p>
                                            </label>
                                            <input id="payment_form_phone_input" value={secondPhone} onChange={(e) => setSecondPhone(e.target.value)}
                                                className="Saira_Medium_Black training_get_in_touch_form_individual_input" placeholder="Phone"
                                                maxLength="20" name="phone" size="20" type="tel" />
                                        </div>
                                    </div>

                                    <div className="login_landing_page_data_fields_two_in_row_div">
                                        <div>
                                            <label htmlFor="company"><p className="data-center-contact-us-form-input-label">Company</p></label>
                                            <input id="payment_form_company_input" placeholder="Company" value={company} onChange={(e) => setCompany(e.target.value)}
                                                className="Saira_Medium_Black training_get_in_touch_form_individual_input" maxLength="80" name="company" size="20" type="text" />
                                        </div>

                                        <div>
                                            <label htmlFor="title"><p className="data-center-contact-us-form-input-label">Title</p></label>
                                            <input id="payment_form_title_input" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)}
                                                className="Saira_Medium_Black training_get_in_touch_form_individual_input" maxLength="80" name="title" size="20" type="text" />
                                        </div>
                                    </div>
                                </div>

                                <p className="payment_course_information_header" style={{ marginTop: '70px' }}>Address</p>
                                <div className="login_landing_page_data_fields_two_in_row_div">
                                    <GoogleMapsAPI address={address} apt={apt} city={city} state={state} zipcode={zipcode} country={country} />
                                </div>


                                <p className="payment_course_information_header" style={{ marginTop: '70px' }}>Event Information</p>
                                <div className="login_landing_page_data_fields_two_in_row_div">
                                    <div>
                                        <label htmlFor="first_name" style={{ display: 'flex', alignItems: 'center', gap: '5px', }}>
                                            <p className="data-center-contact-us-form-input-label">Attendence Type</p>
                                        </label>

                                        <select onChange={(e) => setAttendeeVirtual(e.target.value == 'false' ? false : true)}
                                            className='payment_course_select_course'
                                            style={{ backgroundColor: '#032c4b', marginTop: '15px', padding: '10px 10px', height: '40px', fontSize: '17px' }}>
                                            <option value={true} selected={true}>Virtual</option>
                                            {!event.virtual ? <option value={'false'}>In-Person</option> : null}
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="last_name" style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '17px' }}>
                                            <p className="data-center-contact-us-form-input-label">Payment Type</p>
                                        </label>

                                        <select className='payment_course_select_course'
                                            onChange={(e) => setPaymentType(e.target.value)}
                                            style={{ backgroundColor: '#032c4b', marginTop: '15px', height: '40px', fontSize: '16px', padding: '10px 10px' }}>
                                            <option value='Website' selected={true}>Website</option>
                                            <option value='Payment Link'>Payment Link</option>
                                            <option value='Wire/ACH'>Wire/ACH</option>
                                            <option value='Check'>Check</option>
                                            <option value='Split Payment'>Split Payment</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <p style={{ display: nameRequired ? 'inherit' : 'none', color: 'red', textAlign: 'center', marginTop: '40px' }}>First and Last names are required</p>

                            <div className='admin_course_add_att_next_div' style={{ margin: 'auto', marginTop: '50px' }}
                                onClick={() => addAttendeeToEvent()}>
                                <p>Add Attendee</p>
                            </div>

                            <div className="spinner-container">
                                <div id="spinner"></div>
                            </div>

                            <center id="add_user_event_spinner">
                                <div className="payment-course-before-load-spinner-container" id="generate_invoice_spinner_inner">
                                    <div className="payment-course-before-loading-spinner">
                                    </div>
                                </div>
                                <div id='adding_attendee_all_done'>
                                    <div>
                                        <BsCheck2Circle size={60} />
                                    </div>
                                    <h2 className='Saira_Bold_Black'>Attendee Added!</h2>
                                </div>
                                <div id='adding_attendee_error'>
                                    <div>
                                        <MdOutlineCancel size={60} />
                                    </div>
                                    <h2 className='Saira_Bold_Black'>{errorMessage}</h2>
                                </div>
                            </center >
                        </div>

                        <center id='individual_user_add_course_button_center' style={{ display: addAttendee || (!userInfo.idcaRole?.some(i => listOfLimitedAccessRoles.includes(i))) ? 'none' : 'inherit', marginTop: '20px' }}>
                            <button onClick={e => setAddAttendee(true)} className='individual_user_add_course_button_center_class'>
                                <p className='Saira_Medium_White'>Add New User</p>
                            </button>
                        </center>
                    </div>
                </div >
            )
            }
        </div >
    );
}


export default AdminCourseList

