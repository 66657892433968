import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDocs, query, collection, where, limit, orderBy } from "firebase/firestore";

function IDCA_Admin() {

    const [usersList, setUsersList] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [disableNext, setDisableNext] = useState(false)
    const [disableBack, setDisableBack] = useState(false)

    async function initialUserData() {
        var users = []
        var index = 0
        const db = getFirestore();
        var qUsers = query(collection(db, "Users"), limit(20 * pageNum), orderBy('_createdAt', 'desc'));
        var querySnapshot = await getDocs(qUsers)
        querySnapshot.forEach((data) => {
            if (data.data().email != 'admin@idc-a.org') {
                if (index >= 20 * pageNum - 20) {
                    var createdAt = data.data()._createdAt ? new Date() : null
                    if (createdAt) {
                        createdAt.setTime(data.data()._createdAt)
                        createdAt = createdAt.toDateString()
                    }
                    users.push({ ...data.data(), _createdAt: createdAt })
                }
                index++
            }
        })
        // Disable Next Button
        if (users.length < 20)
            setDisableNext(true)
        else
            setDisableNext(false)

        // Disable Back Button
        if (pageNum == 1)
            setDisableBack(true)
        else
            setDisableBack(false)
        setUsersList(users)
    }

    useEffect(() => {
        initialUserData()
    }, [pageNum])

    function resetUsersList() {
        initialUserData()
        document.getElementById("search_user_email").value = ""
        document.getElementById("search_user_fname").value = ""
        document.getElementById("search_user_lname").value = ""
    }

    async function getUsersFunction() {
        var users = []
        const db = getFirestore();
        var qUsers;
        var querySnapshot;

        if (document.getElementById("search_user_email").value) {
            qUsers = query(collection(db, "Users"), where("email", ">=", document.getElementById("search_user_email").value), where("email", "<=", document.getElementById("search_user_email").value + '\uf8ff'));
            querySnapshot = await getDocs(qUsers)
            querySnapshot.forEach((data) => {
                var createdAt = data.data()._createdAt ? new Date() : null
                if (createdAt) {
                    createdAt.setTime(data.data()._createdAt)
                    createdAt = createdAt.toDateString()
                }
                users.push({ ...data.data(), _createdAt: createdAt })
            })
        }

        if (document.getElementById("search_user_fname").value) {
            qUsers = query(collection(db, "Users"), where("firstName", ">=", document.getElementById("search_user_fname").value), where("firstName", "<=", document.getElementById("search_user_fname").value + '\uf8ff'));
            var querySnapshot = await getDocs(qUsers)
            querySnapshot.forEach((data) => {
                var createdAt = data.data()._createdAt ? new Date() : null
                if (createdAt) {
                    createdAt.setTime(data.data()._createdAt)
                    createdAt = createdAt.toDateString()
                }
                users.push({ ...data.data(), _createdAt: createdAt })
            })
        }
        if (document.getElementById("search_user_lname").value) {
            qUsers = query(collection(db, "Users"), where("lastName", ">=", document.getElementById("search_user_lname").value), where("lastName", "<=", document.getElementById("search_user_lname").value + '\uf8ff'));
            var querySnapshot = await getDocs(qUsers)
            querySnapshot.forEach((data) => {
                var createdAt = data.data()._createdAt ? new Date() : null
                if (createdAt) {
                    createdAt.setTime(data.data()._createdAt)
                    createdAt = createdAt.toDateString()
                }
                users.push({ ...data.data(), _createdAt: createdAt })
            })
        }

        const emails = users.map(o => o.email)
        const filtered = users.filter(({ email }, index) => !emails.includes(email, index + 1) && email != 'admin@idc-a.org')
        setUsersList(filtered)
    }

    function getCreateDate(date) {
        return new Date(date)
    }


    return (
        <div className="Dashboard_Right_Pannel_whole">
            <h1 className='Saira_Regular_Black' style={{ textAlign: "center", paddingTop: '20px' }}>IDCA Admin</h1>
            <div style={{ padding: "20px" }}>
                <h2 className='Saira_Bold_Black' >
                    Update User Detail
                </h2>
                <div className="Dashboard_IDCA_Admin_Update_User_Detail">

                    <div className='Dashboard_IDCA_Admin_Update_User_Detail_fields_only'>

                        <label className='idca_admin_label'>
                            Email:
                            <input className='StandardsDownload_Form_input' type="text" id={`search_user_email`} />
                        </label>
                        <label className='idca_admin_label'>
                            First Name:
                            <input className='StandardsDownload_Form_input' type="text" id={`search_user_fname`} />
                        </label>
                        <label className='idca_admin_label'>
                            Last Name:
                            <input className='StandardsDownload_Form_input' type="text" id={`search_user_lname`} />
                        </label>
                    </div>

                    <div className='Dashboard_IDCA_Admin_Update_User_Detail_find_user_buttons'>
                        <input className='Saira_Bold_White Dashboard_IDCA_Admin_Update_User_Detail_find_user_button data-center-contact-us-form-submit-button'
                            type="submit" id={`user_search`} value={"Find User"}
                            onClick={e => getUsersFunction()}
                        />
                        <input className='Saira_Bold_White Dashboard_IDCA_Admin_Update_User_Detail_find_user_button data-center-contact-us-form-submit-button'
                            type="reset" id={`user_search`} value={"Reset"}
                            onClick={e => resetUsersList()}
                        />
                    </div>

                </div>
            </div>
            <div className='Saira_Medium_Gray' style={{ display: 'flex', gap: '20px', marginLeft: '50px' }}>
                <div style={{ cursor: 'pointer' }} onClick={() => { if (!disableBack) setPageNum(pageNum - 1) }}>
                    <p>{'< back'}</p>
                </div>
                <p>{pageNum}</p>
                <div style={{ cursor: 'pointer' }} onClick={() => { if (!disableNext) setPageNum(pageNum + 1) }}>
                    <p>{'next >'}</p>
                </div>
            </div>


            {
                usersList.map((user) => {
                    return (
                        <div className='Saira_Medium_Black'
                            key={user.uid}>
                            <a href={"/users/" + user.uid} className='Saira_Regular_Gray'
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "none"
                                }}
                            >
                                <div key={user.email} className={"dashboard_idca_admin_users_whole_info_list"} style={{ padding: "20px" }}>

                                    <h3>
                                        {user.firstName + " " + user.lastName}
                                    </h3>

                                    <div className='dashboard_idca_admin_users_info_list'>
                                        <p style={{ margin: "0px" }} className='Saira_Regular_Gray'>UID: <span style={{ textDecoration: 'underline' }}>{user?.uid}</span></p>
                                        <p style={{ margin: "0px" }} className='Saira_Regular_Gray'>Email: <a href={'mailto:' + user?.email}>{user?.email}</a></p>
                                        <p style={{ margin: "0px" }} className='Saira_Regular_Gray'>Phone: <a href={'tel:' + user?.phone}>{user?.phone}</a></p>
                                        <p style={{ margin: "0px" }} className='Saira_Regular_Gray'>Created At: <span style={{ textDecoration: 'underline' }}>{user._createdAt ? user._createdAt : null}</span></p>
                                        <p style={{ margin: "0px" }} className='Saira_Regular_Gray'>Title: <span style={{ textDecoration: 'underline' }}>{user?.title}</span></p>
                                        <p style={{ margin: "0px" }} className='Saira_Regular_Gray'>Company: <span style={{ textDecoration: 'underline' }}>{user?.company}</span></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )
                })
            }
        </div >
    );
}


export default IDCA_Admin

