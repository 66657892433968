
function ListAllMembers({ allMembers }) {
    return (
        <div>
            <div className={'Saira_Medium_Black member_admin_panel_list_of_members_div'} style={{ padding: '10px 20px', width: '90%' }}>
                <p>First Name</p>
                <p>Last Name</p>
                <p>Email</p>
                <p>Mileage</p>
                <p>Date Added</p>
                <p>Plan</p>
                <p>Corporate Member</p>
            </div>
            <div style={{ border: '1px solid gray', width: '90%', marginLeft: '20px', marginBottom: '20px' }}></div>
            {allMembers.map((member, i) => (
                <a href={'/admin/member?uid=' + member.uid} key={i} target="_blank" style={{ textDecoration: 'none' }}>
                    <div key={i} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center', position: 'relative' }}>
                        <div className={'Saira_Medium_Gray member_admin_panel_list_of_members_div admin_event_list_individual_course_div_' + i % 2} style={{ padding: '10px 20px', width: '90%' }}>
                            <p>{member.firstName}</p>
                            <p>{member.lastName}</p>
                            <p>{member.email}</p>
                            <p>{member.mileageScore ? member.mileageScore : 0}</p>
                            <p>{member?.membershipDetail?.dateActivated ? new Date(member.membershipDetail.dateActivated).toDateString() : ''}</p>
                            <p>{member?.membershipDetail?.plan ? member.membershipDetail.plan : ''}</p>
                            <p>{member?.membershipDetail?.underCorporateMembership ? member?.membershipDetail?.corporateMember : ''}</p>
                        </div>
                    </div>
                </a>
            ))}
        </div>
    );
}


export default ListAllMembers

