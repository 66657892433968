import React, { useEffect, useState } from 'react';
import { getFirestore, query, collection, getDocs, doc, orderBy, deleteDoc } from "firebase/firestore";

function SharedExperiencesAdmin() {

    const [sharedE, setsharedE] = useState([]);
    const db = getFirestore();

    useEffect(() => {
        getSharedExperiences()
    }, [])

    async function getSharedExperiences() {
        const q = query(collection(db, "Share Experience"), orderBy("dateAdded", "desc"));
        var querySnapshot = await getDocs(q)
        var temp = []
        querySnapshot.forEach((data) => {
            temp.push(data.data())
        })

        setsharedE(temp)
    }

    async function deletePost(dateAdded) {
        if (window.confirm("Are you sure you want to delete this Post?") == true) {
            await deleteDoc(doc(db, "Share Experience", String(dateAdded)));
            getSharedExperiences()

        }
    }

    return (
        <div className="Dashboard_Right_Pannel_whole Saira_Medium_Black PointRequestsAdminPannel" style={{ marginTop: '150px' }}>

            <h1>
                Shared Experiences
            </h1>

            <table className='memberMileageTable'>
                <thead>
                    <tr className='memberMileageTableRow'>
                        <th className='memberMileageTableHeader'>
                            Date
                        </th>
                        <th className='memberMileageTableHeader'>
                            Writer
                        </th>
                        <th className='memberMileageTableHeader'>
                            What Happened
                        </th>
                        <th className='memberMileageTableHeader'>
                            Get Contacted
                        </th>
                        <th className='memberMileageTableHeader'>
                            Any Changes
                        </th>
                        <th className='memberMileageTableHeader'>
                            Anonymous
                        </th>

                        <th className='memberMileageTableHeader'>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sharedE?.map((wp, i) => (
                            <tr className='memberMileageTableRow' key={i}>

                                <td className="Saira_Regular_Black memberMileageTableData" >
                                    {new Date(wp.dateAdded).toLocaleString()}
                                </td>

                                <td className="Saira_Regular_Black memberMileageTableData" >
                                    {wp.displayName}
                                </td>

                                <td className='memberMileageTableData'>
                                    <button onClick={() => alert(wp.textAreaText)} >View Description</button>
                                </td>

                                <td className="Saira_Regular_Black memberMileageTableData" >
                                    {wp.canPeopleContact ? "Yes" : "No"}
                                </td>

                                <td className="Saira_Regular_Black memberMileageTableData" >
                                    {wp.anythingChanged ? "Yes" : "No"}
                                </td>

                                <td className="Saira_Regular_Black memberMileageTableData" >
                                    {wp.isAnonymous ? "Yes" : "No"}
                                </td>

                                <td className='WhitePaperAdminSingleWhitepaperButtons'>
                                    <button className="WhitePaperAdminSingleDeleteButton" onClick={() => { deletePost(wp.dateAdded) }}>
                                        Delete
                                    </button>

                                </td>

                            </tr>

                        ))
                    }
                </tbody>

            </table>

        </div >
    );
}


export default SharedExperiencesAdmin

