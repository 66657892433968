import React from 'react';
import Dashboard_Left_Pannel from './Dashboard_Left_Pannel/Dashboard_Left_Pannel'
function Dashboard({ user, certificates, userInfo, courses, companyMembershipInfo, page }) {
    return (

        <div >
            <Dashboard_Left_Pannel user={user} certificates={certificates} userInfo={userInfo} courses={courses} companyMembershipInfo={companyMembershipInfo} page={page} />
        </div>
    );
}



export default Dashboard

