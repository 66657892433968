import React, { useState, useEffect } from 'react';
import { getFirestore, updateDoc, doc, getDocs, query, collection, where, limit, orderBy } from "firebase/firestore";

function IDCA_Reward_Claims() {

    const [Rewards, setRewards] = useState([]);
    const db = getFirestore();

    useEffect(() => {
        getRewardClaims()
    }, [])

    async function getRewardClaims() {
        setRewards([])
        const q = query(collection(db, "Rewards Claimed"), orderBy("date", "desc"));
        const querySnapshot = await getDocs(q);
        var temp = []
        querySnapshot.forEach((doc) => {
            temp.push(doc.data())
        });
        setRewards(temp)
    }
    async function grantTheReward(id) {
        if (window.confirm("Are you sure?") == true) {
            const rewardRef = doc(db, "Rewards Claimed", `${id}`);
            await updateDoc(rewardRef, {
                claimed: true
            });
            getRewardClaims()
        }

    }
    return (
        <div className="Dashboard_Right_Pannel_whole">
            <h1 className='Saira_Regular_Black' style={{ textAlign: "center", paddingTop: '20px' }}>Reward Claims</h1>
            <div style={{ "display": "flex", "flexDirection": "column", "alignItems": "center" }}>

                <table className='memberMileageTable' style={{ "width": "90%", "textAlign": "center" }}>
                    <thead>
                        <tr className='memberMileageTableRow'>
                            <th className='memberMileageTableHeader'>
                                Date
                            </th>
                            <th className='memberMileageTableHeader'>
                                User
                            </th>
                            <th className='memberMileageTableHeader'>
                                Reward
                            </th>
                            <th className='memberMileageTableHeader'>
                                Status
                            </th>
                            <th className='memberMileageTableHeader'>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Rewards?.map((wp, i) => (
                                <tr className='memberMileageTableRow' key={i}>

                                    <td className="Saira_Regular_Black memberMileageTableData" id={'WhitePaperAdminSingleWhitepaperTitle' + wp.title}>
                                        {new Date(wp.date).toDateString()}
                                    </td>

                                    <td className="Saira_Regular_Black memberMileageTableData" id={'WhitePaperAdminSingleWhitepaperTitle' + wp.title}>
                                        <button
                                            className='Saira_Regular_Black'
                                            onClick={() => { alert(`Email: ${wp.email}\nPhone: ${wp.phone}`) }}
                                            style={{ "cursor": "pointer" }}
                                        >

                                            {wp.displayName}
                                        </button>
                                    </td>

                                    <td className="Saira_Regular_Black memberMileageTableData" id={'WhitePaperAdminSingleWhitepaperWriter' + wp.title}>

                                        {wp.award_title}
                                    </td>

                                    <td className="Saira_Regular_Black memberMileageTableData" id={'WhitePaperAdminSingleWhitepaperWriter' + wp.title}>
                                        {wp.claimed ? "Granted" : "Pending"}
                                    </td>
                                    {
                                        wp.claimed ? <td className='WhitePaperAdminSingleWhitepaperButtons'> -------- </td> : <td className='WhitePaperAdminSingleWhitepaperButtons'>
                                            <button className="WhitePaperAdminSingleDeleteButton" onClick={() => { grantTheReward(wp.date) }}>
                                                Grant
                                            </button>
                                        </td>
                                    }
                                </tr>

                            ))
                        }
                    </tbody>

                </table>
            </div>
        </div >
    );
}


export default IDCA_Reward_Claims

