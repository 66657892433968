import React, { useState, useEffect } from 'react';
import { getFirestore, limit, getDocs, query, collection, where, orderBy, deleteDoc, doc } from "firebase/firestore";
import { BsFillTrashFill } from "react-icons/bs"
import { clientSanity } from '../../../Components/Sanity/client';
import { getAuth } from "firebase/auth";
import axios from 'axios';


function IDCA_Admin() {
    const api_url = process.env.REACT_APP_FIREBASE_FUNCTION_URL_PRODUCTION

    const [events, setEvents] = useState([])
    const db = getFirestore()

    const now = new Date();
    const startDateInitial = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    const [startDate, setStartDate] = useState(startDateInitial)
    const [endDate, setEndDate] = useState()
    const [hasInPerson, setHasInPerson] = useState(false)
    const [onlyVirtual, setOnlyVirtual] = useState(false)
    const [sortByAttendeeNum, setSortByAttendeeNum] = useState(false)

    const [pageNum, setPageNum] = useState(1)
    const [userInfo, setuserInfo] = useState(null);


    useEffect(() => {
        function_use_effect()
    }, [])

    async function function_use_effect() {
        const eventRef = collection(db, "Event");
        // Create a query against the collection.
        const q = query(eventRef, where("type", "==", 'Training'), where("startDateTime", ">=", startDateInitial.toISOString()), limit(20));
        var eventTemp = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            eventTemp.push(doc.data())
        })
        setEvents(eventTemp)
        getAuth().onAuthStateChanged(async user => {
            if (user) {
                const db = getFirestore();
                const qUser = query(collection(db, "Users"), where("uid", "==", user.uid));
                const querySnapshot = await getDocs(qUser)

                querySnapshot.forEach(async (data) => {
                    setuserInfo(data.data())
                })
            }
        })
    }

    useEffect(() => {
        getEvents()
    }, [startDate, endDate, hasInPerson, onlyVirtual, sortByAttendeeNum, pageNum])

    async function getEvents() {
        const eventRef = collection(db, "Event");
        var q;
        // Create a query against the collection.
        if (startDate && endDate && (hasInPerson || onlyVirtual) && sortByAttendeeNum) {
            const startDateTemp = new Date(startDate)
            const endDateTemp = new Date(endDate)
            if (hasInPerson)
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", startDateTemp.toISOString()),
                    where("startDateTime", "<=", endDateTemp.toISOString()),
                    where("hasInPersonAttendee", '==', true),
                    orderBy('startDateTime'), orderBy("attendees"));
            else
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", startDateTemp.toISOString()),
                    where("startDateTime", "<=", endDateTemp.toISOString()),
                    where("hasInPersonAttendee", '==', false),
                    orderBy('startDateTime'), orderBy("attendees"));
        } else if (startDate && endDate && (hasInPerson || onlyVirtual)) {
            const startDateTemp = new Date(startDate)
            const endDateTemp = new Date(endDate)
            if (hasInPerson)
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", startDateTemp.toISOString()),
                    where("startDateTime", "<=", endDateTemp.toISOString()),
                    where("hasInPersonAttendee", '==', true),
                    limit(20 * pageNum));
            else
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", startDateTemp.toISOString()),
                    where("startDateTime", "<=", endDateTemp.toISOString()),
                    where("hasInPersonAttendee", '==', false),
                    limit(20 * pageNum));
        } else if (startDate && endDate && sortByAttendeeNum) {
            const startDateTemp = new Date(startDate)
            const endDateTemp = new Date(endDate)
            q = query(eventRef, where("type", "==", 'Training'),
                where("startDateTime", ">=", startDateTemp.toISOString()),
                where("startDateTime", "<=", endDateTemp.toISOString()),
                orderBy('startDateTime'), orderBy("attendees"));
        } else if (startDate && (hasInPerson || onlyVirtual) && sortByAttendeeNum) {
            const startDateTemp = new Date(startDate)
            if (hasInPerson)
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", startDateTemp.toISOString()),
                    where("hasInPersonAttendee", '==', true),
                    orderBy('startDateTime'), orderBy("attendees"));
            else
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", startDateTemp.toISOString()),
                    where("hasInPersonAttendee", '==', false),
                    orderBy('startDateTime'), orderBy("attendees"));
        } else if (endDate && (hasInPerson || onlyVirtual) && sortByAttendeeNum) {
            const endDateTemp = new Date(endDate)
            if (hasInPerson)
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", new Date().toISOString()),
                    where("startDateTime", "<=", endDateTemp.toISOString()),
                    where("hasInPersonAttendee", '==', true),
                    orderBy('startDateTime'), orderBy("attendees"));
            else
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", new Date().toISOString()),
                    where("startDateTime", "<=", endDateTemp.toISOString()),
                    where("hasInPersonAttendee", '==', false),
                    orderBy('startDateTime'), orderBy("attendees"));
        } else if (startDate && endDate) {
            const startDateTemp = new Date(startDate)
            const endDateTemp = new Date(endDate)
            q = query(eventRef, where("type", "==", 'Training'),
                where("startDateTime", ">=", startDateTemp.toISOString()),
                where("startDateTime", "<=", endDateTemp.toISOString()),
                limit(20 * pageNum));
        } else if (startDate && (hasInPerson || onlyVirtual)) {
            const startDateTemp = new Date(startDate)
            if (hasInPerson)
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", startDateTemp.toISOString()),
                    where("hasInPersonAttendee", '==', true),
                    limit(20 * pageNum));
            else
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", startDateTemp.toISOString()),
                    where("hasInPersonAttendee", '==', false),
                    limit(20 * pageNum));
        } else if (startDate && sortByAttendeeNum) {
            const startDateTemp = new Date(startDate)
            q = query(eventRef, where("type", "==", 'Training'),
                where("startDateTime", ">=", startDateTemp.toISOString()),
                orderBy('startDateTime'), orderBy("attendees"));
        } else if (endDate && (hasInPerson || onlyVirtual)) {
            const endDateTemp = new Date(endDate)
            if (hasInPerson)
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", new Date().toISOString()),
                    where("startDateTime", "<=", endDateTemp.toISOString()),
                    where("hasInPersonAttendee", '==', true),
                    limit(20 * pageNum));
            else
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", new Date().toISOString()),
                    where("startDateTime", "<=", endDateTemp.toISOString()),
                    where("hasInPersonAttendee", '==', false),
                    limit(20 * pageNum));
        } else if (endDate && sortByAttendeeNum) {
            const endDateTemp = new Date(endDate)
            q = query(eventRef, where("type", "==", 'Training'),
                where("startDateTime", ">=", new Date().toISOString()),
                where("startDateTime", "<=", endDateTemp.toISOString()),
                orderBy('startDateTime'), orderBy("attendees"));
        } else if ((hasInPerson || onlyVirtual) && sortByAttendeeNum) {
            if (hasInPerson)
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", new Date().toISOString()),
                    where("hasInPersonAttendee", '==', true),
                    orderBy('startDateTime'), orderBy("attendees"));
            else
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", new Date().toISOString()),
                    where("hasInPersonAttendee", '==', false),
                    orderBy('startDateTime'), orderBy("attendees"));
        } else if (sortByAttendeeNum) {
            q = query(eventRef, where("type", "==", 'Training'),
                where("startDateTime", ">=", new Date().toISOString()),
                orderBy('startDateTime'), orderBy("attendees"));
        } else if (hasInPerson || onlyVirtual) {
            if (hasInPerson)
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", new Date().toISOString()),
                    where("hasInPersonAttendee", '==', true),
                    limit(20 * pageNum));
            else
                q = query(eventRef, where("type", "==", 'Training'),
                    where("startDateTime", ">=", new Date().toISOString()),
                    where("hasInPersonAttendee", '==', false),
                    limit(20 * pageNum));
        } else if (startDate) {
            const startDateTemp = new Date(startDate)
            q = query(eventRef, where("type", "==", 'Training'),
                where("startDateTime", ">=", startDateTemp.toISOString()),
                limit(20 * pageNum));
        } else if (endDate) {
            const endDateTemp = new Date(endDate)
            q = query(eventRef, where("type", "==", 'Training'),
                where("startDateTime", ">=", new Date().toISOString()),
                where("startDateTime", "<=", endDateTemp.toISOString()),
                limit(20 * pageNum));
        } else {
            q = query(eventRef, where("type", "==", 'Training'), where("startDateTime", ">=", new Date().toISOString()), limit(20 * pageNum));
        }

        var eventTemp = []
        const querySnapshot = await getDocs(q);
        var index = 0;
        querySnapshot.forEach((doc) => {
            if (!sortByAttendeeNum || (sortByAttendeeNum && doc.data().attendees && doc.data().attendees.length != 0)) {
                if (index >= 20 * pageNum - 20) {
                    eventTemp.push(doc.data())
                }
                index++;
            }
        })
        setEvents(eventTemp)
    }

    function returnDate(date) {
        const dateTemp = new Date(date)
        return dateTemp.toDateString()
    }

    async function deleteEvent(id, event) {
        if (window.confirm('Are you sure you want to delete this event?')) {
            if (event?.attendees == null || event?.attendees.length == 0) {
                // Delete from Sanity
                clientSanity
                    .delete(`${id}`)
                    .then(async () => {


                        // Delete Zoom Meeting
                        if (event.zoomMeetingID) {
                            const res = await axios.post(api_url + '/zooom_auth_token', {}, {
                                headers: {
                                    Authorization: 'Basic VWVHd0ZGSDhSWXlSb1ZING11R2hUdzozWWhZaTc2NXZmYW9JRlpjWngxeFl1bkZaU0U2TFpVZA==',
                                    Host: 'zoom.us'
                                }
                            })
                            const zoom_access_token = res?.data?.access_token ? res?.data?.access_token : null

                            const userData = {
                                zoom_access_token: zoom_access_token,
                                meeting_id: event.zoomMeetingID
                            }

                            try {
                                // This is the API on Next JS
                                await axios.post('/api/delete_zoom_meeting', userData)
                                    .then((res) => {
                                        console.log(res)
                                        if (res.data.errorCode)
                                            alert("Delete Zoom Failed: Was not able to remove the zoom meeting associated to the event.")
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    });
                            } catch (error) {
                                console.log(error)
                            }
                        }

                        // Delete From Database
                        await deleteDoc(doc(db, "Event", id))
                        getEvents()
                    })
                    .catch((err) => {
                        alert("Delete Failed: Please contact IDCA support!")
                    })
            } else {
                alert("Delete Failed: Please remove the attendees first.")
            }
        } else {
            // Do nothing!
        }

    }

    return (
        <div className="Dashboard_Right_Pannel_whole">
            <div className='admin_event_list_individual_course_top_div'>
                <div className='admin_event_list_filter_top_div Saira_Medium_DGray'>
                    <div className=''>
                        <h3>Select Date Range</h3>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <input id='admin_event_list_filter_start_date_input_id' type='date' onChange={e => setStartDate(e.target.value)} />
                            <p>to</p>
                            <input id='admin_event_list_filter_end_date_input_id' type='date' onChange={e => setEndDate(e.target.value)} />
                        </div>
                    </div>
                    <div>
                        <h3>Attendee Type</h3>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <div>
                                Has In Person<input id='admin_event_list_filter_in_person_checkbox_id' type='checkbox' onClick={() => {
                                    setHasInPerson(!hasInPerson);
                                    setOnlyVirtual(false);
                                    document.getElementById('admin_event_list_filter_virtual_checkbox_id').checked = false
                                }} />
                            </div>
                            <div>
                                Only Virtual<input id='admin_event_list_filter_virtual_checkbox_id' type='checkbox' onClick={() => {
                                    setOnlyVirtual(!onlyVirtual);
                                    setHasInPerson(false);
                                    document.getElementById('admin_event_list_filter_in_person_checkbox_id').checked = false
                                }} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3>Courses With Attendees</h3><input id='admin_event_list_filter_num_attendees_checkbox_id' type='checkbox' onClick={() => setSortByAttendeeNum(!sortByAttendeeNum)} />
                    </div>
                </div>
                <div className='Saira_Medium_Gray' style={{ display: 'flex', alignItems: 'center', gap: '10px', margin: '0px', position: 'relative', left: '80%' }}>
                    <p style={{ cursor: 'pointer' }} onClick={() => { if (pageNum > 1) setPageNum(pageNum - 1) }}>{'< '}back</p>
                    <p style={{ textDecoration: 'underline' }}>{pageNum}</p>
                    <p style={{ cursor: 'pointer' }} onClick={() => { if (events.length >= 20) setPageNum(pageNum + 1) }}>next{' >'}</p>
                </div>
                <div className='admin_event_list_outer_div Saira_Bold_Black'>
                    <div className='admin_event_list_individual_course_div'>
                        <h4>Event Title</h4>
                        <p>Start Date</p>
                        <p>Number of Attendees</p>
                        <p>Location</p>
                        <p>Virtual</p>
                        <p>Has In Person Attendees</p>
                    </div>
                    <p>Delete</p>
                </div>

                {events.map((event, i) => (

                    <div key={i} className={'admin_event_list_outer_div Saira_Medium_Gray admin_event_list_individual_course_div_' + i % 2}
                        style={{
                            backgroundColor: (event.titleAbbrev == 'DCIE' || event.titleAbbrev == 'DCOM') && '#6770a279'
                        }}>
                        <a href={'/admin/events/course?id=' + event._id} style={{ textDecoration: 'none' }}>
                            <div className='admin_event_list_individual_course_div Saira_Medium_Gray'>
                                <h4>{event.titleAbbrev}</h4>
                                <p>{returnDate(event.startDateTime)}</p>
                                <p>{event.attendees?.length ? event.attendees.length : ''}</p>
                                <p>{event.location}</p>
                                <p>{event.virtual ? 'True' : 'False'}</p>
                                <p>{event.hasInPersonAttendee ? 'Yes' : 'No'}</p>
                            </div>
                        </a>
                        {
                            userInfo?.idcaRole?.includes("superAdmin") || (userInfo?.idcaRole?.includes("admin") && userInfo?.idcaRole?.includes("sales")) ?
                                <button
                                    onClick={() => deleteEvent(event._id, event)}
                                    className='Saira_Medium_Red admin_event_list_delete_button'>
                                    <BsFillTrashFill />
                                </button>
                                :
                                <div></div>
                            // <button
                            //     disabled
                            //     className='Saira_Medium_Gray admin_event_list_delete_button'>
                            //     <BsFillTrashFill />
                            // </button>
                        }
                    </div>

                ))
                }
            </div >
        </div >
    );
}


export default IDCA_Admin

