import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";

export default async function AddMileageToUser(uid, title, type) {

    const db = getFirestore();

    // Add user points
    const userDocRef = doc(db, "Users", uid);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
        var downloadedBefore = false
        if (userDocSnap.data().mileageHistory) {
            userDocSnap.data().mileageHistory.map((history, i) => {
                if (history.pointsName == title) {
                    downloadedBefore = true
                }
            })
        }

        if (!downloadedBefore) {
            const newMileagePointAdd = 50

            const memberPointDetail = {
                pointsName: title,
                type: type,
                points: newMileagePointAdd,
                date: new Date().toISOString()
            }
            const newMileageHistory = userDocSnap.data().mileageHistory ?
                [...userDocSnap.data().mileageHistory, memberPointDetail] : [memberPointDetail]

            console.log(newMileageHistory)

            await updateDoc(userDocRef, {
                mileageScore: userDocSnap.data().mileageScore ? userDocSnap.data().mileageScore + newMileagePointAdd : newMileagePointAdd,
                mileageHistory: newMileageHistory
            });
        }
    } else {
        console.log("No such document!");
    }
}
