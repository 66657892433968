import React, { useEffect, useState } from "react";
import AdminCourses from "../../../components/Pages/AdminCourseList/AdminCourseList.js";
import MemberNavbar from "../../../components/Pages/MemberArea/Navbar/Navbar.js";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Helmet } from "react-helmet"

export default function AdminEventIndiv() {
    const queryParameters = new URLSearchParams(window.location.search)
    const id = queryParameters.get("id")

    const [userInfo, setUserInfo] = useState()

    useEffect(() => {
        const db = getFirestore();
        getAuth().onAuthStateChanged(async user => {
            if (user) {
                // get user info
                const docRef = doc(db, "Users", user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    if (
                        docSnap.data().idcaRole?.includes("membership-admin") ||
                        docSnap.data().idcaRole?.includes("sales") ||
                        docSnap.data().idcaRole?.includes("admin") ||
                        docSnap.data().idcaRole?.includes("member") ||
                        docSnap.data().idcaRole?.includes("corporate-member-admin")) {
                        setUserInfo(docSnap.data())
                    } else {
                        window.location.href = "/";
                    }
                } else {
                    // doc.data() will be undefined in this case
                    window.location.href = "/";
                }
            } else {
                window.location.href = "/login";
            }
        })
    }, [])

    return (
        <div>
            <Helmet>
                <title>Admin Event</title>
            </Helmet>
            <link rel="icon" href="/favicon.png" />
            <link rel="apple-touch-icon" href="/idca-logo-with-bg.jpg" />

            <nav>
                {userInfo &&
                    <MemberNavbar user={userInfo} page='admin event' />
                }
            </nav>

            <main>
                <AdminCourses id={id} />
            </main>
        </div >
    )
}